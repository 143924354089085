import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ActiveActivityState } from "../state/active-activity.state";

export const selectActiveActivityState = createFeatureSelector<ActiveActivityState>('activeactivities');

export const selectActiveActivitiesWithPagination = createSelector(
    selectActiveActivityState,
  (state: ActiveActivityState) => state.activeactivities
);

export const selectActiveActivityLoading = createSelector(
    selectActiveActivityState,
  (state: ActiveActivityState) => state.loading
);

export const selectActiveActivityLoaded = createSelector(
    selectActiveActivityState,
    (state: ActiveActivityState) => state.loaded
  );

export const selectActiveActivityError = createSelector(
    selectActiveActivityState,
  (state: ActiveActivityState) => state.error
);