      <div 
      class="carousel carouselContainer slide" 
      id="diaCarousel"
      data-ride="carousel">
          <!-- slides -->
          <div 
            class="carousel-inner" 
            role="listbox"
            *ngIf="imageUrl">
            <ng-container  *ngFor="let x of (imageUrl); let i = index"  >
                <div
                [class.active]="i === currentIndex"
                        class="carousel-item"
                         >
                    <img 
                    class="image" 
                    [src]= "rtImagePath + x" 
                    alt="Image">
                </div>
            </ng-container>
        
          </div>
          <!-- previous and next controls -->
          <button 
          class="carousel-control-prev" 
          type="button" 
          data-bs-target="#diaCarousel" 
          data-bs-slide="prev"
          *ngIf="imageUrl.length > 1">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
          </button>
          <button 
          class="carousel-control-next" 
          type="button" 
          data-bs-target="#diaCarousel"
          data-bs-slide="next"
          *ngIf="imageUrl.length > 1">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
          </button>
      </div>