import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor() { }

  payWithPaystack(email: string, amount: number, reference: string): Observable<any> 
  {
    console.log(`email: ${email}, amount: ${amount} and ref: ${reference}`);

    return new Observable(observer => {
      const handler = (window as any).PaystackPop.setup({
        key: environment.PaystackPublickKey, // paystack public key
        email: email,
        amount: amount * 100, // the amount value is multiplied by 100 to convert to the lowest currency unit
        currency: environment.SACurrency, // Use GHS for Ghana Cedis or USD for US Dollars
        ref: reference, // Replace with a reference you generated
        callback: (response: any) => {
          // This happens after the payment is completed successfully
          // const paymentReference = response.reference;
          // alert('Payment complete! Reference: ' + paymentReference);
          // Make an AJAX call to your server with the reference to verify the transaction
          observer.next(response);
          observer.complete();
        },
        onClose: () => {
          // alert('Transaction was not completed, window closed.');
          observer.error('Transaction was not completed, window closed.');
        },
      });
      handler.openIframe();
    })

   
  }

  payWithPaystackSuccessMockLocal(email: string, amount: number, reference: string): Observable<any> 
  {
    console.log(`MOCK: email: ${email}, amount: ${amount} and ref: ${reference}`);

    return new Observable(observer => {
      observer.next('successs');
      observer.complete();

      // const handler = (window as any).PaystackPop.setup({
      //   key: environment.PaystackPublickKey, // paystack public key
      //   email: email,
      //   amount: amount * 100, // the amount value is multiplied by 100 to convert to the lowest currency unit
      //   currency: environment.SACurrency, // Use GHS for Ghana Cedis or USD for US Dollars
      //   ref: reference, // Replace with a reference you generated
      //   callback: (response: any) => {
      //     // This happens after the payment is completed successfully
      //     // const paymentReference = response.reference;
      //     // alert('Payment complete! Reference: ' + paymentReference);
      //     // Make an AJAX call to your server with the reference to verify the transaction
      //     observer.next(response);
      //     observer.complete();
      //   },
      //   onClose: () => {
      //     // alert('Transaction was not completed, window closed.');
      //     observer.error('Transaction was not completed, window closed.');
      //   },
      // });
      // handler.openIframe();
    })

   
  }
  
}
