import { createReducer, on } from "@ngrx/store";
import { IPLocationState } from "../state/ipLocation.state";
import { GET_IP_LOCATION, GET_IP_LOCATION_FAIL, GET_IP_LOCATION_SUCCESS } from "../actions/ipLocation.actions";

const initialState: IPLocationState = {
    status: '',
    country: '',
    countryCode: '',
    region: '',
    regionName: '',
    city: '',
    zip: '',
    lat: 0,
    lon: 0,
    timezone: '',
    isp: '',
    org: '',
    as: '',
    query: '',
    loading: false,
    loaded: false,
    error: null,
};

const _ipLocationReducer = createReducer(
    initialState,
    on(GET_IP_LOCATION, state => ({ ...state, loading: true })),
    on(GET_IP_LOCATION_SUCCESS, (state, { ipLocation }) => ({
        ...state,
        ...ipLocation,
        loading: false,
        loaded: true,
        error: null,
    })),
    on(GET_IP_LOCATION_FAIL, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: true,
        error,
    }))
);

export function ipLocationReducer(state: IPLocationState | undefined, action: any) : IPLocationState {
    return _ipLocationReducer(state, action);
  }