<div 
class="container alC">
    <div>
      <button 
        class="btn"
        (click)="goToProfile()"
        style="float: left; color: rgb(39, 166, 230); margin: 10px 0px 0px 0px;">
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
          <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
          <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
        </svg>
        </button>

        <button 
        class="btn"
        (click)="closeSideBar()"
        style="float: right; color: red; margin: 10px 0px 0px 0px;">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
            </svg>
        </button>
        <br><br>
        <hr>
    </div>
    
    

    <div>
        <div class="container">
            <div class="row">
              <div >
                <ul class="nav nav-pills flex-column" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a 
                        class="nav-link btn" 
                        (click)="goToDash()"  
                        data-toggle="tab" 
                        role="tab" 
                        aria-controls="home" 
                        aria-selected="true">
                        Dashboard
                    </a>
                  </li>
                  <li class="nav-item">
                    <a 
                        class="nav-link btn"
                        (click)="goToActivities()" 
                        data-toggle="tab" 
                        role="tab" 
                        aria-controls="profile" 
                        aria-selected="false">
                        Activities
                    </a>
                  </li>
                 
                  <li class="nav-item">
                    <a 
                        class="nav-link btn"
                        (click)="goToEvents()" 
                        data-toggle="tab" 
                        role="tab" 
                        aria-controls="profile" 
                        aria-selected="false">
                        Events
                    </a>
                  </li>

                  <li class="nav-item">
                    <a 
                        class="nav-link btn"
                        (click)="goToComps()" 
                        data-toggle="tab" 
                        role="tab" 
                        aria-controls="profile" 
                        aria-selected="false">
                        Competitions
                    </a>
                  </li>

                  <li class="nav-item">
                    <a 
                        class="nav-link btn"
                        (click)="goToClubs()" 
                        data-toggle="tab" 
                        role="tab" 
                        aria-controls="profile" 
                        aria-selected="false">
                        Clubs
                    </a>
                  </li>

                  <li class="nav-item">
                    <a 
                        class="nav-link btn"
                        (click)="goToProfile()" 
                        data-toggle="tab" 
                        role="tab" 
                        aria-controls="profile" 
                        aria-selected="false">
                        Profile - X
                    </a>
                  </li>

                  <!-- This selection will not be visible by default, only post login -->
                  <li>
                    <div class="dropdown">
                      <button 
                      class="btn btn-secondary dropdown-toggle" 
                      type="button" 
                      id="dropdownMenuButton2" 
                      data-bs-toggle="dropdown" 
                      aria-expanded="false">
                        Admin
                      </button>
                      <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton2">
                        <li>
                          <a 
                          class="dropdown-item active" 
                          (click)="goToActMan()">
                            Activities Manager
                          </a>
                        </li>

                        <li>
                          <a class="dropdown-item" href="#">
                            Events Manager
                          </a>
                        </li>

                        <li>
                          <a class="dropdown-item" href="#">
                            Competitions Manager
                          </a>
                        </li>

                        <li>
                          <a 
                          class="dropdown-item" 
                          (click)="goToAddrMan()">
                            Address Manager
                          </a>
                        </li>

                        <li><hr class="dropdown-divider"></li>

                        <li>
                          <a 
                          class="dropdown-item" 
                          (click)="goToUtil()">
                            Utils
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- <a 
                        class="nav-link btn"
                        (click)="goToProfile()" 
                        data-toggle="tab" 
                        role="tab" 
                        aria-controls="profile" 
                        aria-selected="false">
                        Admin
                    </a> -->
                  </li>
                </ul>
              </div>
          
            </div>
          
          </div>
          <!-- /.container -->
    </div>

</div>
