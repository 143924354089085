<div class="container">
    <ng-template #weekViewHourSegmentTemplate 
       let-segment="segment" vent 
       let-locale="locale" 
       let-segmentHeight="segmentHeight" 
       let-isTimeLabel="isTimeLabel"
     >
      <div #segmentElement 
         class="cal-hour-segment" 
         [style.height.px]="segmentHeight"   
         [class.cal-hour-start]="segment.isStart"
         [class.cal-after-hour-start]="!segment.isStart" 
         [ngClass]="segment.cssClass"
         (mousedown)="startDragToCreate(segment, $event, segmentElement)">
          <div class="cal-time" *ngIf="isTimeLabel">
              {{ segment.date | calendarDate:'weekViewHour':locale }}
          </div>
      </div>
  </ng-template>
  </div>
  
  <mwl-calendar-week-view 
      [viewDate]="viewDate" [events]="events"
      [hourSegmentTemplate]="weekViewHourSegmentTemplate" 
      [weekStartsOn]="weekStartsOn">
  </mwl-calendar-week-view>
  
  <hr>
  <div>Selected Slots</div>
  <pre>{{ slots | json }}</pre>