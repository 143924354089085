import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PragmaInterceptorService implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Exclude 'Pragma' header from the request
    const updatedRequest = request.clone({
      headers: request.headers.delete('Pragma')
    });

    return next.handle(updatedRequest);
  }
}
