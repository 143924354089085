import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { EdtiActivityDialogComponent } from 'src/app/modules/activity/edti-activity-dialog/edti-activity-dialog.component';
import { LocationDialogComponent } from 'src/app/modules/activity/location-dialog/location-dialog.component';
import { AddMediaDialogComponent } from 'src/app/modules/activity/add-media-dialog/add-media-dialog.component';
// import { EditAdminDialogComponent } from 'src/app/modules/activity/edit-admin-dialog/edit-admin-dialog.component';
import { ActivityBookingDialogComponent } from 'src/app/modules/activity/activity-booking-dialog/activity-booking-dialog.component';
import { AddRoleComponent } from 'src/app/modules/utils/add-role/add-role.component';
import { AddUserComponent } from 'src/app/modules/utils/add-user/add-user.component';
import { environment } from 'src/environments/environment';
import { SetEntityAddressComponent } from 'src/app/modules/activity/set-entity-address/set-entity-address.component';
import { EventBookingDialogComponent } from 'src/app/modules/event/event-booking-dialog/event-booking-dialog.component';
import { EnlargeImgComponent } from 'src/app/Templates/enlarge-img/enlarge-img.component';
import { EditEventDialogComponent } from 'src/app/modules/event/edit-event-dialog/edit-event-dialog.component';
import { ShareboxComponent } from 'src/app/Templates/sharebox/sharebox.component';
import { ListComponent } from 'src/app/Templates/bank-details/list/list.component';
import { AddComponent } from 'src/app/Templates/bank-details/add/add.component';
import { AddUserRoleComponent } from 'src/app/modules/utils/add-user-role/add-user-role.component';
import { GenericUser } from 'src/app/types/models/genericuser.model';
import { AddSubscriptionTierComponent } from 'src/app/modules/utils/add-subscription-tier/add-subscription-tier.component';


@Injectable({
  providedIn: 'root'
})
export class DialogService {

  // private imgDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>('initial value');
  private imgDataSubject: any = "nothing"

  constructor(private dialog: MatDialog
            ) { }

  getImageData(): any {
    return this.imgDataSubject;
  }

  updateImageData(newValue: any): void {
    this.imgDataSubject = newValue;
  }

  openConfirmDialog()
  {
    this.dialog.open(AddMediaDialogComponent, {
      width: 'auto',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      // data: {
      //   message: msg
      // } 
    });
  }

  //This modal might not be necessary....
  NewImageUploadsDialog(imageDataSet: any) : any
  {

    let dRef = this.dialog.open(AddMediaDialogComponent, {
      minWidth: '400px',
      maxWidth: '680px',
      minHeight: '220px',
      maxHeight: '700px',
      disableClose: true,
      data: imageDataSet
    })

    // get the image(s) selected (if any) and pass it to the closer
    dRef.afterClosed().subscribe((result: any) => {
      if(!environment.production)
      {
        console.log(`Dialog Result from close media add dialog: ${JSON.stringify(result)}`);
      }
      this.updateImageData(result);
    });

    return dRef;
  }

  ShowLocationDialog(selectedLoci: any)
  {
    let dRef = this.dialog.open(LocationDialogComponent, {
      minWidth: '400px',
      maxWidth: '680px',
      minHeight: '220px',
      maxHeight: '700px',
      disableClose: true,
      data: selectedLoci
    })
  }

  ShowImgDialog(imgUrls: string[],index:number = 0): void        
  {
    let dRef = this.dialog.open(EnlargeImgComponent, {
      minWidth: '400px',
      maxWidth: '100%',
      minHeight: '220px',
      // maxHeight: 'auto',
      disableClose: false,
      hasBackdrop: true ,
      data: {images: imgUrls, index}
    })
    dRef.afterClosed().subscribe(()=>{
      imgUrls = []
    })
  }

  ShowShareDialog(currentRoute : any){
    let dRef = this.dialog.open(ShareboxComponent, {
      minWidth: '400px',
     maxWidth: '100%',
      minHeight: '220px',
      maxHeight: 'auto',
      disableClose: false,
      hasBackdrop: true ,
      data: {route: currentRoute}
    })
  }

  SetAddressDialog(selectedEntity: any)
  {
    let dRef = this.dialog.open(SetEntityAddressComponent, {
      minWidth: '400px',
      maxWidth: '680px',
      minHeight: '220px',
      maxHeight: '700px',
      disableClose: true,
      data: selectedEntity
    })

    // get the image(s) selected (if any) and pass it to the closer
    dRef.afterClosed().subscribe((result: any) => {
      console.log(`Set address result: ${JSON.stringify(result)}`);
    });

    return dRef;
  }


  EditSelectedActivityDialog(selectedActivity: any)
  {
    let dRef = this.dialog.open(EdtiActivityDialogComponent, {
      minWidth: 'auto',
      maxWidth: 'auto',
      minHeight: 'auto',
      maxHeight: 'auto',
      disableClose: true,
      data: selectedActivity
    });

     // get the image(s) selected (if any) and pass it to the closer
     dRef.afterClosed().subscribe((result: any) => {
      console.log(`Dialog Result in dialog service -> EditSelectedActivityDialog: ${JSON.stringify(result)}`);
      // window.location.reload();
    });

    return dRef;
  }

  
  EditSelectedEventDialog(selectedEvent: any)
  {
    let dRef = this.dialog.open(EditEventDialogComponent, {
      minWidth: 'auto',
      maxWidth: 'auto',
      minHeight: 'auto',
      maxHeight: 'auto',
      disableClose: true,
      data: selectedEvent
    });

     // get the image(s) selected (if any) and pass it to the closer
     dRef.afterClosed().subscribe((result: any) => {
      console.log(`Dialog Result in dialog service -> EditSelectedActivityDialog: ${JSON.stringify(result)}`);
      // window.location.reload();
    });

    return dRef;
  }

  // EditAdminDialog(selectedAdmin: any)
  // {
  //   let dRef = this.dialog.open(EditAdminDialogComponent, {
  //     minWidth: 'auto',
  //     maxWidth: 'auto',
  //     minHeight: 'auto',
  //     maxHeight: 'auto',
  //     disableClose: true,
  //     data: selectedAdmin
  //   });

  //   // get the image(s) selected (if any) and pass it to the closer
  //   //  dRef.afterClosed().subscribe((result: any) => {
  //   //   console.log(`Dialog Result: ${JSON.stringify(result)}`);
  //   //   window.location.reload();
  //   // });

  //   // return dRef;
  // }
  
  openAddEntityMediaDialog(entity: any)
  {
    console.log('Data from dialog service: ' + JSON.stringify(entity));

    return this.dialog.open(AddMediaDialogComponent, {
      width: '400px',
      
      disableClose: true,
      data: entity
    })
  }

  private isActivityBookingDialogOpen = false;

  ActivityBookingDialog(activityToBoBooked: any){
    let dRef:any;

     
    if(!environment.production)
    {
      console.log(`In the dialog svc. Sending to activity booking dialog: ${JSON.stringify(activityToBoBooked)}`)
    }
      
    if(!this.isActivityBookingDialogOpen)
    {
      this.isActivityBookingDialogOpen = true;

    
      dRef = this.dialog.open(ActivityBookingDialogComponent, {
        width: 'auto',
        minWidth: '350px',
        maxWidth: '680px',
        minHeight: '220px',
        maxHeight: '700px',
        disableClose: true,
        data: activityToBoBooked
      });

      // get the image(s) selected (if any) and pass it to the closer
      dRef.afterClosed().subscribe((result: any) => {
        console.log(`Dialog Result: ${JSON.stringify(result)}`);
        this.isActivityBookingDialogOpen = false; // Set the flag to indicate the dialog is closed
      });
    }

    return dRef;
  }

  private isEventBookingDialogOpen = false;

  EventBookingDialog(eventToBoReserved: any){
    let dRef:any;

    if(!this.isEventBookingDialogOpen)
    {
      this.isEventBookingDialogOpen = true;

      
      if(!environment.production)
      {
        console.log(`In the dialog svc. Sending to event booking dialog: ${JSON.stringify(eventToBoReserved)}`)
      }
        
      
      dRef = this.dialog.open(EventBookingDialogComponent, {
        width: 'auto',
        minWidth: '350px',
        maxWidth: '680px',
        minHeight: '220px',
        maxHeight: '700px',
        disableClose: true,
        data: eventToBoReserved
      });

      // get the image(s) selected (if any) and pass it to the closer
      dRef.afterClosed().subscribe((result: any) => {
        console.log(`Dialog Result: ${JSON.stringify(result)}`);
        this.isEventBookingDialogOpen = false; // Set the flag to indicate the dialog is closed
      });
    }

    return dRef;
  }

  closeDialog()
  {
    this.dialog.closeAll();
  }

  ShowUserAddDialog(){
    let dRef = this.dialog.open(AddUserComponent, {
      minWidth: '399px',
      maxWidth: '450px',
      height: '650px',
      disableClose: true,
    });

     // get the image(s) selected (if any) and pass it to the closer
     dRef.afterClosed().subscribe((result: any) => {
      console.log(`Dialog Result: ${JSON.stringify(result)}`);
      // window.location.reload();
    });

    return dRef;
  }

  ShowUserRoleAddDialog(selUser: GenericUser, mxpRoles: any){
    let dRef = this.dialog.open(AddUserRoleComponent, {
      minWidth: '399px',
      maxWidth: '450px',
      height: '650px',
      disableClose: true,
      data: {
        user: selUser,
        roles: mxpRoles
      }
    });

     // get the image(s) selected (if any) and pass it to the closer
     dRef.afterClosed().subscribe((result: any) => {
      console.log(`Dialog Result: ${JSON.stringify(result)}`);
      // window.location.reload();
    });

    return dRef;
  }


  ShowRoleAddDialog()
  {
    let dRef = this.dialog.open(AddRoleComponent, {
      minWidth: '399px',
      maxWidth: '450px',
      height: '350px',
      disableClose: true,
    });

     // get the image(s) selected (if any) and pass it to the closer
     dRef.afterClosed().subscribe((result: any) => {
      console.log(`Dialog Result: ${JSON.stringify(result)}`);
      // window.location.reload();
    });

    return dRef;
  }

  ShowSubTierAddDialog()
  {
    let dRef = this.dialog.open(AddSubscriptionTierComponent, {
      minWidth: '399px',
      maxWidth: '450px',
      height: '580px',
      disableClose: true,
    });

     // get the image(s) selected (if any) and pass it to the closer
     dRef.afterClosed().subscribe((result: any) => {
      console.log(`Dialog Result: ${JSON.stringify(result)}`);
      // window.location.reload();
    });

    return dRef;
  }


  
  ShowUserBankAccountsDialog(userId: string)
  {
    // let dRef = this.dialog.open(BankDetailsComponent, {
    let dRef = this.dialog.open(ListComponent, {
      minWidth: 'auto',
      maxWidth: 'auto',
      minHeight: 'auto',
      maxHeight: 'auto',
      disableClose: false,
      data: userId
    });

    dRef.afterClosed().subscribe((result: any) => {
      console.log(`Model showing bank accounts for user: ${userId} closed. Result: ${JSON.stringify(result)}`);
    });

    return dRef;
  }

  AddBankDetailsDialog(userId: string){
    // let dRef = this.dialog.open(BankDetailsComponent, {
    let dRef = this.dialog.open(AddComponent, {
      minWidth: 'auto',
      maxWidth: 'auto',
      minHeight: 'auto',
      maxHeight: 'auto',
      disableClose: false,
      data: userId
    });

    dRef.afterClosed().subscribe((result: any) => {
      console.log(`AddBankDetailsDialog closed. Result: ${JSON.stringify(result)}`);
    });

    return dRef;
  }
}
