import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BankAccountService } from 'src/app/shared/services/claimsRelated/bank-account.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { BankAccount } from 'src/app/types/models/bankAccount.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent {
  userID: string = ''

  bankAccounts: BankAccount[] = [];
  
  constructor(
    public dialogRef: MatDialogRef<ListComponent>,
    private dialogSvc: DialogService,
    private bankaccountService: BankAccountService,
    @Inject(MAT_DIALOG_DATA) public data:any)
  {
    this.userID = data;

    if(!environment.production)
    {
      //log incoming user ID
      console.log(`Incoming user ID: ${JSON.stringify(data)}`);
    }

    this.getAllBankAccountsOfAUser(this.userID);
  }

  getAllBankAccountsOfAUser(userId: string)
  {
    //call API to get all bank account (convert to ngrx later)
    this.bankaccountService.GetAllBankAccountsOfAUserByUserId(this.userID)
    .subscribe((dtResponse: BankAccount[]) => {
      //
      if(!environment.production)
      {
        console.log(`Bank accounts count for user ${this.userID} - ${dtResponse.length}. Data: ${JSON.stringify(dtResponse)}`)
      }
      this.bankAccounts = dtResponse
    })
  }

  // Set a bank account as default
  // setDefault(account: BankAccount) {
  //   this.bankAccounts.forEach(acc => acc.IsDefault = false);
  //   account.IsDefault = true;
  // }

  setDefaultAccount(account: BankAccount)
  {
    account.IsDefault = true;

    this.bankaccountService.UpdateBankAccountOfAUser(account)
    .subscribe((updateAccountResult: any) => {
      if(!environment.production)
      {
        console.log(`update bank account result: ${updateAccountResult}`);
      }

      this.getAllBankAccountsOfAUser(this.userID);
    })
  }

  addNewBankAccountForAUser()
  {
    //close the current dialog > send the response back > open the next modal to add
    this.dialogRef.close("addbankaccount")
  }

  confirmAndClose()
  {
  
    this.dialogRef.close("noactionDefault");
  }
}
