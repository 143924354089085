import { createReducer, on } from "@ngrx/store";
import { FutureEventCreatorsState } from "../state/future-events-creator.state";
import { GET_CREATORS_OF_FUTURE_EVENTS, GET_CREATORS_OF_FUTURE_EVENTS_FAIL, GET_CREATORS_OF_FUTURE_EVENTS_SUCCESS } from "../actions/future-events-creator.actions";

const initialState: FutureEventCreatorsState = {
    futureeventscreators: [],
    loading: false,
    loaded: false,
    error: null,
};


const _maixFutureEventsCreatorsReducer = createReducer(
    initialState,
    on(GET_CREATORS_OF_FUTURE_EVENTS, state => ({ ...state, loading: true })),
    on(GET_CREATORS_OF_FUTURE_EVENTS_SUCCESS, (state, { futureeventscreators }) => ({
        ...state,
        futureeventscreators,
        loading: false,
        loaded: true,
        error: null,
    })),
    on(GET_CREATORS_OF_FUTURE_EVENTS_FAIL, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error,
    }))
);

export function maixFutureEventsCreatorsReducer(state: any, action: any) {
    return _maixFutureEventsCreatorsReducer(state, action);
  }