import { createReducer, on } from "@ngrx/store";
import { LiveMaiXEventState, PastMaiXEventState } from "../state/maixevent-future.state";
import { LOAD_PAST_EVENTS, LOAD_PAST_EVENTS_FAIL, LOAD_PAST_EVENTS_SUCCESS } from "../actions/maixevents.past.actions";

const initialState: PastMaiXEventState = {
    pastMaixevents: [],
    loading: false,
    loaded: false,
    error: null,
};

const _maixPastEventsReducer = createReducer(
    initialState,
    on(LOAD_PAST_EVENTS, (state) => ({ 
        ...state, 
        loading: true,
        loaded: false,
        error: null })),
    on(LOAD_PAST_EVENTS_SUCCESS, (state, { pastMaixevents }) => ({
        ...state,
        pastMaixevents,
        loading: false,
        loaded: true,
        error: null,
    })),
    on(LOAD_PAST_EVENTS_FAIL, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error,
    }))
);

export function maixPastEventsReducer(state: any, action: any) {
    return _maixPastEventsReducer(state, action);
}