import { CommonModule, JsonPipe, NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { GET_ACTIVITIES_BY_USERID } from 'src/app/ngrx/actions/activity.actions';
import { AppState } from 'src/app/ngrx/state/app.state';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ObfuscationServiceService } from 'src/app/shared/services/obfuscation-service.service';
import { Activity } from 'src/app/types/models/activity.model';
import { MaiXEvent } from 'src/app/types/models/maixevent.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-card',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  @Input() entity!: Activity | MaiXEvent;
  @Input() isActivity: boolean = false;
  @Input() isEvent: boolean = false;
  @Input() showButton: boolean = false;
  @Input() showActivityPromoButton: boolean = false;
  isExpanded: boolean = false;

  rtImgPath: string = environment.fileRetriever;
  
  descriptionCutter: number = 50;

  constructor(private router: Router,
    private dialogSvc: DialogService,
    private store: Store<AppState>,
    private obfuscationService: ObfuscationServiceService
  ) {
    
  }

  // Type guard to check if entity is of type Activity
  isActivityTypeGuarded(entity: Activity | MaiXEvent): entity is Activity {
    return (entity as Activity).ActivityPromotionPercentage !== undefined;
  }

  // Type guard to check if entity is of type MaiXEvent
  // isMaiXEventTypeGuarded(entity: Activity | MaiXEvent): entity is MaiXEvent {
  //   return (entity as MaiXEvent).EventPromotionPercentage !== undefined;
  // }

  get displayedDescription(): string {
    if (!this.entity.Description) {
      return '';
    }

    if (this.isExpanded || this.entity.Description.length <= this.descriptionCutter) {
      return this.entity.Description;
    } else {
      return this.entity.Description.substring(0, this.descriptionCutter) //+ '...';
    }
  }

  toggleDescription(event: Event) {
    event.stopPropagation();
    this.isExpanded = !this.isExpanded;
  }

  displayHistory(activityid: number)
  {
    let obfsActivityId = this.obfuscationService.encryptEventId(activityid.toString());
    
    if(!environment.production)
    {
      console.log(`Open history of activity: ${activityid} & obfuscation of activity ID: ${obfsActivityId}`)
    }

    this.router.navigate(['activityHistory', obfsActivityId])
  }

  EditActivityByAdmin(e: any, selActivity: any)
  {
    if(!environment.production)
    {
      console.log(`selected activity for edit: ${JSON.stringify(selActivity)}`);
    } 

    //this prevents the parent routing click action to kickoff 
    e.stopPropagation();
    
    // alert("Edit activity by admin")
    this.dialogSvc.EditSelectedActivityDialog(selActivity)
      .afterClosed().subscribe(_ => {

        if(!environment.production)
        {
          console.log(`Result from edit dialog APS component: ${JSON.stringify(_)}`)

          //respomse will be "noaction" or "refresh" or "noactionDefault"

          if(_.includes("refresh"))
          {
            // window.location.reload();

          }
          else if(_.includes("update"))
          {
            //open the edit of the 
            this.EditActivityByAdmin(e, selActivity)
          }
          else if(_.includes("no update"))
          {
            //open the edit of the 
            this.EditActivityByAdmin(e, selActivity)
          }
          else{

          }

          this.store.dispatch(GET_ACTIVITIES_BY_USERID({userId: this.entity.UserId}))
       
        }
      });
  }

  //get address of the parsed in entity (activity or event)
  
  displayEventHistory(eventid: number)
  {
    let obfsActivityId = this.obfuscationService.encryptEventId(eventid.toString());
    
    if(!environment.production)
    {
      console.log(`Open history of event: ${eventid} & obfuscation of activity ID: ${obfsActivityId}`)
    }

    this.router.navigate(['eventHistory', obfsActivityId])
  }
  

  PromoteActivity(e: any, selActivity: any)
  {

  }
}
