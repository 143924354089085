import { Component, Input } from '@angular/core';
import { Team } from '../team-list/team-list.component';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent {
  @Input() team: Team | undefined;
}
