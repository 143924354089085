import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, switchMap, tap } from 'rxjs';
import { GET_CURRENT_USER } from 'src/app/ngrx/actions/current-user.actions';
import { selectLoggedInUser } from 'src/app/ngrx/selectors/current-user.selectors';
import { AppState } from 'src/app/ngrx/state/app.state';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { UserSpecificService } from 'src/app/shared/services/dataService/user-specific.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { PaymentService } from 'src/app/shared/services/payment.service';
import { LoaderService } from 'src/app/shared/utils/loader.service';
import { GenericUser } from 'src/app/types/models/genericuser.model';
import { SubscriptionTier } from 'src/app/types/models/SubscriptionRelated/SubscriptionTier.model';
import { UserSubscription } from 'src/app/types/models/SubscriptionRelated/UserSubscription.model';
import { environment } from 'src/environments/environment';

import Paystack from '@paystack/inline-js';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent {

  isMonthly:boolean = true
  isAnnually:boolean = false

  // allSubTiers: any
  allSubTiers$ = new BehaviorSubject<any[]>([]);

  usrObject$: Observable<GenericUser | null>
  
  constructor(
    private toastr: ToastrService,
    private router: Router,
    private usrSvc: UserSpecificService,
    private authService: AuthService,
    private paymentService: PaymentService,

    private store: Store<AppState>,
    private loadingService: LoaderService,
    private dialogSvc: DialogService) 
  { 
    this.usrObject$! = this.store.pipe(select(selectLoggedInUser));
  }


  ngOnInit()
  {
    //get subscription tiers
    this.getMaixSubTiers();

    this.usrObject$.pipe(
      // first(), // Take the first value emitted by the observable
      tap(user => {
        if (!user) {
          this.loadingService.show();

          //get the current user to the store
          this.store.dispatch(GET_CURRENT_USER());
  
          this.loadingService.hide()
        }
      }),
      switchMap(() => this.usrObject$) // Listen for new updates from the store
    ).subscribe()
  

 
  }
    
  uniqueTiers: any[] = [];

  getMaixSubTiers()
  {

    const subTierObserver = {
      next: async (response: any) => {
        this.allSubTiers$.next(response);

        // this.allSubTiers = response;
        console.log(`Defined subscription tiers: ${JSON.stringify(response)}`);
      },
      error: async (err: HttpErrorResponse) => {
        console.log(`Error while getting defined subscriptions of maiXp: ${JSON.stringify(err)}`);
       
        //handle token expiry
        if(err.url) //if err response is trying to navigate to another page, then it has expired
        {
          //remove the cookie 
          this.usrSvc.logout()

          this.toastr.error(
            "Session ended. Login", "Error");

          this.router.navigate(['login'],
          {
            queryParams: {
              redirectUrl: 'utils'
            }
          });
        }
    
      },
      complete: async () => {
        console.log(`user http request for subscriptions complete!`);
        // this.router.navigate(['login'])
      }
    }

    //get all roles of MaiX
    this.authService.GetAllSubscriptionsForMaiXp()
    .subscribe(subTierObserver)
  }

  toggleBilling(){
    this.isMonthly = !this.isMonthly
    this.isAnnually = !this.isAnnually

    console.log(`Is mothly: ${this.isMonthly} | Is annal: ${this.isAnnually}`)
  }

  subscibeUserIfLoggedIn(user: GenericUser, tier: SubscriptionTier)
  {
    const email = user.Email
    const amt = tier.Price * 100
    const plan = tier.PSTPlanCode


    if (!user) 
    {
      this.toastr.error('User not logged in', "Authenticate")
      this.router.navigate(['/login']);
    } 
    else 
    {
      //current moment
      let timezone = 'Africa/Johannesburg';
    
      const currentMoment = moment(new Date())
      .tz(timezone)
      .toString()

      //deduced end moment
      const oneMonthFromNow = moment(new Date())
      .tz(timezone)
      .add(1, 'month')
      .toString();

      const oneYearFromNow = moment(new Date())
      .tz(timezone)
      .add(1, 'year')
      .toString();

      
      

      const bCycle = this.isMonthly ? 'monthly' : 'annually'
      const amtPerSelection = this.isMonthly ? amt : (amt * 12)
      const deducedSubEnd = this.isMonthly ? oneMonthFromNow : oneYearFromNow


      const usrSub = new UserSubscription(
        0,
        user.Id!,
        tier.Id,
        bCycle,
        currentMoment,
        deducedSubEnd,
        true
      )

      console.log(`Email: ${email} | Amount: ${amtPerSelection} | Plan: ${plan}`);
      
    
       //addUsrSubToMxp Observer
       const addUsrSubToMxpObserver = {
        next: async (data: any) => {
          this.toastr.success(`User subscription processed successfully !`, 'Success')
          console.log(`User subscription Processed Successfully. Response: ${JSON.stringify(data)}`);
          
          this.loadingService.hide();
        },
        error: (err: HttpErrorResponse) => 
        {
          this.toastr.error(`Error processing user subscription`, 'Error')
          console.log(`Error: ${JSON.stringify(err.error)}`),

          this.loadingService.hide();
        }
      }

      this.loadingService.show();

      //activate subscription
      const popup = new Paystack();
      
      popup.newTransaction({
        key: environment.PaystackPublickKey, // paystack public key
        email: email,
        amount: amtPerSelection, // the amount value is multiplied by 100 to convert to the lowest currency unit
        planInterval: bCycle,
        onSuccess: (transaction) => {
          console.log(transaction);

          console.log(`User subscripton: ${JSON.stringify(usrSub)}`);


          this.authService.AddUserSubscriptionToMxp(usrSub)
          .subscribe(addUsrSubToMxpObserver);
        },
        onLoad: (response) => {
          console.log("onLoad: ", response);

          // this.loadingService.hide();
        },
        onCancel: () => {
          console.log("onCancel");

          this.loadingService.hide();
        },
        onError: (error) => {
          console.log("Error: ", error.message);

          this.loadingService.hide();
        }
      });

    }
  }

  login2Subscribe()
  {
    this.toastr.error('User not logged in', "Authenticate")

    this.router.navigate(['/login']);
  }
}
