import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.scss']
})
export class AddRoleComponent {
  roleName: string = '';

  constructor(private toastr: ToastrService,
    private dialog: DialogService,
    public dialogRef: MatDialogRef<AddRoleComponent>,
    private authService: AuthService)
  {
    
  }

  ngOnInit(): void {
  
  }

  submitRole()
  {
    console.log(`Role to be added: ${JSON.stringify(this.roleName)}`)

    if(!this.roleName)
    {
      this.toastr.error('Invalid role name!', 'Error')
    }
    else if(environment.AppBoundIdentifier < 1)
    {
      this.toastr.error('Invalid application bound!', 'Error')
    }
    else{
      //addRoleObserver
      const addRoleObserver = {
        next: async (data: any) =>  {
          this.toastr.success(`Role: ${this.roleName} added !`, 'Success')
          console.log(`Role add response: ${JSON.stringify(data)}`);
          
          this.dialogRef.close("role added")
          // this.dialog.closeDialog();
        },
        error: (err: HttpErrorResponse) => 
        {
          console.log(`Error: ${JSON.stringify(err)}`),
          this.toastr.error(`${err.error}`, 'Error')
        },
        complete: () => { 
          console.log("Role Added !");
        }
      }



      this.authService.AddSingleRoleToCustomAuthApp(this.roleName, environment.AppBoundIdentifier)
      .subscribe(addRoleObserver)
    }
  }
}
