import { createAction, props } from "@ngrx/store";
import { MaiXEvent } from "src/app/types/models/maixevent.model";

    
export const LOAD_EVENTS_FOR_ONE_USER = createAction('[MaiXEvent] Load Events for one user',
    props<{ userId: string }>());

export const LOAD_EVENTS_SUCCESS_FOR_ONE_USER = createAction('[MaiXEvent] Load Events Success for one user',
    props<{currentuserevents: MaiXEvent[]}>());

export const LOAD_EVENTS_FAIL_FOR_ONE_USER = createAction('[MaiXEvent] Load Events Fail for one user',
    props<{error: string}>());