<div class="container">
    <div 
    class="row text-center"
    style="margin-top: 10px;">
        <div class="col-12">
            <span (click)="goToActivities()" style="float: left;">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                </svg>
            </span>
    

          <button 
            style="border-radius: 40px;"
            class="btn btn-outline-primary">
            creating activity ...
          </button>

        </div>
    
    </div>
</div>

<div style="margin-top: 25px;" class="container">

    <mat-accordion>

         <!-- Add activity info panel -->
        <mat-expansion-panel 
            [expanded]="activityDetailsOpenStateAngMat"
            [disabled]="!activityDetailsOpenStateAngMat">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <svg xmlns="http://www.w3.org/2000/svg" style="color: rgb(68, 68, 210);  margin-right: 7px;" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                        </svg>
                        Info.
                    </mat-panel-title>
                    <mat-panel-description>
                        Add activity details
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <hr>
                <ng-container>
                    <!-- Angular Material version -->
                    <form [formGroup]="activityForm">
                        <!-- id -->
                        <mat-form-field 
                        style="display: flex;"
                        hidden>
                            <mat-label>Id:</mat-label>

                            <input 
                                matInput 
                                [(ngModel)]="activityModel.Id"
                                formControlName="Id"
                                required>

                            <mat-error 
                                *ngIf="activityForm.get('Id').hasError('required')">
                                Activity Id
                            </mat-error>
                        </mat-form-field>

                        <!-- name -->
                        <mat-form-field style="display: flex;">
                            <mat-label>Name:</mat-label>
                            <input 
                                matInput 
                                [(ngModel)]="activityModel.Name"
                                formControlName="Name"
                                required>

                            <mat-error 
                                *ngIf="activityForm.get('Name').hasError('required')">
                                Activity name is required
                            </mat-error>
                        </mat-form-field>

                        <!-- Description -->
                        <mat-form-field style="display: flex;">
                            <mat-label>Description:</mat-label>
                            <input 
                                matInput 
                                [(ngModel)]="activityModel.Description"
                                formControlName="Description"
                                required>
                            <mat-error *ngIf="activityForm.get('Description').hasError('required')">Activity description is required</mat-error>
                        </mat-form-field>

                        <!-- PricePerPerson -->
                        <mat-form-field style="display: flex;">
                            <mat-label>Price Per Person:</mat-label>
                            <input 
                                type="number"
                                matInput 
                                [(ngModel)]="activityModel.PricePerPerson"
                                formControlName="PricePerPerson"
                                required>
                            <mat-error *ngIf="activityForm.get('PricePerPerson').hasError('required')">Price Per Person is required</mat-error>
                        </mat-form-field>

                        <button 
                            style="width: 100%;" 
                            mat-raised-button 
                            color="primary" 
                            (click)="ValidateActivityDetailsAndProceedToImageUpload()"
                            [disabled]="activityForm.invalid">
                            Submit
                        </button>
                    </form>
                </ng-container>
        </mat-expansion-panel>

          <!-- image upload panel -->
        <mat-expansion-panel 
            [expanded]="activityImagesOpenStateAngMat"
            [disabled]="!activityImagesOpenStateAngMat">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <svg xmlns="http://www.w3.org/2000/svg" style="color: rgb(34, 202, 34); margin-right: 7px;" width="16" height="16" fill="currentColor" class="bi bi-images" viewBox="0 0 16 16">
                        <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"/>
                        <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2M14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1M2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1z"/>
                    </svg>
                    Images
                </mat-panel-title>
                <mat-panel-description>
                    Add Images of the activity: {{panelOpenState ? 'open' : 'closed'}}
                </mat-panel-description>
            </mat-expansion-panel-header>
          
            <!-- before files gets selected -->
            <ng-container 
            *ngIf="selFiles.length < 1"
            style="margin-top: 15px;">

                <app-image-upload
                [showProgress]="false"
                [maxFiles]="numberOfUploadableImages"
                [maxSizeMB]="imgSelectMaxSize"
                (filesUploaded)="onFilesUploaded($event)"> 
                    
                    <label 
                        style="width: 100%;"
                        class="imgLabel">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-cloud-arrow-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708l2 2z" />
                            <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                        </svg>
                        <br><br><br>
                        Upload image(s) of activity
                    </label> 

                </app-image-upload>
            
            </ng-container>
        
            <!-- when files gets selected -->
            <div *ngIf="selFiles.length > 0">
                <!-- Card deck -->
                <div class="card-deck row">
    
                    <div 
                    class="col-xs-12 col-sm-6 col-md-4" 
                    *ngFor="let x of selFiles">
                        <!-- Card -->
                        <div class="card">
    
                        <!--Card image-->
                        <div class="view overlay">
                            <img 
                            class="card-img-top" 
                            src={{rtImgPath}}{{x}}
                            alt="x">
                            <a href="#!">
                            <div class="mask rgba-white-slight"></div>
                            </a>
    
                            <span 
                            (click)="removeSelection(x)"
                            class="removeSpan" 
                            style="float: right; color: rgb(255, 0, 0);">
                            
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
                            </svg>
                            </span>
                        </div>
    
                        </div>
                        <!-- Card -->
                    </div>
    
                    <!-- button to append more image(s) -->
                    <div class="col-xs-12 col-sm-6 col-md-4">
                    <app-image-upload
                        [showProgress]="false"
                        [maxFiles]="numberOfUploadableImages"
                        [maxSizeMB]="imgSelectMaxSize"
                        (filesUploaded)="onAppendFilesUploaded($event)"> 
                        
                        <button 
                        class="btn btn-outline-primary"
                        style="margin-top: auto; margin-bottom: auto; margin: 10px; padding: 16px; border-radius: 20px; color: white; background-color: rgb(72, 5, 139);">
    
                        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" fill="currentColor" class="bi bi-node-plus" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M11 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8M6.025 7.5a5 5 0 1 1 0 1H4A1.5 1.5 0 0 1 2.5 10h-1A1.5 1.5 0 0 1 0 8.5v-1A1.5 1.5 0 0 1 1.5 6h1A1.5 1.5 0 0 1 4 7.5zM11 5a.5.5 0 0 1 .5.5v2h2a.5.5 0 0 1 0 1h-2v2a.5.5 0 0 1-1 0v-2h-2a.5.5 0 0 1 0-1h2v-2A.5.5 0 0 1 11 5M1.5 7a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"/>
                        </svg>
                    </button>
    
    
                    </app-image-upload>
    
                    </div>
                </div>
                <!-- Card deck -->
            </div>

            <br>

            <mat-action-row>
                <button 
                    style="float: left;"
                    (click)="returnToActivityInfoFromImageAddAngMat()"
                    mat-raised-button 
                    (click)="null">
                    Back
                </button>

                <button 
                *ngIf="selFiles"
                mat-raised-button 
                (click)="ValidateImageAddAndProceedAngMat()">
                    Next
                </button>
            </mat-action-row>
        </mat-expansion-panel>

          
        <!-- location panel -->
        <mat-expansion-panel 
          [expanded]="locationPanelOpenStateAngMat"
          [disabled]="!locationPanelOpenStateAngMat">

            <mat-expansion-panel-header>
                <mat-panel-title>
                    <svg xmlns="http://www.w3.org/2000/svg" style="color: rgb(234, 44, 44);  margin-right: 7px;" width="16" height="16" fill="currentColor" class="bi bi-geo" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.3 1.3 0 0 0-.37.265.3.3 0 0 0-.057.09V14l.002.008.016.033a.6.6 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.6.6 0 0 0 .146-.15l.015-.033L12 14v-.004a.3.3 0 0 0-.057-.09 1.3 1.3 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465s-2.462-.172-3.34-.465c-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411"/>
                    </svg>
                    Location
                </mat-panel-title>
                <mat-panel-description>
                Add Location: {{panelOpenState ? 'open' : 'closed'}}
                </mat-panel-description>
            </mat-expansion-panel-header>
          
            <div>
                <app-map (addressSelected)="onAddressSelected($event)"></app-map>
            </div>
        
          
            <mat-action-row>
                <button 
                    style="float: left;"
                    (click)="returnToActivityImagesAngMat()"
                    mat-raised-button>
                    Back
                </button>

                <!-- (click)="CompleteActivityAdd()" -->
                <button 
                *ngIf="anAddressIsSelected"
                (click)="ValidateLocationAddAndProceedToPreferencesSettings()"
                mat-raised-button>
                    Next
                </button>
            </mat-action-row>

        </mat-expansion-panel>
    
        <!-- settings (activity promotion...) -->
        <mat-expansion-panel 
            [expanded]="preferencesPanelOpenStateAngMat"
            [disabled]="!preferencesPanelOpenStateAngMat">

            <mat-expansion-panel-header>
                <mat-panel-title>
                    <svg xmlns="http://www.w3.org/2000/svg" style="color: rgb(68, 68, 210);  margin-right: 7px;" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                    </svg>
                    Preferences.
                </mat-panel-title>
                <mat-panel-description>
                    Set Preferences
                </mat-panel-description>
            </mat-expansion-panel-header>
            <hr>
            <ng-container>

                <!-- enable promoter -->
                <div class="row mb-3">
                    <div>
                        <label 
                            for="inputPassword6" 
                            class="col-sm-2 col-form-label col-form-label-sm">
                            Enable Promotion
                            <span type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                                <svg xmlns="http://www.w3.org/2000/svg" style="color: rgb(56, 56, 211);" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                </svg>
                            </span>
                            :
                        </label>
            
                        <!-- when promotion is not enabled -->
                        <span 
                            class="col-sm-10"
                            *ngIf="!activityModel.EnablePromotion"
                            (click)="toggleAppendPromotion()"
                            style="text-align: center;">
                            <svg xmlns="http://www.w3.org/2000/svg" style="color: rgb(225, 42, 42);" width="36" height="26" fill="currentColor" class="bi bi-toggle-off" viewBox="0 0 16 16">
                                <path d="M11 4a4 4 0 0 1 0 8H8a5 5 0 0 0 2-4 5 5 0 0 0-2-4zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8M0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5"/>
                            </svg>
                        </span>
            
                        <!-- when promotion is enabled -->
                        <span 
                            class="col-sm-10"
                            *ngIf="activityModel.EnablePromotion"
                            (click)="toggleAppendPromotion()"
                            style="text-align: center;">
                            <svg xmlns="http://www.w3.org/2000/svg" style="color: rgb(111, 241, 42);" width="36" height="26" fill="currentColor" class="bi bi-toggle-on" viewBox="0 0 16 16">
                                <path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8"/>
                            </svg>
                        </span>
                    </div>
                </div>
               
        
                <!-- promotion percentage setting -->
                <div 
                    *ngIf="activityModel.EnablePromotion"
                    class="row mb-3">
        
                      <label 
                      for="promoPercentage" 
                      class="col-sm-2 col-form-label col-form-label-sm">
                        Set Promo %
                        <svg xmlns="http://www.w3.org/2000/svg" style="color: rgb(56, 56, 211);" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                        </svg>
                        :
                      </label>
                        
        
                        <!-- show percentage update when promotion is enabled -->
                        <span class="col-sm-10">
                            <input 
                                type="number" 
                                id="promoPercentage" 
                                [(ngModel)]="this.activityModel.ActivityPromotionPercentage"
                                class="form-control form-control-sm"
                                aria-describedby="passwordHelpInline"
                                required
                                style="width: 100%;">
                        </span>
        
                </div>
            </ng-container>


            <mat-action-row>
                <button 
                    style="float: left;"
                    (click)="returnToLocationAddPanelFromPreferencesSettings()"
                    mat-raised-button>
                    Back
                </button>

                <!-- if you havent enabled promotion - all goo -->
                <!-- if you enable promotion, percentage must be valid -->
                <button 
                (click)="CompleteActivityAdd()"
                mat-raised-button>
                    Complete
                </button>
            </mat-action-row>

        </mat-expansion-panel>
    </mat-accordion>
</div>













