import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment'


@Injectable({
  providedIn: 'root'
})
export class PerformerclassService {
  // APIUrl = 'https://localhost:7032/api/'
  private APIUrl: string | undefined;

  constructor(private http: HttpClient) 
  { 
    this.APIUrl = environment.APIUrl;
  }

  //add AddActivity Perfomances To An Activity
  //REMOVE
  AddActivityPerfomancesToAnActivity(types: any, newActivity: number)
  {
    console.log(`Perfomance add url: ${ this.APIUrl + 'ActivityPerformerClass/AddActivityPerfomancesToAnActivity/' + newActivity}`);
    
    return this.http.post<any>(
      this.APIUrl + 'ActivityPerformerClass/AddActivityPerfomancesToAnActivity/' + newActivity, 
      types, 
      this.getHttpOptions());
  }

  //get all activity performer classes
  GetPerfomanceClassesForAllActivities(): Observable<any[]> {
    return this.http.get<any>(
      this.APIUrl + 'ActivityPerformerClass/GetPerfomanceClassesForAllActivities', this.getHttpOptions());
  }

  //GetPerformanceClassesOfAnActivityByActivityId
  GetPerformanceClassesOfAnActivityByActivityId(activityId: number)
  {
    return this.http.get<any>(this.APIUrl + `ActivityPerformerClass/GetPerformanceClassesOfAnActivityByActivityId/${activityId}`, this.getHttpOptions());
  }

    //GetPerformanceClassOfAnActivityByPerformanceClassId
    GetPerformanceClassOfAnActivityByPerformanceClassId(performanceClassId: number)
    {
      return this.http.get<any>(this.APIUrl + `ActivityPerformerClass/GetPerformanceClassesOfAnActivityByActivityId/${performanceClassId}`, this.getHttpOptions());
    }

 

  //RemoveActivityPerformanceClassOfAnActivity
  RemoveActivityPerformanceClassOfAnActivity(performanceClassId: number){
    return this.http.post<any>(
      this.APIUrl + `ActivityPerformerClass/RemoveActivityPerformanceClassOfAnActivity/${performanceClassId}`,
      null,
      this.getHttpOptions());
  }

  getHttpOptions(){
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };

    return httpOptions;
  }

}
