import { Directive, ElementRef, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: '[appIntersectionObserver]',
})
export class IntersectionObserverDirective {

  @Output() isIntersecting = new EventEmitter<boolean>();

  private observer!: IntersectionObserver;

  constructor(private element: ElementRef) {}

  ngOnInit() {
    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // Emit only for items that enter the view AFTER initial load
            console.log('Element is now in view:', entry.target);
            this.isIntersecting.emit(true);
          }
        });
      },
      {
        threshold: 0.1,  // Adjust the visibility threshold to 10%
        rootMargin: '0px 0px -100px 0px'  // Pushes the "intersecting" trigger earlier
      }
    );

    // Observe the element
    setTimeout(() => {
      this.observer.observe(this.element.nativeElement);
    }, 100); // Debounce for a brief time (100ms) to avoid emitting during the initial load
  }

  //  constructor(private element: ElementRef) {
  //   this.observer = new IntersectionObserver(([entry]) => {
  //     console.log('Element is intersecting:', entry.isIntersecting); // Debugging
  //     this.isIntersecting.emit(entry.isIntersecting);
  //   }, { threshold: 0.1 });  // Adjust threshold for when to trigger
  // }

  // ngOnInit() {
  //   console.log('Observing element:', this.element.nativeElement); // Debugging
  //   this.observer.observe(this.element.nativeElement);
  // }

  ngOnDestroy() {
    this.observer.unobserve(this.element.nativeElement);
  }

}
