import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent {
  @Output() dateChange = new EventEmitter<string>();

  @Input() placeholder: string = 'select date';
  @Input() minDate: Date = new Date();
  @Input() datePickerStyle: { [key: string]: string } = {};
  @Input() appearance: MatFormFieldAppearance = 'fill'; //'legacy' | 'standard' | 'fill' | 'outline'
  
  dateControl = new FormControl();


  onDateChange(event: any): void {
    // const eventStartDateValue = this.eventFormGroup.get('EventStartDate')?.value;

    // if(!environment.production)
    // {
    //   console.log('Event Start Date RAW:', eventStartDateValue);
    // }

    // if (eventStartDateValue) 
    // {
    //   const eventStartDate = new Date(eventStartDateValue);

    //   //the timezone will eventually be replaced by a global service based on user selection
    //   let timezone = 'Africa/Johannesburg';
    
    //   const eventStartDateUTC = moment(eventStartDateValue).tz(timezone).toString()

    //   if(!environment.production)
    //   {
    //     console.log(`Event Start Date: ${eventStartDate} && Event Start Date UTC moment: ${eventStartDateUTC} `);
    //   }
    // }


    this.dateChange.emit(this.dateControl.value);
  }
}
