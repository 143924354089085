import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ActivityService } from 'src/app/shared/services/activity.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { AddressService } from 'src/app/shared/services/address.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { ToastrService } from 'ngx-toastr';
import { UserSpecificService } from 'src/app/shared/services/dataService/user-specific.service';
import { ActivityStatus } from 'src/app/types/enums/activityStatus.enum';
import { ActionsSubject, Store, select } from '@ngrx/store';
import { AppState } from 'src/app/ngrx/state/app.state';
import { GenericRole } from 'src/app/types/models/genericrole.model';
import { Activity } from 'src/app/types/models/activity.model';
import { BehaviorSubject, Observable, Subscription, catchError, combineLatest, filter, first, map, of, switchMap, tap } from 'rxjs';
import { GenericUser } from 'src/app/types/models/genericuser.model';
import { selectActiveActivitiesWithPagination, selectActiveActivityLoaded, selectActiveActivityLoading } from 'src/app/ngrx/selectors/active-activity.selectors';
import { selectActiveActivityCreators, selectActiveActivityCreatorsError, selectActiveActivityCreatorsLoaded, selectActiveActivityCreatorsLoading } from 'src/app/ngrx/selectors/active-activity-creators.selectors';
import { GET_ACTIVE_ACTIVITIES, GET_ACTIVE_ACTIVITIES_SUCCESS } from 'src/app/ngrx/actions/active-activity.actions';
import { GET_CREATORS_OF_ACTIVE_ACTIVITIES } from 'src/app/ngrx/actions/active-activity-creators.actions';
import { ObfuscationServiceService } from 'src/app/shared/services/obfuscation-service.service';
import { selectCurrentUserRolesState, selectLoggedInUserRoles } from 'src/app/ngrx/selectors/current-user-roles.selectors';
import { selectapplicationRoles } from 'src/app/ngrx/selectors/application-roles.selectors';
import { GET_APP_ROLES } from 'src/app/ngrx/actions/application-roles.actions';
import { GET_CURRENT_USER_ROLES } from 'src/app/ngrx/actions/current-user-roles.actions';


@Component({
  selector: 'app-activity-per-status',
  templateUrl: './activity-per-status.component.html',
  styleUrls: ['./activity-per-status.component.scss']
})
export class ActivityPerStatusComponent {
  allActivities: any;
  locationResponse: any;

  allMxpRoles: GenericRole[] = [];

  //this is an input property that determines the data that will be displayed
  // @Input()
  @Input() activeSt: any;

  rtImgPath: string = environment.fileRetriever;

  isUsrLoggedIn: boolean = false;

  assignedUserRoles: string[] | undefined

  activeActivityStatus: number = 0;

  pageNumber: number = 1;
  pageSize: number = 100;

  activeActivitiesPaginated$:  Observable<Activity[]>
  activeActivitiesCreators$: Observable<GenericUser[]>
  activeActivitiesLoading$: any
  activeActivitiesLoaded$: any
  loading$: any
  loaded$: any
  error$: any

  
  combinedData$: Observable<{activeActivities: Activity, creator: GenericUser | undefined}[]>;
  
  private subscription: Subscription | undefined = new Subscription();
  
  canAddActivity: boolean = false;
  // canAddActivity$!: Observable<boolean>

  private canAddActivitySubject = new BehaviorSubject<boolean>(false);
  canAddActivity$ = this.canAddActivitySubject.asObservable();


  userRoles$!: Observable<GenericRole[]>

  applicationRoles$!: Observable<GenericRole[]>


  useId:string | undefined;

  private useIdSubject = new BehaviorSubject<string | null>('');
  useId$: Observable<string | null> = this.useIdSubject.asObservable();


  p: number = 1
  itemsPerPage: number = 5

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private addrService: AddressService,
    private dialogSvc: DialogService,
    public userSvc: UserSpecificService,
    private authSvc: AuthService,
    private toastr: ToastrService,
    private store: Store<AppState>,
    private actionsSubject$: ActionsSubject,
    private obfuscationService: ObfuscationServiceService,
    private activityService: ActivityService) 
  { 
    if(!environment.production)
    {
      console.log('PComponent Constructor');
    }

    this.activeActivitiesPaginated$ = this.store.pipe(select(selectActiveActivitiesWithPagination));
    this.activeActivitiesCreators$ = this.store.pipe(select(selectActiveActivityCreators))
    this.activeActivitiesLoading$ = this.store.pipe(select(selectActiveActivityLoading));
    this.activeActivitiesLoaded$ = this.store.pipe(select(selectActiveActivityLoaded));
    this.loaded$ = this.store.pipe(select(selectActiveActivityCreatorsLoaded));
    this.error$ = this.store.pipe(select(selectActiveActivityCreatorsError));
    this.combinedData$ = of([]); // Initialize combinedData$ to an empty observable array
  }


  getEnumValueFromInt(value: number): ActivityStatus {
    switch (value) {
      case 0:
        return ActivityStatus.Active;
      case 1:
        return ActivityStatus.Inactive;
      case 2:
        return ActivityStatus.Disabled;
      default:
        throw new Error(`Invalid value: ${value}`);
    }
  }

  ngOnInit()
  {
    console.log(`PComponent ngOnInit`);
    // subscribe to activeActivitiesPaginated$ observable and
    // defined a pure function that gets called when value emits
    this.subscription = this.activeActivitiesPaginated$.subscribe(activeActivities => {
      if (!activeActivities || activeActivities.length === 0) {
        this.store.dispatch(GET_ACTIVE_ACTIVITIES({ statusCode: this.activeActivityStatus, pageNumber: this.pageNumber, pageSize: this.pageSize }));
      }
    });

    //who can add activity
    this.subscription.add(
      this.store.pipe(select(selectLoggedInUserRoles)).pipe(
        tap((userRoles) => {
          
          
          if (
            !userRoles || 
            userRoles.length === 0 || 
            !this.useIdSubject.getValue() || 
            this.useIdSubject.getValue()!?.length < 1) 
          {

            this.authSvc.GetCurrentlyLoggedInUserIdIfAny()?.subscribe({
              next: (response: string) => {
                // this.useId = response;
                this.useIdSubject.next(response);
        
                if (!environment.production) {
                  console.log(`About to dispatch current user roles`);
                }
        
                // Dispatch action to get user roles
                this.store.dispatch(GET_CURRENT_USER_ROLES());
              },
              error: (err: any) => {
                if(!environment.production)
                {
                  console.log("Unable to get logged in user: " + err);
                }
              },
              complete() {
                if(!environment.production)
                {
                  console.log("Complete");
                }
              }
            });
          }
        }),
        filter(userRoles => userRoles && userRoles.length > 0),
        tap(userRoles => {
          
          const usrRoleToExclude = 'mxp_General';
          
          const filteredUserRoles = userRoles.filter(role => role !== usrRoleToExclude);

         
          if(filteredUserRoles.includes('mxp_ActivityHost'))
          {
            this.canAddActivitySubject.next(true);
          }
          else{
            this.canAddActivitySubject.next(false);
          }
        })
      ).subscribe()
    );


    //creators of activities
    this.subscription.add(
      this.actionsSubject$.pipe(
        filter(action => action.type === GET_ACTIVE_ACTIVITIES_SUCCESS.type),
        switchMap(() => this.activeActivitiesPaginated$.pipe(
          map((activeActivities: Activity[]) => activeActivities.map(activeActivities => activeActivities.UserId!)),
          tap((userIds: string[]) => {
            //dispatch the next action with tap
            this.store.dispatch(GET_CREATORS_OF_ACTIVE_ACTIVITIES({ arrayOfUserId: userIds }));
          }),
          catchError(error => {
            console.error('Error getting creators of active activities', error);
            return of([]);
          })
        ))
      ).subscribe()
    );
    
    
    //combine data from two observables: this.activeActivitiesPaginated$ and this.activeActivitiesCreators$
    //then pipe and mapp over the combined data to create a new structure / transform it
    this.combinedData$ = combineLatest([
      this.activeActivitiesPaginated$,
      this.activeActivitiesCreators$
    ]).pipe(
      map(([activeActivities, creators]) => 
        activeActivities.map(activeActivity => ({
          activeActivities: activeActivity,
          creator: creators.find(creator => creator.Id === activeActivity.UserId)
        }))
      )
    );

    //get all roles the application has
    this.authSvc.GetAllRolesForMaiXp(environment.AppBoundIdentifier)
    .subscribe((data: any) => {

      this.allMxpRoles = data.map((role: any) => ({
        Name: role.name,
        AppBound: role.AppBound,
        Id: role.Id,
      }));

      if(!environment.production)
      {
        console.log(`All roles For App MaiXp: ${JSON.stringify(data)}`);
        console.log(`Roles Object: ${JSON.stringify(this.allMxpRoles)}`)
      }
    });
      
  }

  isLoggedIn() {
    this.userSvc.isUserLoggedIn.subscribe((x: boolean) => {
      this.isUsrLoggedIn = x;

      if(!environment.production)
      {
        console.log(`Is user logged in: ${x}`);
      }
      //if user is logged in, assign user object
    })
  }
 

  //======================//======================//===============
  //Behavior related logic: "Who can see the [Add Activity] button"
  //======================//======================//===============
  // Answer: Someone that has either "mxp_ActivityManager" or "mxp_ActivityHost" role assigned to them

  //add activity should only be seen by those with the right roles (activityHost, activityManager and super)
  // canSeeAddActivityBtn()
  // {
  //   //mxp_ActivityManager , mxp_ActivityHost
  //   const allowedRoles: string[] = ['mxp_ActivityManager', 'mxp_ActivityHost']
    
  //   //get all the roles that a logged in user has
  //   this.authSvc.getAllRolesAUserHas().subscribe((response: any) => {
  //     if(!environment.production)
  //     {
  //       //get a response of all the roles a user has
  //       console.log(`All roles a usee has: ${JSON.stringify(response)}`)
  //     }
      
  //   })
  // }
  


  // getUserIdIfUserLoggedIn()
  // {
  //   //if a user is logged in then try to decrypt the user's cookie
  //   if(this.isUsrLoggedIn)
  //   {
  //     this.authSvc.GetCurrentlyLoggedInUserIdIfAny()?.subscribe({
  //       next: (response: string) =>
  //       {
  //         this.useId = response;
  //       },
  //       error: (error: any) =>
  //       {
  //         console.log("Funny Error")
  //       },
  //       complete() {
  //         console.log("Funny complete")
  //       }
  //     })
  //   }

  //   return false;
  // }

  roleIsValid : boolean = false;
  
  roleIsValidForEditActivityAction(){
    if(
      this.assignedUserRoles?.includes("mxp_ActivityHost") ||
      this.assignedUserRoles?.includes("mxp_ActivityManager"))
    {
      this.roleIsValid = true;
    }
  }


  RouteToAddNewActivity() {

    //observer to validate the cookie against the server
    const tknValidationObserver = {
      // if cookie is still valid
      next: async (response: any) => {
        if(!environment.production)
        {
          console.log(`Token validation from server response: ${JSON.stringify(response)}`);
        }

        // if token comes back as valid: route to addactivity
        this.router.navigate(['addactivity']);
        
      },
      error: async (err: HttpErrorResponse) => {
        if(!environment.production)
        {
          console.log(`Error response from token validation: ${JSON.stringify(err)}`);
        }
        
        if(err.url) //if err response is trying to navigate to another page, then it has expired
        {
          //remove the cookie (if any)
          this.userSvc.logout()

          //show the user some error
          this.toastr.error(
            "User not signed in...", "Error");

          //redirect to the login page
          this.router.navigate(['login'],
          {
            queryParams: {
              redirectUrl: 'addactivity'
            }
          });
        }
      },
      complete: async () => {
        if(!environment.production)
        {
          console.log(`user http request complete!`);
        }
      }
    }

    //validate the token against the server
    this.authSvc.ValidateUserCookie().subscribe(tknValidationObserver)
    
  }

  ViewActivity(activity: any)
  {
    let obfsActivityId = this.obfuscationService.encryptEventId(activity.Id.toString());

    if(!environment.production)
    {
      console.log(`obfuscation of activity ID: ${obfsActivityId}`)
    }

    this.router.navigate(['activitydetails', obfsActivityId]);
  }


  EditActivityByAdmin(e: any, selActivity: any)
  {
    if(!environment.production)
    {
      console.log(`selected activity for edit: ${JSON.stringify(selActivity)}`);
    } 

    //this prevents the parent routing click action to kickoff 
    e.stopPropagation();
    
    // alert("Edit activity by admin")
    this.dialogSvc.EditSelectedActivityDialog(selActivity)
      .afterClosed().subscribe(_ => {

        if(!environment.production)
        {
          console.log(`Result from edit dialog APS component: ${JSON.stringify(_)}`)

          //respomse will be "noaction" or "refresh" or "noactionDefault"

          if(_.includes("refresh"))
          {
            // window.location.reload();

          }
          else if(_.includes("update"))
          {
            //open the edit of the 
            this.EditActivityByAdmin(e, selActivity)
          }
          else if(_.includes("no update"))
          {
            //open the edit of the 
            this.EditActivityByAdmin(e, selActivity)
          }
          else{

          }
        }
      });
  }

  ViewAdmin(creator: GenericUser)
  {
    console.log(`Activity activity creator: ${JSON.stringify(creator)}`)
  }


  ViewLocationOfActivity(e: any, addressId: number)
  {
    if(!environment.production){
      console.log(`Extracted activity address ID: ${addressId}`);
      
    }

    const addrRetrieverObserver = {
      next: async (response: any) =>  {
        this.locationResponse = response;

    
        if(!environment.production)
        {
          console.log("location retrieved successfully:" + JSON.stringify(this.locationResponse));
        }
         
        await this.dialogSvc.ShowLocationDialog(this.locationResponse);

        // //get the longitude and latitude down
        // console.log(`Longitude: ${response?.Longitude}. Latitude: ${this.locationResponse?.Latitude}`);
        
        // let latitude = this.locationResponse?.Longitude; // Example latitude
        // let longitude = this.locationResponse?.Latitude; // Example longitude
        // // Build the map URL with the latitude and longitude parameters
        // // let mapUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
        
        // let mapUrl = `http://maps.google.com/maps?q=${latitude},${longitude}`;

        //  // Open the map in a new browser window or tab
        //  window.open(mapUrl, '_blank');
      },
      error: (err: HttpErrorResponse) => console.log(err),
      complete: () =>{
        if(!environment.production)
        {
          console.log("Add activity COMPLETE");
        }
      }
    }


    //get the location of the activity
    this.addrService.GetAddressByAddressId(addressId)
      .subscribe(addrRetrieverObserver);
        
    //open on maps

    // alert("View location of an activity by anyone")
    //this prevents the parent routing click action to kickoff
    e.stopPropagation();
  }

  
  ViewImage(e: any, creator: GenericUser)
  {
    //make sure creator object is not empty
    //if profile isnt empty send image if not use a default
      
    let AdminImageUrl: string[] = [];

    if(creator && creator.ProfilePicture)
    {
      if(!environment.production){
        console.log(`Extracted Image Url of Admin : ${creator.ProfilePicture}`);
      }
      
      AdminImageUrl.push(creator.ProfilePicture) 
      this.dialogSvc.ShowImgDialog(AdminImageUrl);
    }   
    else {
      AdminImageUrl.push('nImg.png') 
      this.dialogSvc.ShowImgDialog(AdminImageUrl);
    }
    AdminImageUrl = [] ;
    
    e.stopPropagation();
  }


}
