import { Injectable } from '@angular/core';
import { LoaderService } from './loader.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, delay, finalize } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(public loaderService: LoaderService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if(!environment.production)
    // {
    //   console.log('HTTP Request intercepted, showing loader');
    // }

    // this.loaderService.show();

    return next.handle(req).pipe(
      // delay(6000), 
      finalize(
        () => {
          // if(!environment.production)
          // {
          //   console.log('HTTP Request completed, hiding loader');
          // }

          // this.loaderService.hide();
        }
      )
    );
  }
}
