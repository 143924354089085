import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { BankAccountService } from 'src/app/shared/services/claimsRelated/bank-account.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent {


  // // Add a new bank account
  // addBankAccount() {
  //   if (this.newAccount) {
  //     this.newAccount.Id = this.bankAccounts.length + 1;
  //     this.bankAccounts.push(this.newAccount);
  //     this.newAccount = new BankAccount(0, '', '', '', '', '', '', 0, false);
  //   }
  // }

  public bankAccountForm: FormGroup = this.formBuilder.group('');
  

  userID: string = ''
  banks: any
  
  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private bankService: BankAccountService,
    public dialogRef: MatDialogRef<AddComponent>,
    private bankaccountService: BankAccountService,
    @Inject(MAT_DIALOG_DATA) public data:any)
  {
    this.userID = data;

    if(!environment.production)
    {
      //log incoming user ID
      console.log(`Incoming user ID: ${JSON.stringify(data)}`);
    }


    this.bankService.getBanks("south africa").subscribe(
      (allBanks) => {
        this.banks = allBanks.data;

        if(!environment.production)
        {
          console.log(`List of banks: ${JSON.stringify(this.banks)}`);
        }
      }
    );

    this.bankAccountForm = this.formBuilder.group({
      Id: [0],
      UserId: [this.userID, [Validators.required]],
      RecipientType: [environment.TransferRecipientType_SA, [Validators.required]],
      BankName: ['', [Validators.required]],
      BankCode: [''],
      AccountNumber: [0, [Validators.required]],
      AccountName: ['', [Validators.required]],
      Currency: [environment.SACurrency, [Validators.required]],
      IsDefault: [false],

      // usernameOrEmail: ['', [Validators.required]],
      // password: ['', [Validators.required, Validators.minLength(6)]]
    });

    
  }

  ngOnInit(): void {
    // this.bankForm = this.fb.group({
    //   BankName: [''],
    //   BankCode: ['']
    // });

    // Listen for changes in the BankName field
    this.bankAccountForm.get('BankName')!.valueChanges.subscribe(selectedBankName => {
      const selectedBank = this.banks.find((bank: any) => bank.name === selectedBankName);
      if (selectedBank) {
        this.bankAccountForm.patchValue({ BankCode: selectedBank.code });
      }
    });
  }

  onSubmitBankAccountAdd()
  {
    if(this.bankAccountForm.valid)
    {
      if(!environment.production)
      {
        console.log(
          `Add bank account form: ${JSON.stringify(this.bankAccountForm.value)}`)
      }

      this.bankaccountService.AddBankAccount(this.bankAccountForm.value)
      .subscribe((response: any) => {
        if(!environment.production)
        {
          console.log(`Add bank account response: ${JSON.stringify(response)}`);
        }

        //redirect to list bank accounts and open the modal
        this.dialogRef.close("bankaccountadded")
      })
    }
    else{
      this.toastr.error("Invalid form details. Please review...", "Invalid form")
    }
  }

  confirmAndClose()
  {
  
    this.dialogRef.close("noactionDefault");
  }
}
