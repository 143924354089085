

<!-- show a public page for a specific user (logged in or userId supplied as a query parameter) -->

<!-- show all the ACCEPTED activities for the user (logged in or userId supplied as a query parameter) -->

<div class="container"> 

    <!-- back to activity promotions dashboard -->
    <ng-container *ngIf="usrObject$ | async as userObject">

    
        <button 
        (click)="backToActivityPromotionDashboard()"
        class="navtor" 
        *ngIf="userObject"
        type="button"
        style="color: black;">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
            </svg>
                    
        </button>
    </ng-container>
  
    <ng-container>

        <!-- when the user is logged in -->
        <ng-container *ngIf="(usrObject$ | async) as userObject">
            <div  
            *ngIf="userObject"
            class="text-center">
    
                <button 
                    style="border-radius: 40px; margin: 10px 0px 0px 0px;"
                    class="btn btn-outline-primary">
                    promotion log(s)
                </button>
            </div>
        </ng-container>

      
    </ng-container>
  
    <hr>
  
    <section>
      <ng-container *ngIf="activitiesPromotingLoading$ | async; else loadedState">
        <!-- Loading Spinner or Placeholder -->
        <div class="spinner-container">
          <div class="spinner"></div>
        </div>
      </ng-container>
  

      <!-- loaded state -->
      <ng-template #loadedState>
        <ng-container 
        *ngIf="(activitiesPromotingLoaded$ | async); else errorState">
          
           
            <ng-container 
            class="con" 
            *ngIf="activitydeduction$ | async as activitydeduction">
            
            <a *ngFor="let activity of activitydeduction">
              

                <!-- {{activity | json}} -->
                <app-card 
                    [entity]="activity"
                    [isActivity]="true"  
                    [showButton]="showButtonHistoryAndEdit"
                    [showActivityPromoButton]="showActivityPromoButton"
                    [showBookingButtonFromActivityPromotion]="showBookingButtonFromActivityPromotion"
                    [ActivityPromoterUserId]="activityPromoterUserId">
                </app-card>

                <br>    


                <!-- {{activity | json}} -->
                <!-- <app-card 
                [entity]="activity"
                [isActivity]="true"  
                [showButton]="showButtonInFirstCard"></app-card> -->
            </a>
            </ng-container>
          
            <ng-container
            *ngIf="!(activitydeduction$ | async) as activitydeduction">
                <span class="text-center">
                    No Promotions

                    <br><br><br>

                    <div>
                        Navigate to profile and view promotable activities
                        <!-- <button 
                        class="btn btn-outline-primary">
                            view promotable activities
                        </button> -->
                    </div>
                </span>

                
            </ng-container>
        </ng-container>
      </ng-template>
  
        <!-- <ng-template #noActivities>
          <p>No activities found for this user.</p>
        </ng-template> -->
  
        <!-- Error state -->
        <ng-template #errorState>
          <!-- Handle Error State -->
          <div class="error-message">
            <!-- {{ hostactivityListLoadedError$ | async }} -->
              Page error
          </div>
        </ng-template>
    </section>
  
  </div>
  
  
  
  
  