import { Component,Input,EventEmitter, Output, OnInit, Inject, HostListener  } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-enlarge-img',
  templateUrl: './enlarge-img.component.html',
  styleUrls: ['./enlarge-img.component.scss']
})
export class EnlargeImgComponent {
  imageUrl: string[];
  currentIndex: number = 0;

  rtImagePath = environment.fileRetriever;

  selectedImageUrls: string[] = [];

  
  startX: number = 0;
  startY: number = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { images: string[],index: number } 
                  ,private dialogSvc: DialogService,) {
    console.log("this is the data " + data.images);
    this.currentIndex = data.index ;
    console.log("this is the  index " + this.currentIndex);
    //this.imageUrl = data ;
    console.log('Received images:', data.images);
    this.imageUrl = data.images;
  }

  ngOnInit(): void {

    if (this.imageUrl) {
      console.log('Image URL is ' + this.imageUrl);
    }
    else if (!this.imageUrl) {
      console.error('Image URL is required ' );
    }
  }

  
  next() {
    this.currentIndex = (this.currentIndex + 1) % this.imageUrl.length;
  }

  prev() {
    this.currentIndex = (this.currentIndex - 1 + this.imageUrl.length) % this.imageUrl.length;
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    this.startX = event.touches[0].clientX;
    this.startY = event.touches[0].clientY;
  }

  @HostListener('touchend', ['$event'])
  onTouchEnd(event: TouchEvent) {
    const endX = event.changedTouches[0].clientX;
    const endY = event.changedTouches[0].clientY;
    const deltaX = endX - this.startX;
    const deltaY = endY - this.startY;

    if (Math.abs(deltaX) > Math.abs(deltaY) && Math.abs(deltaX) > 50) {
      if (deltaX > 0) {
        this.prev();
      } else {
        this.next();
      }
    } else if (Math.abs(deltaY) > Math.abs(deltaX) && Math.abs(deltaY) > 50) {
      if (deltaY > 0) {
        this.closeDialog();
      }
    }
  }
  
  closeDialog() {
    this.dialogSvc.closeDialog();
  }


}
