

<div class="container">
  <mat-tab-group #tabGroup>
    <mat-tab label="Home">
      
      <!-- User profile card -->

      
      <div 
      *ngIf="usrObject$ | async as usrObject" 
      class="container profileContainer">
          <div 
            class="card profileCard">
              
              <div class="img-container">
                <img 
                [src]="rtImgPath + (usrObject?.ProfilePicture ?? 'nexPP.png')" 
                class="card-img-top img img-thumbnail" 
                alt="ProfilePicture"
                >

                <label
                  class="btn btn-outline-primary change-image-label"
                  for="folder">
                  <svg style="color: white;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                  </svg>
                </label>
              </div>


              <!-- qr code section -->
               <!-- THE QR CODE ONLY GOES TO MAIXPERIENCE.CO.ZA ATM -->
              <div 
              *ngIf="usrRoles$ | async as usrRoles"
              [hidden]="!canUseQR">

                <img 
                *ngIf="hasClickedViewQR"
                [src]="qrCodeImage" 
                alt="QR Code">
                <!-- <canvas #canvas></canvas> -->

                <!-- sales people will have unique QR code that will bring users to their profile -->
                <label
                  *ngIf="usrRoles.includes('mxp_ActivityPromoter')"
                  (click)="toggleHasClickedQR()"
                  class="btn btn-outline-primary"
                  style="text-decoration: none; color: blue;">
                 
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-qr-code" viewBox="0 0 16 16">
                    <path d="M2 2h2v2H2z"/>
                    <path d="M6 0v6H0V0zM5 1H1v4h4zM4 12H2v2h2z"/>
                    <path d="M6 10v6H0v-6zm-5 1v4h4v-4zm11-9h2v2h-2z"/>
                    <path d="M10 0v6h6V0zm5 1v4h-4V1zM8 1V0h1v2H8v2H7V1zm0 5V4h1v2zM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8zm0 0v1H2V8H1v1H0V7h3v1zm10 1h-1V7h1zm-1 0h-1v2h2v-1h-1zm-4 0h2v1h-1v1h-1zm2 3v-1h-1v1h-1v1H9v1h3v-2zm0 0h3v1h-2v1h-1zm-4-1v1h1v-2H7v1z"/>
                    <path d="M7 12h1v3h4v1H7zm9 2v2h-3v-1h2v-1z"/>
                  </svg>

                  {{qrText}}
                </label>
              </div>


              <!-- change/edit image frontend logic -->
              <div 
                style="margin-top: 8px;"
                class="edit">

                <!-- <label
                  class="btn btn-outline-primary"
                  style="text-decoration: none; color: blue;"
                  for="folder">
                  
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                  </svg>

                  change image
                </label> -->

                
                
                <!-- {{this.usrObject$ | async | json}} -->

                <div style="visibility: hidden;">
                  <div class="loading">
                      <div class="loading2"></div>
                  </div>
                      
                  <!-- the input below is only accessible by the label tag above -->
                  <input 
                      type="file" 
                      id="folder" 
                      #fileInput
                      (change)="onFileSelected($event)"
                      formControlName="formSelectedFiles"
                      accept="image/*"
                      hidden>
                      
                </div>
              </div>

              <div class="card-body">
                <h3 
                  (click)="navigateToTab(1)"
                  class="card-title">
                    {{usrObject.Firstname ?? 'Firstname'}} {{usrObject.Lastname ?? 'Lastname'}}  
                  
                  <svg 
                  style="text-decoration: none; color: blue;" 
                  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                  </svg>
                </h3>

                <p 
                (click)="navigateToTab(1)"
                class="card-text">
                  {{usrObject.PhoneNumber ?? 'xxx xxx xxxx'}}
                <svg 
                style="text-decoration: none; color: blue;" 
                xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                  <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                </svg>
                </p>
                <a  
                  (click)="InDevMsg()"
                  class="btn btn-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
                  </svg>
                  <span style="margin-top: 4px;">
                    Message
                  </span>
                </a>
              </div>
          </div>

        
      </div>

      <hr>

      <!-- Body -->
      <div class="container ppBody">
  
        <!-- User details card -->
        <div class="card">
          
          <div class="card-body">
            <h3 class="card-title">
              <svg xmlns="http://www.w3.org/2000/svg" style="color: rgb(64, 70, 240);" width="22" height="22" fill="currentColor" class="bi bi-journal-text" viewBox="0 0 16 16">
                <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5"/>
                <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2"/>
                <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z"/>
              </svg>
              User Details
            </h3>
            <p class="card-text">
              See your account infomation and update details to personalize your experience
            </p>
          </div>

          <div class="card-footer">
            <a 
              (click)="navigateToTab(1)">
              View my details

              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-link" viewBox="0 0 16 16">
                <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9q-.13 0-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"/>
                <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4 4 0 0 1-.82 1H12a3 3 0 1 0 0-6z"/>
              </svg>
            </a>
          </div>
        </div>

        <!-- roles card -->
        <div class="card">
          
          <div class="card-body">
            <h3 class="card-title">
              <svg xmlns="http://www.w3.org/2000/svg" style="color: rgb(228, 228, 41);" width="22" height="22" fill="currentColor" class="bi bi-file-earmark-lock" viewBox="0 0 16 16">
                <path d="M10 7v1.076c.54.166 1 .597 1 1.224v2.4c0 .816-.781 1.3-1.5 1.3h-3c-.719 0-1.5-.484-1.5-1.3V9.3c0-.627.46-1.058 1-1.224V7a2 2 0 1 1 4 0M7 7v1h2V7a1 1 0 0 0-2 0M6 9.3v2.4c0 .042.02.107.105.175A.64.64 0 0 0 6.5 12h3a.64.64 0 0 0 .395-.125c.085-.068.105-.133.105-.175V9.3c0-.042-.02-.107-.105-.175A.64.64 0 0 0 9.5 9h-3a.64.64 0 0 0-.395.125C6.02 9.193 6 9.258 6 9.3"/>
                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
              </svg>
              Manage Roles
            </h3>
            <p class="card-text">
              See the roles that attached to your profile and manage the role(s) of interest.
            </p>
          </div>
          <div class="card-footer">
            <a 
            (click)="navigateToTab(2)">
              View and Manage Roles 
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-link" viewBox="0 0 16 16">
                <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9q-.13 0-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"/>
                <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4 4 0 0 1-.82 1H12a3 3 0 1 0 0-6z"/>
              </svg>
            </a>
            
          </div>
        </div>

        <!-- Funds card -->
        <!-- <div class="card">
          
          <div class="card-body">
            <h3 class="card-title">
              Manage Funds
            </h3>
            <p class="card-text">
              View profile income, process refunds or withdrawals.
            </p>
          </div>
          <div class="card-footer">
            <a 
            (click)="navigateToTab(3)">
              View funds
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-link" viewBox="0 0 16 16">
                <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9q-.13 0-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"/>
                <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4 4 0 0 1-.82 1H12a3 3 0 1 0 0-6z"/>
              </svg>
            </a>

          </div>
        </div> -->


         <!-- Vouchers card -->
         <!-- <div class="card">
          
          <div class="card-body">
            <h3 class="card-title">
              Manage Vouchers
            </h3>
            <p class="card-text">
              View voucher hostory, generate a new voucher in real time (only applies to activities and events that has given permission to do so).
            </p>
          </div>
          <div class="card-footer">
            <a 
            (click)="navigateToTab(4)">
              Voucher Manager
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-link" viewBox="0 0 16 16">
                <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9q-.13 0-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"/>
                <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4 4 0 0 1-.82 1H12a3 3 0 1 0 0-6z"/>
              </svg>
            </a>
          </div>
        </div> -->

        <!-- promotable activities -->
        <ng-container *ngIf="usrRoles$ | async as usrRoles">
        
        
          <div 
          *ngIf="usrRoles.includes('mxp_ActivityPromoter')"
          class="card">
              
          <div class="card-body">
            <h3 class="card-title">
              <svg xmlns="http://www.w3.org/2000/svg" style="color: rgb(213, 40, 40);" width="22" height="22" fill="currentColor" class="bi bi-award" viewBox="0 0 16 16">
                <path d="M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702z"/>
                <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1z"/>
              </svg>
              Promote Activities
            </h3>
            <p class="card-text">
              Explore activities that open for promotion.
            </p>
          </div>
          <div class="card-footer">
            <a 
            (click)="navigateToTab(3)">
              View promotable activities
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-link" viewBox="0 0 16 16">
                <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9q-.13 0-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"/>
                <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4 4 0 0 1-.82 1H12a3 3 0 1 0 0-6z"/>
              </svg>
            </a>
            
          </div>
          </div>
        </ng-container>

      </div>
    </mat-tab>


    <mat-tab 
    label="User"
    *ngIf="usrObject$ | async as usrObject"> 
      <app-user-profile [usrObjectInp]="usrObject"></app-user-profile>
    </mat-tab>


    <!-- Roles tab -->
    <mat-tab 
    label="Role(s)"
    *ngIf="usrObject$ | async as usrObject"> 
    
      <app-user-role [usrObjectInp]="usrObject"></app-user-role>
    </mat-tab>

    <!-- <mat-tab label="Fund(s)"> 
      <div class="container fundsContainer">
        <span class="centered-span">
          <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" fill="currentColor" class="bi bi-wallet2" viewBox="0 0 16 16">
            <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5z"/>
          </svg>
          <br>
        </span>
        
      </div>
    </mat-tab> -->


    <!-- <mat-tab label="Voucher" > 
      <div class="text-center">
        Voucher Manager
      </div>
    </mat-tab> -->
  
    
  

    

    <!-- activity promotions tabs -->
    <!-- <mat-tab 
    label="Promote activities"
    *ngIf="usrObject$ | async as usrObject"> 
    
      <app-activity-promotion [usrObjectInp]="usrObject"></app-activity-promotion>
    </mat-tab> -->

    <ng-container *ngIf="usrRoles$ | async as usrRoles">
      <ng-container *ngIf="(usrRoles.includes('mxp_ActivityPromoter'))">
        <mat-tab label="Promote activities"> 
        
          <ng-container *ngIf="(usrObject$ | async) as usrObject">

            <app-activity-promotion [usrObjectInp]="usrObject"></app-activity-promotion>
          </ng-container>
        </mat-tab>
      </ng-container>
    </ng-container>

  </mat-tab-group>
  

</div>

