import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { catchError, map, Observable, of } from 'rxjs';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { UserSpecificService } from 'src/app/shared/services/dataService/user-specific.service';

@Injectable({
  providedIn: 'root'
})
export class AGuardGuard  {

  isAuthenticated: boolean = false

  constructor(private authService: AuthService, private router: Router) 
  {
    
    
  }
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      

      // Return the observable so it waits for the async operation to complete
      return this.authService.GetCurrentlyLoggedInUserIdIfAny().pipe(
        map((response: string) => {
          // User is authenticated if a valid user ID is returned
          this.isAuthenticated = !!response; // true if response is not null or empty
          if (this.isAuthenticated) {
            return true;
          } else {
            // Redirect to login if not authenticated
            return this.router.createUrlTree(['/login']);
          }
        }),
        catchError((err: any) => {
          // Handle error by navigating to login and returning false
          this.isAuthenticated = false;
          this.router.navigate(['/login']);
          return of(false); // Use `of(false)` to return an observable
        })
      );
      
      
      // this.authService.GetCurrentlyLoggedInUserIdIfAny()
      // .subscribe({
      //   next: (response: string) => {
      //     this.isAuthenticated = true

      //     return this.isAuthenticated;
      //   },
      //   error: (err: any) => {
      //     this.isAuthenticated = false

      //     this.router.navigate(['/login']);
      //     return this.isAuthenticated;
      //   },
      //   complete: () => {
      //     alert(`Is authenticated: ${this.isAuthenticated}`)

      //     return this.isAuthenticated;
      //   }
      // })

    }
}
