import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, tap } from "rxjs";
import { environment } from "src/environments/environment";
import { MaiXEventService } from "src/app/shared/services/maiXEventRelated/mai-xevent.service";
import { MaiXEvent } from "src/app/types/models/maixevent.model";
import * as CurrentUserEventsActions from "../actions/current-user-events.actions";

@Injectable()
export class CurrentUserEventsEffects {

  pageNumber: number = 1;
  pageSize: number = 50;

  loadCurrentEventsForAUser$ = createEffect(() => this.actions$.pipe(
      ofType(CurrentUserEventsActions.LOAD_EVENTS_FOR_ONE_USER),
      mergeMap(action => this.eventService.GetAllEventsForAUserWithPagination(action.userId, this.pageNumber, this.pageSize)
      .pipe(
          tap((currentuserevents: MaiXEvent[]) => {

            if(!environment.production)
            {
              console.log('Current user events loaded successfully:', currentuserevents);
            }
          }),
          map(currentuserevents => CurrentUserEventsActions.LOAD_EVENTS_SUCCESS_FOR_ONE_USER({ currentuserevents })),
          catchError(error => of(CurrentUserEventsActions.LOAD_EVENTS_FAIL_FOR_ONE_USER({ error })))
      ))
      )
  );


  constructor(
    private actions$: Actions,
    private eventService: MaiXEventService
  ) {}
}