import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as EventActions from "../actions/maixevent.actions";
import { catchError, map, mergeMap, of, tap } from "rxjs";
import { Injectable } from "@angular/core";
import { MaiXEvent } from "src/app/types/models/maixevent.model";
import { MaiXEventService } from "src/app/shared/services/maiXEventRelated/mai-xevent.service";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

@Injectable()
export class MaiXEventEffects {
  pageNumber: number = 1;
  pageSize: number = 100;

  //#region loadEvents
  loadEvents$ = createEffect(() => this.actions$.pipe(
      ofType(EventActions.LOAD_EVENTS),
      mergeMap(() => this.maixEventService.GetAllEventsWithPagination(this.pageNumber, this.pageSize)
      .pipe(
          tap((maixevents: MaiXEvent[]) => {
              console.log('Events loaded successfully:', maixevents);
          }),
          map(maixevents => EventActions.LOAD_EVENTS_SUCCESS({ maixevents })),
          catchError(error => of(EventActions.LOAD_EVENTS_FAIL({ error })))
      ))
      )
  );

  //#endregion
  
  //#region addEvent
  addEvent$ = createEffect(() =>
      this.actions$.pipe(
        ofType(EventActions.ADD_EVENT),
        mergeMap(action =>
          this.maixEventService.AddEvent(action.maixevent).pipe(
            map((maixevent: MaiXEvent) => EventActions.ADD_EVENT_SUCCESS({ maixevent })),
            catchError(error => of(EventActions.ADD_EVENT_FAIL({ error })))
          )
        )
      )
    );
  //#endregion

  
  //#region eventsByStatus

  getEventsByStatus$ = createEffect(() => this.actions$.pipe(
    ofType(EventActions.GET_EVENTS_BY_STATUS),
    mergeMap(action => this.maixEventService.GetAllEventsByStatusWithPagination(action.eventStatusCode, action.pageNumber, action.pageSize)
    .pipe(
        tap((eventsByStatus : MaiXEvent[]) => {
          if(!environment.production)
          {
            console.log(`${eventsByStatus.length} Events with status ${action.eventStatusCode} loaded successfully:`);
          }
        }),
        map(eventsByStatus => EventActions.GET_EVENTS_BY_STATUS_SUCCESS({ eventsByStatus })),
        catchError(error => of( EventActions.GET_EVENTS_BY_STATUS_FAIL({ error })))
    ))
    )
  );

  
  //#endregion

  constructor(
      private actions$: Actions,
      private maixEventService: MaiXEventService
  ) {}
}