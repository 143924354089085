import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-leftbar',
  templateUrl: './leftbar.component.html',
  styleUrls: ['./leftbar.component.scss']
})
export class LeftbarComponent {
  @Output() sbCloser : EventEmitter<any> = new EventEmitter();

  @Output() toggleRightBarForMe : EventEmitter<any> = new EventEmitter();

    
  constructor(
      private router: Router,
      private cookieService: CookieService) 
  { }
  
  ngOnInit()
  {
  }

  goToDash()
  {
    this.closeSideBar();
    this.router.navigate([''])
  }

  goToActivities(){
    this.closeSideBar();

    this.router.navigate(['activitylist'])
  }
  
  goToProfile(){
    this.closeSideBar();

    // alert("In development")
    this.router.navigate(['dashboard'])
  }

  goToComps(){
    this.closeSideBar();

    // alert("In development")
    this.router.navigate(['competees'])
  }

  goToEvents(){
    this.closeSideBar();

    // alert("In development")
    // this.router.navigate(['eves'])
    this.router.navigate(['eventsperstatus']);
  }

  goToClubs(){
    this.closeSideBar();

    // alert("In development")
    this.router.navigate(['ubs'])
  }


  goToActMan()
  {
    this.closeSideBar();
    this.router.navigate(['activitiesman']);
  }

  goToUtil()
  {
    this.closeSideBar();
    this.router.navigate(['utils']);
  }

  goToAddrMan(){
    this.closeSideBar();
    this.router.navigate(['addrman']);
  }
  
  closeSideBar(){
    this.sbCloser.emit();
  }
}
