import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BankAccount } from 'src/app/types/models/bankAccount.model';
import { BookingClaim } from 'src/app/types/models/bookingClaim.model';
import { TransferReference } from 'src/app/types/models/transferReference.model';
import { TransferRecipientModel } from 'src/app/types/models/transferrecipient.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BookingClaimService {

  private apiUrl: string | undefined = environment.APIUrl;
  private paystackTranferRecipientApiUrl = environment.paystackRecipientBaseUrl //'https://api.paystack.co/transferrecipient';
  private paystackFetchTranferRecipientApiUrl = environment.paystackRecipientBaseUrl //'https://api.paystack.co/transferrecipient';
  private paystackUpdateTranferRecipientApiUrl = environment.paystackRecipientBaseUrl //'https://api.paystack.co/transferrecipient';
  private paystackTranferApiUrl = environment.paystackTransferUrl //'https://api.paystack.co/transferrecipient';
  private secretKey = environment.PaystackSecretKey //'YOUR_SECRET_KEY'; // replace with your actual secret key

  constructor(private http: HttpClient) { }

  createTransferRecipient(transferRecipient: TransferRecipientModel): Observable<any> {
    if(!environment.production)
    {
      console.log(`Using auth key: ${this.secretKey}`)
    }
    
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.secretKey}`,
      'Content-Type': 'application/json'
    });


    // const body = {
    //   type: 'basa',
    //   name: 'Joshua Soladoye',
    //   account_number: '51035401716',
    //   bank_code: '678910',
    //   currency: 'ZAR'
    // };

    return this.http.post(this.paystackTranferRecipientApiUrl, transferRecipient, { headers });
  }

  fetchTransferRecipient(recipientCode: string) : Observable<any>
  {
    if(!environment.production)
    {
      console.log(`Using auth key (for FETCH): ${this.secretKey}`)
    }
      
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.secretKey}`,
      'Content-Type': 'application/json'
    });


    return this.http.get(
      `${environment.paystackRecipientBaseUrl}/${recipientCode}`,
      { headers });
  }

  // Method to update transfer recipient
  updateTransferRecipient(idOrCode: string, bc: BankAccount): Observable<any> {
    //update transfer recipient
     let transferRecipient = new TransferRecipientModel(
                    
      bc?.RecipientType!,
      bc?.AccountName!,
      bc?.AccountNumber!,
      bc?.BankCode!,
      bc?.Currency!
    )
    
    if(!environment.production)
    {
      console.log(`Using auth key (for UPDATE): ${this.secretKey}`)
    }

    const url = `${environment.paystackRecipientBaseUrl}/${idOrCode}`;
    
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.secretKey}`,
      'Content-Type': 'application/json'
    });



    const body = {
      name: '',
      details: {
        account_number: `${bc.AccountNumber}`,
        account_name: `${bc.AccountName}`,
        bank_code: `${bc.BankCode}`,
        bank_name: `${bc.BankName}`,
      }
    };

    return this.http.put(url, transferRecipient, { headers });
  }

  createTransfer(transferReference: TransferReference): Observable<any> {
    if(!environment.production)
    {
      console.log(`Using auth key: ${this.secretKey}`)
    }

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.secretKey}`,
      'Content-Type': 'application/json'
    });

    return this.http.post(this.paystackTranferApiUrl, transferReference, { headers });
  }

  // use with caution. It marks our customer as inactive with Paystack
  deleteTransferRecipient(idOrCode: string): Observable<any> {
    const url = `${environment.paystackRecipientBaseUrl}/${idOrCode}`;
    
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.secretKey}`
    });

    return this.http.delete(url, { headers });
  }

  //=========================================================
  
  //=========================================================
  AddBookingClaim(bookingClaim: BookingClaim) {
    
    return this.http.post<any>(
      this.apiUrl + 'BookingClaim/AddBookingClaim', 
      bookingClaim, 
      this.getHttpOptions());
  }

  GetBookingClaimById(id: number) {
    let url = this.apiUrl + `BookingClaim/GetBookingClaimById?id=${id}`;
  
    return this.http.get<any>(
      url,
      this.getHttpOptions());
  }

  GetBookingClaimByReference(reference: string) {
    let url = this.apiUrl + `BookingClaim/GetBookingClaimByReference?recipientLinkRefernece=${reference}`;
  
    return this.http.get<any>(
      url,
      this.getHttpOptions());
  }

  GetAllBookingClaimsOfAUserByRecipientCode(recipientCode: string) {
    let url = this.apiUrl + `BookingClaim/GetAllBookingClaimsOfAUserByRecipientCode?paystackRecipientCode=${recipientCode}`;
  
    return this.http.get<any>(
      url,
      this.getHttpOptions());
  }

  UpdateBookingClaim(bookingClaimChanges: BookingClaim) {
    return this.http.put<any>(
      this.apiUrl + 'BookingClaim/UpdateBookingClaim', 
      bookingClaimChanges, 
      this.getHttpOptions());
  }

  getHttpOptions(){
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };

    return httpOptions;
  }
}
