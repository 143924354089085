<!-- <h3>
    User Object:
</h3>

{{usrObjectInp | json}}

<hr>  -->

<!-- 
<h3>

    Activities Open for promotion:
</h3>

    <div *ngFor="let aOFP of activitiesOpenForPromotion">

        {{aOFP | json}}

        <hr>
    </div> -->

<div class="container">

    <!-- <button 
    (click)="backToDashboard()"
    class="navtor" 
    type="button"
    style="color: black;">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
        </svg>
                
    </button> -->

    <ng-container>
        <div class="text-center">
    
          <button 
            style="border-radius: 40px; margin: 10px 0px 0px 0px;"
            class="btn btn-outline-primary">
            promotable activities
          </button>
        </div>
    </ng-container>
    
    <hr>

    <section *ngIf="activitiesOpenForPromotion && (activitiesPromoting$ | async) as activitiesPromoting">
        <!-- {{activitiesPromoting | json}} -->
        <ng-container *ngIf="activitiesOpenForPromotion!.length > 0">


            <a *ngFor="let activity of activitiesOpenForPromotion">
                <!-- {{activity | json}} -->
                <!-- <app-card 
                [entity]="activity"
                [isActivity]="true"  
                [showButton]="showButtonHistoryAndEdit"
                [showActivityPromoButton]="showActivityPromoButton"
                [loggedInUser]="usrObjectInp"></app-card> -->
            
                <app-card 
                    [entity]="activity"
                    [isActivity]="true"  
                    [showButton]="showButtonHistoryAndEdit"
                    [showActivityPromoButton]="showActivityPromoButton"
                    [showBookingButtonFromActivityPromotion]="showBookingButtonFromActivityPromotion"
                    [ActivityPromoterUserId]="activityPromoterUserId"
                    [loggedInUser]="usrObjectInp">
                </app-card>

                
                <br>        
            </a>

        </ng-container>

        <ng-container *ngIf="activitiesOpenForPromotion!.length == 0">
            <div 
            style="text-align: center; color: red;">
                Region has 0 promotable activities
            </div>
        </ng-container>
    </section>

</div>

