import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, tap } from "rxjs";
import { MaiXEventService } from "src/app/shared/services/maiXEventRelated/mai-xevent.service";
import { MaiXEvent } from "src/app/types/models/maixevent.model";
import { LOAD_FEATURED_EVENTS, LOAD_FEATURED_EVENTS_FAIL, LOAD_FEATURED_EVENTS_SUCCESS } from "../actions/featuredEvent.actions";

@Injectable()
export class FeaturedEventEffects {


    loadFeaturedEvents$ = createEffect(() => this.actions$.pipe(
        ofType(LOAD_FEATURED_EVENTS),
        mergeMap(() => this.maiXEventService.getFeaturedEvents(2)
        .pipe(
            tap((featuredEvents: MaiXEvent[]) => {
                console.log('Featured events loaded successfully:', featuredEvents);
            }),
            map(featuredEvents => LOAD_FEATURED_EVENTS_SUCCESS({ featuredEvents })),
            catchError(error => of(LOAD_FEATURED_EVENTS_FAIL({ error })))
        ))
        )
    );

    constructor(
        private actions$: Actions,
        private maiXEventService: MaiXEventService
    ) {}
}