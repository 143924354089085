import { Component, HostListener, Input, SimpleChanges } from '@angular/core';
import { BookingStatus } from 'src/app/types/enums/bookingStatis.enum';
import { Booking } from 'src/app/types/models/booking.model';
import { MatTableDataSource } from '@angular/material/table'
import { MEEntity } from 'src/app/types/enums/meEntity.enum';
import { AppState } from 'src/app/ngrx/state/app.state';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription, catchError, combineLatest, distinctUntilChanged, filter, first, from, map, mergeMap, of, switchMap, tap, toArray } from 'rxjs';
import { selectEntityBookings, selectEntityBookingsError, selectEntityBookingsLoaded, selectEntityBookingsLoading } from 'src/app/ngrx/selectors/entity-bookings.selectors';
import { LOAD_ENTITY_BOOKINGS } from 'src/app/ngrx/actions/entity-bookings.actions';
import { MaiXEvent } from 'src/app/types/models/maixevent.model';
import { Activity } from 'src/app/types/models/activity.model';
import { ActivityService } from 'src/app/shared/services/activity.service';
import { MaiXEventService } from 'src/app/shared/services/maiXEventRelated/mai-xevent.service';
import { UserSpecificService } from 'src/app/shared/services/dataService/user-specific.service';
import { environment } from 'src/environments/environment';
import { ObfuscationServiceService } from 'src/app/shared/services/obfuscation-service.service';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { ToastrService } from 'ngx-toastr';
import { BankAccountService } from 'src/app/shared/services/claimsRelated/bank-account.service';
import { BankAccount } from 'src/app/types/models/bankAccount.model';
import { BookingClaimService } from 'src/app/shared/services/claimsRelated/booking-claim.service';
import { TransferRecipientModel } from 'src/app/types/models/transferrecipient.model';
import { PaystackRecipientLinkService } from 'src/app/shared/services/claimsRelated/paystack-recipient-link.service';
import { PaystackRecipientLink } from 'src/app/types/models/paystackRecipientLink.model';
import { HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment';
import { BookingClaim } from 'src/app/types/models/bookingClaim.model';
import { v4 as uuidv4} from 'uuid';
import { BookingService } from 'src/app/shared/services/booking.service';

// Declare bootstrap as a global variable for TypeScript to recognize
declare var bootstrap: any;

@Component({
  selector: 'app-booking-table',
  templateUrl: './booking-table.component.html',
  styleUrls: ['./booking-table.component.scss']
})
export class BookingTableComponent {
  @Input() tableTitle: string = '';
  @Input() tableCommand: string = 'future'; // can be: future, past or current
  @Input() mEEntity: MEEntity | undefined;
  @Input() entityId: number = 1;
  @Input() redirectToActivityOnClickBack: string = 'activityhost';
  @Input() redirectToEventOnClickBack: string = 'eventhost';


  // entityBookings$!: Observable<Booking[]>;
  entityBookings$!: Observable<Booking[] | any>;
  entityBookingsLoading$!: Observable<boolean>;
  entityBookingsLoaded$!: Observable<boolean>;
  entityBookingsError$!: Observable<string | null>;

  aggregateUnclaimedBookings$!: Observable<any>;
  totalPricePerPerson$!: Observable<number>;

  private subscriptions: Subscription[] = [];

  panelOpenState = false;
  
  activityServiceFee = environment.ActivityServiceFee
  eventServiceFee = environment.EventServiceFee
  
  rtImgPath: string = environment.fileRetriever;

  private useIdSubject = new BehaviorSubject<string | null>('');
  userId$: Observable<string | null> = this.useIdSubject.asObservable();

  allUserAccounts: BankAccount[] | undefined;
  userDefaultAccount: BankAccount | undefined;

  aggregateTotalPayable: number = 0;

  matchedClaimStatus$!: Observable<string>;
  
  constructor(private activityService: ActivityService,
    private bankaccountService: BankAccountService, 
    private mxEventService: MaiXEventService,
    private userService: UserSpecificService,
    private obfuscationService: ObfuscationServiceService,
    private bookingService: BookingService,
    private bookingClaimsService: BookingClaimService,
    private paystackRecipientLinkService: PaystackRecipientLinkService,
    private dialogSvc: DialogService,
    private authSvc: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private store: Store<AppState>) {
    // get the current user activities from store
    this.entityBookings$ = this.store.pipe(select(selectEntityBookings))
    this.entityBookingsLoading$ = this.store.pipe(select(selectEntityBookingsLoading))
    this.entityBookingsLoaded$ = this.store.pipe(select(selectEntityBookingsLoaded))
    this.entityBookingsError$ = this.store.pipe(select(selectEntityBookingsError))
  }

  ngOnInit(): void {
    console.log("current ME: " + this.mEEntity);
    console.log("entity ID: " + this.entityId);

    this.loadBookings();

    // Inside ngOnInit
   
    this.aggregateUnclaimedBookings$ = this.entityBookings$.pipe(
      switchMap((bookings: any[]) =>
        from(bookings).pipe(
          filter(booking => (!booking.IsClaimed) || 
                  (booking.IsClaimed && booking.matchedClaim.Status !== 'success')), // Only include unclaimed bookings
          toArray()
        )
      )
    );
  }


  getTotalPayableToActivityHost(bookings: Booking[]): number {
    return bookings.reduce((sum, booking) => {
      // Use type assertion to access relatedEntity
      // const relatedEntity = (booking as any).relatedEntity;
      // const pricePerPerson = relatedEntity?.PricePerPerson || 0;
      const totalPayable = booking.PayableAmount || 0;
      this.aggregateTotalPayable = sum + totalPayable;
      return this.aggregateTotalPayable;
    }, 0);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }


  //future past current
  loadBookings(): void {
    // Always dispatch the action to load bookings each time this template loads

    // Make this PAGINATED as soon as you can
    this.store.dispatch(LOAD_ENTITY_BOOKINGS({ mEEntity: this.mEEntity!, entityId: this.entityId }));

    this.subscriptions.push(
      this.store.pipe(
        select(selectEntityBookings),
        tap(entityBookings => {
          if (entityBookings) {
            this.applyFilter(this.tableCommand);
          }
        })
      ).subscribe()
    );
  }


  private applyFilter(commandString: string): void {
    const today = new Date();
    
    this.entityBookings$ = this.entityBookings$.pipe(
      switchMap((bookings: Booking[]) => {
        let filteredData: Booking[] = [];
  
        switch (commandString.toLowerCase()) {
          case 'future':
            filteredData = bookings.filter(booking => new Date(booking.DateCreatedFor!) > today);
            break;
          case 'past':
            filteredData = bookings.filter(booking => new Date(booking.DateCreatedFor!) < today);
            break;
          case 'current':
            filteredData = bookings.filter(booking => {
              const bookingDate = new Date(booking.DateCreatedFor!);
              return bookingDate.toDateString() === today.toDateString();
            });
            break;
          case 'all':
            filteredData = bookings;
            break;
          default:
            filteredData = [];
            break;
        }
  
        // return filteredData;
        
        return from(filteredData).pipe(
          mergeMap(booking => this.fetchRelatedEntity(booking)),
          toArray()
        );
      })
    );
  
    // Update aggregateUnclaimedBookings$ after applying the filter
    // this.aggregateUnclaimedBookings$ = this.entityBookings$.pipe(
    //   switchMap((bookings: Booking[]) =>
    //     from(bookings).pipe(
    //       filter((booking: any) => !booking.IsClaimed && booking.matchedClaim?.Status !== 'success'),
    //       toArray()
    //     )
    //   )
    // );
  }


  private fetchRelatedEntity(booking: Booking): Observable<Booking> {
    let entityObservable: Observable<any>;
  
    switch (booking.mEEntity) {
      case MEEntity.Activity:
        entityObservable = this.activityService.GetActivityById(booking.EntityId);
        break;
      case MEEntity.Event:
        entityObservable = this.mxEventService.GetEventById(booking.EntityId);
        break;

      // case MEEntity.Competition:
      //   entityObservable = this.competitionService.GetCompetitionById(booking.entityId);
      //   break;
      // case MEEntity.Club:
      //   entityObservable = this.clubService.GetClubById(booking.entityId);
      //   break;

      default:
        entityObservable = of(null);
        break;
    }
  

    // Fetch related user information
    const userThatBookedObservable = this.userService.GetUserByIdForAppByAppId(booking.GenericUserId, environment.AppBoundIdentifier);
    
    // const userObservable: Observable<any> = entityObservable
    // //  this.userService
    //   // .GetUserByIdForAppByAppId(booking.GenericUserId, environment.AppBoundIdentifier)
    //   .pipe(
    //     switchMap((entityUserResponse) => {
    //       // recipientLinkResponse is the result from the first observable
    //       // You can now call another method that depends on this response
    //       // return this.userService.GetUserByIdForAppByAppId(
    //       //   entityUserResponse.UserId, 
    //       //   environment.AppBoundIdentifier)

    //       return this.userService.GetUserByIdForAppByAppId(
    //         booking.GenericUserId, 
    //         environment.AppBoundIdentifier)

    //     })
    //   )

    
    //link current user to their claims
    
    const paystackRecipientLinkObservable = entityObservable
    .pipe(
      switchMap((entityResponse) => {
        
        return this.paystackRecipientLinkService
          .GetPaystackRecipientLinkByUserId(entityResponse?.UserId)
      })
    )
    
    
    // Sort claims by CreatedAt in descending order and get the first 
    // the sorting is needed for DEV cases where you have claimed the same items many times
    const matchedClaimObservable: Observable<any> = paystackRecipientLinkObservable
      .pipe(
        switchMap((recipientLinkResponse) => {
          
          // recipientLinkResponse is the result from the first observable
          // You can now call another method that depends on this response
          return this.bookingClaimsService
          .GetAllBookingClaimsOfAUserByRecipientCode(
            recipientLinkResponse.Recipient_code)
            .pipe(
              // Sort the claims by CreatedAt in descending order and get the first one
              map((claims) => {
                const sortedClaims = claims.sort((a: any, b: any) => new Date(b.CreatedAt).getTime() - new Date(a.CreatedAt).getTime());
                return sortedClaims[0]; // Return the first claim directly
              })
            );

        })
      )


      
    // Combine both observables
    return combineLatest(
      [
        entityObservable, 
        userThatBookedObservable, 
        paystackRecipientLinkObservable,
        matchedClaimObservable])
    .pipe(
      map(([relatedEntity, userThatMadeBooking, paystackLocalLink, matchedClaim]) => {
        
        

        return { 
          ...booking, 
          relatedEntity, //activity or event data
          paystackLocalLink, 
          matchedClaim,
          userThatMadeBooking // Add the additional data to the booking object
        };
      })
    );

   
  }


  getFormattedDate(dateString: string): string {
    // Parse the date string to a Date object
    const date = new Date(dateString);
  
    // Extract year, month, and day
    const year = date.getFullYear();
    const day = String(date.getDate()).padStart(2, '0');
    
    // Array of month names
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
  
    // Get month name
    const month = monthNames[date.getMonth()];
  
    // Extract hours and minutes
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    
    // // Format to "24 Mar 2024"
    // return `${day} ${month} ${year}`;

     // Format to "24 Mar 2024 15:30"
    return `${day} ${month} ${year} ${hours}:${minutes}`;
  }


  ViewActivity(activityId: number)
  {
    let obfsActivityId = this.obfuscationService.encryptEventId(activityId.toString());

    if(!environment.production)
    {
      console.log(`Before obs: ${activityId} | obfuscation of activity ID: ${obfsActivityId}`)
    }

    this.router.navigate(['activitydetails', obfsActivityId]);
  }

  backToSpecifiedRedirect()
  {
    //what if the route requires ID or any other data
    if(this.mEEntity == 0)
    {
      this.router.navigate([`${this.redirectToActivityOnClickBack}`])
    }
    else if (this.mEEntity == 1)
    {
      this.router.navigate([`${this.redirectToEventOnClickBack}`])
    }
  }

  // userHasAtLeaseOneDefaultBankAccount(userId: string): boolean
  // {
  //   //
  //   if(userId)
  //   {
  //     try{
  //       // const allUserAccounts = await this.bankaccountService.GetAllBankAccountsOfAUserByUserId(userId).toPromise();
  //       this.bankaccountService.GetAllBankAccountsOfAUserByUserId(userId)
  //       .subscribe((bankAccs: any) => {

  //         if (!environment.production) {
  //           console.log(`Bank accounts for user: ${userId} = ${JSON.stringify(bankAccs)}`);
  //         }
  
  //         this.allUserAccounts = bankAccs;

  //         // Find out if there is a default bank account
  //         // const defAcc = allUserAccounts.find((x: any) => x.IsDefault);
  //         // return !!defAcc;  // Return true if found, false otherwise
  //         this.userDefaultAccount = bankAccs?.find((x: any) => x.IsDefault);
          
  //         if (!environment.production) {
  //           console.log(`user: ${userId} default account: ${JSON.stringify(this.userDefaultAccount)}`);
  //         }

  //         return (this.userDefaultAccount) ? true : false;
  //         // return !!this.userDefaultAccount;  // Return true if found, false otherwise
  //       })
  //     }
  //     catch (error) {
  //       if(!environment.production)
  //       {
  //         console.error(error);
  //       }
  //       return false;  // Return false in case of error
  //     }
  //   }

  //   return false; // Return false if no userId is provided
  // }

  UpdateBankDetails(usrId: string)
  {
    if(!environment.production)
    {
      console.log(`Logged in user ID: ${usrId}`);
    }

    this.dialogSvc.ShowUserBankAccountsDialog(usrId)
    .afterClosed().subscribe(_ => {

        if(!environment.production)
        {
          console.log(`Result from edit dialog APS component: ${JSON.stringify(_)}`)
        }
        //response can be "addbankaccount" or nothing

        if(_.includes("addbankaccount"))
        {
          //navigate and open the add new dialog component and send userId to it
          this.dialogSvc.AddBankDetailsDialog(usrId)
          .afterClosed().subscribe(_ab => {

            if(!environment.production)
            {
              console.log(`Result from edit dialog APS component: ${JSON.stringify(_ab)}`)

              //respomse will be "noaction" or "refresh" or "noactionDefault"

              // if(_.includes("refresh"))
              // {
              //   window.location.reload();

              // }

              if(_ab.includes("bankaccountadded"))
              {
                //open up the list modal again
                this.UpdateBankDetails(usrId)
              }
            }


          })
        }
    });
  }

  claimBookings(IsIndividualClm: boolean = false, IsAggregateClm: boolean = true)
  {
    if(!environment.production)
    {
      console.log('starting claimBookings');
    }
    
    //timezone
    let timezone = 'Africa/Johannesburg';

    //transfer reference:  new v4 UUID
    let txRef = uuidv4();

    if(!environment.production)
    {
      console.log(`Transfer reference: ${txRef}`)
    }

    //BookingId(s): all the ids that constitutes to this transfer
    // Subscribe to the observable to extract the BookingIds
    this.aggregateUnclaimedBookings$.pipe(
      map(unclaimedBookings => unclaimedBookings.map((booking: any) => booking.Id)), // Extract all Id fields
      first() // Only take the first emission
    ).subscribe((bookingIds: number[]) => {
      const bookingIdsString = bookingIds.join(', ');

      if(!environment.production)
      {
        console.log('Unclaimed Booking Ids:', bookingIdsString);
      }

      // You can now use the bookingIds array as needed

      //get logged in user ID
      this.authSvc.GetCurrentlyLoggedInUserIdIfAny().subscribe((usrResponse: string) => {
        if(usrResponse)
        {
          this.useIdSubject.next(usrResponse);

          if(!environment.production)
          {
            console.log(`Currently logged in user from booking history: ${this.useIdSubject.getValue()!}`)
          }

            //confirm that user has at least one default account
          this.bankaccountService.GetAllBankAccountsOfAUserByUserId(this.useIdSubject.getValue()!)
          .subscribe((bankAccs: any) => {

            if (!environment.production) {
              console.log(`Bank accounts for user: ${this.useIdSubject.getValue()!} = ${JSON.stringify(bankAccs)}`);
            }
    
            this.allUserAccounts = bankAccs;

            // Find out if there is a default bank account
            // const defAcc = allUserAccounts.find((x: any) => x.IsDefault);
            // return !!defAcc;  // Return true if found, false otherwise
            this.userDefaultAccount = bankAccs?.find((x: any) => x.IsDefault);
            
            if (!environment.production) {
              console.log(`user: ${this.useIdSubject.getValue()!} default account: ${JSON.stringify(this.userDefaultAccount)}`);
            }

            if(this.userDefaultAccount)
            {
              //ask the user if they want to add a new account or 
              // if they want to proceed with the found default
              if(confirm(`Default account found: ${this.userDefaultAccount?.BankName} - ${this.userDefaultAccount?.AccountNumber}. Proceed with this account ?`))
              {
                //log info
                if(!environment.production)
                {
                  console.log(`All user accounts: ${JSON.stringify(this.allUserAccounts)} and default account: ${JSON.stringify(this.userDefaultAccount)}`)
                }

                //process all unclaimed bookings into the default bank account
                this.toastr.success("Account validated", "Verifying details with merchant...")
                

                // **********************************************************

                // process with merchant
                this.ProcessClaimsWithMerchant(txRef, IsIndividualClm, IsAggregateClm, bookingIdsString, timezone);
                
                // **********************************************************
              }
              else
              {
                //confirm if they want to add a new account
                if(confirm(`Add a new account ?`))
                {
                  this.UpdateBankDetails(this.useIdSubject.getValue()!);
                }
              }
            }
            else {
              // prompt to add/set default bank account

              if(confirm("Banking details could not be retrieved. Update details to complete claim."))
              {
                this.UpdateBankDetails(this.useIdSubject.getValue()!);
              }

              //************************ */
            }

          })
        }
        else{
          if(!environment.production)
          {
            console.log("Unable to get logged in user: " + usrResponse);
          }

          this.toastr.error("Authentication error occurred. Please login again... ", "Authentication error")
          
          // Set a delay before showing the error message
          setTimeout(() => {
            this.router.navigate(['login'])
          }, 3000); // 2000 milliseconds = 2 seconds
        }
      });


    });   
  }

  createRecipientAndPersist(
    txRef: string,
    IsIndiClm: boolean = false, 
    IsAggrClm: boolean = true, 
    transferRecipient: TransferRecipientModel, 
    bookingIdsString: string,
    timezone: string)
  {
     // ==> create the recipient
     this.bookingClaimsService.createTransferRecipient(transferRecipient)
     .subscribe(
       (response: any) => {
         if(!environment.production)
         {
           console.log('Transfer recipient created successfully:', JSON.stringify(response));
         }

         //save the response code of the user
         let paystackRecipientLinkCreationResponse = new PaystackRecipientLink(
           0,
           this.useIdSubject.getValue()!,
           response?.data?.recipient_code ?? '',
           moment(new Date()).tz(timezone).toString(),
           environment.production ? "live" : "test"
         )

         if(!environment.production)
         {
           console.log(`Response from creating new recipient: ${JSON.stringify(paystackRecipientLinkCreationResponse)}`)
         }

         const saveRecipientLinkObserver = {
           next: async (persistToDbresponse: any) => {
             if(!environment.production)
             {
               console.log(`Response from adding recipient link: ${JSON.stringify(persistToDbresponse)}`);
             }
   
             this.toastr.success("Link to merchant established...", "Status update");

             //create BookingClaim
             let bookingClaimTx = new BookingClaim(
               0,
               txRef,
               IsIndiClm,
               IsAggrClm,
               bookingIdsString,
               this.aggregateTotalPayable, //this was calculated in the getTotalPayableToActivityHost() method
               response?.data?.recipient_code,
               "pending",
               moment(new Date()).tz(timezone).toString()
             )
             
             if(!environment.production)
             {
               console.log(`bookingClaim Tx before sending to generateTransferReference: ${JSON.stringify(bookingClaimTx)}`)
             }

             //if we created a new transfer recipient then we need to proceed to generate a transfer reference
             this.generateTransferReference(bookingClaimTx);
           },
           error: async (err: HttpErrorResponse) => {
             if(!environment.production)
             {
               console.log(`Error while establishing connection with merchant: ${JSON.stringify(err)}`);
             }
   
             // this.toastr.error(err?.error?.Message, "Error");
           },
           complete: async () => {
             if(!environment.production)
             {
               console.log(`User link to paystack complete!`);
             }
           }
         }
   
         //saved the response to the receipient link table
         this.paystackRecipientLinkService.AddPaystackRecipientLink(paystackRecipientLinkCreationResponse)
         .subscribe(saveRecipientLinkObserver)
       }
     );
  }


  ProcessClaimsWithMerchant(
    txRef: string,
    IsIndiClm: boolean = false, 
    IsAggrClm: boolean = true, 
    bookingIdsString: string,
    timezone: string)
  {
    //#1. check our DB to see if user has a recipient_code. 
    //    If not, create a transfer recipient (if the user does not already have a reference)
    this.paystackRecipientLinkService.GetPaystackRecipientLinkByUserId(this.useIdSubject.getValue()!)
    .subscribe((usrLinkresponse: PaystackRecipientLink) => {
      //check that we have at least one record
      if(!environment.production)
      {
        console.log(`User Link: ${JSON.stringify(usrLinkresponse)}`)
      }

      //if user does not have recipient_code, create it
      if(!usrLinkresponse) {

        //create transfer recipient
        let transferRecipient = new TransferRecipientModel(
          
          this.userDefaultAccount?.RecipientType!,
          this.userDefaultAccount?.AccountName!,
          // '', // user names: John Doe
          this.userDefaultAccount?.AccountNumber!,
          this.userDefaultAccount?.BankCode!,
          this.userDefaultAccount?.Currency!
        )

        //create recipient with merchange and and save persist in mxp -> DB
        this.createRecipientAndPersist(txRef, IsIndiClm, IsAggrClm, transferRecipient, bookingIdsString, timezone);

      }
      else  //user already has a recipient code (do checks and confirm that everything is good)
      {
        //  2. ensure that the account we have as default is in sync with the account the merchant has
        this.bookingClaimsService.fetchTransferRecipient(usrLinkresponse.Recipient_code)
        .subscribe((recipientCheckResponse: any) => {
          if(!environment.production)
          {
            console.log(`fetch recpient response (for delta check): ${JSON.stringify(recipientCheckResponse)}`)
          }

          //check for delta in bank account information
          if(
            recipientCheckResponse.data.details.bank_name != this.userDefaultAccount?.BankName ||
            recipientCheckResponse.data.details.account_number != this.userDefaultAccount?.AccountNumber ||
            recipientCheckResponse.data.details.account_name != this.userDefaultAccount?.AccountName) {

              //  if there is delta, we have to update the merchant
              // delete that recipient and add a new one
              this.bookingClaimsService.deleteTransferRecipient(usrLinkresponse.Recipient_code)
              .pipe(
                catchError((error) => {
                  if(environment.production)
                  {
                    // Handle the error here
                    console.error('Error deleting transfer recipient with merchant:', error);
                  }
                  
                  //assuming the error is because merchant does not have the recipient
                  //we have to proceed and delete in our own DB (coz it was found before)
                  this.deleteRecipientLocallyAndProcessAgain(
                    txRef, 
                    IsIndiClm, 
                    IsAggrClm, 
                    bookingIdsString, 
                    timezone,
                    usrLinkresponse.Recipient_code)

                  // Return an observable that emits a mock response to continue the flow
                  return of({ status: false, message: 'Handled locally after error' });
                })
              )
              .subscribe((deleteRecipientResponse: any) => {

                if(!environment.production)
                {
                  console.log(`delete recipient response (for delta): ${JSON.stringify(deleteRecipientResponse)}`)
                }

                // response of deleting a recipient
                // Ensure response has a 'status' property
                if (deleteRecipientResponse && deleteRecipientResponse.status) {
                  //old recipient has been deleted successfully
                  this.deleteRecipientLocallyAndProcessAgain(
                    txRef, 
                    IsIndiClm, 
                    IsAggrClm, 
                    bookingIdsString, 
                    timezone,
                    usrLinkresponse.Recipient_code)
                }
                else{

                  //the above: deleteRecipientLocallyAndProcessAgain() should have handled it
                  if(!environment.production)
                  {
                    console.log(`the above: deleteRecipientLocallyAndProcessAgain() should have handled it`);
                  }
                }
              })
              // then proceed to (3) - intiate the transfer
         
          }
          else
          {
            // only if there is no delta, do we initiate the transfer: banking information is in sync

            //  3. Initiate the transfer

            //  3.1 create BookingClaim (transfer object)

            let bookingClaimTx = new BookingClaim(
              0,
              txRef,
              IsIndiClm,
              IsAggrClm,
              bookingIdsString,
              this.aggregateTotalPayable, //this was calculated in the getTotalPayableToActivityHost() method,
              usrLinkresponse?.Recipient_code,
              "pending",
              moment(new Date()).tz(timezone).toString()
            )

            //2.2 persist the booking claim
            this.generateTransferReference(bookingClaimTx);

          }
        })
        
        //4. Listen for transfer status (done with webhook in the API)
      }

    });

  }

  deleteRecipientLocallyAndProcessAgain(
    txRef: string,
    IsIndiClm: boolean = false, 
    IsAggrClm: boolean = true, 
    bookingIdsString: string,
    timezone: string,
    recipientCode: string
  )
  {
     // delete it in our database
     this.paystackRecipientLinkService.DeletePaystackRecipientLinkByRecipientCode(recipientCode)
     .subscribe((localDeletionResponse: any) => {
       if(!environment.production)
       {
         console.log(`local deletion of recipient link response: ${JSON.stringify(localDeletionResponse)}`)
       }

       let timezone = 'Africa/Johannesburg';
        
       //call yourself again
       this.ProcessClaimsWithMerchant(txRef, IsIndiClm, IsAggrClm, bookingIdsString, timezone);
       
      
       // this.ProcessClaimsWithMerchant(timezone);
     })
  }

  generateTransferReference(bookingClaimTx: BookingClaim) {

    if(!environment.production)
    {
      console.log(`Tx Reference: ${JSON.stringify(bookingClaimTx)}`)
    }

    // transfer body
    const body = {
      source: 'balance',
      amount: Math.round(bookingClaimTx.AmountPaid) * 100,
      reference: bookingClaimTx.Reference,
      recipient: bookingClaimTx.RecipientCode,
      reason: "service claim"
    };

    if(!environment.production)
    {
      console.log(`Claim transfer body: ${JSON.stringify(body)}`)
    }

      
    //1.1 initiate the transfer with paystack
    this.bookingClaimsService.createTransfer(body)
    .subscribe((transferInitResponse: any) => {
      //log
      if(!environment.production)
      {
        console.log(`Transfer initiation response: ${JSON.stringify(transferInitResponse)}`)
      }

      // if the initiation is true
      if(transferInitResponse.status)
      {
        //update the status
        bookingClaimTx.Status = transferInitResponse.data.status

        //1.2 persist in our DB
        this.bookingClaimsService.AddBookingClaim(bookingClaimTx)
        .subscribe((persistenceResponse: any) => {
          if(!environment.production)
          {
            console.log(`Persistence claim response: ${JSON.stringify(persistenceResponse)}`)
          }
    
          //ensure we have successfully saved the claim
          if(persistenceResponse.Value.includes("Booking claim added"))
          {
            this.toastr.success("Tranfer successfully initiated...", "Transfer initiated")
            this.authSvc.SendClaimEmail(transferInitResponse);
          }

          //update each processed booking if status is immediately success
          if(transferInitResponse.data.status == "success")
          {
            this.updateProcessedBooking(bookingClaimTx.BookingIds)
          }
        })
      }

    })
  }

  verifyTransfer()
  {
    //verify a transfer made

    //update the database

    //notify the user
  }

  updateProcessedBooking(bookingIds: string)
  {
    //
    if(!environment.production)
    {
      console.log(`Ids to update ${bookingIds}`)
    }

    this.bookingService.UpdateClaimsById(bookingIds)
    .subscribe((response: any) => {
      if(!environment.production)
      {
        console.log(`Update status: ${JSON.stringify(response)}`)
      }

      if(response.Value == "Bookings updated successfully.")
      {
        //update this to update only the satate later
        // window.location.reload();
        this.loadBookings()
        //
      }
    })
  }
}
