<mat-toolbar style="background-color: rgb(29, 22, 22);" class="header-toolbar">
    
    <a 
    class="navbar-brand" 
    (click)="goToDashboard()"
    style="border: 1px solid white;">
      <span style="padding: 15px 7px 15px 7px; color: white;">
          mxp
      </span>
    </a>
    
    <!-- Spacer to push menu to the right -->
    <span class="spacer"></span>

    <!-- Menu Section --> 
    <nav>
       
        <!-- when user is not logged in -->
        <ng-container *ngIf="!(userSvc.loggedIn$ | async)">
            <!-- search menu -->
            <ng-container>
                <button mat-icon-button [matMenuTriggerFor]="toolingMenu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #toolingMenu="matMenu">
                    <!-- <button mat-menu-item>
                        <mat-icon>dialpad</mat-icon>
                        <span>Redial</span>
                    </button>

                    <button mat-menu-item disabled>
                        <mat-icon>voicemail</mat-icon>
                        <span>Check voice mail</span>
                    </button>

                    <button mat-menu-item>
                        <mat-icon>notifications_off</mat-icon>
                        <span>Disable alerts</span>
                    </button> -->

                    <button 
                        mat-menu-item
                        (click)="goToLogin()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"/>
                            <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
                        </svg>

                        Login
                    </button>

                    <button 
                        mat-menu-item
                        (click)="goToRegister()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                          </svg>
                        Sign up
                    </button>

                </mat-menu>
            </ng-container>

            <!-- 
            <button 
                mat-button
                (click)="goToLogin()">
                Login
            </button>

            <button 
                mat-button
                (click)="goToRegister()">
                Sign up
            </button> -->
        </ng-container>
        
        <!-- when user is logged in -->
        <ng-container *ngIf="userSvc.loggedIn$ | async">

            <!-- profile menu -->
            <ng-container>
                <button 
                    mat-icon-button 
                    [matMenuTriggerFor]="profile" 
                    aria-label="Example icon-button with a menu">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
                    </svg>
                </button>
                <mat-menu #profile="matMenu">
                    <button 
                        mat-menu-item
                        *ngIf="canViewDashboard$ | async">
                        <svg style="color: red;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-activity" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2"/>
                        </svg>
                        <span
                            (click)="goToHostDashboard()">
                            Activity-Host Dashboard
                        </span>
                    </button>

                    <button 
                        mat-menu-item 
                        *ngIf="canViewEventDashboard$ | async">
                        <svg style="color: rgb(57, 57, 210);" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar2-event" viewBox="0 0 16 16">
                            <path d="M11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z"/>
                            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1z"/>
                            <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5z"/>
                        </svg>
                        <span
                            (click)="goToEventHostDashboard()">
                            Event-Host Dashboard
                        </span>
                    </button>

                    <button mat-menu-item>
                        <svg style="color: rgb(62, 228, 40);" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-lines-fill" viewBox="0 0 16 16">
                            <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1z"/>
                        </svg>
                        <span
                            (click)="goToProfile()">
                            Profile
                        </span>
                    </button>

                    <button mat-menu-item>
                        <svg style="color: rgb(239, 232, 24);" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-left" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0z"/>
                            <path fill-rule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708z"/>
                          </svg>
                        <span
                            (click)="logOff()">
                            Sign out
                        </span>
                    </button>
                </mat-menu>
            </ng-container>
    

            <!-- search menu -->
            <!-- <ng-container>
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item>
                    <mat-icon>dialpad</mat-icon>
                    <span>Redial</span>
                    </button>
                    <button mat-menu-item disabled>
                    <mat-icon>voicemail</mat-icon>
                    <span>Check voice mail</span>
                    </button>
                    <button mat-menu-item>
                    <mat-icon>notifications_off</mat-icon>
                    <span>Disable alerts</span>
                    </button>
                </mat-menu>
            </ng-container> -->
        </ng-container>


        
      
    </nav>
</mat-toolbar>