import { createReducer, on } from "@ngrx/store";
import { MaiXEventState } from "../state/maixevent.state";
import * as EventActions from "../actions/maixevent.actions";

const initialState: MaiXEventState = {
    maixevents: [],
    loading: false,
    loaded: false,
    error: null,
};


//
// Note: the 'on' method takes 2 arguments
//      1. The action type to handle
//      2. A function that specifies how the state should be updated when action is dispatched

const _maixEventsReducer = createReducer(
    initialState,

    // #region loadEvents
    on(EventActions.LOAD_EVENTS, state => ({ ...state, loading: true })),
    on(EventActions.LOAD_EVENTS_SUCCESS, (state, { maixevents }) => ({
        ...state,
        maixevents: [...maixevents],
        loading: false,
        loaded: true,
        error: null,
    })),
    on(EventActions.LOAD_EVENTS_FAIL, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error,
    })),

    //#endregion

    // #region addEvent
    on(EventActions.ADD_EVENT, state => ({ ...state, loading: true })),
    on(EventActions.ADD_EVENT_SUCCESS, (state, { maixevent }) => ({
        ...state,
        maixevents: [...state.maixevents, maixevent],
        loading: false,
        loaded: true,
        error: null,
    })),
    on(EventActions.ADD_EVENT_FAIL, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error,
    })),
    //#endregion

    // #region getEventsByStatus
    on(EventActions.GET_EVENTS_BY_STATUS, state => ({ ...state, loading: true })),
    on(EventActions.GET_EVENTS_BY_STATUS_SUCCESS, (state, { eventsByStatus }) => ({
        ...state,
        eventsByStatus: eventsByStatus,
        loading: false,
        loaded: true,
        error: null,
    })),
    on(EventActions.GET_EVENTS_BY_STATUS_FAIL, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error,
    }))
    //#endregion
);

export function maixEventReducer(state: any, action: any) {
    return _maixEventsReducer(state, action);
  }