import { Component } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { AddressService } from 'src/app/shared/services/address.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ActivityService } from 'src/app/shared/services/activity.service';
import { Time } from '@angular/common';
import { ActivityStatus } from 'src/app/types/enums/activityStatus.enum';

@Component({
  selector: 'app-all-activities',
  templateUrl: './all-activities.component.html',
  styleUrls: ['./all-activities.component.scss']
})
export class AllActivitiesComponent {
 
  activeStus: ActivityStatus = ActivityStatus.Active;

  
  constructor() 
  { }

  ngOnInit()
  {
   
  }
}








