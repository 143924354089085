import { createAction, props } from "@ngrx/store";
import { MaiXEvent } from "src/app/types/models/maixevent.model";


//#region loadEvents
export const LOAD_EVENTS = createAction('[MaiXEvents] Load Events');

export const LOAD_EVENTS_SUCCESS = createAction('[MaiXEvents] Load Events Success',
    props<{maixevents: MaiXEvent[]}>());

export const LOAD_EVENTS_FAIL = createAction('[MaiXEvents] Load Events Fail',
    props<{error: string}>());

//#endregion

//#region add_event
export const ADD_EVENT = createAction('[MaiXEvent] Add Event',
    props<{ maixevent: MaiXEvent }>());

export const ADD_EVENT_SUCCESS = createAction('[MaiXEvent] Load Events Success',
    props<{maixevent: MaiXEvent}>());

export const ADD_EVENT_FAIL = createAction('[MaiXEvent] Load Events Fail',
    props<{error: string}>());

//#endregion

// #region activeEvents : not working

export const GET_EVENTS_BY_STATUS = createAction('[MaiXEvent] Get events by status',
    props<{ eventStatusCode: number, pageNumber: number, pageSize: number }>());
    
export const GET_EVENTS_BY_STATUS_SUCCESS = createAction('[MaiXEvent] Get events by status Success',
    props<{ eventsByStatus: MaiXEvent[]}>());

export const GET_EVENTS_BY_STATUS_FAIL = createAction('[MaiXEvent] Get events by status Fail',
    props<{error: string}>());

//#endregion