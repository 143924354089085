import { createFeatureSelector, createSelector } from "@ngrx/store";
import { FutureEventCreatorsState } from "../state/future-events-creator.state";
import { ActiveActivityCreatorsState } from "../state/active-activity-creators.state";

export const selectActiveActivityCreatorState = createFeatureSelector<ActiveActivityCreatorsState>('activeactivitycreators');

export const selectActiveActivityCreators = createSelector(
    selectActiveActivityCreatorState,
  (state: ActiveActivityCreatorsState) => state.activeactivitycreators
);

export const selectActiveActivityCreatorsLoading = createSelector(
    selectActiveActivityCreatorState,
  (state: ActiveActivityCreatorsState) => state.loading
);

export const selectActiveActivityCreatorsLoaded = createSelector(
    selectActiveActivityCreatorState,
    (state: ActiveActivityCreatorsState) => state.loaded
  );

export const selectActiveActivityCreatorsError = createSelector(
    selectActiveActivityCreatorState,
  (state: ActiveActivityCreatorsState) => state.error
);
