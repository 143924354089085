import { createFeatureSelector, createSelector } from "@ngrx/store";
import { EntityBookingState } from "../state/entity-bookings.state";

export const entityBookingState = createFeatureSelector<EntityBookingState>('entitybookings');

export const selectEntityBookings = createSelector(
    entityBookingState, 
  (state: EntityBookingState) => state.entitybookings
);

export const selectEntityBookingsLoading = createSelector(
    entityBookingState,
  (state: EntityBookingState) => state.loading
);

export const selectEntityBookingsLoaded = createSelector(
    entityBookingState,
    (state: EntityBookingState) => state.loaded
  );

export const selectEntityBookingsError = createSelector(
    entityBookingState,
  (state: EntityBookingState) => state.error
);