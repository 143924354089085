<div>
    
    <mat-dialog-content>
        
        <!-- close button to the right -->
        <mat-dialog-content>
            <mat-dialog-actions style="float: left; z-index: 1; margin-top: 8px;">
                <p style="font-size: 1.2em;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-receipt" viewBox="0 0 16 16">
                        <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27m.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0z"/>
                        <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5"/>
                    </svg>
                    Add bank account
                </p>
            </mat-dialog-actions>
            
            <mat-dialog-actions style="float: right; z-index: 1; ">
                <span>
                    <button 
                    mat-raised-button 
                    color="warn" 
                    (click)="confirmAndClose()">
                        X
                    </button>
                </span>

                
            </mat-dialog-actions>
            <br> <br>
            <hr>
    
        </mat-dialog-content>


        <div style="margin-top: 5px;">
            <!-- USER ID : {{userID}} -->

            <form
            [formGroup]="bankAccountForm" 
            (ngSubmit)="onSubmitBankAccountAdd()">
                <!-- ID (hidden)-->
                <div class="mb-3" hidden>
                    <input 
                        type="text" 
                        id="Id" 
                        class="form-control"
                        formControlName="Id" 
                        required>
                </div>

                <!-- UserId (hidden) -->
                <div class="mb-3" hidden>
                    <input 
                        type="text" 
                        id="UserId" 
                        class="form-control"
                        formControlName="UserId" 
                        required>
                </div>

                <!-- RecipientType (hidden) -->
                <div class="mb-3" hidden>
                    <input 
                        type="text" 
                        id="RecipientType" 
                        class="form-control"
                        formControlName="RecipientType" 
                        required>
                </div>
  
                <!-- BankName -->
                <div class="mb-3">
                    <label for="BankName">Select bank</label>
                    <select 
                        id="BankName" 
                        class="form-control" 
                        formControlName="BankName"
                        required>
                    <option value="" disabled>--Select a bank--</option>
                    <option 
                    *ngFor="let bank of banks" 
                    [value]="bank.name">{{ bank.name }}</option>
                    </select>
                </div>


                <!-- BankCode -->
                <div class="mb-3">
                    <label for="BankCode">Bank Code</label>
                    <input 
                    type="text" 
                    id="BankCode" 
                    class="form-control" 
                    formControlName="BankCode" 
                    readonly>
                </div>

                

                <!-- AccountNumber -->
                <div class="mb-3">
                    <label for="AccountNumber">Account Number</label>
                    <input 
                        type="text" 
                        id="AccountNumber" 
                        class="form-control"
                        formControlName="AccountNumber" 
                        required
                        placeholder="Provide account number">
                        <small> kindly double check the account number. Maixperience will not be liable if invalid account is provided</small>
                </div>

                <!-- AccountName -->
                <div class="mb-3">
                    <label for="AccountName">Account Name</label>
                    <input 
                        type="text" 
                        id="AccountName" 
                        class="form-control"
                        formControlName="AccountName" 
                        required
                        placeholder="Provide account name">
                </div>


                <!-- Currency (hidden) -->
                <div class="mb-3" hidden>
                    <input 
                        type="text" 
                        id="Currency" 
                        class="form-control"
                        formControlName="Currency" 
                        required>
                </div>


                <!-- IsDefault -->
                <div class="mb-3 form-check">
                    <label class="form-check-label" for="IsDefault">
                        Select as default
                    </label>

                    <input 
                        style="border: 1px solid rgb(78, 78, 235);"
                        type="checkbox" 
                        id="IsDefault" 
                        class="form-check-input"
                        formControlName="IsDefault">
                </div>

                <div class="buttons">
                    <!-- <button class="register-button">Register</button> -->
                    <button type="submit" class="addBA-button">
                        Add bank details
                    </button>
                </div>
            </form>
        </div>


        <mat-dialog-actions>
            <button 
                mat-raised-button 
                color="primary" 
                (click)="confirmAndClose()">
                Close
            </button>
        </mat-dialog-actions>
    </mat-dialog-content>
</div>