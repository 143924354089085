import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { environment } from 'src/environments/environment';
// import { CustomRegisterationModel, EmailData, GenericRole, GenericUser, TokenRetrievalModel } from '../../auth/login/login.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GenericRole } from 'src/app/types/models/genericrole.model';
import { GenericUser } from 'src/app/types/models/genericuser.model';
import { CustomRegisterationModel } from 'src/app/types/models/customregistration.model';
import { TokenRetrievalModel } from 'src/app/types/models/tokenretrieval.model';
import { EmailData } from 'src/app/types/models/emaildata.model';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent {
  firstname: string = '';
  lastname: string = '';
  username: string = '';
  email: string = '';
  password: string = '';


  allRoles: GenericRole[] | any;

  genUser: GenericUser | any
  genRole: GenericRole | any
  tokenModel: TokenRetrievalModel | any
  userToken: any;

  customUser: CustomRegisterationModel | any;

  emailTemplate: string = '';
  regEmailDt: EmailData | any;

  userManualRegistrationForm: FormGroup | any;
  
  constructor(private toastr: ToastrService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddUserComponent>,)
  {
    this.userManualRegistrationForm = this.formBuilder.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      username: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      role: [null, Validators.required]
    });
  }

  ngOnInit()
  {
    //get all the roles of this application

    this.authService.GetAllRolesForMaiXp(environment.AppBoundIdentifier)
    .subscribe((data)=> {
      console.log(`All roles: ${JSON.stringify(data)}`);
      
      this.allRoles = data;
    })
  }

  addUser()
  {
    // this.userManualRegistrationForm.values;
    console.log(`Form vals: ${JSON.stringify(this.userManualRegistrationForm.value)}`)
    
    //generic user
    this.genUser = new GenericUser(
      this.userManualRegistrationForm.value.username,
      this.userManualRegistrationForm.value.email, 
      environment.AppBoundIdentifier,
      this.userManualRegistrationForm.value.firstname,
      this.userManualRegistrationForm.value.lastname)

    //selected role
    this.genRole = new GenericRole(
      this.userManualRegistrationForm.value.role,
      environment.AppBoundIdentifier)

    //pwd
    let PasswordToBeThrownAway = this.userManualRegistrationForm.value.password;

    //
    this.customUser = new CustomRegisterationModel(this.genUser, 
      this.genRole, PasswordToBeThrownAway)
    

    if(!environment.production)
    {
      console.log(`user to be added: ${JSON.stringify(this.genUser)}`)
      
      console.log(`role attached: ${JSON.stringify(this.genRole)}`)
      
      console.log(`pwd: ${JSON.stringify(PasswordToBeThrownAway)}`)
    }

    if(!this.genUser || !this.genRole || !PasswordToBeThrownAway)
    {
      this.toastr.error('Invalid user registration details!', 'Error')
    }
    else if(environment.AppBoundIdentifier < 1)
    {
      this.toastr.error('Invalid application bound!', 'Error')
    }
    else //send to customAuth to persist user data
    {
      //email sender observer
      const emailUserObserver = {
        next: async (response: any) => {
          console.log(`Response from email send out: ${response}`);
        },
        error: async (err: HttpErrorResponse) => {
          console.log(`Error while sending email: ${JSON.stringify(err)}`);
        },
        complete: async () => {
          console.log(`Email sent !`);
        }
      }

      //tokenRequestObserver
      const tokenReqObserver = {
        next: async (data: any) => {
          //send email 
          this.userToken = data.Data.Value;
          console.log(`Token reponse: ${JSON.stringify(this.userToken)}`);

          //call emailSender
          let rstTemplateGenModel = {
            "encodedToken": this.userToken,
            "email": this.genUser
          }
          
          this.authService.RegistrationTemplateGenerator(rstTemplateGenModel)
          .subscribe((response) => {
            console.log(`Template gen response: ${response}`);
            
            this.emailTemplate = response.toString();

            console.log(`Email template response: ${this.emailTemplate}`);
            
            this.regEmailDt = new EmailData(
              this.userManualRegistrationForm.value.email,
              "Confirm Email",
              this.emailTemplate,
              3 // environment.AppBoundIdentifier
            )
            

            this.authService.SendConfirmRegistrationEmail(this.regEmailDt)
            .subscribe(emailUserObserver);
            
            //send the email out 
          })

          this.dialogRef.close("user added")
        },
        error: async (err: HttpErrorResponse) => {
          console.log(`Token request error: ${JSON.stringify(err)}`);
          
        },
        complete: async () => {

        }
      }

      //addUserObserver
      const addUserObserver = {
        next: async (data: any) =>  {
          this.toastr.success(`User: ${this.genUser?.UserName} added !`, 'Success')
          console.log(`User add response: ${JSON.stringify(data)}`);
          

          //request token for the user

          //
          this.tokenModel = new TokenRetrievalModel(this.genUser, true);
  
          this.authService.RequestUserToken(this.tokenModel)
          .subscribe(tokenReqObserver)

          
          // this.dialog.closeDialog();
        },
        error: (err: HttpErrorResponse) => 
        {
          console.log(`Error: ${JSON.stringify(err)}`),
          this.toastr.error('Error occurred, please try again!', 'Error')
        },
        complete: () => { 
          console.log("User Added !");
        }
      }

      //make the request
      this.authService.RegisterUserForMaiXperience(this.customUser)
      .subscribe(addUserObserver)
    }

  }
}
