import { Component, ElementRef, HostListener } from '@angular/core';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent {
  constructor(private element: ElementRef) {}

  @HostListener("wheel", ["$event"])
  public onScroll(event: WheelEvent) {
    // this.element.nativeElement.scrollLeft += event.deltaY;
    this.element.nativeElement.scrollLeft -= event.deltaY * 10;
  }

  // $(function () {
  //   $("body, html, .slider").mousewheel(function (event, delta) {
  //     this.scrollLeft -= delta * 10;
  //     event.preventDefault();
  //   });
  // });
}
