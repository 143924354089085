import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment'


@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  private APIUrl: string | undefined;
  private fileUploadUrl: string | undefined;
  // private baseUrl = 'https://localhost:7001/api/UploadFile/';

  constructor(private http: HttpClient) { 
    this.APIUrl = environment.APIUrl;
    this.fileUploadUrl = environment.fileUploadBaseUrl
  }

  upload(files: File[]): Observable<any> {
    var formData: FormData = new FormData();

    // files.forEach((e:any) => {
    //   formData.append('files', e);
    // });

    if(!environment.production)
    {
      console.log(`${files.length} files sent`);
    }
    

    for(let i = 0; i < files.length; i++)
    {
      if(!environment.production)
      {
        console.log(`Append Operation: ${files[i]} : ${files[i].name}`);
      }

      // formData.append('files[]', files[i]);
      formData.append(`file${i}`, files[i], files[i].name);
    }

    let urlPath = this.fileUploadUrl + "UploadImage";

    return this.http.post<any>(
      urlPath, 
      formData,  
      {reportProgress: true, observe: 'events'},
    );
  }

  getFiles(): Observable<any> {
    let filesPath = this.fileUploadUrl + "files";
    return this.http.get(filesPath);
  }

  getHttpOptions()
  {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };

    return httpOptions;
  }
}
