import { Component } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ActivityService } from 'src/app/shared/services/activity.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { AddressService } from 'src/app/shared/services/address.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ActivityStatus } from 'src/app/types/enums/activityStatus.enum';


@Component({
  selector: 'app-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.scss']
})
export class ManagerComponent {
  inActiveStus: ActivityStatus = ActivityStatus.Inactive;

  disabledStus: ActivityStatus = ActivityStatus.Disabled;

}
  
