<div class="container"> 

  <button 
  (click)="backToDashboard()"
  class="navtor" 
  type="button"
  style="color: black;">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
      </svg>
              
  </button>

  <ng-container>
    <div class="text-center">

      <button 
        style="border-radius: 40px; margin: 10px 0px 0px 0px;"
        class="btn btn-outline-primary">
        my activities
      </button>
    </div>
  </ng-container>

  <hr>

  <section>
    <ng-container *ngIf="hostactivityListLoading$ | async; else loadedState">
      <!-- Loading Spinner or Placeholder -->
      <div class="spinner-container">
        <div class="spinner"></div>
      </div>
    </ng-container>

    <ng-template #loadedState>
      <ng-container *ngIf="(hostactivityListLoaded$ | async); else errorState">
        <ng-container 
          class="con" 
          *ngIf="hostactivityList$ | async as hostList">
          
          <a *ngFor="let activity of hostList">
            <!-- {{activity | json}} -->
                <app-card 
                [entity]="activity"
                [isActivity]="true"  
                [showButton]="showButtonInFirstCard"></app-card>
          </a>
        </ng-container>
      </ng-container>
    </ng-template>

      <!-- <ng-template #noActivities>
        <p>No activities found for this user.</p>
      </ng-template> -->

      <ng-template #errorState>
        <!-- Handle Error State -->
        <div class="error-message">
          {{ hostactivityListLoadedError$ | async }}
        </div>
      </ng-template>
  </section>

</div>




