import { createReducer, on } from "@ngrx/store";
import { LiveMaiXEventState } from "../state/maixevent-future.state";
import { LOAD_LIVE_EVENTS, LOAD_LIVE_EVENTS_FAIL, LOAD_LIVE_EVENTS_SUCCESS } from "../actions/maixevents.live.actions";

const initialState: LiveMaiXEventState = {
    liveMaixevents: [],
    loading: false,
    loaded: false,
    error: null,
};

const _maixLiveEventsReducer = createReducer(
    initialState,
    on(LOAD_LIVE_EVENTS, (state) => ({ 
        ...state, 
        loading: true,
        loaded: false,
        error: null })),
    on(LOAD_LIVE_EVENTS_SUCCESS, (state, { liveMaixevents }) => ({
        ...state,
        liveMaixevents,
        loading: false,
        loaded: true,
        error: null,
    })),
    on(LOAD_LIVE_EVENTS_FAIL, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error,
    }))
);

export function maixLiveEventsReducer(state: any, action: any) {
    return _maixLiveEventsReducer(state, action);
}