<div class="container">

    <!-- main definition -->
    <h2 style="margin-top: 8px;">
        # Events
    </h2>

    <hr>
      
    <p>
        An "event" in this context is created by an organized or structured entity and it 
        involves physical or recreational participation within a fixed (but not recurring) duration. 
        We continuously onboard and work with event organizers to streamline and imporve their event creation experience,
        help them expand their customer base, open up a wider range of activity places that are open to event hosts etc.
    </p>


    <!-- who can create an event -->
    <h2>
        # Who can create new events
    </h2>
    <hr>

    <div>
        An "event host" is the entity or individual responsible 
        for creating, managing, and facilitating events. This entity can take 
        the form of a business, organization, or individual who specializes in 
        hosting specific event(s) such as hike, a shooting instructor, 
        ice-skating instructor, horse riding instructor etc. The
        "event host" gets to define the following:


        <div style="margin-top: 30px;"> 
            <ol class="my-list">
                <li tabindex="1">
                    Details of the event
                </li>
                <li tabindex="2">
                    Images of the event that customers will see
                </li>
                <li tabindex="3">
                    Location of the event
                </li>
                <li tabindex="4">
                    Start time of the event 
                </li>
                <li tabindex="5">
                    End time of the event
                </li>
                <li tabindex="6">
                    Open to event promotion ? <span style="color: red;"> in backlog </span> 
                </li>
            </ol>
        </div>

        The figure below depicts the "event host" role in the context of maixperience:

        <div 
        style="width: 400px; height: 360px; margin-top: 40px; "
        class="container">
            <div 
            id="carouselExampleIndicators" 
            class="carousel slide">

                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                </div>

                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img 
                        [src]="rtImagePath + 'evHost001.webp'" 
                        class="d-block w-100" 
                        alt="event host">
                    </div>

                    <div class="carousel-item">
                        <img 
                        [src]="rtImagePath + 'evHost0.webp'" 
                        class="d-block w-100" 
                        alt="event host">
                    </div>

                    <div class="carousel-item">
                        <img 
                        [src]="rtImagePath + 'evHost1.webp'" 
                        class="d-block w-100" 
                        alt="event host">
                    </div>
                    
                    <div class="carousel-item">
                        <img 
                        [src]="rtImagePath + 'evHost2.webp'" 
                        class="d-block w-100" 
                        alt="event host">
                    </div>
                </div>

                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>

                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>

        </div>
       
    </div>


    <br><br><br>
    
    <!-- who can view and book events -->
    <h2>
        # Who can view and book events
    </h2>
    <hr>
    <p>
        Any user on the platform can book an event
    </p>


    <!-- benefit of creating events -->
    <h2>
        # "Event Host" benefits
    </h2>
    
    <hr>

    <div>
        By applying to be an "event host", you get access to independent marketing tools, 
        customized easy-to-manage customer payments, visibility into your recurring customers etc
    </div>


      <!-- event promoters benefit -->
      <h2>
        # "Event Promoter(s)" benefits
    </h2>
    
    <hr>

    <div>
        By applying to be an "event Promoter" and marketing independently for an event, 
        you can be eligible to 15% on each booking that is done with your promotion code.   
    </div>
</div>