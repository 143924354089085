import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent {
  token: string | undefined;
  userEmail: string | undefined;


  emailConfirmed: boolean = false;


  constructor(private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private authService: AuthService) { }

  
  ngOnInit(): void {


    //get clikced action (token and email)
    this.route.queryParams.subscribe(params => {

      this.token = params['token'];
      this.userEmail = params['email'];

      // console.log(`incoming parameters: ${JSON.stringify(params)}`);
      // console.log(`Token: ${JSON.stringify(this.token)} | User Email: ${this.user}`);

      // this.token = params.token;
      // this.user = params.user;

      if(this.userEmail && this.token)
      {
          var confirmEmailOnReg = {
            "userEmail": this.userEmail,
            "Token": this.token
          }

          const userEmailConfirmationObserver = {
            next: (x: any) => {
              
              //success result 
              var resopnse = x;

              if(resopnse.Value.includes("confirmed"))
              {
                this.emailConfirmed = true;
              }
    
              this.toastr.success('Email confirmation successful', 'Email confirmed')
              //wait for 2.5 seconds and then go to login
              setTimeout(() => {
                console.log('sleep');
                this.router.navigate(['login']);
              }, 2500);

              this.router.navigate(['login'])
            },
            error: (err: any) => {
              this.router.navigate(['signup']);
    
              this.toastr.error("Error occurred", "Error");
            }
          };
          
          //call the ConfirmEmail with token and email
          this.authService.confirmEmail(confirmEmailOnReg).subscribe(userEmailConfirmationObserver);
      }
    })
    
    //get response

    //loadt the page
  }
}
