import { createAction, props } from "@ngrx/store";
import { Activity } from "src/app/types/models/activity.model";

export const LOAD_ACTIVITIES = createAction('[Activity] Load Activities');

export const LOAD_ACTIVITIES_SUCCESS = createAction('[Activity] Load Activities Success',
    props<{activities: Activity[]}>());

export const LOAD_ACTIVITIES_FAIL = createAction('[Activity] Load Activities Fail',
    props<{error: string}>());


export const GET_ACTIVITIES_BY_USERID = createAction('[Activity] Get activities by user id',
    props<{ userId: string }>());
    
export const GET_ACTIVITIES_BY_USERID_SUCCESS = createAction('[Activity] Get activities by user id Success',
    props<{usrActivities: Activity[]}>());

export const GET_ACTIVITIES_BY_USERID_FAIL = createAction('[Activity] Get activities by user id Fail',
    props<{error: string}>());


export const GET_ACTIVITY_BY_ID = createAction('[Activity] Get activity by id',
    props<{ activityId: number }>());
    
export const GET_ACTIVITY_BY_ID_SUCCESS = createAction('[Activity] Get activity by id Success',
    props<{activity: Activity}>());

export const GET_ACTIVITY_BY_FAIL = createAction('[Activity] Get activity by id Fail',
    props<{error: string}>());



        
export const ADD_ACTIVITY = createAction('[Activity] Add Activity',
    props<{ activity: Activity }>());
    
export const ADD_ACTIVITY_SUCCESS = createAction('[Activity] Add Activity Success',
    props<{ activity: Activity }>());

export const ADD_ACTIVITY_FAILURE = createAction('[Activity] Add Activity Failure', 
    props<{ error: any }>());
