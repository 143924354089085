<div>
    
    <mat-dialog-content>
        
        <!-- close button to the right -->
        <mat-dialog-content>
            <mat-dialog-actions style="float: left; z-index: 1; margin-top: 8px;">
                <p style="font-size: 1.2em;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-receipt" viewBox="0 0 16 16">
                        <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27m.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0z"/>
                        <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5"/>
                    </svg>
                    Bank account(s)
                </p>
            </mat-dialog-actions>
            
            <mat-dialog-actions style="float: right; z-index: 1; ">
                <span>
                    <button 
                    mat-raised-button 
                    color="warn" 
                    (click)="confirmAndClose()">
                        X
                    </button>
                </span>

                
            </mat-dialog-actions>
            <br> <br>
            <hr>
    
        </mat-dialog-content>
        <!-- <mat-dialog-actions >
           
            <span style="float: right; z-index: 1; ">
                <button 
                style="position: fixed;"
                mat-raised-button 
                color="warn" 
                (click)="confirmAndClose()">
                    X
                </button>
            </span>
        </mat-dialog-actions> -->


        <div style="margin-top: 10px;">
           
            <!-- USER ID : {{userID}} -->

            <div class="account-list">
                <ul *ngIf="bankAccounts.length > 0">
                  <li *ngFor="let account of bankAccounts">
                    <div>
                      <span>
                        {{ account.BankName }} - {{ account.AccountNumber }}
                    </span>

                    <ng-container *ngIf="!account.IsDefault">
                        <button 
                            class="btn btn-outline-primary btn-sm"
                            (click)="setDefaultAccount(account)">
                            Set as Default
                        </button>
                    </ng-container>
                    <ng-container *ngIf="account.IsDefault">
                        <svg xmlns="http://www.w3.org/2000/svg" style="color: rgb(73, 198, 73);" width="22" height="22" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"/>
                            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"/>
                        </svg>
                    </ng-container>
                    </div>
                  </li>
                </ul>


                <ng-container *ngIf="bankAccounts.length == 0">
                    <div class="card text-center">
                        <div class="card-body">
                            (0) bank account found
                        </div>
                    </div>
                </ng-container>
                    
                <br>


                <button
                    (click)="addNewBankAccountForAUser()"
                    style="width: 100%;"
                    class="btn btn-outline-primary text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-bank" viewBox="0 0 16 16">
                            <path d="m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.5.5 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89zM3.777 3h8.447L8 1zM2 6v7h1V6zm2 0v7h2.5V6zm3.5 0v7h1V6zm2 0v7H12V6zM13 6v7h1V6zm2-1V4H1v1zm-.39 9H1.39l-.25 1h13.72z"/>
                        </svg>
                        Add new bank account
                </button>
            </div>
              
           
        </div>

    </mat-dialog-content>

    <mat-dialog-actions>
        <button 
            mat-raised-button 
            color="primary" 
            (click)="confirmAndClose()">
            Close
        </button>
    </mat-dialog-actions>
</div>

