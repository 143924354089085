<div class="container">
    <div class="card">

        <div class="card-header" style="background-color: white;">
            <nav>
                <div 
                class="nav nav-tabs" 
                id="nav-tab" 
                role="tablist">
                    <button 
                        class="nav-link active" 
                        id="nav-home-tab" 
                        data-bs-toggle="tab" 
                        data-bs-target="#nav-home" 
                        type="button" 
                        role="tab" 
                        aria-controls="nav-home" 
                        aria-selected="true"
                        style="color: black;">
                    General
                    </button>
                  
                    <button 
                        class="nav-link" 
                        id="nav-profile-tab" 
                        data-bs-toggle="tab" 
                        data-bs-target="#nav-profile" 
                        type="button" 
                        role="tab" 
                        aria-controls="nav-profile" 
                        aria-selected="false"
                        style="color: black;" >
                        Activity Host
                    </button>
                 
                </div>
            </nav>
        </div>

        <div class="card-body">
            <div class="tab-content" id="nav-tabContent">
                <div
                    class="tab-pane fade show active" 
                    id="nav-home" 
                    role="tabpanel" 
                    aria-labelledby="nav-home-tab" 
                    tabindex="0">
                    <!-- General user registration -->

                    <form 
                        [formGroup]="registrationForm" 
                        (ngSubmit)="onSubmit(true)">
                        
                        <div class="mb-3">
                            <input 
                                type="text" 
                                id="username" 
                                class="ffields"
                                formControlName="username" 
                                required
                                placeholder="Username">
                        </div>

                        <div class="mb-3">
                            <input 
                                type="email" 
                                id="email" 
                                class="ffields"
                                formControlName="email" 
                                required
                                placeholder="Email Address">
                        </div>
                            
                        <div class="mb-3">
                            <input 
                                type="{{ passwordFieldType }}"   
                                id="password" 
                                class="ffields"
                                formControlName="password" 
                                required
                                placeholder="Password (minimum length: 6)">
                        </div>

                        <div 
                            *ngIf="password?.value?.length > 0" 
                            class="toggle-password-checkbox">
                            
                            <input 
                              type="checkbox" 
                              id="showPasswordCheckbox" 
                              (change)="togglePasswordVisibility()" 
                              [checked]="passwordFieldType === 'text'">
                            <label 
                                style="margin-left: 3px; padding-bottom: -5px;"
                                for="showPasswordCheckbox"> 
                                Show Password
                            </label>
                        </div>

                        <div>
                            Already registered ? Click 
                            <a [routerLink]="['/login']">
                                here
                            </a>
                            to login
                        </div>
                            
                        <div class="buttons">
                            <button
                                class="register-button">
                                Register
                            </button>
                        </div>
                    </form>
                </div>
        
                <div
                    class="tab-pane fade" 
                    id="nav-profile"
                    role="tabpanel" 
                    aria-labelledby="nav-profile-tab" 
                    tabindex="0">
                    <!-- Host resgitration -->
                    <form 
                        [formGroup]="registrationForm" 
                        (ngSubmit)="onSubmit(false)">

                        <div class="mb-3">
                            <input 
                                type="text" 
                                id="username" 
                                class="ffields"
                                formControlName="username" 
                                required
                                placeholder="Username">
                        </div>

                        <div class="mb-3">
                            <input 
                                type="email" 
                                id="email" 
                                class="ffields"
                                formControlName="email" 
                                required
                                placeholder="Email Address">
                        </div>

                        <div class="mb-3">
                            <input 
                                type="{{ passwordFieldType }}"   
                                id="password" 
                                class="ffields"
                                formControlName="password" 
                                required
                                placeholder="Password (minimum length: 6)">
                        </div>

                        <div 
                            *ngIf="password?.value?.length > 0" 
                            class="toggle-password-checkbox">
                            
                            <input 
                              type="checkbox" 
                              id="showPasswordCheckbox" 
                              (change)="togglePasswordVisibility()" 
                              [checked]="passwordFieldType === 'text'">
                            <label 
                                style="margin-left: 3px; padding-bottom: -5px;"
                                for="showPasswordCheckbox"> 
                                Show Password
                            </label>
                        </div>

                        <div>
                            Already registered ? Click 
                            <a [routerLink]="['/login']">
                                here
                            </a>
                            to login
                        </div>
                            
                        <div class="buttons">
                            <button
                                class="register-button">
                                Register
                            </button>
                        </div>
                    </form>
                </div>
              </div>
             
            
        </div>
    </div>
</div>