import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ActivityPromoterRequestNotification } from 'src/app/types/models/NotificationRelated/activityPromoterRequestNotification.model';
import { ActivityPromoterToHostNotification } from 'src/app/types/models/NotificationRelated/activityPromoterToHostNotification.model';
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class ActivityPromoterNotificationTempGenService {

    private APIUrl: string | undefined; // = 'https://localhost:7032/api/'

    constructor(private http: HttpClient) 
    { 
        this.APIUrl = environment.APIUrl;

        if(!environment.production)
        {
        console.log(`Env: ${environment} || URL: ${environment.APIUrl}`);
        }
        
    }

    //get Promotion Request To ActivityHost
    GetPromotionRequestToActivityHostTemplateAndSendEmail(
        recipientEmail: string,
        activityPromoterToHostNotification: ActivityPromoterToHostNotification): Observable<any> {
        
        let url = this.APIUrl + `ActivityPromoterNotificationTempGen/GetPromotionRequestToActivityHostTemplateAndSendEmail/${recipientEmail}`;
        
        const promotionReqTemplateToActivityHost = this.http.post<ActivityPromoterToHostNotification>(
        url,
        activityPromoterToHostNotification);

        return promotionReqTemplateToActivityHost;
    }

    
    //get Request Notification To Activity Promoter
    GetRequestNotificationToActivityPromoterTemplateAndSendEmail(
        recipientEmail: string,
        activityPromoterRequestNotification: ActivityPromoterRequestNotification): Observable<any> {
        
        let url = this.APIUrl + `ActivityPromoterNotificationTempGen/GetRequestNotificationToActivityPromoterTemplateAndSendEmail/${recipientEmail}`;
        
        const promoterReqNotificationTemplate = this.http.post<ActivityPromoterRequestNotification>(
            url,
            activityPromoterRequestNotification);

        return promoterReqNotificationTemplate;
    }


    ActivityPromotionResponseWithQueryParams(payload: any) : Observable<any> 
    {
        const url = `${this.APIUrl }ActivityPromoterNotificationTempGen/ActivityPromotionResponse`;
    
        // Construct HttpParams for query parameters
        let params = new HttpParams();
        for (const key in payload) {
          if (payload.hasOwnProperty(key) && payload[key] !== undefined) {
            params = params.set(key, payload[key]);
          }
        }
    
        // Send the POST request with query parameters
        return this.http.post(url, null, { params });
    }


    // SendPromotionRequestEmailToActivityHost(emailContent: string): Observable<any>
    // {
    //     let url = this.APIUrl + 'ActivityPromoterNotificationTempGen/SendPromotionRequestEmailToActivityHost';
        
    //     const promoterReqNotificationTemplate = this.http.post<ActivityPromoterRequestNotification>(
    //         url,
    //         emailContent);

    //     return promoterReqNotificationTemplate;
    // }

    // SendPromotionNotificationToActivityPromoter(emailContent: string): Observable<any>
    // {
    //     let url = this.APIUrl + 'ActivityPromoterNotificationTempGen/SendPromotionNotificationToActivityPromoter';
        
    //     const promoterReqNotificationTemplate = this.http.post<ActivityPromoterRequestNotification>(
    //         url,
    //         emailContent);

    //     return promoterReqNotificationTemplate;
    // }
  

    getHttpOptions(){

        const httpOptions = {
        
        };

        return httpOptions;
    }

}
