<!-- a team template has: -->
<!-- team name (clickable) -->

<div class="container-fluid">
    <div class="col-12">
      <div class="list-group" id="list-tab" role="tablist">
        <a 
        class="list-group-item list-group-item-action teamSelect" 
        href="#list-home" >
            {{team?.Name}}
        
            <span style="float: right;" class="badge bg-primary rounded-pill">14</span>
        </a>
      </div>
    </div>
  </div>

<!-- <div class="container">
  
    <h2>List group with Badges</h2>
    
    <ul class="list-group">
      <li class="list-group-item">Some content goes here<span class="badge">1</span></li>
      <li class="list-group-item">Some content goes here<span class="badge">2</span></li>
      <li class="list-group-item">Some content goes here<span class="badge">3</span></li>
      <li class="list-group-item">Some content goes here<span class="badge">4</span></li>
    </ul>
    
    <h2>List group of links</h2>
    
    <div class="list-group">
      <a href="#" class="list-group-item active">Link 1</a>
      <a href="#" class="list-group-item">Link 2</a>
      <a href="#" class="list-group-item">Link 3</a>
      <a href="#" class="list-group-item">Link 4</a>
    </div>
    
    <h2>List group with flags</h2>
    
    <div class="list-group">
      <a href="#" class="list-group-item list-group-item-success">Link 1</a>
      <a href="#" class="list-group-item list-group-item-info">Link 2</a>
      <a href="#" class="list-group-item list-group-item-warning">Link 3</a>
      <a href="#" class="list-group-item list-group-item-danger">Link 4</a>
    </div>
    
    <h2>List group with custom content</h2>
    
    <div class="list-group">
      <a href="" class="list-group-item">
        <h3 class="list-group-item-heading">Heading</h3>
        <p class="list-group-item-text">This is the content.</p>
      </a>
    </div>
    
  </div> -->