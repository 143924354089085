<div>

    <!-- carousel image card -->
    <div 
        class="card imgCard" 
        style="width: 100%;">

        <div
        id="myCarousel" 
        class="carousel carouselContainer slide" 
        data-ride="carousel">
            <!-- slides (before image is available) -->
            <div 
            class="carousel-inner" 
            role="listbox">
                <ng-container 
                *ngFor="let x of (imgs$ | async) as imgs; let i = index"  (click)="setCurrentIndex(i)">
                    <div
                    [class.active]="i === currentIndex"
                    class="carousel-item">
                        <img 
                        [src]="rtImgPath + x"
                        alt="Chania"
                        (click)="ViewImage($event, imgs ,i)"
                        >
                    </div>
                </ng-container>
                
            </div>
            
            <div 
            *ngIf="loading$ | async;" 
            class="spinner-container">
                <div class="spinner"></div>
            </div>
            <!-- previous and next controls -->
            <button 
            class="carousel-control-prev" 
            type="button" 
            data-bs-target="#myCarousel" 
            data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>

            <button 
            class="carousel-control-next" 
            type="button" 
            data-bs-target="#myCarousel"
            data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>

            <!-- back and location btns -->
            <button 
            (click)="backToEvntList()"
            class="navtor" 
            type="button"
            style="color: black;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                </svg>
                        
            </button>

            <span 
                (click)="locationCheck($event)"
                class="locationSpan">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                    </svg>
            </span>
            
            <span
            (click)="share($event)"
            class="eventShare">
            <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-share-2"
          >
            <circle cx="18" cy="5" r="3"></circle>
            <circle cx="6" cy="12" r="3"></circle>
            <circle cx="18" cy="19" r="3"></circle>
            <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
            <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
          </svg>
          
        </span>
        </div>
    </div>

    <!-- Actions on event -->
    <div class="container" *ngIf="(eventSelect$ | async) as eventSelect">
        
        <div class="container">
            <br>
            
            <!-- Event name and price per person -->
            <div class="row">

                <!-- event name -->
                <div class="col-8 evntName">
                    {{eventSelect?.Name}}
                </div>

                <!-- event price -->
                <div class="col-4" style="text-align: right; font-size: 16px;">
                    <!-- {{this.selectedActivitySlot?.PricePerSlot ?? 100 | currency: 'ZAR':true:'1.2-2' }} -->
                    {{ this.eventSelect?.PricePerPerson | currency: 'ZAR':true:'1.2-2'}}
                </div>
            </div>
        
            <br>

            <!-- show when event starts but NOT ended -->
            <div 
                class="row" 
                style="text-align: center;"
                [hidden]="!((eventStarted$ | async) && !(eventEnded$ | async))">
                    <div class="col-12">
                        <button
                            style="border-radius: 25px; width: 250px; background-color: green;"
                            class="btn btn-success">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                            </svg>
                            Event is in progress
                        </button>
                    </div>
            </div>
            
             <!-- show when has ended -->
            <div 
            class="row" 
            style="text-align: center;"
            [hidden]="!(eventEnded$ | async)">
                <div class="col-12">
                    <button
                        style="border-radius: 25px; width: 250px; background-color: rgb(241, 25, 25);"
                        class="btn btn-success">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                        </svg>
                        Event Ended
                    </button>
                </div>  
            </div>

            <!-- <hr [hidden]="(eventEnded$ | async)"> -->

            <!-- rsvp/ book event (if the event has not ended) -->
            <!-- Event Started: {{eventStarted$ | async}} -->
            <!-- Event Ended: {{eventEnded$ | async}} -->
            <div 
                [hidden]="(eventStarted$ | async)"
                class="row" 
                style="text-align: center;">

                <div class="col-12">
                    <button 
                        (click)="BookEvent()"
                        style="border-radius: 25px; width: 250px;"
                        class="btn btn-success">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                        </svg>
                        Book event
                    </button>
                </div>
            </div>

        
            <hr>
        </div>

        <!-- description section (with no regard for future or past events) -->
        <div
            class="accordion" 
            id="DescriptionAccordion">
             
            <div 
            class="accordion-item">
                <h2 
                class="accordion-header" 
                id="headingOne">
 
                    <!-- When the accordion is closed -->
                    <button 
                         class="accordion-button collapsed" 
                         type="button" 
                         data-bs-toggle="collapse" 
                         data-bs-target="#collapseDesc" 
                         aria-expanded="true" 
                         aria-controls="Desc">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
                                </svg>
 
                                Event Description
                            </span>
                    </button>
                </h2>
             
                <!-- When the accordion is opened -->
                <div 
                     id="collapseDesc" 
                     class="accordion-collapse collapse hide" 
                     aria-labelledby="headingOne" 
                     data-bs-parent="#DescriptionAccordion">
 
                    <div class="accordion-body">

                        <div class="row">
                            <div 
                                class="col-8 ackDesc">
                                {{eventSelect?.Description}}
                            </div>

                            <!-- <div 
                            class="col-4 rt">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-left-dots" viewBox="0 0 16 16">
                                    <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                    <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                </svg>
                                    0 comment(s)

                            </div> -->
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>


        

        <!-- Time related info wrapped in an accordion -->
        <!-- [hidden]="(eventEnded$ | async)" -->
        <div 
            class="accordion" 
            id="accordionExample">

            <hr>

            <div class="accordion-item">
                
                <!-- time details accordion header (when closed) -->
                <h2 class="accordion-header" id="headingOne">

                    <!-- Time details accordion button with its span -->
                    <button 
                        class="accordion-button collapsed" 
                        type="button" 
                        data-bs-toggle="collapse" 
                        data-bs-target="#collapseOne" 
                        aria-expanded="true" 
                        aria-controls="collapseOne">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
                                <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
                            </svg>
                            Time details
                        </span>
                    </button>
                </h2>
            
                <!-- When the accordion opens -->
                <div 
                    id="collapseOne" 
                    class="accordion-collapse collapse hide" 
                    aria-labelledby="headingOne" 
                    data-bs-parent="#accordionExample">

                    <div class="accordion-body">
                    
                        <!--  when event has NOT ended -->
                        <ng-container
                        *ngIf="!(eventEnded$ | async)">
                            <div 
                                class="row">
                                <!-- *ngIf="!isInThePast(eventSelect?.EventStartDateTime)"> -->
                                
                                <label 
                                    class="timeLbl"
                                    for="strt">Start-date</label>

                                <input 
                                    type="text" 
                                    id="ip2"
                                    [value]="eventStartTime"/>
                            </div>

                            <br>

                            <div 
                                class="row">
                                
                                <label 
                                for="countdown"
                                class="timeLbl">Countdown</label>
                        
                                <app-countdown-timer 
                                    style="text-align: center;"
                                    [startdate]="eventSelect!?.EventStartDateTime">
                                </app-countdown-timer>

                                <!-- when event is in progress -->
                                <div 
                                    class="row" 
                                    style="text-align: center;"
                                    [hidden]="!((eventStarted$ | async) && !(eventEnded$ | async))">
                                        <div class="col-12">
                                            <button
                                                style="border-radius: 25px; width: 250px; background-color: green;"
                                                class="btn btn-success">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                                </svg>
                                                Event is in progress
                                            </button>
                                        </div>
                                </div>
                            </div>


                            <br>
                            <div 
                                class="row">

                                <label 
                                for="end"
                                class="timeLbl">End-date</label>

                                <input 
                                    type="text" 
                                    id="ip2"
                                    [value]="eventEndTime"/>
                    
                            </div>

                        </ng-container>

                         
                        <!-- when event ended -->
                        <div
                        class="row"
                        *ngIf="(eventEnded$ | async)">
                        <!-- *ngIf="isInThePast(eventSelect?.EventStartDateTime)"> -->
                            
                            <div class="container">
                                
                                <p 
                                    class="text-center"
                                    style="border: 1px solid black; border-radius: 40px; padding: 4px; font-weight: bold; background-color: red;">
                                    
                                    Event Ended

                                </p>
                            </div>

                            <!-- <hr> -->
                        </div>
                    </div>
                </div>
            </div>

            
        </div>

        
        

        <!-- host details section -->

        <div 
        class="accordion" 
        id="HostDetailsAccordion">
        
            <hr>
            
            <!-- Host details accordion -->
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">

                    <!-- When the accordion is closed -->
                    <button 
                        class="accordion-button collapsed" 
                        type="button" 
                        data-bs-toggle="collapse" 
                        data-bs-target="#collapseHostDetails" 
                        aria-expanded="true" 
                        aria-controls="collapseHostDetails">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person-video3" viewBox="0 0 16 16">
                                <path d="M14 9.5a2 2 0 1 1-4 0 2 2 0 0 1 4 0m-6 5.7c0 .8.8.8.8.8h6.4s.8 0 .8-.8-.8-3.2-4-3.2-4 2.4-4 3.2"/>
                                <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h5.243c.122-.326.295-.668.526-1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v7.81c.353.23.656.496.91.783Q16 12.312 16 12V4a2 2 0 0 0-2-2z"/>
                              
                            </svg>
                            Host details
                        </span>
                    </button>
                </h2>
            
                <!-- When the accordion is opened -->
                <div 
                    id="collapseHostDetails" 
                    class="accordion-collapse collapse hide" 
                    aria-labelledby="headingOne" 
                    data-bs-parent="#HostDetailsAccordion">

                    <div class="accordion-body">

                        <!-- Host imag div -->
                        <div 
                            *ngIf="(eventSelectUsr$ | async) as eventSelectUsr; else noEventUsr"
                            class="row container"
                            style="text-align: center;"> 

                            <span>
                                <img 
                                    class="mr-3 rounded-circle" 
                                    src="{{rtImgPath}}{{eventSelectUsr?.ProfilePicture ?? 'nImg.png'}}" 
                                    alt="Generic placeholder image" 
                                    style="width:90px; height: 90px; border:solid 3px rgba(255,255,255,0.3); margin-right: 10px;"
                                    (click)="ViewAdminImage($event, eventSelectUsr?.ProfilePicture ?? 'nImg.png')"
                                    >
                                
                                <br>

                                <span class="text-center">
                                    {{eventSelectUsr?.UserName ?? 'Admin'}}
                                </span>
                           </span>
                                

                        </div>

                        <!-- if no user is found -->
                        <ng-template #noEventUsr>
                            <div class="text-center">
                                <span>
                                    <img 
                                    class="mr-3 rounded-circle text-center" 
                                    src="{{rtImgPath}}{{'nImg.png'}}" 
                                    alt="Generic placeholder image" 
                                    style="width:50px; height: 50px; border:solid 3px rgba(255,255,255,0.3);"
                                    (click)="ViewAdminImage($event, 'nImg.png')"
                                    >
                                    
                                    <br>

                                    <span class="text-center">
                                        {{'Admin'}}
                                    </span>
                                </span>
                            </div>
                        </ng-template>


                    </div>
                </div>
            </div>
        </div>
        
        

        <!-- attendees section -->
        <!-- [hidden]="this.attendees?.length == 0" -->
        <div 
        class="accordion"
        id="AttendeesAccordion">

            <hr>
            <div class="accordion-item">
                <!-- When the accordion is closed -->
                <h2 class="accordion-header" id="headingOne">

                    <button 
                        class="accordion-button collapsed" 
                        type="button" 
                        data-bs-toggle="collapse" 
                        data-bs-target="#collapseAttendees" 
                        aria-expanded="true" 
                        aria-controls="collapseAttendees">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-list-check" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0m0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0m0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0"/>
                            </svg>

                            Attendees
                        </span>
                    </button>
                </h2>
            
                <!-- When the accordion is opened -->
                <div 
                    id="collapseAttendees" 
                    class="accordion-collapse collapse hide" 
                    aria-labelledby="headingOne" 
                    data-bs-parent="#AttendeesAccordion">

                    <div class="accordion-body">
                        <div *ngIf="validAttendeeUsers$ | async as validAttendeeUsers">
                            <!-- where there is at least 1 attendee -->
                            <div 
                            *ngIf="validAttendeeUsers.length > 0"
                            class="horizontal-scroll">

                                <ng-container
                                *ngFor="let atds of validAttendeeUsers">
                                    <div class="horizontal-scroll__item">
                                        <div 
                                            class="card text-center" 
                                            style="width: 18rem;">
                                            
                                            <img 
                                                class="img img-thumbnail"
                                                style="width: 18rem; height: 230px; padding: 5px;"
                                                [src]="atds?.ProfilePicture ? rtImgPath + atds?.ProfilePicture :  rtImgPath + 'nImg.png'"
                                                >
                                                
                                            <hr>
                                            
                                            <div 
                                            class="card-body" 
                                            style="height: 50px;">
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person-bounding-box" viewBox="0 0 16 16">
                                                        <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5M.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5"/>
                                                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                                                    </svg>
                                                    
                                                    {{ atds?.UserName ?? 'Admin'}} 

                                                </span>

                                                
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>


                            </div>

                            <!-- when there are no attendees -->
                            <div
                            *ngIf="validAttendeeUsers?.length === 0">
                                <p 
                                    class="text-center" 
                                    style="border: 1px solid black; color: white; padding: 15px; background-color: rgb(202, 30, 27);">
                                    No attendees
                                </p>    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
           

       

        <!-- review and report -->
        <div 
        style="margin: 20px 0px 0px 0px;"
        class="row">

            <hr>
            
            <!-- review -->
            <div 
            class="col-4"
            (click)="InDevMsg()"
            style="text-align: left">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                </svg>
                0 review(s)
            </div>

            <!-- report  -->
            <div 
            class="col-8"
            (click)="InDevMsg()"
            style="text-align: right;">
                <button 
                    style="border-radius: 25px; width: 150px;"
                    class="btn btn-outline-danger">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-stop" viewBox="0 0 16 16">
                        <path d="M3.5 5A1.5 1.5 0 0 1 5 3.5h6A1.5 1.5 0 0 1 12.5 5v6a1.5 1.5 0 0 1-1.5 1.5H5A1.5 1.5 0 0 1 3.5 11V5zM5 4.5a.5.5 0 0 0-.5.5v6a.5.5 0 0 0 .5.5h6a.5.5 0 0 0 .5-.5V5a.5.5 0 0 0-.5-.5H5z"/>
                    </svg> -->
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-flag-fill" viewBox="0 0 16 16">
                        <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001"/>
                    </svg>
                    Report
                </button>
            </div>
        </div>
    </div>
</div>