import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LeftbarComponent } from './components/leftbar/leftbar.component';
import { RightbarComponent } from './components/rightbar/rightbar.component';
import { RouterModule } from '@angular/router';
import { MatExpansionModule } from '@angular/material/expansion';
import { CookieService } from 'ngx-cookie-service';
import { TrancatePipe } from './utils/pipes/trancate.pipe';
// import { LazyLoadImageDirective } from './utils/directives/lazy-load-image.directive';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    LeftbarComponent,
    RightbarComponent,
    TrancatePipe,
    // LazyLoadImageDirective,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatExpansionModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    LeftbarComponent,
    RightbarComponent
  ],
  providers: []
})
export class SharedModule { }
