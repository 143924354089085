import { Component } from '@angular/core';

@Component({
  selector: 'app-selected-clubs',
  templateUrl: './selected-clubs.component.html',
  styleUrls: ['./selected-clubs.component.scss']
})
export class SelectedClubsComponent {

}
