import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { map, Observable, of, switchMap, take, tap } from 'rxjs';
import { LOAD_ACTIVITIES_I_AM_PROMOTING } from 'src/app/ngrx/actions/activity-i-am-promoting.actions';
import { GET_CURRENT_USER } from 'src/app/ngrx/actions/current-user.actions';
import { selectActivitiesIAmPromotingWithPagination, selectActivitiesIAmPromotingWithPaginationError, selectActivitiesIAmPromotingWithPaginationLoaded, selectActivitiesIAmPromotingWithPaginationLoading } from 'src/app/ngrx/selectors/activity-i-am-promoting.selectors';
import { selectLoggedInUser } from 'src/app/ngrx/selectors/current-user.selectors';
import { AppState } from 'src/app/ngrx/state/app.state';
import { ActivityService } from 'src/app/shared/services/activity.service';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { CardComponent } from 'src/app/Templates/card/card.component';
import { ActivityPromotersLog } from 'src/app/types/models/activityPromotersLog.model';
import { GenericUser } from 'src/app/types/models/genericuser.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-promoting-activities-private',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent
  ],
  templateUrl: './promoting-activities-private.component.html',
  styleUrl: './promoting-activities-private.component.scss'
})
export class PromotingActivitiesPrivateComponent {

  usrObject$: Observable<GenericUser | null>
  usrObjectGlobal: GenericUser | undefined
  
  activitiesPromoting$: Observable<ActivityPromotersLog[]> | undefined
  activitiesPromotingLoading$: Observable<boolean> | undefined
  activitiesPromotingLoaded$: Observable<boolean> | undefined
  activitiesPromotingError$: Observable<string | null> 

  activitydeduction$!: Observable<any>;

  pageNumber: number = 1;
  pageSize: number = 10;


  // activitiesOpenForPromotion: Activity[] | undefined
  
  showButtonHistoryAndEdit = false; // user does not get to view those actions here
  showActivityPromoButton = true
  showBookingButtonFromActivityPromotion: boolean = true;
  activityPromoterUserId: string = '12345'
  // activityPromoterUserId$!: Observable<string | null>
  // activityPromoterUserIdAndActivityId$: Observable<{ 
  //   promoterUserId: string | null, 
  //   activityId: string | null }> | undefined;


  promoterUserId: string | undefined
  activityId: string | undefined 

  iAmPromotingTheseActivities: boolean = false
  
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authSvc: AuthService,
    private activityService: ActivityService, 
    private store: Store<AppState>) 
  {
    //currently logged in user
    this.usrObject$! = this.store.pipe(select(selectLoggedInUser));


    // get the activities I am promoting from store
    this.activitiesPromoting$ = this.store.pipe(select(selectActivitiesIAmPromotingWithPagination))
    this.activitiesPromotingLoading$ = this.store.pipe(select(selectActivitiesIAmPromotingWithPaginationLoading))
    this.activitiesPromotingLoaded$ = this.store.pipe(select(selectActivitiesIAmPromotingWithPaginationLoaded))
    this.activitiesPromotingError$ = this.store.pipe(select(selectActivitiesIAmPromotingWithPaginationError))


    
  }

  ngOnInit()
  {
    //if the promoterUser is in query parameter
    // this.activityPromoterUserIdAndActivityId$ = this.activatedRoute.queryParams.pipe(
    //   map(params => ({
    //     promoterUserId: params['promoterUserId'] || null,
    //     activityId: params['activityId'] || null
    //   }))
    // );

    //testing
    // this.activatedRoute.queryParams.pipe(
    //   map(params => ({
    //     promoterUserId: params['promoterUserId'] || null,
    //     activityId: params['activityId'] || null
    //   }))
    // ).subscribe(queryParams => {
    //   console.log('Promoter User ID:', queryParams.promoterUserId);
    //   console.log('Activity ID:', queryParams.activityId);
    // });

    // Retrieve query parameters
    // this.activatedRoute.queryParams.subscribe(params => {
    //   this.promoterUserId = params['promoterUserId'] || null;
    //   this.activityId = params['activityId'] || null;

    //   console.log('Query Param 1:', this.promoterUserId);
    //   console.log('Query Param 2:', this.activityId);
    // });

    
    // //get activity ID from route param
    // const activityId$ = this.route.paramMap.pipe(
    //   map(paramMap => paramMap.get('activityId')),
    //   filter(activityId => !!activityId)
    // );


    //if the activity is already selected

    // Subscribe to the observable to update the queryParamValue property
    
    // this.activityPromoterUserIdAndActivityId$.subscribe(({promoterUserId, activityId}) => {
    // // this.activityPromoterUserId$.subscribe(value => {
    //   if(promoterUserId && !activityId)
    //   {
    //     //scenario 1: QR code was used to access this page
    //     this.activityPromoterUserId = promoterUserId;

    //     if(!environment.production)
    //     {
    //       console.log(`Promoter User retrieved: ${promoterUserId} with NO activityId`)
    //     }

      
    //     //load promoting activities for the retrieved user
    //     this.store.dispatch(LOAD_ACTIVITIES_I_AM_PROMOTING({
    //       promoterUserId: this.activityPromoterUserId,
    //       pageNumber: this.pageNumber,
    //       pageSize: this.pageSize
    //     }));
      
    //   }
    //   else if (promoterUserId && activityId)
    //   {
    //     //at this point, open the activity booking dialog
    //     if(!environment.production)
    //       {
    //         console.log(`Promoter User retrieved: ${promoterUserId} AND`)
    //         console.log(`Activity Id retrieved ?: ${activityId}`)
    //       }
    //   }
    // });


    // scenario 2: the promoter is logged in
    this.usrObject$.pipe(
      // first(), // Take the first value emitted by the observable
      tap(user => {
        if (!user) {
          // dispatch
          this.store.dispatch(GET_CURRENT_USER());
        }
        else {
          //assign global user object
          this.usrObjectGlobal = user
          this.promoterUserId = user.Id!

          //dispatch
          this.store.dispatch(LOAD_ACTIVITIES_I_AM_PROMOTING({
            promoterUserId: user?.Id!,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize
          }));
        }
      }),
      switchMap(() => this.usrObject$) // Listen for new updates from the store
    ).subscribe()

   
    this.activitydeduction$ = this.activitiesPromoting$!.pipe(
      take(1), // Ensure it only emits once
      switchMap(data => {

        if(!environment.production)
        {
          console.log('Observable emitted');
        }

        if (data && data.length > 0) 
        {
          if(!environment.production)
          {
            console.log('Promotion data:', data);
          }

          //deduce if logged in user ID matches the promotion log ID
          const hasMatch = data.some(activitiesPromoting => 
            activitiesPromoting.PromoterId === this.usrObjectGlobal!?.Id);
          
          this.iAmPromotingTheseActivities = hasMatch

          // Extract ActivityId from each object in the array
          const activityIds = data.map(item => item.ActivityId);

          if(!environment.production)
          {
            console.log('Extracted ActivityIds:', activityIds);
          }

          // Return the observable from GetActivitiesByIds
          return this.activityService.GetActivitiesByIds(activityIds);
        } else {
          if(!environment.production)
          {
            console.log('No Promotion available');
          }

          // If no promotion data, return an empty observable or a fallback value
          return of(null);
        }
      })
    );

    
  }

  backToActivityPromotionDashboard()
  {
    this.router.navigate(['APDashboard']);
  }

}
