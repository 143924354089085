import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ActivityState, UserActivitiesState } from "../state/activity.state";

export const selectActivityState = createFeatureSelector<ActivityState>('activity');

//#region selectAllActivities
export const selectAllActivities = createSelector(
  selectActivityState,
  (state: ActivityState) => state.activities
);

export const selectActivityLoading = createSelector(
  selectActivityState,
  (state: ActivityState) => state.loading
);

export const selectActivityLoaded = createSelector(
    selectActivityState,
    (state: ActivityState) => state.loaded
  );

export const selectActivityError = createSelector(
  selectActivityState,
  (state: ActivityState) => state.error
);
//#endregion

//#region selectActivitiesByUserId
export const selectUserActivitiesState = createFeatureSelector<UserActivitiesState>('userActivities');

export const selectActivitiesOfUser = createSelector(
  selectUserActivitiesState,
  (state: UserActivitiesState) => state.userActivities
);

export const selectActivitiesOfUserLoading = createSelector(
  selectUserActivitiesState,
  (state: UserActivitiesState) => state.loading
);

export const selectActivitiesOfUserLoaded = createSelector(
  selectUserActivitiesState,
    (state: UserActivitiesState) => state.loaded
  );

export const selectActivitiesOfUserError = createSelector(
  selectUserActivitiesState,
  (state: UserActivitiesState) => state.error
);

//#endregion