import { createAction, props } from "@ngrx/store";
import { IpLocation } from "../state/ipLocation.state";


export const GET_IP_LOCATION = createAction('[IP Location] Get Ip Location',
    props<{ipAddress: string}>());

export const GET_IP_LOCATION_SUCCESS = createAction('[IP Location] Get Ip Location Success',
    props<{ipLocation: IpLocation}>());

export const GET_IP_LOCATION_FAIL = createAction('[IP Location] Get Ip Location Fail',
    props<{error: string}>());
