import { createFeatureSelector, createSelector } from "@ngrx/store";
import { LiveMaiXEventState, MaiXFutureEventState, PastMaiXEventState } from "../state/maixevent-future.state";
// import { LiveMaiXEventState, PastMaiXEventState } from "../state/maixevent-live.state";

export const selectFutureEventState = createFeatureSelector<MaiXFutureEventState>('futuremaixevents');

export const selectFutureEventsWithPagination = createSelector(
    selectFutureEventState,
  (state: MaiXFutureEventState) => state.futuremaixevents
);

export const selectFutureEventLoading = createSelector(
    selectFutureEventState,
  (state: MaiXFutureEventState) => state.loading
);

export const selectFutureEventLoaded = createSelector(
    selectFutureEventState,
    (state: MaiXFutureEventState) => state.loaded
  );

export const selectFutureEventError = createSelector(
    selectFutureEventState,
  (state: MaiXFutureEventState) => state.error
);

//live events
export const selectLiveEventState = createFeatureSelector<LiveMaiXEventState>('liveMaixevents');

export const selectLiveEventsWithPagination = createSelector(
  selectLiveEventState,
  (state: LiveMaiXEventState) => state.liveMaixevents
);

export const selectLiveEventLoading = createSelector(
  selectLiveEventState,
  (state: LiveMaiXEventState) => state.loading
);

export const selectLiveEventLoaded = createSelector(
  selectLiveEventState,
    (state: LiveMaiXEventState) => state.loaded
  );

export const selectLiveEventError = createSelector(
  selectLiveEventState,
  (state: LiveMaiXEventState) => state.error
);

//live events
export const selectPastEventState = createFeatureSelector<PastMaiXEventState>('pastMaixevents');

export const selectPastEventsWithPagination = createSelector(
  selectPastEventState,
  (state: PastMaiXEventState) => state.pastMaixevents
);

export const selectPastEventLoading = createSelector(
  selectPastEventState,
  (state: PastMaiXEventState) => state.loading
);

export const selectPastEventLoaded = createSelector(
  selectPastEventState,
    (state: PastMaiXEventState) => state.loaded
  );

export const selectPastEventError = createSelector(
  selectPastEventState,
  (state: PastMaiXEventState) => state.error
);
