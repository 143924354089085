import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription, catchError, delayWhen, filter, interval, of, switchMap, take, tap } from 'rxjs';
import { GET_APP_ROLES } from 'src/app/ngrx/actions/application-roles.actions';
import { GET_CURRENT_USER_ROLES } from 'src/app/ngrx/actions/current-user-roles.actions';
import { GET_CURRENT_USER } from 'src/app/ngrx/actions/current-user.actions';
import { selectapplicationRoles } from 'src/app/ngrx/selectors/application-roles.selectors';
import { selectLoggedInUserRoles } from 'src/app/ngrx/selectors/current-user-roles.selectors';
import { AppState } from 'src/app/ngrx/state/app.state';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { UserSpecificService } from 'src/app/shared/services/dataService/user-specific.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ObfuscationServiceService } from 'src/app/shared/services/obfuscation-service.service';
import { LoaderService } from 'src/app/shared/utils/loader.service';
import { CustomLoginModel } from 'src/app/types/models/customlogin.model';
import { GenericRole } from 'src/app/types/models/genericrole.model';
import { GenericUser } from 'src/app/types/models/genericuser.model';
import { KnownApplication } from 'src/app/types/models/knownapplication.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  subscription: Subscription | undefined;
  
  public loginForm: FormGroup = this.formBuilder.group('');

  redirectPage: string | null = '';
  activitySelect: any;
  act2beBooked : any;
  
  eventSelect: any;
  evnt2beReserved : any;

  currentLogedInUsr$! : Observable<GenericUser>
  // currentLogedInUsrRole$! : Observable<GenericRole>
  currentLogedInUsrRole$! : Observable<string[]>
  
  passwordFieldType: string = 'password';

  applicationRoles$!: Observable<GenericRole[]>

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private userSvc: UserSpecificService,
    private dialogSvc: DialogService,
    private obfuscationService: ObfuscationServiceService,
    private location: Location,
    private loadingService: LoaderService,
    private store: Store<AppState>) 
  {
    //
    this.applicationRoles$ = this.store.pipe(select(selectapplicationRoles));
    this.currentLogedInUsrRole$ = this.store.pipe(select(selectLoggedInUserRoles))

    // email: ['', [Validators.required, Validators.email]],
    this.loginForm = this.formBuilder.group({
      usernameOrEmail: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }
  
  ngOnInit(): void {

    this.subscription = this.applicationRoles$.subscribe(applicationRoles => {
      if (!applicationRoles || applicationRoles.length === 0) {
        this.store.dispatch(GET_APP_ROLES());
      }
    });

    // Get the route parameter
    this.route.queryParams.subscribe(params => {
      this.redirectPage = params['redirectUrl'];

      if(this.redirectPage && !environment.production)
      {
        console.log(`redirect page: ${this.redirectPage}`);
      }

      if(this.redirectPage?.includes("activitydetails"))
      {  
        // Retrieve data from query param of 'activityDetails' page
        if(params['data'])
        {
          this.activitySelect = JSON.parse(params['data']);
        }

        // log data retrieved
        if(this.activitySelect && !environment.production)
        {
          console.log(`Selected activity sent to login page: ${JSON.stringify(this.activitySelect)}`);
        }

        //get activity to be booked from query param of 'activityDetails' page
        //act2be is a more complex object of the entire activity, slot etc
        if(params['act2be'])
        {
          this.act2beBooked = JSON.parse(params['act2be']);
        }

        if(this.act2beBooked && !environment.production)
        {
          
          console.log(`Activity to be booked: ${JSON.stringify(this.act2beBooked)}`);
        }
      }
      else if(this.redirectPage?.includes("eventdetails"))
      {
        // Retrieve data from query param of 'eventdetails' page
        if(params['data'])
        {
          this.eventSelect = JSON.parse(params['data']);
        }

        // log data retrieved
        if(this.eventSelect && !environment.production)
        {
          console.log(`Selected event sent to login page: ${JSON.stringify(this.eventSelect)}`);
        }

        //get event to be booked from query param of 'eventDetails' page
        if(params['evnt2be'])
        {
          this.evnt2beReserved = JSON.parse(params['evnt2be']);
        }

        if(this.evnt2beReserved && !environment.production)
        {
          
          console.log(`Event to be booked: ${JSON.stringify(this.evnt2beReserved)}`);
        }
      }
      else if(this.redirectPage?.includes("addactivity"))
      {
        // get data: { act info, act images, location }
      }
     
    });

  }

  togglePasswordVisibility() {
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
  }

  get password() {
    return this.loginForm.get('password');
  }
  

  onLoginSubmittion()
  {
    //
    if(this.loginForm.valid)
    {
      if(!environment.production)
      {
        console.log(
          `Login form submittion: ${JSON.stringify(this.loginForm.value)}`)
      }
        
      let lgUsr = null;

      //if you have sent username > (Does not include @)
      if(!this.loginForm.value.usernameOrEmail.includes('@'))
      {
        //send to backend
        lgUsr = new CustomLoginModel(
          new KnownApplication(
            environment.AppNameForCustomAuth,
            environment.AppBoundIdentifier
          ),
          this.loginForm.value.usernameOrEmail,
          this.loginForm.value.password,
          false,
          ''
        );
      }
      //else if you have sent email (includes @)
      else{
        lgUsr = new CustomLoginModel(
          new KnownApplication(
            environment.AppNameForCustomAuth,
            environment.AppBoundIdentifier),
          '',
          this.loginForm.value.password,
          false,
          this.loginForm.value.usernameOrEmail,
        );
      }

      this.loadingService.show();

      //
      const loginObserver = {
        next: async (response: any) => {
          if(!environment.production)
          {
            console.log(`Response from login operation: ${JSON.stringify(response)}`);
          }

          // login success handler
          this.store.dispatch(GET_CURRENT_USER());

          //
          this.store.dispatch(GET_CURRENT_USER_ROLES());

          // role based action
          this.currentLogedInUsrRole$
          .pipe(
            // Filter out initial undefined/null values
            filter(roles => roles != null),
            // Use take(1) to complete after the first non-null value
            take(1),
            // Chain another function or observable
            switchMap(roles => {
              // The user was coming from somewhere and 
              // user should to go back there
              if(this.redirectPage) {
                this.handleRedirect(this.redirectPage)
              }
              //its a fresh login. determine where user should go to based on role
              else {
                this.handleUserRoles(roles)
              } 
              
              return of(null);
            })
          ).subscribe()


          this.loadingService.hide()
          
          this.toastr.success("Welcome", "Success");

          this.userSvc.setLoginStatus(true);

          if (!environment.production) {
            console.log(`I am redirecting to: ${this.redirectPage} from login`);
          }
    
          // if the redirect page is activitydetails and eventdetails page
          // that means data was sent here
          // get the obsufactory ID (it is needed to redirect successfully to the detais page(s))
          if(this.redirectPage == "activitydetails" || 
            this.redirectPage == "eventdetails"
          ) {
            let obfsActivityId = this.obfuscationService.encryptEventId(this.activitySelect.Id.toString());

            if(!environment.production)
            {
              console.log(`obfuscation of activity ID: ${obfsActivityId}`)
            }

            this.router.navigate(['activitydetails', obfsActivityId]);
          }
          else{

            this.router.navigate([this.redirectPage])
          }
        },
        error: async (err: HttpErrorResponse) => {
          if(!environment.production)
          {
            console.log(`Error while logging in: ${JSON.stringify(err)}`);
          }

          // Clear the username and password input controls
          this.loginForm.get('username')?.setValue('');
          this.loginForm.get('password')?.setValue('');

          this.loadingService.hide()

          this.toastr.error(err?.error?.Message, "Error");
          
        },
        complete: async () => {
          

          if(!environment.production)
          {
            console.log(`User logged in!`);
          }
        }
      }

      //
      this.authService.LoginToMaiXperience(lgUsr)
        .subscribe(loginObserver);
    }
    else
    {
      let errAry = [];
      const usernameControl = this.loginForm.get('usernameOrEmail');
      const passControl = this.loginForm.get('password');

      if (usernameControl?.errors?.['required']) {
        errAry.push('Username is required.');

        if(!environment.production)
        {
          console.log('Username or Email is required.');
        }
      }

      if (passControl?.errors?.['required']) {
        errAry.push('Password is required.');
        if(!environment.production)
        {
          console.log('Password is required.');
        }
      }
      
      if (passControl?.errors?.['minlength']) {
        errAry.push('Password must be 6 or more in length.');

        if(!environment.production)
        {
          console.log('Password must be 6 or more in length.');
        }
      }

      this.toastr.error(errAry[0], "Error");
    }
  }


  handleRedirect(redirectPage: string) {
    
    //Activity Details Redirect
    if(redirectPage?.includes('activitydetails')) {
      let obfsEventId = this.obfuscationService.encryptEventId(this.activitySelect?.Id?.toString());

      this.router.navigate(['activitydetails', obfsEventId])
        .then(() => {
          //open up the booking modal for the selected activity if the redirect page = activitydetails
          this.dialogSvc.ActivityBookingDialog(this.act2beBooked) 
      })
    }
    //event details redirect
    else if(redirectPage?.includes('eventdetails'))
    {
      let obfsEventId = this.obfuscationService.encryptEventId(this.eventSelect?.Id?.toString());

      this.router.navigate(['eventdetails', obfsEventId])
        .then(() => {
          //open up the booking modal for the selected event if the redirect page = eventdetails
          this.dialogSvc.EventBookingDialog(this.evnt2beReserved) 
        })
    }
    else{
      //just go to the page as instructed
      this.router.navigate([`${redirectPage}`])
    }
  }

  handleUserRoles(roles: string[]): void {
    if (roles.includes('mxp_ActivityHost')) {
      // Perform the desired action

      this.router.navigate(['activityhost'])
    }
    else if(roles.includes('mxp_EventHost'))
    {
      alert("User is an event host")
    }
    else{
      //go to dashboard
      this.router.navigateByUrl("/");
    }
  }
  
}





















