<div 
class="container">

    <h1 mat-dialog-title>
        Add new role
    </h1>
    <hr>
    <div mat-dialog-content>
        <div class="mb-3">
            <label 
            for="exampleFormControlInput1" 
            class="form-label">Enter role name
            </label>
            <input 
            type="text" 
            class="form-control" 
            [(ngModel)]="roleName" 
            id="rolename" placeholder="enter role name">
          </div>

    </div>
    <hr>
    <div mat-dialog-actions>
        <button 
            mat-raised-button
            (click)="submitRole()"
            color="primary">
            Submit
        </button>
        <button 
            mat-raised-button
            color="accent" 
            mat-dialog-close>Close</button>
    </div>


</div>