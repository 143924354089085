import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, tap } from "rxjs";
import { environment } from "src/environments/environment";
import * as ActivityPromotersLogActions from "../actions/activity-promoter-logs.actions";
import { Booking } from "src/app/types/models/booking.model";
import { ActivityPromoterLogsService } from "src/app/shared/services/activity-promoter-logs.service";
import { ActivityPromotersLog } from "src/app/types/models/activityPromotersLog.model";

@Injectable()
export class ActivityPromoterLogsEffects {

    activityPromoterLogs$ = createEffect(() => this.actions$.pipe(
        ofType(ActivityPromotersLogActions.LOAD_ACTIVITY_PROMOTER_LOGS_FOR_A_USER),
        mergeMap( action => this.ActivityPromoterLogsService.ViewAllPromotersForActivityWithPagination(
            action.activityId,
            action.pageNumber,
            action.pageSize,
        )
        .pipe(
            tap((activityPromoterLogs: ActivityPromotersLog[]) => {
                if(!environment.production)
                {
                    console.log(`Promoters logs retrieved: ${ JSON.stringify(activityPromoterLogs) }`);
                }
            }),
            map(activityPromoterLogs => ActivityPromotersLogActions.LOAD_ACTIVITY_PROMOTER_LOGS_FOR_A_USER_SUCCESS({ activityPromoterLogs })),
            catchError(error => of(ActivityPromotersLogActions.LOAD_BOOKINGS_FAIL({ error })))
        ))
        )
    );

  constructor(
    private actions$: Actions,
    private ActivityPromoterLogsService: ActivityPromoterLogsService
  ) {}
}