import { createReducer, on } from "@ngrx/store";
import * as ActivityActions from "../actions/activity.actions";
import { ActivityState } from "../state/activity.state";


const initialState: ActivityState = {
    activities: [],
    loading: false,
    loaded: false,
    error: null,
};

const _activityReducer = createReducer(
    initialState,
    on(ActivityActions.LOAD_ACTIVITIES, state => ({ ...state, loading: true })),
    on(ActivityActions.LOAD_ACTIVITIES_SUCCESS, (state, { activities }) => ({
        ...state,
        activities: [...activities],
        loading: false,
        loaded: true,
        error: null,
    })),
    on(ActivityActions.LOAD_ACTIVITIES_FAIL, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error,
    })),

    //#region getActivityById
    on(ActivityActions.GET_ACTIVITY_BY_ID, state => ({ ...state, loading: true })),
    on(ActivityActions.GET_ACTIVITY_BY_ID_SUCCESS, (state, { activity }) => ({
        ...state,
        selectedActivity: activity,
        loading: false,
        loaded: true,
        error: null,
    })),
    on(ActivityActions.GET_ACTIVITY_BY_FAIL, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error,
    })),
    //#endregion


    // #region getActivityByUserId
    on(ActivityActions.GET_ACTIVITIES_BY_USERID, state => ({ ...state, loading: true })),
    on(ActivityActions.GET_ACTIVITIES_BY_USERID_SUCCESS, (state, { usrActivities }) => ({
        ...state,
        userActivities: usrActivities,
        loading: false,
        loaded: true,
        error: null,
    })),
    on(ActivityActions.GET_ACTIVITIES_BY_USERID_FAIL, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error,
    })),
    //#endregion


    // #region addActivity
    on(ActivityActions.ADD_ACTIVITY, state => ({ ...state, loading: true })),
    on(ActivityActions.ADD_ACTIVITY_SUCCESS, (state, { activity }) => ({
        ...state,
        activities: [...state.activities, activity],
        loading: false,
        loaded: true,
        error: null,
    })),
    on(ActivityActions.LOAD_ACTIVITIES_FAIL, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error,
    }))
    //#endregion
);

export function activityReducer(state: any, action: any) {
    return _activityReducer(state, action);
  }