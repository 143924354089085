import { Component } from '@angular/core';
import { BankAccount } from 'src/app/types/models/bankAccount.model';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent {
  // editingAccount: BankAccount | null = null;

  // // Edit a bank account
  // editBankAccount(account: BankAccount) {
  //   this.editingAccount = { ...account };
  // }
    
  //     // Save edited bank account
  // saveEditedBankAccount() {
  //   if (this.editingAccount) {
  //     const index = this.bankAccounts.findIndex(acc => acc.Id === this.editingAccount!.Id);
  //     if (index !== -1) {
  //       this.bankAccounts[index] = this.editingAccount;
  //       this.editingAccount = null;
  //     }
  //   }
  // }

  // // Cancel editing
  // cancelEdit() {
  //   this.editingAccount = null;
  // }
    
}
