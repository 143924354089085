<!-- {{entity | json}} -->
<article class="card">
    <figure class="card-img">
      <!-- this img tag below can be replace with carousel -->
      
      <img 
        [src]="rtImgPath + entity.DefaultImage"/>
    </figure>
    
    <div class="card-body">
      <h2 class="card-title">{{ entity.Name }}</h2>
      <p class="card-text">
        {{ displayedDescription }}
        <a 
        *ngIf="entity?.Description?.length! > descriptionCutter" 
        (click)="toggleDescription($event)">
          {{ isExpanded ? 'show less' : 'show more' }}
        </a>
      </p>

      <p 
        *ngIf="!showActivityPromoButton"
        style="font-size: 16px; font-weight: bold;">
        {{entity.PricePerPerson | currency: 'ZAR': 'symbol' }}
      </p>

      <p 
        *ngIf="showActivityPromoButton && isActivityTypeGuarded(entity)"
        style="font-size: 16px; font-weight: bold;">
        Paying: {{(
          (entity.PricePerPerson) * 
          (entity.ActivityPromotionPercentage / 100)) | currency: 'ZAR': 'symbol' }}
      </p>

      <!-- <p 
      *ngIf="showActivityPromoButton && isActivityTypeGuarded(entity)"
      style="font-size: 16px; font-weight: bold;">
      Paying: {{(
        (entity.PricePerPerson) * 
        (entity.ActivityPromotionPercentage / 100)) | currency: 'ZAR': 'symbol' }}
      </p> -->
      

      <!-- activity history -->
      <ng-container 
        
        *ngIf="isActivity">

        <div 
        class="button-container">
          
          <button 
            *ngIf="showButton"
            style="border-radius: 40px;"
            class="btn btn-outline-primary activityBtn"
            (click)="displayHistory(entity.Id!)">View History</button>

          <button 
            *ngIf="showButton"
            style="border-radius: 40px;"
            class="btn btn-outline-primary activityBtn"
            (click)="EditActivityByAdmin($event, entity)">Edit</button>

          <button 
            *ngIf="showActivityPromoButton"
            style="border-radius: 40px;"
            class="btn btn-outline-primary activityBtn"
            (click)="PromoteActivity($event, entity)">Promote</button>

        </div>
        
         
        
      </ng-container>

      <!-- event history -->
      <ng-container 
        *ngIf="isEvent">
        
        <button 
        *ngIf="showButton"
        style="border-radius: 40px;"
        class="btn btn-outline-primary"
        (click)="displayEventHistory(entity.Id!)">View History</button>
      </ng-container>
      
    </div>
  </article>
  