import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { LoaderService } from 'src/app/shared/utils/loader.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent {
  @Input() maxFiles: number = 5;
  @Input() maxSizeMB: number = 7;  // Maximum file size in MB
  @Input() allowMultiple: boolean = true;
  @Input() showProgress: boolean = false;
  @Output() filesUploaded = new EventEmitter<string[]>();

  selectedFiles: File[] = [];
  progressInfos: { value: number, fileName: string }[] = [];
  errorMessage: string = '';

  constructor(
    private fileUploadSvc: FileUploadService,
    private loadingService: LoaderService
  ) {}

  get accept(): string {
    return 'image/*';
  }

  onFileSelected(event: any): void {
    this.loadingService.show()

    const fileList = event.target.files;
    const numOfFiles = fileList.length;

    if (!environment.production) {
      console.log(`Number of files: ${numOfFiles}`);
    }

    this.errorMessage = '';
    this.selectedFiles = [];
    this.progressInfos = [];

    if (numOfFiles > this.maxFiles) {
      this.errorMessage = `You can only select up to ${this.maxFiles} files.`;
      return;
    }

    for (let i = 0; i < numOfFiles; i++) {
      const file = fileList[i];
      const fileType = file.type.toLowerCase();
      const fileSizeMB = file.size / 1024 / 1024;  // Convert size to MB

      if (!fileType.startsWith('image/')) {
        this.errorMessage = `Only image files are allowed.`;
        return;
      }

      if (fileSizeMB > this.maxSizeMB) {
        this.errorMessage = `File size should not exceed ${this.maxSizeMB} MB.`;
        return;
      }

      this.selectedFiles.push(file);

      if (!environment.production) {
        console.log(`Name: ${file.name}`);
        console.log(`Size: ${file.size}`);
        console.log(`Type: ${file.type}`);
      }
    }

    this.uploadFiles(fileList);
  }

  async uploadFiles(fileList: File[]): Promise<void> {
    const uploadSelectionObserver = {
      next: async (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progressInfos = Array.from(fileList).map((file, index) => ({
            value: Math.round(100 * event.loaded / event.total),
            fileName: file.name
          }));
        } else if (event.type === HttpEventType.Response) {
          this.filesUploaded.emit(event.body);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorMessage = `Could not upload the files.`;
        if(!environment.production)
        {
          console.log(err);
        }
      },
      complete: () => {
        this.loadingService.hide()

        if(!environment.production)
        {
          console.log("Upload complete");
        }
      }
    };

    await this.fileUploadSvc.upload(fileList).subscribe(uploadSelectionObserver);
  }
}
