import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { LoaderService } from 'src/app/shared/utils/loader.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {

  passwordFieldType: string = 'password';

  public passResetForm: FormGroup = this.formBuilder.group('');
  
  canReset: boolean = false;

  token: any
  email: any

  constructor(
    private route: ActivatedRoute,
    private loadingService: LoaderService,
    private formBuilder: FormBuilder,
    private router: Router,
    private authSvc: AuthService,
    private toastr: ToastrService
  )
  {
    this.passResetForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]]
    }, 
    {
      validator: this.passwordMatchValidator // Add custom validator
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
      this.email = params['email'];

      if(!environment.production)
      {
        // Now you have the token and email, you can use them as needed
        console.log('Token:', this.token);
        console.log('Email:', this.email);
        // You can also trigger any further actions based on these parameters
      }

      //validate token and email info 
      if(this.token && this.email)
      {
        this.canReset = true;

      }

    });
    
  }

  get password() {
    return this.passResetForm.get('password');
  }

  get confirmPassword() {
    return this.passResetForm.get('confirmPassword');
  }

  togglePasswordVisibility() {
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
  }

 // Custom validator function
  passwordMatchValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const password = control.get('password');
    const confirmPassword = control.get('confirmPassword');
  
    if (password && confirmPassword && password.value !== confirmPassword.value) {
      return { 'passwordMismatch': true };
    }
  
    return null;
  }

  onSubmitPasswordReset()
  {
    if (this.passResetForm.valid) 
    {
      // Submit the form

      if(!environment.production)
      {
        console.log('Form Vals:', this.passResetForm.value);
      }

      //prep data
      const rstPassModel = {
        "email": this.email,
        "token": this.token,
        "password": this.passResetForm.value.password,
        "appBound": environment.AppBoundIdentifier
      }

      this.loadingService.show();

      const passRstObserver = {
        next: async (response: any) => {
          if(!environment.production)
          {
            console.log(`Response from login operation: ${response}`);
          }
          
          this.toastr.success("Password reset was successful ... ", "Success");
          
          this.loadingService.hide();

          //redirect to Login
          this.router.navigate(['login'])
         
        },
        error: async (err: HttpErrorResponse) => {
          this.loadingService.hide();

          if(!environment.production)
          {
            console.log(`Error while logging in: ${JSON.stringify(err)}`);
          }

          this.toastr.error(`${err?.error?.Data?.Value?.errors[0] ?? "Unknown error..."}`, "Reset password error");
        },
        complete: async () => {

          

          if(!environment.production)
          {
            console.log(`User logged in!`);
            // this.router.navigate(['login'])
          }
        }
      }


      //send request with token and email
      this.authSvc.ResetPasswordForAKnownAppUserWithValidToken(rstPassModel)
      .subscribe(passRstObserver)
      
    } else {
      // Form is invalid

      this.toastr.error("Passwords does not match or is less than 6 characters in length. Please correct inputs", "Password(s) error")
    
      //clear the inputs
      this.passResetForm.get('password')?.setValue('');
      this.passResetForm.get('confirmPassword')?.setValue('');
    }
  }
}
