import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ActivityState } from "../state/activity.state";
import { CurrentLoggedInUserState, EventCreatorsByStatusState } from "../state/current-user.state";


//#region loggedinUser
export const selectLoggedinUserState = createFeatureSelector<CurrentLoggedInUserState>('currentloggedinuser');

export const selectLoggedInUser = createSelector(
    selectLoggedinUserState,
  (state: CurrentLoggedInUserState) => state.currentloggedinuser
);

export const selectLoggedInUserLoading = createSelector(
    selectLoggedinUserState,
  (state: CurrentLoggedInUserState) => state.loading
);

export const selectLoggedInUserLoaded = createSelector(
    selectLoggedinUserState,
    (state: CurrentLoggedInUserState) => state.loaded
  );

export const selectLoggedInUserError = createSelector(
    selectLoggedinUserState,
  (state: CurrentLoggedInUserState) => state.error
);
//#endregion

//#region creatorsOfEventsByStatus
export const selectcreatorsOfEventsByStatusState = createFeatureSelector<EventCreatorsByStatusState>('creatorsofeventsbystatus');


export const selectcreatorsOfEventsByStatus = createSelector(
  selectcreatorsOfEventsByStatusState,
(state: EventCreatorsByStatusState) => state.eventcreatorsbystatus
);

export const selectcreatorsOfEventsByStatusLoading = createSelector(
  selectcreatorsOfEventsByStatusState,
(state: EventCreatorsByStatusState) => state.loading
);

export const selectcreatorsOfEventsByStatusLoaded = createSelector(
  selectcreatorsOfEventsByStatusState,
  (state: EventCreatorsByStatusState) => state.loaded
);

export const selectcreatorsOfEventsByStatusError = createSelector(
  selectcreatorsOfEventsByStatusState,
(state: EventCreatorsByStatusState) => state.error
);
//#endregion