import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, tap } from "rxjs";
import { ActivityService } from "src/app/shared/services/activity.service";
import * as CurrentUserActivitiesActions from "../actions/current-user-activities.actions";
import { Activity } from "src/app/types/models/activity.model";
import { environment } from "src/environments/environment";
import { BookingService } from "src/app/shared/services/booking.service";
import * as EntityBookingActions from "../actions/entity-bookings.actions";
import { Booking } from "src/app/types/models/booking.model";

@Injectable()
export class EntityBookingEffects {

  pageNumber: number = 1;
  pageSize: number = 50;

  entityBookings$ = createEffect(() => this.actions$.pipe(
      ofType(EntityBookingActions.LOAD_ENTITY_BOOKINGS),
      mergeMap(action => this.bookingService.GetAllBookingsPerEntityByIdWithPagination(action.mEEntity, action.entityId, this.pageNumber, this.pageSize)
      .pipe(
          tap((entityBookings: Booking[]) => {

            if(!environment.production)
            {
              console.log(`Bookings for entity: ${action.mEEntity} loaded successfully: ${JSON.stringify(entityBookings)}`);
            }
          }),
          map(entitybookings => EntityBookingActions.LOAD_ENTITY_BOOKINGS_SUCCESS({ entitybookings })),
          catchError(error => of(EntityBookingActions.LOAD_ENTITY_BOOKINGS_FAIL({ error })))
      ))
      )
  );


  constructor(
    private actions$: Actions,
    private bookingService: BookingService
  ) {}
}