export class AddressModel{
  constructor( 
      public Id: number,  
      public Street_number: string,
      public Street: string,
      public Suburb: string,
      public City: string,
      public State: string,
      public IsDefault: Boolean,
      public Building_name?: string,
      public Unit_number?: string,
      public Address_instruction?: string,
      public Country?: string,
      public Postal_code?: string,
      public Longitude?: string,
      public Latitude?: string,
      public CreatedAt?: Date | null,
      public ModifiedAt?: Date | null
  ){}  
}
