import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CurrentUserRoleState } from "../state/current-user-roles.state";

export const selectCurrentUserRolesState = createFeatureSelector<CurrentUserRoleState>('currentloggedinuserroles');

export const selectLoggedInUserRoles = createSelector(
    selectCurrentUserRolesState,
  (state: CurrentUserRoleState) => state.currentloggedinuserroles
);

export const selectLoggedInUserRolesLoading = createSelector(
    selectCurrentUserRolesState,
  (state: CurrentUserRoleState) => state.loading
);

export const selectLoggedInUserRolesLoaded = createSelector(
    selectCurrentUserRolesState,
    (state: CurrentUserRoleState) => state.loaded
  );

export const selectLoggedInUserRolesError = createSelector(
    selectCurrentUserRolesState,
  (state: CurrentUserRoleState) => state.error
);