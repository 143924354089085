import { createAction, props } from "@ngrx/store";
import { Activity } from "src/app/types/models/activity.model";

export const GET_ACTIVE_ACTIVITIES = createAction('[Activity] Get Active Activities',
props<{ statusCode: number, pageNumber: number, pageSize: number }>());

export const GET_ACTIVE_ACTIVITIES_SUCCESS = createAction('[Activity] Get Active Activities Success',
    props<{activeactivities: Activity[]}>());

export const GET_ACTIVE_ACTIVITIES_FAIL = createAction('[Activity] Get Active Activities Fail',
    props<{error: string}>());
