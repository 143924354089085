	    <!-- HTML Code start -->
		<section class='content'>
            <div class="container">
              <div class="row-fluid clearfix">
                
                <div class="pricing-wrapper comparison-table clearfix style-3">
                  <div class="col-md-3 pricing-col list-feature">
                    <div class="pricing-card">
                      <div class="pricing-header">
                        <h5>Choose Your Plan</h5>
                        <p>Compare Package Feature</p>
                      </div>
                      <div class="pricing-feature">
                        <li>
                          <p>monthly updates</p>
                        </li>
                        <li>
                          <p>activity listing</p>
                        </li>
                        <li>
                          <p>event listing</p>
                        </li>
                        <li>
                          <p>payment processing </p>
                        </li>
                        <li>
                          <p>booking history </p>
                        </li>
                        <li>
                          <p>Custom Domains</p>
                        </li>
                        <li>
                          <p>Technical Support</p>
                        </li>
                        <li>
                          <p>Monthly Bandwidth</p>
                        </li>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 pricing-col person">
                    <div class="pricing-card">
                        <div class="pricing-header">
                            <h5>BASIC</h5>
                            
                            <div class="price-box">
                                <div class="price">29
                                    <div class="currency">$</div>
                                    <div class="plan">/ Mo</div>
                                </div>
                            </div>
                      </div>
                      <div class="pricing-feature">
                        <li>
                          <p>
                            <i class="fa fa-check available"></i>
                          </p>
                        </li>
                        <li>
                          <p>
                            <span>50 GB</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <span>50</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <span>5</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <span>10</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <i class="fa fa-times unavailable"></i>
                          </p>
                        </li>
                        <li>
                          <p>
                            <i class="fa fa-times unavailable"></i>
                          </p>
                        </li>
                        <li>
                          <p>
                            <span>25 GB</span>
                          </p>
                        </li>
                      </div>
                      <div class="pricing-footer">
                        <a href="#" class="btn btn-act rounded btn-line">
                          <span>Order Now</span>
                          <i class="fa fa-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 pricing-col current unlim">
                    <div class="pricing-card">
                      <div class="pricing-header">
                        <h5>Standard</h5>
                        <a href="" class="ribbon">
                          <i class="fa fa-star"></i>
                          <span>Feature</span>
                        </a>
                        <div class="price-box">
                          <div class="price">49
                            <div class="currency">$</div>
                            <div class="plan">/ Mo</div>
                          </div>
                        </div>
                      </div>
                      <div class="pricing-feature">
                        <li>
                          <p>
                            <i class="fa fa-check available"></i>
                          </p>
                        </li>
                        <li>
                          <p>
                            <span>150 GB</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <span>150</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <span>15</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <span>20</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <i class="fa fa-times unavailable"></i>
                          </p>
                        </li>
                        <li>
                          <p>
                            <i class="fa fa-check available"></i>
                          </p>
                        </li>
                        <li>
                          <p>
                            <span>50 GB</span>
                          </p>
                        </li>
                      </div>
                      <div class="pricing-footer">
                        <a href="#" class="btn btn-act rounded btn-line">
                          <span>Order Now</span>
                          <i class="fa fa-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 pricing-col business">
                    <div class="pricing-card">
                      <div class="pricing-header">
                        <h5>Unlimited</h5>
                        <div class="price-box">
                          <div class="price">99
                            <div class="currency">$</div>
                            <div class="plan">/ Mo</div>
                          </div>
                        </div>
                      </div>
                      <div class="pricing-feature">
                        <li>
                          <p>
                            <i class="fa fa-check available"></i>
                          </p>
                        </li>
                        <li>
                          <p>
                            <span>300 GB</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <span>300</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <span>30</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <span>50</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <i class="fa fa-check available"></i>
                          </p>
                        </li>
                        <li>
                          <p>
                            <i class="fa fa-check available"></i>
                          </p>
                        </li>
                        <li>
                          <p>
                            <span>Unlimitied</span>
                          </p>
                        </li>
                      </div>
                      <div class="pricing-footer">
                        <a href="#" class="btn btn-act rounded btn-line">
                          <span>Order Now</span>
                          <i class="fa fa-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
  
              </div>
            </div>
        </section>
 
    <!-- <section class="footer">
    &copy; Mrsahar
    </section> -->