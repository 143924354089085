import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [  
    MatDatepickerModule,
    DatePipe 
  ],
})
export class DateTimePickerComponent {
  @Output() dateTimeChange = new EventEmitter<{ date: Date, time: string }>();
 

  dateControl = new FormControl();
  timeControl = new FormControl();

  getCurrentDate(): Date {
    return new Date();
  }

  onDateChange(event: any): void {
    // const eventStartDateValue = this.eventFormGroup.get('EventStartDate')?.value;

    // if(!environment.production)
    // {
    //   console.log('Event Start Date RAW:', eventStartDateValue);
    // }

    // if (eventStartDateValue) 
    // {
    //   const eventStartDate = new Date(eventStartDateValue);

    //   //the timezone will eventually be replaced by a global service based on user selection
    //   let timezone = 'Africa/Johannesburg';
    
    //   const eventStartDateUTC = moment(eventStartDateValue).tz(timezone).toString()

    //   if(!environment.production)
    //   {
    //     console.log(`Event Start Date: ${eventStartDate} && Event Start Date UTC moment: ${eventStartDateUTC} `);
    //   }
    // }

    this.emitDateTimeChange();
  }

  onTimeChange(event: any): void {

    // this.startTimeConvert = this.convertTo24HourFormat(newTime);

    // if(!environment.production)
    // {
    //   console.log(`start time:, ${newTime} | converted 24: ${this.startTimeConvert}`);
    // }

    this.emitDateTimeChange();
  }

  private emitDateTimeChange(): void {
    const date = this.dateControl.value;
    const time = this.timeControl.value;
    this.dateTimeChange.emit({ date, time });
  }
}
