import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { GET_CURRENT_USER_ROLES, GET_CURRENT_USER_ROLES_FAIL, GET_CURRENT_USER_ROLES_SUCCESS } from "../actions/current-user-roles.actions";
import { catchError, map, mergeMap, of, tap } from "rxjs";
import { AuthService } from "src/app/shared/services/CustomAuthenticator/auth.service";
// import { GenericRole } from "src/app/types/models/genericrole.model";
import { environment } from "src/environments/environment";

@Injectable()
export class UserRolesEffects {

    rolesOfLoggedInUser$ = createEffect(() => this.actions$.pipe(
        ofType(GET_CURRENT_USER_ROLES),
        mergeMap(() => this.authService.getAllRolesAUserHas()
        .pipe(
            tap((currentloggedinuserroles: any) => {
                if(!environment.production)
                {
                    console.log(`Roles of currently logged in user: ${ JSON.stringify(currentloggedinuserroles) }`);
                }
            }),
            map(currentloggedinuserroles => GET_CURRENT_USER_ROLES_SUCCESS({ currentloggedinuserroles })),
            catchError(error => of(GET_CURRENT_USER_ROLES_FAIL({ error })))
        ))
        )
    );

  constructor(
    private actions$: Actions,
    private authService: AuthService
  ) {}
}