import { createReducer, on } from "@ngrx/store";
import { CurrentUserRoleState } from "../state/current-user-roles.state";
import { GET_CURRENT_USER_ROLES, GET_CURRENT_USER_ROLES_FAIL, GET_CURRENT_USER_ROLES_SUCCESS } from "../actions/current-user-roles.actions";

const initialState: CurrentUserRoleState = {
    currentloggedinuserroles: [],
    loading: false,
    loaded: false,
    error: null,
};

const _currentLoggedinUserRoleReducer = createReducer(
    initialState,
    on(GET_CURRENT_USER_ROLES, state => ({ ...state, loading: true })),
    on(GET_CURRENT_USER_ROLES_SUCCESS, (state, { currentloggedinuserroles }) => ({
        ...state,
        currentloggedinuserroles,
        loading: false,
        loaded: true,
        error: null,
    })),
    on(GET_CURRENT_USER_ROLES_FAIL, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error,
    }))
);

export function currentLoggedinUserRoleReducer(state: any, action: any) {
    return _currentLoggedinUserRoleReducer(state, action);
  }