import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { ActivityData } from 'src/app/types/models/activitydata.model';
import { ActivityHostData } from 'src/app/types/models/activityHostData.model';
import { EventHostData } from 'src/app/types/models/eventHostData.model';
import { CustomLoginModel } from 'src/app/types/models/customlogin.model';
import { CustomRegisterationModel } from 'src/app/types/models/customregistration.model';
import { EmailData } from 'src/app/types/models/emaildata.model';
import { EventData } from 'src/app/types/models/eventdata.model';
import { GenericRole } from 'src/app/types/models/genericrole.model';
import { GenericUser } from 'src/app/types/models/genericuser.model';
import { KnownApplication } from 'src/app/types/models/knownapplication.model';
import { TokenRetrievalModel } from 'src/app/types/models/tokenretrieval.model';
// import { CustomLoginModel, CustomRegisterationModel, EmailData, GenericRole, KnownApplication, TokenRetrievalModel, eventData } from 'src/app/modules/auth/login/login.component';
import { environment } from 'src/environments/environment';
import { BookingClaim } from 'src/app/types/models/bookingClaim.model';
import { SubscriptionTier } from 'src/app/types/models/SubscriptionRelated/SubscriptionTier.model';
import { UserSubscription } from 'src/app/types/models/SubscriptionRelated/UserSubscription.model';
// import Paystack from '@paystack/inline-js';

import Paystack from '@paystack/inline-js';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private authBaseURL = environment.CustomAuthBaseUrl
  private maixBaseUrl = environment.APIUrl
  private mailerBaseUrl = environment.MailerBaseUrl


  constructor(
    private client: HttpClient,
    private cookieService: CookieService,
    private router: Router) { }

  // #region Application_specific_endpoints(IsAppRegitered, 
  //  RegisterToCustomAuth, 
  //  RequestUserToken,
  //  RegistrationTemplateGenerator,
  //  SendConfirmRegistrationEmail,
  //  confirmEmail,
  //  )


  
  IsAppRegistered(appId: number, appName: string) : Observable<any>
  {
    return this.client.post(
      `${this.authBaseURL}Application/IsAppRegistered/${appId}/${appName}`,
      null)
  }

  RegisterToCustomAuth(knownApp: KnownApplication) : Observable<any>
  {
    return this.client.post(`${this.authBaseURL}Identity/RegisterApplicationToCustomAuth`,
    knownApp);
  }
  

  RequestUserToken(tknModel: TokenRetrievalModel)
  {
    return this.client.post(`${this.authBaseURL}Identity/GetTokenForAUser`,
    tknModel);
  }

  RegistrationTemplateGenerator(passRstTemplateModel: any)
  {
    return this.client.post(
      `${this.maixBaseUrl}AuthHelper/RegistrationTemplateGenerator`,
      passRstTemplateModel);
  }

  EventPaymentTemplateGenerator(emailPmtTemplate : any)
  {
    return this.client.post(
      `${this.maixBaseUrl}AuthHelper/EventPaymentConfirmedTemplateGenerator`,
      emailPmtTemplate);
  }

  PassResetTemplateGenerator(passRstTemplateModel: any)
  {
    return this.client.post(
      `${this.maixBaseUrl}AuthHelper/PasswordResetTemplateGenerator`,
      passRstTemplateModel); 
      // {withCredentials: true});   this is strict with CORS 
  }

  SendConfirmRegistrationEmail(mlData: EmailData)
  {
    return this.client.post(
      `${this.authBaseURL}Identity/EmailValidationMessageForUserRegistration`,
      mlData);
  }

  //same as above, give it a better name and clean up
  SendConfirmEmailForPasswordReset(mlData: EmailData)
  {
    return this.client.post(
      `${this.authBaseURL}Identity/EmailValidationMessageForUserRegistration`,
      mlData);
  }

  confirmEmail(confirmEmailOnReg: any) {
    
    return this.client.post(
      `${this.authBaseURL}Identity/ConfirmEmail`,
      confirmEmailOnReg);

    // return this.client.post(this.APIUrl + "Identity/ConfirmEmail", confirmEmailOnReg);
  }

  SendConfirmPaymentEmail(eventData: EventData)
  {
    return this.client.post(
      `${this.maixBaseUrl}AuthHelper/EventPaymentNotification`,
      eventData);
  }

  SendActivityConfirmPaymentEmail(activityData: ActivityData)
  {
    return this.client.post(
      `${this.maixBaseUrl}AuthHelper/ActivityPaymentNotification`,
      activityData);
  }

  SendActivityHostPaymentEmail(activityHostData: ActivityHostData)
  {
    return this.client.post(
      `${this.maixBaseUrl}AuthHelper/ActivityHostPaymentNotification`,
      activityHostData);
  }

  SendEventHostPaymentEmail(eventHostData: EventHostData){
    return this.client.post(
      `${this.maixBaseUrl}AuthHelper/EventHostPaymentNotification`,
      eventHostData);
  }

  SendContactUsMail(formData: any): Observable<any> {
    return this.client.post(
      `${this.maixBaseUrl}AuthHelper/ContactUs`,
      formData);
  }
  SendClaimEmail(content:BookingClaim):Observable<any>{
    
    // const activityClaimsPayment = {
    //   recipientEmail: 'maixperience.rsa@gmail.com',
    //   emailSubject: content.subject,
    //   templateContent: `Message from ${content.name} from ${content.email} \n\n ${content.message}`,
    //   appBound: 4  // Indicates MaiExp use only
    // };

    // content = JSON.stringify(activityClaimsPayment); 
    const headers = { 'Content-Type': 'application/json' };

    return this.client.post(
      `${this.mailerBaseUrl}api/appauth/sendcustomemail`, content , { headers })
      .pipe(
        map(response => response),
        catchError(error => {
          console.error('Error occurred:', error);
          return throwError(() => new Error('Error occurred while sending email'));
        })
      );
  }

  
  //#endregion

  // #region User_Specific_Endpoints(RegisterUserForMaiXperience)
  //  LoginToMaiXperience, 
  //  RequestUserToken,
  //  GetAllUsersOfMaiXpByAppId,
  //  GetAllUsersOfMaiXpByAppName,
  //  ValidateUserCookie,
  //  RemoveUserById,
  //  DecryptCookie
  //  )
  RegisterUserForMaiXperience(customRegModel: CustomRegisterationModel): Observable<any>
  {
    return this.client.post(
      `${this.authBaseURL}Identity/RegisterUserToAKnownApplication`,
      customRegModel,
      { withCredentials: true })
  }


  LoginToMaiXperience(csLogin: CustomLoginModel): Observable<any>
  {
    return this.client.post(
      `${this.authBaseURL}Identity/UserLoginToAKnownApplication`,
      csLogin,
      { withCredentials: true }
    )
  }

  InitiateResetPasswordForAKnownAppUser(forgotPassModel: any)
  {
    return this.client.post(
      `${this.authBaseURL}Identity/InitiateResetPasswordForAKnownAppUser`,
      forgotPassModel,
      { withCredentials: true }
    )
  }

  ResetPasswordForAKnownAppUserWithValidToken(resetPassModel: any)
  {
    return this.client.post(
      `${this.authBaseURL}Identity/ResetPasswordForAKnownAppUserWithValidToken`,
      resetPassModel,
      { withCredentials: true }
    )
  }

  GetAllUsersOfMaiXpByAppId(appBound: number)
  {
    return this.client.get(
      `${this.authBaseURL}UserManager/GetAllUsersOfAnAppByAppId/${appBound}`,
      { withCredentials: true });
  }

  GetAllUsersOfMaiXpByAppName(appName: string)
  {
    return this.client.get(`${this.authBaseURL}UserManager/GetAllUsersOfAnAppByAppName/${appName}`);
  }

  RemoveUserById(usrId: string)
  {
    return this.client.delete(
      `${this.authBaseURL}UserManager/RemoveUserById/${usrId}`); 
  }

  //returning roles
  DecryptCookie(): Observable<any>
  {
    if(!environment.production)
    {
      console.log(`Auth base URL: ${this.authBaseURL}`);
    }
    
    return this.client.post(
      `${this.authBaseURL}UserManager/DecryptCookie`,
      null,
      { withCredentials: true }
    )
  }

  //returning claims
  DecryptCookieForClaims(): Observable<any>
  {
    if(!environment.production)
    {
      console.log(`Auth base URL: ${this.authBaseURL}`);
    }
    
    return this.client.post(
      `${this.authBaseURL}UserManager/DecryptCookieForClaims`,
      null,
      { withCredentials: true }
    )
  } 

  

  // DecryptCookieAndReturnUser(): Observable<GenericUser> {
  //   return this.client.post(
  //     `${this.authBaseURL}UserManager/DecryptCookieAndReturnUser`,
  //     null,
  //     { withCredentials: true }
  //   )
  // }

  ValidateUserCookie()
  {
    return this.client.post(
      `${this.authBaseURL}UserManager/ValidateUserCookie`,
      null,
      { withCredentials: true }
    )
  }



  // return the ID of the currenty logged in user (if any)
  GetCurrentlyLoggedInUserIdIfAny() : Observable<string>
  {
    return new Observable((observer) => {
      // Retrieve the cookie from document.cookie
      const cookies = document.cookie.split(';');

      if(!environment.production)
      {
        console.log(`Document Cookie: ${document.cookie} and Result of COOKIES SPLIT ${cookies}`);
      }

      let cookieValue = null;

      for (const cookie of cookies) {
        const [name, value] = cookie.split('=').map(c => c.trim());
        if (name === 'CustomAuthCookie') {
          cookieValue = value;
          break;
        }
      }

      if(!environment.production)
      {
        console.log(`cookie retrieved on authSvc -> getAllRolesAUserHas ${cookieValue}`);
      }

      // Ensure the cookie is not empty
      if (cookieValue) {
        // Include the cookie in the request headers
        const headers = new HttpHeaders().set('Authorization', `Bearer ${cookieValue}`);
        
          this.client.post(
            `${this.authBaseURL}UserManager/DecryptCookieForClaims`,
            null,
            { headers, withCredentials: true }
          ).subscribe({
            next: (response: any) => {
              
              if(!environment.production)
              {
                //get the userId
                console.log("cookie decrypt: " + JSON.stringify(response))
              }

              let usrIdClaim = response.find((x: any) => x?.Type == "UserIdClaim");

              let usrID = usrIdClaim?.Value;
              
              if(!environment.production)
              {
                console.log("User ID from cookie decrypt: " + usrID);
              }

              observer.next(usrID);
              observer.complete(); 
            },
            error: (e) => {
              if(!environment.production)
              {
                console.log(`Error on cookie token decrypt: ${JSON.stringify(e)}`)
              }

              observer.error(e);
            },
            complete: () => {
              console.info('complete') 
            }
          });
      }
      else
      {
        observer.next(undefined);
        observer.complete(); 
      }
    })
  }
  // #endregion

  //  #region Role_specific_endpoints(GetAllRolesForMaiXp,
  //  AddSingleRoleToCustomAuthApp,
  //  AddRolesToCustomAuth,
  //  RemoveRoleById,
  //  getAllRolesAUserHas,
  //  ConfirmThatUserHasRole
  //  )

  GetAllRolesForMaiXp(appBound: number) : Observable<GenericRole[]>
  {
    // return this.client.get(
    //   `${this.authBaseURL}RoleManager/ViewAllRolesOfAnApp/${appBound}`,
    //   { withCredentials: true });

      return this.client.get<GenericRole[]>(
        `${this.authBaseURL}RoleManager/ViewAllRolesOfAnApp/${appBound}`)
  }

  
  GetAllSubscriptionsForMaiXp() : Observable<SubscriptionTier[]>
  {
      return this.client.get<SubscriptionTier[]>(
        `${this.maixBaseUrl}SubscriptionTier/GetAllSubscriptionTiers`)
  }

  AddSingleRoleToCustomAuthApp(roleName: string, appBound: number)
  {
    return this.client.post(
      `${this.authBaseURL}RoleManager/AddANewRoleForAnApp/${roleName}/${appBound}`,
      null, 
      {
        withCredentials: true
      });
  }


  createPlanWithPaystack(
    tierName: string,
    planTnterval: string,
    price: number
  ): Observable<any> {
    
    const headers = new HttpHeaders({
      Authorization: `Bearer ${environment.PaystackSecretKey}`
    });

    const body = {
      name: tierName,
      interval: planTnterval,
      amount: (price * 100).toString(),
    };

    return this.client.post(
      environment.paystackPlanUrl, 
      body, { headers });
  }

  AddSingleSubTierToMxp(subTierModel: SubscriptionTier)
  {
    return this.client.post(
      `${this.maixBaseUrl}SubscriptionTier/AddSubscriptionTier`,
      subTierModel);
  }


  //add subscription to paystack
  GetSubscriptionAuthorization(
    usrEmail: string,
    amount: number,
    plan: string
  ): Observable<any> {
    
    const headers = new HttpHeaders({
      Authorization: `Bearer ${environment.PaystackSecretKey}`
    });

    const body = {
      email: usrEmail,
      amount: (amount * 100).toString(),
      plan: plan,
    };

    const url = `${environment.paystackSubscriptionUrl}/transaction/initialize`
    
    return this.client.post(
      url, 
      body, 
      { headers });
  }

  AddUserSubscriptionToMxp(userSub: UserSubscription)
  {
    return this.client.post(
      `${this.maixBaseUrl}UserSubscription/AddNewUserSubscription`,
      userSub);
  }

  AddRolesToCustomAuth(genericRoles: GenericRole[])
  {
    return this.client.post(`${this.authBaseURL}RoleManager/AddMultipleRolesToApplication`,
    genericRoles);
  }

  RemoveRoleById(roleId: string)
  {
    return this.client.delete(
      `${this.authBaseURL}RoleManager/RemoveRoleById/${roleId}`,
      {
        withCredentials: true
      });
  }

  RemoveSubTierById(subTierId: string)
  {
    return this.client.post(
      `${this.maixBaseUrl}SubscriptionTier/RemoveSubscriptionTier/${subTierId}`,
      null);
  }
 
  getAllRolesAUserHas() : Observable<any>
  {
    let rolesTBR: any;
    //Get all the roles that a user has

    // Retrieve the cookie from document.cookie
    const cookies = document.cookie.split(';');
    let cookieValue = null;

    for (const cookie of cookies) {
      const [name, value] = cookie.split('=').map(c => c.trim());
      if (name === 'CustomAuthCookie') {
        cookieValue = value;
        break;
      }
    }

    if(!environment.production)
    {
      console.log(`cookie retrieved on authSvc -> getAllRolesAUserHas ${cookieValue}`);
    }

    // Ensure the cookie is not empty
    if (cookieValue) {
      // Include the cookie in the request headers
      const headers = new HttpHeaders().set('Authorization', `Bearer ${cookieValue}`);
      
      return new Observable((observer) => {

        this.client.post(
          `${this.authBaseURL}UserManager/DecryptCookie`,
          null,
          { headers, withCredentials: true }
        ).subscribe({
          next: (cookieResponse: any) => {
            if(!environment.production)
            {
              console.log(`Response from cookie decrypt: ${JSON.stringify(cookieResponse)}`);
            }

            let assignedUserRoleObjects = cookieResponse
            
            if(!environment.production)
            {
              console.log(`logged in user role(s) object array: ${JSON.stringify(assignedUserRoleObjects)}`)
            }
            
            // rolesTBR = 
            observer.next(assignedUserRoleObjects);
            observer.complete();
          },
          error: (e) => {
            console.log("Error on token decrypt")
            observer.error(e);
          },
          complete: () => {
            if(!environment.production)
            {
              console.info('complete') 
            }
          }
        });

      })
    }
    else
    {

      return new Observable(undefined);
    }

  }


  getAllClaimsAUserHas() : Observable<any>
  {
    let rolesTBR: any;
    //Get all the roles that a user has

    // Retrieve the cookie from document.cookie
    const cookies = document.cookie.split(';');
    let cookieValue = null;

    for (const cookie of cookies) {
      const [name, value] = cookie.split('=').map(c => c.trim());
      if (name === 'CustomAuthCookie') {
        cookieValue = value;
        break;
      }
    }

    if(!environment.production)
    {
      console.log(`cookie retrieved on authSvc -> getAllRolesAUserHas ${cookieValue}`);
    }

    // Ensure the cookie is not empty
    if (cookieValue) {
      // Include the cookie in the request headers
      const headers = new HttpHeaders().set('Authorization', `Bearer ${cookieValue}`);
      
      return new Observable((observer) => {

        this.client.post(
          `${this.authBaseURL}UserManager/DecryptCookie`,
          null,
          { headers, withCredentials: true }
        ).subscribe({
          next: (cookieResponse: any) => {
                
            // console.log(`Response from cookie decrypt: ${JSON.stringify(cookieResponse)}`);
          
            //from the list of roles, get those with "Type": "RoleClaim" of first subject in the array
            let assignedUserClaimsObjects = cookieResponse.filter((x: any) => x?.Type == "RoleClaim")

            // if(!environment.production)
            // {
            //   console.log(`All claims of the user from assignedUserRoleObjects > auth service: ${JSON.stringify(assignedUserClaimsObjects)}`);
            // }
           
            // rolesTBR = 
            observer.next(assignedUserClaimsObjects);
            observer.complete();
          },
          error: (e) => {
            console.log("Error on token decrypt")
            observer.error(e);
          },
          complete: () => {
            console.info('complete') 
          }
        });

      })
    }
    else
    {

      return new Observable(undefined);
    }
  }

  ConfirmThatUserHasRole(roleName: string) : string | undefined
  {
    console.log(`confirming that user has role: ${roleName}`);
        
    //look within yourself and answer if the role is valid
    this.DecryptCookie().subscribe((cookieResponse: any) => {
      if(cookieResponse)
      {
        // console.log(`cookie decrypt response: ${JSON.stringify(cookieResponse)}`);
        console.log(`Cookie decrypted`);
        
        //from the list of roles, get those with "Type": "RoleClaim" of first subject in the array
        let assignedUserRoleObjects = cookieResponse.filter((x: any) => x?.Type == "RoleClaim")
        
        if(!environment.production)
        {
          console.log(`All claims of the user from ConfirmThatUserHasRole > auth service: ${JSON.stringify(assignedUserRoleObjects)}`);

        }

        const requestedRoleValue = assignedUserRoleObjects.map((obj: any) => obj.Value == roleName);
        
        if(requestedRoleValue)
        {
          console.log(`${roleName} found for user. ${requestedRoleValue}`)
        }

        console.log(`logged in user role(s) values: ${JSON.stringify(requestedRoleValue)}`)
        
        return requestedRoleValue.toString();
      }
      else{
        this.router.navigate(['login']);
      }
    });

    return undefined;
  }
  

  getHttpOptions(){
   
    // const headers = new HttpHeaders()
    //   .set('Cache-Control', 'max-age=3600'); // Set the cache control header

    // const options = { headers };

    // const options = {
    //   headers: headers.delete('Pragma') // Remove the 'Pragma' header
    // };

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      })
      .set('Cache-Control', 'max-age=3600') // Set the cache control header,
      .delete('Pragma')
    };

    return httpOptions;
  }
  //#endregion
}
