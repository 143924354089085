import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { GET_CURRENT_USER } from 'src/app/ngrx/actions/current-user.actions';
import { AppState } from 'src/app/ngrx/state/app.state';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { UserSpecificService } from 'src/app/shared/services/dataService/user-specific.service';
import { LoaderService } from 'src/app/shared/utils/loader.service';
import { GenericUser } from 'src/app/types/models/genericuser.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent {


  // Define a FormGroup
  usrForm: FormGroup | undefined;
  isFormChanged: boolean = false;  // Boolean flag to track form changes
  initialFormValues: any;  // Store initial form values
  trackingFormValues: any;  // Store initial form values

  useId: any
  @Input() usrObjectInp: any;

  
  constructor(private fb: FormBuilder,
    private authSvc: AuthService,
    private userSvc: UserSpecificService,
    private toastrService: ToastrService,
    private loadingService: LoaderService,
    private store: Store<AppState>
  ) {
  }

  ngOnInit() 
  {
    // Define a regex pattern for letters only (for firstname and lastname)
    const lettersOnlyPattern = /^[a-zA-Z]+$/;

    // Define a regex pattern for 10-digit phone numbers
    const phoneNumberPattern = /^[0-9]{10}$/;


    if(!environment.production)
    {
      console.log(`User Data: ${JSON.stringify(this.usrObjectInp)}`)
    }
      
    // Initialize the form with FormBuilder
    this.usrForm = this.fb.group({
      firstname: [this.usrObjectInp.Firstname ?? '',[Validators.required, Validators.pattern(lettersOnlyPattern)]], 
      lastname: [this.usrObjectInp.Lastname ?? '', [Validators.required, Validators.pattern(lettersOnlyPattern)]], 
      username: [this.usrObjectInp.UserName],
      email: [this.usrObjectInp.Email], 
      phone: [this.usrObjectInp.PhoneNumber ?? '', [Validators.required, Validators.pattern(phoneNumberPattern)]] 
    });

    // Store a deep copy of the initial form values
    this.initialFormValues = this.deepClone(this.usrForm.value);
    this.trackingFormValues = this.deepClone(this.usrForm.value);

    console.log(`Initial form value: ${JSON.stringify(this.initialFormValues)}`)
    console.log(`Initial form value2: ${JSON.stringify(this.trackingFormValues)}`)
  }

  // // Method to check if any form field has changed compared to initial values
  // private checkIfFormChanged(): boolean {
  //   const currentValues = this.deepClone(this.usrForm!.value); // Get current form values
  //   // const currentValues = this.usrForm!.value; // Get current form values
  //   return !this.deepEqual(currentValues, this.initialFormValues);
  // }

  // // Utility function to perform deep comparison of two objects
  // private deepEqual(obj1: any, obj2: any): boolean {
  //   return JSON.stringify(obj1) === JSON.stringify(obj2);
  // }

  deepClone(obj: any): any {
    // Custom deep cloning logic
    return JSON.parse(JSON.stringify(obj));
  }

  UpdateUserInformation()
  {

    if (this.usrForm!.valid) {
      if(!environment.production)
      {
        console.log('Form Updated', this.usrForm!.value);
      }
    
      //build generic user based on that
      let genUser = new GenericUser(
        this.usrObjectInp.UserName, //username
        this.usrObjectInp.Email, //email
        environment.AppBoundIdentifier,
        this.usrForm!.value.firstname, //firstname 
        this.usrForm!.value.lastname, //lastname 
        this.usrObjectInp.ProfilePicture, //profile pic 
        this.usrObjectInp.Id, //id 
        this.usrForm!.value.phone, //phone 
        this.usrObjectInp.IPRegisteredWith, //ip reg with 
      );

      const userUpdateObserver = {

      }

      this.loadingService.show()
      

      // add UpdateUser to the servce and call it for update
      this.userSvc.UpdateUser(genUser)
      .subscribe((updateUserResponse: any) => {

        if(!environment.production)
        {
          console.log(`Response from user update: ${JSON.stringify(updateUserResponse)}`)
        }

        this.loadingService.hide()
        
        this.toastrService.success("User information updated successfully", "Update status");

        //dispatch
        this.store.dispatch(GET_CURRENT_USER());

        
      });
    }

  }


}
