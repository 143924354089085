import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent {
  @Output() timeChange = new EventEmitter<string>();
  @Input() placeholder: string = 'select time';
  @Input() timePickerStyle: { [key: string]: string } = {};
  @Input() appearance: MatFormFieldAppearance = 'fill'; //'legacy' | 'standard' | 'fill' | 'outline'
  
  timeControl = new FormControl();

  onTimeChange(event: any): void {

    // this.startTimeConvert = this.convertTo24HourFormat(newTime);

    // if(!environment.production)
    // {
    //   console.log(`start time:, ${newTime} | converted 24: ${this.startTimeConvert}`);
    // }

    this.timeChange.emit(this.timeControl.value);
  }


  // convertTo24HourFormat(timeString: string): string {
  //   const [time, period] = timeString.split(' ');

  //   let [hours, minutes] = time.split(':').map(Number);

  //   if (period === 'PM' && hours !== 12) {
  //       hours += 12;
  //   } else if (period === 'AM' && hours === 12) {
  //       hours = 0;
  //   }

  //   // Ensure leading zeros for single-digit hours and minutes
  //   const formattedHours = hours.toString().padStart(2, '0');
  //   const formattedMinutes = minutes.toString().padStart(2, '0');

  //   return `${formattedHours}:${formattedMinutes}`;
  // }
}
