import { createAction, props } from "@ngrx/store";
import { ActivityPromotersLog } from "src/app/types/models/activityPromotersLog.model";


// get all the activities that I am promoting
export const LOAD_ACTIVITIES_I_AM_PROMOTING = createAction('[ActivityPromotersLog] Load Activities I am Promoting',
    props<{ promoterUserId: string, pageNumber: number, pageSize: number }>());

export const LOAD_ACTIVITIES_I_AM_PROMOTING_SUCCESS = createAction('[ActivityPromotersLog] Load Activities I am Promoting Success',
    props<{activitiesiampromoting: ActivityPromotersLog[]}>());

export const LOAD_ACTIVITIES_I_AM_PROMOTING_FAIL = createAction('[ActivityPromotersLog] Load Activities I am Promoting Fail',
    props<{error: string}>());