  <!-- google map section (plus marker) -->
<mat-dialog-content>
    <mat-dialog-actions style="float: right;">
        <button 
        mat-raised-button 
        color="warn" 
        mat-dialog-close 
        mat-dialog-close>
            X
        </button>
    </mat-dialog-actions>

    <br><br><br>

    <div>
        <app-map (addressSelected)="onAddressSelected($event)"></app-map>
    </div>

  
</mat-dialog-content>

<mat-dialog-actions>
    <button 
        *ngIf="addressReadyForUpdate"
        (click)="SaveAddressAndHarvestItsID()"
        mat-raised-button 
        color="primary">
    Use address
    </button>
    <button 
        mat-raised-button 
        color="warn"  
        mat-dialog-close>
        Close
    </button>
</mat-dialog-actions>