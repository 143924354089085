import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { GET_CREATORS_OF_EVENTS_BY_STATUS, GET_CREATORS_OF_EVENTS_BY_STATUS_FAIL, GET_CREATORS_OF_EVENTS_BY_STATUS_SUCCESS, GET_CURRENT_USER, GET_CURRENT_USER_FAIL, GET_CURRENT_USER_SUCCESS } from "../actions/current-user.actions";
import { catchError, map, mergeMap, of, tap } from "rxjs";
import { UserSpecificService } from "src/app/shared/services/dataService/user-specific.service";
import { GenericUser } from "src/app/types/models/genericuser.model";
import { environment } from "src/environments/environment";

@Injectable()
export class UserEffects {

    // currentLoggedInUser$ = createEffect(() => this.actions$.pipe(
    //     ofType(GET_CURRENT_USER),
    //     mergeMap(action => this.userService.GetUserByIdForAppByAppId(action.userId, environment.AppBoundIdentifier)
    //     .pipe(
    //         tap((currentloggedinuser: GenericUser) => {
    //             if(!environment.production)
    //             {
    //                 console.log(`Current logged in user: ${ currentloggedinuser}`);
    //             }
    //         }),
    //         map(currentloggedinuser => GET_CURRENT_USER_SUCCESS({ currentloggedinuser })),
    //         catchError(error => of(GET_CURRENT_USER_FAIL({ error })))
    //     ))
    //     )
    // );

    currentLoggedInUser$ = createEffect(() => this.actions$.pipe(
        ofType(GET_CURRENT_USER),
        mergeMap(() => this.userService.GetCurrentlyLoggedInUserIdIfExist()
                .pipe(
                    tap((currentloggedinuser: GenericUser) => {
                        if(!environment.production)
                        {
                            console.log(`Current logged in user (from current user effect): ${ JSON.stringify(currentloggedinuser)}`);
                        }
                    }),
                    map((currentloggedinuser: GenericUser) => GET_CURRENT_USER_SUCCESS({ currentloggedinuser })),
                    catchError(error => of(GET_CURRENT_USER_FAIL({ error })))
                )
            )
        ))

        
    //load event creators by event status
    loadEventCreatorsByEventStatus$ = createEffect(() => this.actions$.pipe(
        ofType(GET_CREATORS_OF_EVENTS_BY_STATUS),
        mergeMap(action => this.userService.GetAllUsersOfAnAppByUserIds(environment.AppBoundIdentifier, action.arrayOfUserId)
        .pipe(
            tap((eventscreatorsbystatus: GenericUser[]) => {
                if(!environment.production)
                {
                    console.log('User IDs:', action.arrayOfUserId);
                    console.log(`Creators of ${action.eventStatus} events (with status code ${action.eventStatus}) loaded successfully: ${JSON.stringify(eventscreatorsbystatus)}`);
                }
            }),
            map(eventscreatorsbystatus => GET_CREATORS_OF_EVENTS_BY_STATUS_SUCCESS({ eventscreatorsbystatus })),
            catchError(error => of(GET_CREATORS_OF_EVENTS_BY_STATUS_FAIL({ error })))
        ))
        )
    );

    // getActiveEvents$ = createEffect(() => this.actions$.pipe(
    //     ofType(EventActions.GET_EVENTS_BY_STATUS),
    //     mergeMap(action => this.maixEventService.GetAllEventsByStatusWithPagination(action.eventStatusCode, action.pageNumber, action.pageSize)
    //     .pipe(
    //         tap((eventsByStatus : MaiXEvent[]) => {
    //           if(!environment.production)
    //           {
    //             console.log(`${eventsByStatus.length} Events with status ${action.eventStatusCode} loaded successfully:`);
    //           }
    //         }),
    //         map(eventsByStatus => EventActions.GET_EVENTS_BY_STATUS_SUCCESS({ eventsByStatus })),
    //         catchError(error => of( EventActions.GET_EVENTS_BY_STATUS_FAIL({ error })))
    //     ))
    //     )
    //   );
    
    
        
  constructor(
    private actions$: Actions,
    private userService: UserSpecificService
  ) {}
}