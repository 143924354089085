import { GenericRole } from "./genericrole.model";
import { GenericUser } from "./genericuser.model";

export class CustomRegisterationModel
{
  constructor(
    public genericUser: GenericUser,
    public genericRole: GenericRole,
    public PasswordToBeThrownAway: string
    
  ){}
}