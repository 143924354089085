import { CommonModule, JsonPipe, NgFor, NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { concatMap, filter, first, Observable, startWith, switchMap, tap } from 'rxjs';
import { LOAD_ACTIVITIES_I_AM_PROMOTING } from 'src/app/ngrx/actions/activity-i-am-promoting.actions';
import { GET_ACTIVITIES_BY_USERID } from 'src/app/ngrx/actions/activity.actions';
import { GET_CURRENT_USER } from 'src/app/ngrx/actions/current-user.actions';
import { selectActivitiesIAmPromotingWithPagination } from 'src/app/ngrx/selectors/activity-i-am-promoting.selectors';
import { selectLoggedInUser } from 'src/app/ngrx/selectors/current-user.selectors';
import { AppState } from 'src/app/ngrx/state/app.state';
import { ActivityPromoterLogsService } from 'src/app/shared/services/activity-promoter-logs.service';
import { AddressService } from 'src/app/shared/services/address.service';
import { UserSpecificService } from 'src/app/shared/services/dataService/user-specific.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ActivityPromoterNotificationTempGenService } from 'src/app/shared/services/NotificationsRelated/ActivityPromoterNotificationTempGen.service';
import { ObfuscationServiceService } from 'src/app/shared/services/obfuscation-service.service';
import { LoaderService } from 'src/app/shared/utils/loader.service';
import { ActivityPromotionAcceptanceStatus } from 'src/app/types/enums/activityPromotionAcceptanceStatus.enum';
import { Activity } from 'src/app/types/models/activity.model';
import { ActivityPromotersLog } from 'src/app/types/models/activityPromotersLog.model';
import { GenericUser } from 'src/app/types/models/genericuser.model';
import { MaiXEvent } from 'src/app/types/models/maixevent.model';
import { ActivityPromoterRequestNotification } from 'src/app/types/models/NotificationRelated/activityPromoterRequestNotification.model';
import { ActivityPromoterToHostNotification } from 'src/app/types/models/NotificationRelated/activityPromoterToHostNotification.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-card',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  @Input() entity!: Activity | MaiXEvent;
  @Input() isActivity: boolean = false;
  @Input() isEvent: boolean = false;
  @Input() showButton: boolean = false;
  @Input() showActivityPromoButton: boolean = false;
  @Input() loggedInUser: GenericUser | undefined;
  @Input() showBookingButtonFromActivityPromotion: boolean = false;
  @Input() ActivityPromoterUserId: string = '';
  isExpanded: boolean = false;

  rtImgPath: string = environment.fileRetriever;
  
  descriptionCutter: number = 50;

  usrObject$: Observable<GenericUser | null>
  activitiesPromoting$: Observable<ActivityPromotersLog[]> | undefined

  pageNumber: number = 1;
  pageSize: number = 10;

  constructor(private router: Router,
    private userSpecificSvc: UserSpecificService,
    private dialogSvc: DialogService,
    private toastrSvc: ToastrService,
    private loadingService: LoaderService,
    private activityPromotionLog: ActivityPromoterLogsService,
    private addrService: AddressService,
    private store: Store<AppState>,
    private obfuscationService: ObfuscationServiceService,
    private activityPromoterNotificationTempGen: ActivityPromoterNotificationTempGenService
  ) {
    //currently logged in user
    this.usrObject$! = this.store.pipe(select(selectLoggedInUser));

    //when userObject$ becomes available get the
    // get all the activities user is promoting
    this.activitiesPromoting$ = this.store.pipe(select(selectActivitiesIAmPromotingWithPagination))

    
    // Reactive approach to get all activities the user is promoting
    // this.activitiesPromoting$ = this.usrObject$.pipe(
    //   filter(user => !!user), // Ensure the user data is loaded
    //   switchMap(user => 
    //     this.store.pipe(
    //       select(selectActivitiesIAmPromotingWithPagination)
    //     )
    //   )
    // );
  }


  ngOnInit(){
    // this.usrObject$.pipe(
    //   first(), // Take the first value emitted by the observable
    //   tap(user => {
    //     if (!user) {
    //       // dispatch
    //       this.store.dispatch(GET_CURRENT_USER());
    //     }
    //     else {
    //       this.store.dispatch(LOAD_ACTIVITIES_I_AM_PROMOTING({
    //         promoterUserId: user?.Id!,
    //         pageNumber: this.pageNumber,
    //         pageSize: this.pageSize
    //       }));
    //     }
    //   }),
    //   switchMap(() => this.usrObject$) // Listen for new updates from the store
    // ).subscribe()


    
    // Subscribe to the activities observable and log the data when it updates
    // Log the observable itself to check if it's emitting
    this.activitiesPromoting$!.subscribe(data => {
      console.log('Observable emitted');
      if (data) {
        console.log('Promotion data:', data);
      } else {
        console.log('No Promotion available');
      }
    });

    // this.usrObject$.pipe(
    //   tap(user => {
    //     if (!user) {
    //       // Dispatch and get the logged-in user
    //       this.store.dispatch(GET_CURRENT_USER());
    //     }
    //   }),
    //   switchMap(user => {
    //     // After the user is available, listen for updates on activities
    //     return this.activitiesPromoting$.pipe(
    //       // Provide a default value to avoid undefined emission
    //       startWith([]), // Ensures that the observable emits an empty array initially if nothing else is emitted
    //       tap((promotingActs: ActivityPromotersLog[] | null) => {
    //         // Log the data when it changes
    //         console.log(`promotingActs response: ${JSON.stringify(promotingActs)}`);
    
    //         // If the activities list is empty, dispatch the action to load activities
    //         if (!promotingActs || promotingActs.length < 1) {
    //           console.log(`Dispatching Load I am promoting for user: ${JSON.stringify(user)}`);
    //           this.store.dispatch(LOAD_ACTIVITIES_I_AM_PROMOTING({
    //             promoterUserId: user?.Id!,
    //             pageNumber: this.pageNumber,
    //             pageSize: this.pageSize
    //           }));
    //         }
    //       })
    //     );
    //   })
    // ).subscribe();

    
  }

  EntityIsInPromotionList(
    activitiesPromoting: ActivityPromotersLog[], 
    currentEntity: Activity | MaiXEvent){
      
    //find the entity promotion match out of activitiesPromoting array
    const activityPromotionMatch = activitiesPromoting.find(activityPromoting => activityPromoting.ActivityId === currentEntity.Id);

    if(activityPromotionMatch)
    {
      if(!environment.production)
      {
        console.log(`Entity ${JSON.stringify(currentEntity)} includes ${JSON.stringify(activitiesPromoting)}. Result: 
        TRUE`);
      }

      return true;
    }
    else
    {
      if(!environment.production)
      {
        console.log(`Entity ${JSON.stringify(currentEntity)} does NOT include ${JSON.stringify(activitiesPromoting)}. Result: 
        FALSE`);
      }
      return false
    }
  }

  findAcceptanceStatusForEntity(
    activitiesPromoting: ActivityPromotersLog[], 
    currentEntity: Activity | MaiXEvent
  ): ActivityPromotionAcceptanceStatus | null
  {
    //find the entity promotion match out of activitiesPromoting array
    const activityPromotionMatch = activitiesPromoting.find(activityPromoting => activityPromoting.ActivityId === currentEntity.Id);

    if(activityPromotionMatch)
    {

      return activityPromotionMatch.AcceptanceStatus;

      // //check the current status it is in
      // if(activityPromotionMatch.AcceptanceStatus )
      // return 

    }
    else{
      return null
    }
  }

  // CheckIfEntityContainsActivityPromotionList(
  //   activitiesPromoting: ActivityPromotersLog[], 
  //   currentEntity: Activity | MaiXEvent){

  //     console.log(`Entity ${JSON.stringify(currentEntity)} includes ${JSON.stringify(activitiesPromoting)}. Result: 
  //     ${!activitiesPromoting.some(activityPromoting => activityPromoting.Id === currentEntity.Id)}`);

  //     return !activitiesPromoting.some(activityPromoting => activityPromoting.Id === currentEntity.Id);
  // }

  // Type guard to check if entity is of type Activity
  isActivityTypeGuarded(entity: Activity | MaiXEvent): entity is Activity {
    return (entity as Activity).ActivityPromotionPercentage !== undefined;
  }

  // Type guard to check if entity is of type MaiXEvent
  // isMaiXEventTypeGuarded(entity: Activity | MaiXEvent): entity is MaiXEvent {
  //   return (entity as MaiXEvent).EventPromotionPercentage !== undefined;
  // }

  get displayedDescription(): string {
    if (!this.entity.Description) {
      return '';
    }

    if (this.isExpanded || this.entity.Description.length <= this.descriptionCutter) {
      return this.entity.Description;
    } else {
      return this.entity.Description.substring(0, this.descriptionCutter) //+ '...';
    }
  }

  toggleDescription(event: Event) {
    event.stopPropagation();
    this.isExpanded = !this.isExpanded;
  }

  displayHistory(activityid: number)
  {
    let obfsActivityId = this.obfuscationService.encryptEventId(activityid.toString());
    
    if(!environment.production)
    {
      console.log(`Open history of activity: ${activityid} & obfuscation of activity ID: ${obfsActivityId}`)
    }

    this.router.navigate(['activityHistory', obfsActivityId])
  }

  EditActivityByAdmin(e: any, selActivity: any)
  {
    if(!environment.production)
    {
      console.log(`selected activity for edit: ${JSON.stringify(selActivity)}`);
    } 

    //this prevents the parent routing click action to kickoff 
    e.stopPropagation();
    
    // alert("Edit activity by admin")
    this.dialogSvc.EditSelectedActivityDialog(selActivity)
      .afterClosed().subscribe(_ => {

        if(!environment.production)
        {
          console.log(`Result from edit dialog APS component: ${JSON.stringify(_)}`)

          //respomse will be "noaction" or "refresh" or "noactionDefault"

          if(_.includes("refresh"))
          {
            // window.location.reload();

          }
          else if(_.includes("update"))
          {
            //open the edit of the 
            this.EditActivityByAdmin(e, selActivity)
          }
          else if(_.includes("no update"))
          {
            //open the edit of the 
            this.EditActivityByAdmin(e, selActivity)
          }
          else{

          }

          this.store.dispatch(GET_ACTIVITIES_BY_USERID({userId: this.entity.UserId}))
       
        }
      });
  }

  //get address of the parsed in entity (activity or event)
  
  displayEventHistory(eventid: number)
  {
    let obfsActivityId = this.obfuscationService.encryptEventId(eventid.toString());
    
    if(!environment.production)
    {
      console.log(`Open history of event: ${eventid} & obfuscation of activity ID: ${obfsActivityId}`)
    }

    this.router.navigate(['eventHistory', obfsActivityId])
  }
  
  PromotingMessage()
  {
    this.toastrSvc.info("Promotion Status.", "You are a promoter of this activity.")
  }

  PromotionPendingMessage(){
    this.toastrSvc.info("Awaiting response.", "Request has been sent to the host, please wait for them to accept or reject it.")
  }

  PromoteActivity(e: any, selActivity: any, loggedInUser: GenericUser | undefined)
  {
    let activityCreator:any;
    let activityPromotionLogId: number;

    if(!environment.production)
    {
      console.log(`Selected Entity For Promotion: ${JSON.stringify(selActivity)}`)
      console.log(`Promoting logged in user: ${JSON.stringify(loggedInUser)}`)
    }
    if(loggedInUser && selActivity)
    {
      let timezone = '';

      if (window.location.href.includes('.co.za')) 
      {
        timezone = 'Africa/Johannesburg';
      } else if (window.location.href.includes('.ng')) {
        timezone = 'Africa/Lagos';
      } else {
        timezone = 'Africa/Johannesburg';
      }
      
      const timePromotionIsCreated = moment(new Date()).tz(timezone).toString()

      // Convert the moment string back to a moment object and get the year as a string
      const yearAsString = moment(timePromotionIsCreated).year().toString();

    
      const createActivityPromotion = new ActivityPromotersLog(
        0, //Id
        selActivity.Id, //ActivityId
        loggedInUser.Id!, //PromoterUserId
        ActivityPromotionAcceptanceStatus.Pending, //AcceptanceStatus
        false, //IsActivityLogRemoved
        timePromotionIsCreated, // ActivityCreationDateTime - moment string with timezone
        '', //ActivityStatusLastUpdatedDateTime - not being updated
        '' // UserUpdating - not an update action
      );
    

      //send email to host and promoter observer
      const templateGenForHostOfPromotionAndEmailSendObserver = {
        next: async (data: any) =>  {
          if(!environment.production)
          {
            console.log("response from email to host:" + JSON.stringify(data));
          }

          //WE ARE DONE
        },
        error: (err: HttpErrorResponse) => {
          if(!environment.production)
          {
            console.log("Error response from email to promoter:" + JSON.stringify(err));
          }

        }
      }


      const templateGenForPromoterAndEmailSendObserver = {
        next: async (data: any) =>  {
          if(!environment.production)
          {
            console.log("Promoter notification template gen for promotion request:" + JSON.stringify(data));
            console.log("Activity ownder details:" + JSON.stringify(activityCreator));
          }


          let approveRejectBaseUrl = `${environment.myUrl}#/activitypromotionresponse`
          
          //APPROVAL query params CONSTRUCT
          let approveQueryParams = {
            plogId: activityPromotionLogId,
            responseCode: 1,
            promotingActivityName: selActivity.Name,
            linkToMxp: environment.myUrl,
            linkToDashboard: `${environment.myUrl}#/activityhost`,
            linkToPromotionsPage: `${environment.myUrl}#/pvtpromotingacts`,
            linkToMoreActivitiesToPromote: `${environment.myUrl}#/profile`,
            promoterEmailAddy: loggedInUser.Email,
            hostEmailAddy: activityCreator?.Email,
            promoterName: loggedInUser.UserName,
            hostName: activityCreator?.UserName,
          };

          let approveRedirectLink = this.obfuscationService.encryptUrlParams(
            approveRejectBaseUrl,
            approveQueryParams
          );
          
          
          //REJECT query params CONSTRUCT
          const rejectQueryParams = {
            plogId: activityPromotionLogId,
            responseCode: 0,
            promotingActivityName: selActivity.Name,
            linkToMxp: environment.myUrl,
            linkToDashboard: `${environment.myUrl}#/activityhost`,
            linkToPromotionsPage: `${environment.myUrl}#/pvtpromotingacts`,
            linkToMoreActivitiesToPromote: `${environment.myUrl}#/profile`,
            promoterEmailAddy: loggedInUser.Email,
            hostEmailAddy: activityCreator?.Email,
            promoterName: loggedInUser.UserName,
            hostName: activityCreator?.UserName,
          };

          let rejectRedirectLink = this.obfuscationService.encryptUrlParams(
            approveRejectBaseUrl,
            rejectQueryParams
          );
         
          //get host template model email notification of promotion
          const activityPromoterToHostNotification = new ActivityPromoterToHostNotification(
            activityCreator?.UserName!, //hostName
            loggedInUser.UserName, //promoterUsername
            approveRedirectLink, //approveRedirectLink
            rejectRedirectLink, //rejectRedirectLink
            yearAsString //currentYear
          )


          //get template for host to respond to promotion request
          this.activityPromoterNotificationTempGen
          .GetPromotionRequestToActivityHostTemplateAndSendEmail(
            activityCreator?.Email,
            activityPromoterToHostNotification)
          .subscribe(templateGenForHostOfPromotionAndEmailSendObserver)
        },
        error: (err: HttpErrorResponse) => {
          if(!environment.production)
          {
            console.log(`Error occurred wile getting Host template for promotion request: ${JSON.stringify(selActivity)}. Details: ${JSON.stringify(err)}`)
          }
        }
      }


      const creatorOfActivityObserver = {
        next: async (data: any) =>  {
          if(!environment.production)
          {
            console.log("creator of activity retrieved:" + JSON.stringify(data));
          }
          
          activityCreator = data;

          const backToHome = environment.myUrl;

          //host template model for email notification
          const activityPromoterRequestNotification = new ActivityPromoterRequestNotification(
            loggedInUser.UserName, //promoterName
            selActivity.Name, //promotingActivityName
            backToHome, //homeLink
            yearAsString //currentYear
          )

          //get template for promoter notification and send the email
          this.activityPromoterNotificationTempGen
            .GetRequestNotificationToActivityPromoterTemplateAndSendEmail(
              loggedInUser.Email,
              activityPromoterRequestNotification)
            .subscribe(templateGenForPromoterAndEmailSendObserver)
          
        },
        error: (err: HttpErrorResponse) => {
          if(!environment.production)
          {
            console.log(`Error occurred wile getting creator of entity: ${JSON.stringify(selActivity)}. Details: ${err}`)
          }
        }
      }

      //activity promotion create observer
      const createActivityPromotionObserver = {
        next: async (data: any) =>  {
          if(!environment.production)
          {
            console.log("creation activity promotion returns the ID:" + JSON.stringify(data));
          }
  
          activityPromotionLogId = Number(data); // Convert to a number

          console.log('Extracted APL ID:', activityPromotionLogId);

          //get the updated log record ?
          this.store.dispatch(LOAD_ACTIVITIES_I_AM_PROMOTING({
            promoterUserId: loggedInUser.Id!,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize
          }));

          //get the creator of the activity in question for emailing purposes
          this.userSpecificSvc.GetUserByIdForAppByAppId(
            selActivity.UserId, 
            environment.AppBoundIdentifier
          ).subscribe(creatorOfActivityObserver)

          this.loadingService.hide();
        },
        error: (err: HttpErrorResponse) => {
          if(!environment.production)
          {
            console.log(`Error occurred wile creating activity promotion - ${err}`)
          }

          this.loadingService.hide();
        },
        complete: () =>{
          

        }
      }

      this.loadingService.show();

      this.activityPromotionLog.CreateActivityPromotersLog(createActivityPromotion)
      .subscribe(createActivityPromotionObserver)
    }
    else{
      this.toastrSvc.error("Error", "User error")
    }
  }


  selectedActivitySlot: any;
  activityPCs: any;
  actvtyAddr: any;
  activityLocation: any;

  BookActivityForPromoter(selectedActivity: any)
  {
    //ensure that user is logged in 
    this.usrObject$.pipe(
      // first(), // Take the first value emitted by the observable
      tap(user => {
        if (!user) {
          // redirect to login but keep the promoters page intact
          // this.router.navigate(['/promotingacts'], { queryParams: { paramName: 'value' } });
          
          this.router.navigate(['login'],
          {
            queryParams: {
              redirectUrl: `promotingacts?promoterUserId=${this.ActivityPromoterUserId}&activityId=${selectedActivity.Id.toString()}`
            }
          });
        }
        else {
          //user is logged in > PROCEED

          //an activity and user login details will be parsed in
          if(!environment.production)
          {
            console.log(`Selected activity for promotion: ${JSON.stringify(selectedActivity)}`)
          }

          //get the location of the activity
          this.extractLocationById(selectedActivity.AddressId);

          const addrRetrieverObserver = {
            next: async (response: any) =>  {
              this.activityLocation = response;

              if(!environment.production)
              {
                console.log("location retrieved successfully:" + JSON.stringify(this.activityLocation));
              }  

              //compose object of activity to be booked
              let act2beBooked = {
                Details: selectedActivity,
                Slot: this.selectedActivitySlot,
                PerformerClasses: this.activityPCs,
                ActivityLoci: this.activityLocation,
                PromoterUserId: this.ActivityPromoterUserId
              }

              
              if(!environment.production)
              {
                console.log(`Activity being booked through promotion: ${JSON.stringify(act2beBooked)}`)
              }

              //go to activitydetails
              let obfsActivityId = this.obfuscationService.encryptEventId(selectedActivity.Id.toString());

              if(!environment.production)
              {
                console.log(`obfuscation of activity ID: ${obfsActivityId}`)
              }

              // this.router.navigate(['activitydetails', obfsActivityId]);
              
              // const url = `promotingacts?promoterUserId=${this.ActivityPromoterUserId}&activityId=${selectedActivity.Id.toString()}`;
              // const url = `promotingacts?promoterUserId=${this.ActivityPromoterUserId}&activityId=${selectedActivity.Id.toString()}`;
              
              this.router.navigateByUrl(`/activitydetails/${obfsActivityId}`)
              .then(() => {
                  //then open activity booking dialog
                  // alert("I AM BACK")
                  this.dialogSvc.ActivityBookingDialog(act2beBooked)
              })

              

            },
            error: (err: HttpErrorResponse) => {
              if(!environment.production)
              {
                console.log("location retrieved error:" + JSON.stringify(err));
              }
            }
          }

          //get the location of the activity
          this.addrService.GetAddressByAddressId(selectedActivity.AddressId)
            .subscribe(addrRetrieverObserver);
          }
        }),
        switchMap(() => this.usrObject$) // Listen for new updates from the store
      ).subscribe()

  }

  
  extractLocationById(activityId: number)
  {
    
  }

}
