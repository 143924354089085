import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrIconClasses, ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent {

  contactForm: FormGroup;
  successMsg: string | null = null;
  errorMsg: string | null = null;

  constructor(private fb: FormBuilder,
              private authSvc: AuthService,
            private toaster: ToastrService) {
    this.contactForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', [Validators.required]],
      message: ['', [Validators.required, Validators.minLength(10)]]
    });
  }



  onSubmit(): void {
    if (this.contactForm.valid) {
      this.authSvc.SendContactUsMail(this.contactForm.value).subscribe(
        (response) => {
          this.toaster.success('Thank you! Your message has been sent successfully.');
          this.errorMsg = null;
          this.contactForm.reset();
        }
      );
    } else {
      this.errorMsg = 'Please fill out the form correctly before submitting.';
      this.successMsg = null;
    }
  }
}
