import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ApplicationRoleState } from "../state/application-roles.state";

export const selectAppRolesState = createFeatureSelector<ApplicationRoleState>('applicationroles');

export const selectapplicationRoles = createSelector(
    selectAppRolesState,
  (state: ApplicationRoleState) => state.applicationroles
);

export const selectapplicationRolesLoading = createSelector(
    selectAppRolesState,
  (state: ApplicationRoleState) => state.loading
);

export const selectapplicationRolesLoaded = createSelector(
    selectAppRolesState,
    (state: ApplicationRoleState) => state.loaded
  );

export const selectapplicationRolesError = createSelector(
    selectAppRolesState,
  (state: ApplicationRoleState) => state.error
);