<mat-form-field 
[appearance]="appearance"
[ngStyle]="timePickerStyle" 
style="width: 100%;">
    <input
      [placeholder]="placeholder"
      matInput
      [ngxTimepicker]="picker"
      [formControl]="timeControl"
      (ngModelChange)="onTimeChange($event)">
    <ngx-material-timepicker #picker></ngx-material-timepicker>
    <mat-icon matSuffix (click)="picker.open()">schedule</mat-icon>
</mat-form-field>