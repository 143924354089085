import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActivitySlotService {
  private APIUrl: string | undefined;

  constructor(private http: HttpClient) 
  { 
    this.APIUrl = environment.APIUrl;
  }

  //add new slot to activity
  AddSlot(newSlot: any)
  {
    return this.http.post<any>(
      this.APIUrl + 'Slot/AddSlot', 
      newSlot, 
      this.getHttpOptions());
  }
 
  //get all slots
  GetAllSlots(): Observable<any[]> {
    let url = this.APIUrl + 'Slot/GetAllSlots';
 
    return this.http.get<any>(
      url,
      this.getHttpOptions());
  }
 
  //  getCachedData(url: string) {
  //    const cachedResponse = this.cacheService.get(url);
  //    if (cachedResponse) {
 
  //      return cachedResponse;
  //      // this.cachedData = cachedResponse; // Assuming the cached response is an HttpResponse object
  //    } 
  //    // else {
  //    //   // Data not found in cache
  //    // }
  //  }
 
   //GetActivityById
    
  GetSlotById(slotId: number)
  {
    return this.http.get<any>(
      this.APIUrl + `Slot/GetSlotById/${slotId}`, 
      this.getHttpOptions());
  }

  //Get distinct Slot By Activity Id
  GetSlotBActivityId(activityId: number)
  {
    return this.http.get<any>(
      this.APIUrl + `Slot/GetSlotByActivityId/${activityId}`, 
      this.getHttpOptions());
  }

  //UpdateSlot
  UpdateSlot(slotChanges: any)
  {
    return this.http.put<any>(
      this.APIUrl + 'Slot/UpdateSlot', 
      slotChanges, 
      this.getHttpOptions());
  }
 
  //RemoveSlot
  RemoveSlot(slotId: number){
    return this.http.post<any>(
      this.APIUrl + `Slot/RemoveSlot/${slotId}`,
      null,
      this.getHttpOptions());
  }
 
  getHttpOptions(){
  
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      })
      .set('Cache-Control', 'max-age=3600') // Set the cache control header,
      .delete('Pragma')
    };

    return httpOptions;
  }
}
