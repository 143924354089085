import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment'


@Injectable({
  providedIn: 'root'
})
export class TeamService {
  private APIUrl: string | undefined;
  // APIUrl = 'https://localhost:7032/api/'

  constructor(private http: HttpClient) 
  { 
    this.APIUrl = environment.APIUrl;
  }

  GetAllTeams(): Observable<any[]>{
    return this.http.get<any>(this.APIUrl + 'Team/GetAllTeams', this.getHttpOptions());
  }

  GetAllTeamsPerActivity(activityId: number):Observable<any[]>{
    return this.http.get<any>(this.APIUrl + `Team/GetAllTeamsPerActivity/${activityId}`, this.getHttpOptions());
  }

  GetTeamById(id: number): Observable<any>
  {
    return this.http.get<any>(this.APIUrl + `Team/GetTeamById/${id}`, this.getHttpOptions());
  }

  getHttpOptions(){
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };

    return httpOptions;
  }
}
