import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, tap } from "rxjs";
import { ActivityService } from "src/app/shared/services/activity.service";
// import * as ActivityActions from "../actions/activity.actions";
// import { LOAD_ACTIVITIES, LOAD_ACTIVITIES_FAIL, LOAD_ACTIVITIES_SUCCESS } from "../actions/activity.actions";
import * as CurrentUserActivitiesActions from "../actions/current-user-activities.actions";
import { Activity } from "src/app/types/models/activity.model";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable()
export class CurretUserActivitiesEffects {

  pageNumber: number = 1;
  pageSize: number = 50;

  loadCurrentActivitiesForAUser$ = createEffect(() => this.actions$.pipe(
      ofType(CurrentUserActivitiesActions.LOAD_ACTIVITIES_FOR_ONE_USER),
      mergeMap(action => this.activityService.GetAllActivitiesForAUserWithPagination(action.userId, this.pageNumber, this.pageSize)
      .pipe(
          tap((currentuseractivities: Activity[]) => {

            if(!environment.production)
            {
              console.log('Current user activities loaded successfully:', currentuseractivities);
            }
          }),
          map(currentuseractivities => CurrentUserActivitiesActions.LOAD_ACTIVITIES_SUCCESS_FOR_ONE_USER({ currentuseractivities })),
          catchError(error => of(CurrentUserActivitiesActions.LOAD_ACTIVITIES_FAIL_FOR_ONE_USER({ error })))
      ))
      )
  );


  constructor(
    private actions$: Actions,
    private activityService: ActivityService
  ) {}
}