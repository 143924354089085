<!-- src/app/countdown-timer/countdown-timer.component.html -->
<div 
*ngIf="timeLeft"
style="font-weight: bold; color: rgb(214, 198, 24);">
    <span *ngIf="timeLeft.months > 0">{{ timeLeft.months }} month(s), </span>
    <span *ngIf="timeLeft.weeks > 0">{{ timeLeft.weeks }} week(s), </span>
    <span *ngIf="timeLeft.days > 0">{{ timeLeft.days }} day(s), </span>
    <span *ngIf="timeLeft.hours > 0">{{ timeLeft.hours }} hour(s), </span>
    <span *ngIf="timeLeft.minutes > 0">{{ timeLeft.minutes }} minute(s), </span>
    <span *ngIf="timeLeft.seconds > 0">{{ timeLeft.seconds }} second(s)</span>
  </div>