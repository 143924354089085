


<!-- app-map.component.html -->
<!-- <google-map
  height="400px"
  width="100%"
  [center]="center"
  [zoom]="16"
>
  <map-marker 
    *ngIf="markerPosition"
    [position]="markerPosition"
    [options]="{ icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png' }"></map-marker>
</google-map> -->

<!-- Input for the address search -->
<!-- <input id="addressSearch" type="text" placeholder="Search for address" class="form-control" /> -->

<!-- Display the selected address and save it -->
<!-- <div *ngIf="selectedAddress">
  <p>Address: {{ selectedAddress.street }}</p>
  <button mat-raised-button color="primary" (click)="saveAddress()">Save Address</button>
</div> -->



<google-map
  height="400px"
  width="100%"
  [center]="center$ | async"
  [zoom]="14">
  
  <!-- Only render the marker when markerPosition$ has a value -->
  <map-marker *ngIf="(markerPosition$ | async) as markerPosition" [position]="markerPosition"></map-marker>
</google-map>

<!-- Input for the address search -->
<input id="addressSearch" type="text" placeholder="Search for address" class="form-control" />


<!-- Display the selected address and save it -->
<!-- <div *ngIf="selectedAddress">
  <p><strong>Street:</strong> {{ selectedAddress.Street_number }} {{ selectedAddress.Street }}</p>
  <p><strong>Suburb:</strong> {{ selectedAddress.Suburb }}</p>
  <p><strong>City:</strong> {{ selectedAddress.City }}</p>
  <p><strong>State:</strong> {{ selectedAddress.State }}</p>
  <p><strong>Country:</strong> {{ selectedAddress.Country }}</p>
  <p><strong>Postal Code:</strong> {{ selectedAddress.Postal_code }}</p>
  <button mat-raised-button color="primary" (click)="saveAddress()">Save Address</button>
</div> -->
