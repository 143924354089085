import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-add-media-dialog',
  templateUrl: './add-media-dialog.component.html',
  styleUrls: ['./add-media-dialog.component.scss']
})
export class AddMediaDialogComponent {
  incomingFileList: any;

  // rtImgPath: string = "C:/code/Microservice-Suite/AppsAuthenticationAndAuthorizationService/FileService/Resources/Images/";
  // rtImgPath: string = "http://localhost:808/";
  rtImgPath: string = environment.fileRetriever;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    private router: Router,
    public dialogRef: MatDialogRef<AddMediaDialogComponent>,
    private dialogSvc: DialogService) 
    { 
      this.incomingFileList = data;
      console.log(`Incoming: ${JSON.stringify(this.incomingFileList)}`);
    }

  ngOnInit(): void {
    
  }

  removeImgSelectedFromActivity(sel: any)
  {
    if(!environment.production)
    {
      console.log(`Removing: ${sel} from image list`);
    }

    const indexToRemove: number = this.incomingFileList.indexOf(sel);

    if (indexToRemove !== -1) {
      this.incomingFileList.splice(indexToRemove, 1); // Remove 1 element at the index
    }
  }
}
