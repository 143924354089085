import { createReducer, on } from "@ngrx/store";
import { BookingState } from "../state/bookings.state";
import * as BookingActions from "../actions/bookings.actions";


const initialState: BookingState = {
    bookings: [],
    loading: false,
    loaded: false,
    error: null,
};

const _bookingReducer = createReducer(
    initialState,
    on(BookingActions.LOAD_BOOKINGS, state => ({ ...state, loading: true })),
    on(BookingActions.LOAD_BOOKINGS_SUCCESS, (state, { bookings }) => ({
        ...state,
        bookings: [...bookings],
        loading: false,
        loaded: true,
        error: null,
    })),
    on(BookingActions.LOAD_BOOKINGS_FAIL, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error,
    }))
);

export function bookingReducer(state: any, action: any) {
    return _bookingReducer(state, action);
  }