<div class="image-upload-container">
    <div class="image-upload-front" (click)="fileInput.click()">
      <ng-content></ng-content>
    </div>
    <input
      #fileInput
      type="file"
      accept="image/*"
      (change)="onFileSelected($event)"
      [multiple]="allowMultiple"
      style="display: none;"
    />
</div>

<div 
    *ngIf="errorMessage" 
    class="error-message">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-ban-fill" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M2.71 12.584q.328.378.706.707l9.875-9.875a7 7 0 0 0-.707-.707l-9.875 9.875Z"/>
    </svg>
    {{ errorMessage }}
</div>
  

<span *ngIf="showProgress">
    <div 
    class="progress-container" 
    *ngFor="let progressInfo of progressInfos">
        <div>{{ progressInfo.fileName }}</div>
        <progress [value]="progressInfo.value" max="100">{{ progressInfo.value }}%</progress>
    </div>
</span>  