// bookings that an activity/event host user has

import { createAction, props } from "@ngrx/store";
import { MEEntity } from "src/app/types/enums/meEntity.enum";
import { Booking } from "src/app/types/models/booking.model";

export const LOAD_ENTITY_BOOKINGS = createAction('[Bookings] Load User Bookings',
    props<{ mEEntity: MEEntity, entityId: number }>());

export const LOAD_ENTITY_BOOKINGS_SUCCESS = createAction('[Bookings] Load User Bookings Success',
    props<{entitybookings: Booking[]}>());

export const LOAD_ENTITY_BOOKINGS_FAIL = createAction('[Bookings] Load User Bookings Fail',
    props<{error: string}>());