import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, tap } from "rxjs";
import { environment } from "src/environments/environment";
import { ActivityPromoterLogsService } from "src/app/shared/services/activity-promoter-logs.service";
import { ActivityPromotersLog } from "src/app/types/models/activityPromotersLog.model";
import * as ActivityPromotingActions from "../actions/activity-i-am-promoting.actions";

@Injectable()
export class ActivityIAmPromotingEffects {

    activityIamPromoting$ = createEffect(() => this.actions$.pipe(
        ofType(ActivityPromotingActions.LOAD_ACTIVITIES_I_AM_PROMOTING),
        mergeMap( action => this.ActivityPromoterLogsService.ViewActivityIamPromotingWithPagination(
            action.promoterUserId,
            action.pageNumber,
            action.pageSize,
        )
        .pipe(
            tap((activitiesiampromoting: ActivityPromotersLog[]) => {
                if(!environment.production)
                {
                    console.log(`Activities I am promoting retrieved: ${ JSON.stringify(activitiesiampromoting) }`);
                }
            }),
            map(activitiesiampromoting => ActivityPromotingActions.LOAD_ACTIVITIES_I_AM_PROMOTING_SUCCESS({ activitiesiampromoting })),
            catchError(error => of(ActivityPromotingActions.LOAD_ACTIVITIES_I_AM_PROMOTING_FAIL({ error })))
        ))
        )
    );

  constructor(
    private actions$: Actions,
    private ActivityPromoterLogsService: ActivityPromoterLogsService
  ) {}
}