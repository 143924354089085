<section class="wrapper">
    <div class="container">
        <!-- Status Received: {{eventStatus}} -->

        <!-- actual page data -->

        <div>
            <!-- spinner: for when the page is loading -->
            <div 
                *ngIf="loading$ | async;" 
                class="spinner-container">
                    <div class="spinner"></div>
            </div>

            

             <!-- when data has loaded -->
             <!-- <div 
             *ngIf="!(futureEvents$ | async) as paginatedEvents">
                Data not loaded 
            </div> -->
            <!-- when data has loaded -->
            <div 
            class="row futureEves"
            *ngIf="(futureEvents$ | async) as paginatedEvents">
            
                <div 
                *ngIf="paginatedEvents.length > 0"
                class="custom-pagination-container flex-center">
                    <pagination-controls 
                        [id]="paginatedEvents[0]?.Status ?? 0"
                        [totalItems]="totalEvents"
                        (pageChange)="p = $event"></pagination-controls>
                </div>

                <br>

                <ng-container   
                    class="text-center flex-center"
                    *ngIf="paginatedEvents.length < 1">
                    <div 
                        style="border: 1px solid black; color: white; background-color: red; width: 100%; height: 80px;">
                        No event(s) data. 

                    </div>
                </ng-container>

                <!-- <br> -->
                <!-- ID: {{paginatedEvents[0]?.Status}} -->
                <ng-container 
                *ngFor="let evnt of paginatedEvents | paginate: { itemsPerPage: itemsPerPage, currentPage: p, id: paginatedEvents[0]?.Status ?? 0 }">
                    
                
                    <!-- Visualize the data being sent it -->

                    <!-- <br>
                    <span *ngIf="this.futureCombinedData$ | async as combinedData">
                    
                        <div *ngFor="let combinedlist of combinedData; let i = index">
                            {{ i + 1 }} - {{combinedlist | json}}

                            <br>
                            =======================
                            <br>
                        </div>
                       
                        <br><br>
                        Event - {{evnt | json}}

                    </span> -->
                    



                    <!-- This is the card template (to be applied later) -->
                    <!-- <app-card 
                        [entity]="evnt" 
                        [showButton]="showButtonInSecondCard"
                        [isActivity]="false"
                        [isEvent]="true">
                    </app-card> -->

                    <div 
                    (click)="ViewEvent(evnt)"
                    class="col-sm-12 col-md-6 col-lg-4 mb-4">
                        
                        <div 
                        class="card text-white card-has-bg click-col" 
                        style="background-image:url('{{rtImgPath}}{{evnt?.DefaultImage}}')"
                        >
                            

                            <div class="card-img-overlay d-flex flex-column">
                                

                                <div class="card-body">
                                    <small 
                                    style="display: flex; align-items: center;"
                                    class="card-meta mb-2">
                                        {{evnt?.Name}}

                                        <span
                                        class="EntityMessageSpan" 
                                        style="color: rgb(62, 158, 199);">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tag-fill" viewBox="0 0 16 16">
                                            <path d="M2 1a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l4.586-4.586a1 1 0 0 0 0-1.414l-7-7A1 1 0 0 0 6.586 1H2zm4 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                        </svg>
                                            Event
                                        
                                        </span>
                                    </small>
                                    <h4 class="card-title mt-0 ">
                                        <a class="text-white" herf="#">
                                            <!-- {{activity.Description}} -->

                                            {{ (evnt?.Description?.length! > 21) ? 
                                                (evnt?.Description | slice:0:21)+'...' : 
                                                (evnt?.Description) }}
                                        </a>
                                    </h4>

                                </div>

                                <div class="card-footer">

                                    <div 
                                    *ngIf="futureCombinedData$ | async"
                                    class="media">
                                        <span 
                                        *ngFor="let data of futureCombinedData$ | async">
                                            <!-- Combined Data: {{data | json}} -->
                                            <!-- if the event matches the array of deduced events [] -->
                                            <ng-container
                                            *ngIf="data.deducedEvent?.Id == evnt?.Id">
                                                <img 
                                                    class="mr-3 rounded-circle" 
                                                    [src]="data.creator?.ProfilePicture ? rtImgPath + data.creator?.ProfilePicture : rtImgPath + 'nImg.png'"
                                                    alt="Generic image"
                                                    (click)="ViewImage($event,data.creator!)"
                                                    >

                                                <span class="media-body">
                                                    <!-- If user is found, use the username -->
                                                    <h6 class="my-0 text-white d-block">
                                                        <span 
                                                        class="text-center"
                                                        *ngIf="data.creator">
                                                            {{data.creator.UserName}}
                                                        </span>
                                                        
                                                    </h6>
                                                    
                                                    <span *ngIf="!data.creator!">
                                                        <small>Admin</small>
                                                    </span>

                                                </span>
                                            </ng-container>

                                            <span
                                            *ngIf="(useId$ | async) as useId">

                                                <span 
                                                    (click)="EditActivityByAdmin($event, evnt)"
                                                    class="EditSpan" 
                                                    style="float: right; color: rgb(0, 179, 255);"
                                                    *ngIf="((data.deducedEvent?.Id == evnt?.Id) && (evnt.UserId == useId))">
                                                    
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                                        <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                                    </svg>
                                                </span>
                                                
                                            </span>
                                        </span>


                                        <!-- role based functionality for MaiX admin -->
                                        <!-- if you are the creator -->
                                        <!-- <span 
                                            (click)="EditActivityByAdmin($event, evnt)"
                                            class="EditSpan" 
                                            style="float: right; color: rgb(0, 179, 255);"
                                            *ngIf="((evnt.UserId == useId) || roleIsValid)">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                                </svg>
                                        </span> -->

                                        <span 
                                            (click)="ViewLocationOfActivity($event, evnt?.AddressId!)"
                                            class="locationSpan" 
                                            style="float: right; color: red;">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                                                </svg>
                                        </span>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <!-- Pagination controls -->
                <!-- <div class="pagination-controls">
                    <button 
                        class="pageButton btn btn-outline-primary"
                        (click)="onpreviousPagePerStatus(2)" 
                        [disabled]="pageNumber === 1">
                        Previous
                    </button>
                    
                    <span>Page {{ pageNumber }}</span>
                    
                    <button 
                        class="pageButton btn btn-outline-primary"
                        (click)="onFetchMoreEvents(2)" 
                        [disabled]="(paginatedEvents.length < pageSize)"
                        *ngIf="paginatedEvents.length > 0">
                        Next
                    </button>
                </div> -->

            </div>
        </div>
    </div>
  </section>
  