<div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card shadow-lg">
          <div 
          class="card-header bg-dark text-white text-center">
            <h2>We appreciate your feeback. Get in touch</h2>
          </div>
          <div class="card-body p-4">
            <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
              <div class="form-group mb-3">
                <label for="name">Name</label>
                <input type="text" id="name" formControlName="name" class="form-control" placeholder="Enter your name" />
                <div *ngIf="contactForm.controls['name'].invalid && contactForm.controls['name'].touched" class="text-danger">
                  Name is required and should be at least 3 characters long.
                </div>
              </div>
  
              <div class="form-group mb-3">
                <label for="email">Email</label>
                <input type="email" id="email" formControlName="email" class="form-control" placeholder="Enter your email" />
                <div *ngIf="contactForm.controls['email'].invalid && contactForm.controls['email'].touched" class="text-danger">
                  Please enter a valid email address.
                </div>
              </div>
  
              <div class="form-group mb-3">
                <label for="email">Subject</label>
                <input type="subject" id="subject" formControlName="subject" class="form-control" placeholder="Enter your subject" />
                <div *ngIf="contactForm.controls['subject'].invalid && contactForm.controls['subject'].touched" class="text-danger">
                  Please enter subject
                </div>
              </div>

              <div class="form-group mb-3">
                <label for="message">Message</label>
                <textarea id="message" formControlName="message" class="form-control" rows="5" placeholder="Your message"></textarea>
                <div *ngIf="contactForm.controls['message'].invalid && contactForm.controls['message'].touched" class="text-danger">
                  Message should be at least 15 characters long.
                </div>
              </div>
  
              <div class="form-group text-center">
                <button type="submit" class="btn btn-primary" [disabled]="contactForm.invalid">Send Message</button>
              </div>
            </form>
            <div *ngIf="successMsg" class="alert alert-success mt-3 text-center">
              {{ successMsg }}
            </div>
            <div *ngIf="errorMsg" class="alert alert-danger mt-3 text-center">
              {{ errorMsg }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  