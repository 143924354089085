import { createReducer, on } from "@ngrx/store";
import { FeaturedEventState } from "../state/featuredEventState.state";
import { LOAD_FEATURED_EVENTS, LOAD_FEATURED_EVENTS_FAIL, LOAD_FEATURED_EVENTS_SUCCESS } from "../actions/featuredEvent.actions";

const initialState: FeaturedEventState = {
    featuredEvents: [],
    loading: false,
    loaded: false,
    error: null,
};

const _featuredEventReducer = createReducer(
    initialState,
    on(LOAD_FEATURED_EVENTS, state => ({ ...state, loading: true })),
    on(LOAD_FEATURED_EVENTS_SUCCESS, (state, { featuredEvents }) => ({
        ...state,
        featuredEvents,
        loading: false,
        loaded: true,
        error: null,
    })),
    on(LOAD_FEATURED_EVENTS_FAIL, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error,
    }))
);

export function featuredEventReducer(state: any, action: any) {
    return _featuredEventReducer(state, action);
  }