import { createReducer, on } from "@ngrx/store";
import { EntityBookingState } from "../state/entity-bookings.state";
import * as entityBookingActions from "../actions/entity-bookings.actions";


const initialState: EntityBookingState = {
    entitybookings: [] ,
    loading: false,
    loaded: false,
    error: null,
};

const _entityBookingsReducer = createReducer(
    initialState,
    on(entityBookingActions.LOAD_ENTITY_BOOKINGS, state => ({ ...state, loading: true })),
    on(entityBookingActions.LOAD_ENTITY_BOOKINGS_SUCCESS, (state, { entitybookings }) => ({
        ...state,
        entitybookings: [...entitybookings],
        loading: false,
        loaded: true,
        error: null,
    })),
    on(entityBookingActions.LOAD_ENTITY_BOOKINGS_FAIL, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error,
    }))
);

export function entityBookingsReducer(state: any, action: any) {
    return _entityBookingsReducer(state, action);
  }