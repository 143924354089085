<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
<div class="container">
    <div class="card">

        <div>
            <h3 class="text-center">
                Login
            </h3>
            <hr>
        </div>


        <form
        [formGroup]="loginForm" 
        (ngSubmit)="onLoginSubmittion()">
            <div class="mb-3">
                <input 
                    type="text" 
                    id="usernameEmail" 
                    class="form-control"
                    formControlName="usernameOrEmail" 
                    required
                    placeholder="Username/Email">
            </div>

            <div class="mb-3">
                <input 
                    type="{{ passwordFieldType }}"  
                    id="password" 
                    class="form-control" 
                    formControlName="password" 
                    required
                    placeholder="Password (minimum length: 6)">

            </div>

            <div 
            *ngIf="password?.value?.length > 0" 
            class="toggle-password-checkbox">
                <input 
                  type="checkbox" 
                  id="showPasswordCheckbox" 
                  (change)="togglePasswordVisibility()" 
                  [checked]="passwordFieldType === 'text'">
                <label 
                    style="margin-left: 3px; padding-bottom: -5px;"
                    for="showPasswordCheckbox"> 
                    Show Password
                </label>
            </div>

        
            
            <div>
                <a 
                style="text-decoration: none"
                [routerLink]="['/signup']">
                    Create account
                </a>
            </div>

            <div>
                <a 
                style="text-decoration: none"
                [routerLink]="['/forgotpassword']">
                    forgot password ?
                </a>
            </div>

            <div class="buttons">
                <!-- <button class="register-button">Register</button> -->
                <button type="submit" class="login-button">Login</button>
            </div>
        </form>
    </div>
</div>