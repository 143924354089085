import { Component, EventEmitter, Output, Renderer2 } from '@angular/core';
import { BehaviorSubject, filter, Observable, of } from 'rxjs';
import { AddressModel } from 'src/app/types/models/addressModel.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrl: './map.component.scss'
})
export class MapComponent {
  private defaultLocation: google.maps.LatLngLiteral = { lat: -34.397, lng: 150.644 };  // Default location
  private centerSubject = new BehaviorSubject<google.maps.LatLngLiteral>(this.defaultLocation);
  private markerPositionSubject = new BehaviorSubject<google.maps.LatLngLiteral>(this.defaultLocation);
  
  center$: Observable<any> = this.centerSubject.asObservable();
  markerPosition$ = this.markerPositionSubject.asObservable();

  autocomplete: google.maps.places.Autocomplete | undefined;
  selectedAddress: AddressModel | null = null;

  @Output() addressSelected = new EventEmitter<AddressModel>();

  ngOnInit() {
    this.centerSubject.subscribe(value => console.log('Center value:', value));
  this.markerPositionSubject.subscribe(value => console.log('Marker position value:', value));


    this.initializeAutocomplete();
    this.setUserLocation();  // Automatically set the user's location on load
  }

  // Get the user's current location and set the marker
  setUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLat = position.coords.latitude;
          const userLng = position.coords.longitude;

          // Update center and marker position using BehaviorSubjects
          const userLocation: google.maps.LatLngLiteral = { lat: userLat, lng: userLng };
          this.centerSubject.next(userLocation);
          this.markerPositionSubject.next(userLocation);

          console.log('User location set to:', userLocation);
        },
        (error) => {
          console.error('Geolocation error:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }

  // Initialize Google Places Autocomplete
  initializeAutocomplete() {
    const retryDelay = 500; // Delay in milliseconds
    const maxRetries = 5;  // Maximum number of retries
    let attempt = 0;
  
    const tryInitialize = () => {
      const input = document.getElementById('addressSearch') as HTMLInputElement;
  
      if (input) {
        clearInterval(intervalId); // Stop retrying once successful
  
        this.autocomplete = new google.maps.places.Autocomplete(input, { types: ['geocode'] });
  
        this.autocomplete.addListener('place_changed', () => {
          const place = this.autocomplete?.getPlace();
  
          if (place && place.geometry && place.geometry.location) {
            const location = place.geometry.location;
  
            // Update center and marker position reactively
            const newLocation: google.maps.LatLngLiteral = { lat: location.lat(), lng: location.lng() };
            this.centerSubject.next(newLocation);
            this.markerPositionSubject.next(newLocation);
  
            // Populate the selectedAddress object with place details
            this.selectedAddress = new AddressModel(
              0,  // Assign a default or dynamic ID
              this.getAddressComponent(place, 'street_number'),
              this.getAddressComponent(place, 'route'),
              this.getAddressComponent(place, 'sublocality_level_1'),
              this.getAddressComponent(place, 'locality'),
              this.getAddressComponent(place, 'administrative_area_level_1'),
              false,  // Default for IsDefault, adjust as needed
              '',  // Building name (if applicable)
              '',  // Unit number (if applicable)
              '',  // Address instructions (optional)
              this.getAddressComponent(place, 'country'),
              this.getAddressComponent(place, 'postal_code'),
              location.lng().toString(),
              location.lat().toString(),
              null,
              null
            );
  
            console.log('Selected place:', place);
  
            this.saveAddress();
          } else {
            console.error('No geometry available for this place');
          }
        });
      } else if (attempt >= maxRetries) {
        clearInterval(intervalId); // Stop retrying after max retries
        console.error('Failed to find autocomplete input after maximum retries');
      } else {
        attempt++;
        console.error('Autocomplete input not found. Retrying...', attempt);
      }
    };
  
    const intervalId = setInterval(tryInitialize, retryDelay);
  }
  
  
  // initializeAutocomplete() {
  //   const input = document.getElementById('addressSearch') as HTMLInputElement;

  //   if (input) {
  //     this.autocomplete = new google.maps.places.Autocomplete(input, { types: ['geocode'] });

  //     this.autocomplete.addListener('place_changed', () => {
  //       const place = this.autocomplete?.getPlace();

  //       if (place && place.geometry && place.geometry.location) {
  //         const location = place.geometry.location;

  //         // Update center and marker position reactively
  //         const newLocation: google.maps.LatLngLiteral = { lat: location.lat(), lng: location.lng() };
  //         this.centerSubject.next(newLocation);
  //         this.markerPositionSubject.next(newLocation);

  //         // Populate the selectedAddress object with place details
  //         this.selectedAddress = new AddressModel(
  //           0,  // Assign a default or dynamic ID
  //           this.getAddressComponent(place, 'street_number'),
  //           this.getAddressComponent(place, 'route'),
  //           this.getAddressComponent(place, 'sublocality_level_1'),
  //           this.getAddressComponent(place, 'locality'),
  //           this.getAddressComponent(place, 'administrative_area_level_1'),
  //           false,  // Default for IsDefault, adjust as needed
  //           '',  // Building name (if applicable)
  //           '',  // Unit number (if applicable)
  //           '',  // Address instructions (optional)
  //           this.getAddressComponent(place, 'country'),
  //           this.getAddressComponent(place, 'postal_code'),
  //           location.lng().toString(),
  //           location.lat().toString(),
  //           null,
  //           null
  //         );

  //         console.log('Selected place:', place);

  //         this.saveAddress()
  //       } else {
  //         console.error('No geometry available for this place');
  //       }
  //     });
  //   } else {
  //     console.error('Autocomplete input not found');
  //   }
  // }

  // Helper function to extract address components from the Google Place object
  getAddressComponent(place: google.maps.places.PlaceResult, type: string): string {
    const component = place.address_components?.find(c => c.types.includes(type));
    return component ? component.long_name : '';
  }

  // Save the selected address
  saveAddress() {
    if (this.selectedAddress) {
      // Emit the selected address to the parent component or save it in the database
      this.addressSelected.emit(this.selectedAddress);
      console.log('Address saved:', this.selectedAddress);
    } else {
      console.error('No address selected to save');
    }
  }



  // center: google.maps.LatLngLiteral = { lat: -34.397, lng: 150.644 }; // Default center
  // markerPosition: google.maps.LatLngLiteral | null = null;
  // selectedAddress: any;
  // autocomplete: google.maps.places.Autocomplete | undefined;
  // @Output() addressSelected = new EventEmitter<AddressModel>();

  // ngOnInit() {
  //   this.initializeAutocomplete();
  //   this.setUserLocation();  // Call to set the user's location on load
  // }

  // // Initialize the Google Places Autocomplete
  // initializeAutocomplete() {
  //   const input = document.getElementById('addressSearch') as HTMLInputElement;

  //   console.log(`initializeAutocomplete. input is: ${input}`);

  //   if (input) {
  //     this.autocomplete = new google.maps.places.Autocomplete(input, { types: ['geocode'] });
      
  //     // Add listener for place_changed event
  //     this.autocomplete.addListener('place_changed', () => {
  //       const place = this.autocomplete?.getPlace();
  //       console.log('Place selected: ', place); // Log for debugging

  //       // Ensure that the place has geometry (lat/lng)
  //       if (place && place.geometry && place.geometry.location) {
  //         const location = place.geometry.location;

  //         this.selectedAddress = {
  //           street: place.formatted_address,
  //           lat: location!.lat(),
  //           lng: location!.lng(),
  //         };

  //         console.log('Selected Address: ', this.selectedAddress); // Log selected address

  //         // Update map center and marker position
  //         this.center = { lat: location.lat(), lng: location.lng() };
  //         this.markerPosition = { lat: location.lat(), lng: location.lng() };
  //       } else {
  //         console.error('No geometry available for this place'); // Error logging
  //       }
  //     });
  //   } else {
  //     console.error('Autocomplete input not found');
  //   }
  // }


  // // Get the user's current location
  // setUserLocation() {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         const userLat = position.coords.latitude;
  //         const userLng = position.coords.longitude;

  //         // Update the map center and marker position to the user's location
  //         this.center = { lat: userLat, lng: userLng };
  //         this.markerPosition = { lat: userLat, lng: userLng };

  //         console.log('User location set to:', this.center);
  //       },
  //       (error) => {
  //         console.error('Geolocation failed: ', error);
  //       }
  //     );
  //   } else {
  //     console.error('Geolocation is not supported by this browser.');
  //   }
  // }

  // // Optional: Set marker manually by clicking the map
  // setMarker(event: google.maps.MapMouseEvent) {
  //   if (event.latLng) {
  //     this.markerPosition = { lat: event.latLng.lat(), lng: event.latLng.lng() };
  //     console.log('Marker position set: ', this.markerPosition); // Log marker position
  //   }
  // }

  // // Save the selected address
  // saveAddress() {
  //   if (this.selectedAddress) {
  //     const addressModel = new AddressModel(
  //       0, // ID will be assigned by the backend
  //       '', // Street_number (if available)
  //       this.selectedAddress.street,
  //       '', // Suburb, City, State, etc.
  //       '',
  //       '',
  //       true, // IsDefault
  //       undefined,
  //       undefined,
  //       undefined,
  //       'Country Name', // Or extract dynamically
  //       'Postal Code', // Or extract dynamically
  //       this.selectedAddress.lng,
  //       this.selectedAddress.lat,
  //       new Date(),
  //       null
  //     );
  //     this.addressSelected.emit(addressModel);
  //   } else {
  //     console.error('No address selected');
  //   }
  // }
}
