<div 
*ngIf="userAndRoles"
class="container">
    <h1 mat-dialog-title>
        Add role to {{userAndRoles.user?.UserName ?? 'user'}}
    </h1>
    <hr>
    <mat-form-field 
        style="width: 100%;" 
        appearance="fill">
        <mat-label>Select a role for {{userAndRoles.user?.UserName ?? 'user'}}</mat-label>
        <mat-select [(ngModel)]="selectedRole">
          <mat-option 
            *ngFor="let role of this.userAndRoles.roles" 
            [value]="role">
            {{ role }}
          </mat-option>
        </mat-select>
    </mat-form-field>
      
    <!-- <p>Selected Role: {{ selectedRole }}</p> -->

    <hr>
    <div style="margin-top: 60px;" mat-dialog-actions>
        <button 
            *ngIf="selectedRole"
            mat-raised-button
            (click)="submitRole('ADD')"
            color="primary">
            Submit
        </button>
        <button 
            mat-raised-button
            color="accent" 
            mat-dialog-close>
                Close
        </button>
    </div>


</div>