import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaystackRecipientLink } from 'src/app/types/models/paystackRecipientLink.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaystackRecipientLinkService {

  private APIUrl: string | undefined;

  constructor(private http: HttpClient) 
  { 
    this.APIUrl = environment.APIUrl;
  }

  //AddPaystackRecipientLink
  AddPaystackRecipientLink(paystackRecipientLink: PaystackRecipientLink)
  {
    return this.http.post<any>(
      this.APIUrl + 'PaystackRecipientLink/AddPaystackRecipientLink', 
      paystackRecipientLink, 
      this.getHttpOptions());
  }

  //GetPaystackRecipientLinkById
  GetPaystackRecipientLinkById(id: number) 
  {
    let url = this.APIUrl + `PaystackRecipientLink/GetPaystackRecipientLinkById?id=${id}`;
  
    return this.http.get<any>(
      url,
      this.getHttpOptions());
  }
  
  //GetPaystackRecipientLinkByRecipientCode
  GetPaystackRecipientLinkByRecipientCode(recipientCode: string) 
  {
    let url = this.APIUrl + `PaystackRecipientLink/GetPaystackRecipientLinkByRecipientCode?recipientCode=${recipientCode}`;
  
    return this.http.get<any>(
      url,
      this.getHttpOptions());
  }
  
  //GetPaystackRecipientLinkByUserId
  GetPaystackRecipientLinkByUserId(userId: string) 
  {
    let url = this.APIUrl + `PaystackRecipientLink/GetPaystackRecipientLinkByUserId?userId=${userId}`;
  
    return this.http.get<any>(
      url,
      this.getHttpOptions());
  }

  DeletePaystackRecipientLinkByRecipientCode(recipientCde: string)
  {
    let url  = this.APIUrl + `PaystackRecipientLink/DeletePaystackRecipientLinkByRecipientCode?recipientCode=${recipientCde}`;

    return this.http.delete<any>(
      url,
      this.getHttpOptions());


  }


  getHttpOptions(){
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };

    return httpOptions;
  }
}
