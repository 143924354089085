import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BankAccount } from 'src/app/types/models/bankAccount.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BankAccountService {

  private APIUrl: string | undefined;
  private paystackBankListUrl: string | undefined;

  private headers = new HttpHeaders({
    'Authorization': `Bearer ${environment.PaystackPublickKey}` // Replace YOUR_SECRET_KEY with your actual secret key
  });

  constructor(private http: HttpClient) 
  { 
    
    this.APIUrl = environment.APIUrl;
    this.paystackBankListUrl = environment.paystackBankUrl
    if(!environment.production)
    {
      console.log(`Env: ${environment} || URL: ${environment.APIUrl}`);
    }
  }

  //get banks supported by paystack
  getBanks(country: string): Observable<any> {
    // Add the query parameter for country
    let params = new HttpParams().set('country', country);
    
    return this.http.get(this.paystackBankListUrl!, { headers: this.headers, params: params });
  }

  AddBankAccount(bankAccount: BankAccount)
  {
    return this.http.post<any>(
      this.APIUrl + 'BankAccount/AddBankAccount', 
      bankAccount, 
      this.getHttpOptions());
  }

  GetAllBankAccounts() 
  {
    let url = this.APIUrl + 'BankAccount/GetAllBankAccounts';
  
    return this.http.get<any>(
      url,
      this.getHttpOptions());

  }

  GetAllBankAccountsWithPagination(pageNumber: number, pageSize: number){
    let url = this.APIUrl + `BankAccount/GetAllBankAccountsWithPagination?pageNumber=${pageNumber}&pageSize=${pageSize}`;

    return this.http.get<BankAccount[]>(
      url
    );  
  }

  GetAllBankAccountsOfAUserByUserId(userId: string) : Observable<any>
  {
    let url = this.APIUrl + `BankAccount/GetAllBankAccountsOfAUserByUserId?userId=${userId}`;
    
    return this.http.get<any>(
      url,
      this.getHttpOptions());
      
  }

  GetAllBankAccountsOfAUserByUserIdWithPagination(userId: string, pageNumber: number, pageSize: number)
  {
    let url = this.APIUrl + `BankAccount/GetAllBankAccountsOfAUserByUserIdWithPagination?userId=${userId}&pageNumber=${pageNumber}&pageSize=${pageSize}`;

    return this.http.get<BankAccount[]>(
      url
    );  
  }

  GetBankAccountOfAUserByBankAccountId(userId: string, bankAccountId: number) {
    let url = this.APIUrl + `BankAccount/GetBankAccountOfAUserByBankAccountId?userId=${userId}&bankAccountId=${bankAccountId}`;

    return this.http.get<BankAccount[]>(
      url
    );  
  }
  
  RemoveBankAccountOfAUser(userId: string, bankAccountId: number) {
    return this.http.post<any>(
      this.APIUrl + `BankAccount/RemoveBankAccountOfAUser?userId=${userId}&bankAccountId=${bankAccountId}`,
      null,
      this.getHttpOptions());
  }
  
  UpdateBankAccountOfAUser(bankAccountChanges: BankAccount) {
    return this.http.put<any>(
      this.APIUrl + 'BankAccount/UpdateBankAccountOfAUser', 
      bankAccountChanges, 
      this.getHttpOptions());
  }

  getHttpOptions(){
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };

    return httpOptions;
  }


}
