<div class="">


    <!-- role based functionality for MaiX eventHost and Admin -->
    <div 
    class="row">
    
        <div 
        class="col-12 actBtnsDv" 
        style="text-align: center;">
            
            <button
                (click)="RouteToAddNewEvent()"
                style="border-radius: 20px; margin: 10px 0px 20px 5px;"
                type="button" 
                class="btn btn-outline-primary"
                *ngIf="canAddEvent$ | async">
            Create Event
            </button>

        
        </div>

    
    </div>

    <mat-tab-group>
        <mat-tab label="Future"> 
            <app-selected-events [eventStatus]="2"></app-selected-events>
        </mat-tab>
        <mat-tab label="Live"> 
            <app-selected-events [eventStatus]="1"></app-selected-events>
        </mat-tab>
        <mat-tab label="Past"> 
            <app-selected-events [eventStatus]="0"></app-selected-events>
        </mat-tab>
    </mat-tab-group>
  
</div>