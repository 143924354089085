
<div class="container">
    
    <div class="card">
        <div>
            <h3 class="text-center">
                Forgot Password ?
            </h3>
            <hr>
        </div>

        <form
        [formGroup]="pwdResetForm" 
        (ngSubmit)="submitPasswordReset()">
            <input 
                    type="text" 
                    id="usernameEmail" 
                    formControlName="usernameEmail" 
                    required
                    placeholder="Enter username or email">

            <a 
                [routerLink]="['/login']"
                style="text-decoration: none">
                Back to login
            </a>

             
            <div class="buttons">
                <button 
                type="submit" 
                class="login-button">
                    Submit
                </button>
            </div>
        </form>
    </div>
</div>