import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Observable, Subscription, filter, map } from 'rxjs';
import { AuthService } from '../../services/CustomAuthenticator/auth.service';
import { environment } from 'src/environments/environment';
import { UserSpecificService } from '../../services/dataService/user-specific.service';
import { DialogService } from '../../services/dialog.service';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { AppState } from 'src/app/ngrx/state/app.state';
import { Store, select } from '@ngrx/store';
import { selectLoggedInUserRoles } from 'src/app/ngrx/selectors/current-user-roles.selectors';
import { GET_CURRENT_USER_ROLES } from 'src/app/ngrx/actions/current-user-roles.actions';

export interface CountryCityData {
  country: string;
  cities: string[];
  code: string
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  onActivitiesPage: boolean = false
  onMainCore: boolean = true

  ccData: CountryCityData[] = [
    {
      country: 'Algeria',
      cities: ['Algiers', 'Oran', 'Constantine', 'Annaba'],
      code: 'DZA'
    },
    {
      country: 'Angola',
      cities: ['Luanda', 'Huambo', 'Lobito', 'Benguela'],
      code: 'AGO'
    },
    {
      country: 'Nigeria',
      cities: ['Ibadan', 'Lagos', 'Abuja'],
      code: 'NGA'
    },
    {
      country: 'South Africa',
      cities: ['Gauteng', 'Eastern Cape', 'Free State', 'KwaZulu-Natal', 'Limpopo', 'Mpumalanga', 'North West', 'Northern Cape', 'Western Cape'],
      code: 'ZAF'
    }
    // Add more countries and cities here
    // ...
  ];

  selectedCountry: any;

  public activitiesLink = "/activitylist"
    
  DDToggler: string = "dropdown"

  @Output() toggleSideBarForMe : EventEmitter<any> = new EventEmitter();

  @Output() toggleRightBarForMe : EventEmitter<any> = new EventEmitter();

  // canViewDashboard: boolean = false;
  canViewDashboard$: Observable<boolean> | undefined;
  canViewEventDashboard$: Observable<boolean> | undefined;
  isUserLoggedIn: boolean = false;

  activitiesFilterToggled:boolean = false;

  currentLogedInUsrRole$!: Observable<string[]>;
  
  constructor(
      private router: Router,
      private authService: AuthService,
      private location: Location,
      private toastr: ToastrService,
      public userSvc: UserSpecificService,
      private cookieService: CookieService,
      private dailogSvc: DialogService,
      private cdRef: ChangeDetectorRef,
      private store: Store<AppState>) 
  { 
    this.currentLogedInUsrRole$ = this.store.pipe(select(selectLoggedInUserRoles));

    // Map roles to a boolean indicating if the dashboard can be viewed
    this.canViewDashboard$ = this.currentLogedInUsrRole$.pipe(
      map((roles: string[]) => roles.includes('mxp_ActivityHost'))
    );
    this.canViewEventDashboard$ = this.currentLogedInUsrRole$.pipe(
      map((roles: string[]) => roles.includes('mxp_EventHost'))
    );

    this.router.events.subscribe((event) => 
    {
      if (event instanceof NavigationStart) 
      {
        console.log('Route changed to:', event.url);

        localStorage.setItem('currentRoute', event.url);

        this.onActivitiesPageChecker(event.url);

        // if(event.url.includes('activitylist'))
        // {
        //   this.onActivitiesPage = true;
        //   this.onMainCore = false;
        // }
        // //more page conditioning to come
        // else{
        //   this.onActivitiesPage = false;
        //   this.onMainCore = true;
        // }
        // console.log(`==> Start: On activities page: ${this.onActivitiesPage}`);
      }

     
    });
  }
  
  ngOnInit()
  {
    const storedRoute = localStorage.getItem('currentRoute');
    if (storedRoute) {
      this.onActivitiesPageChecker(storedRoute);
      console.log('Current Route (on reload):', storedRoute);
    }

    this.authService.GetCurrentlyLoggedInUserIdIfAny()?.subscribe({
      next: (response: string) => {

        // this.isUserLoggedIn = true;

        // alert(`Dispatching user roles From header`)
        // Dispatch action to get user roles
        this.store.dispatch(GET_CURRENT_USER_ROLES());
      }
    })

    //trigger a check to see if the user is logged in
    this.userSvc.isUserLoggedIn
  }

  toggleActivityFilter()
  {
    this.activitiesFilterToggled = !this.activitiesFilterToggled;
  }
  
  onActivitiesPageChecker(pageUrl: string)
  {
    this.onActivitiesPage = (pageUrl.includes('activitylist')) ?
           true : false;

    if(!environment.production)
    {
      console.log(`==> On activities page: ${this.onActivitiesPage}`);
    }
         
  }
  
  onSelectCountry(country: any) {
    console.log(`Selection: ${JSON.stringify(country)}`);
    this.selectedCountry = country;
  }

  goToActivitiesThenRefresh(){
    this.router.navigate(['activitylist'])
      .then(() => window.location.reload());
  }

  goHomeThenRefresh()
  {
    this.router.navigate([''])
      .then(() => window.location.reload());
  }

  goToCompetitionThenRefresh()
  {
    alert("In development")
    window.location.reload()
    // goToActivitiesThenRefresh(){
    //   this.router.navigate(['activitylist'])
    //     .then(() => window.location.reload());
    // }
  }

  goToLogin(){
    this.router.navigate(['login']);
  }

  goToRegister(){
    this.router.navigate(['signup']);
  }

  toggleLeftbar(){
    this.toggleSideBarForMe.emit();
  }
  
  goToDashboard(){
    this.router.navigate(['']);
  }

  goToProfile()
  {
    this.router.navigate(['profile']);
  }

  goToHostDashboard(){
    this.router.navigate(['activityhost']);
    
  }

  goToEventHostDashboard() {
    this.router.navigate(['eventhost']);

  }

  logOff()
  {
    this.userSvc.logout().subscribe((response: any) => {
      
      if(
          !environment.production && 
          !environment.qa &&
          !environment.dev)
      {
        this.deleteCookie('CustomAuthCookie', '/', 'localhost').then(() => {
          this.router.navigate(['/login']); // Redirect to the login page
        }).then(() => {
          this.toastr.success("You have been logged out from local.", "logout mesage")
        });
      }
      else if(environment.dev)
      {
        this.deleteCookie('CustomAuthCookie', '/', '.maixperience.co.za').then(() => {
          this.router.navigate(['/login']); // Redirect to the login page
        }).then(() => {
          this.toastr.success("You have been logged out from DEV.", "logout mesage")
        });
      }
      else if(environment.production)
      {
        this.deleteCookie('CustomAuthCookie', '/', 'maixperience.co.za').then(() => {
          this.router.navigate(['/login']); // Redirect to the login page
        }).then(() => {
          this.toastr.success("You have been logged out.", "logout mesage")
        });
      }
      
      // Dispatch an action to clear the store
      
      // this.cookieService.delete('CustomAuthCookie', '/', '.maixperience.co.za'); // Specify the domain when removing the cookie
      if(!environment.production)
      {
        console.log(`Logout response: ${JSON.stringify(response)}`)
      }

      
    });
    

      // this.userSvc.logout() //clear all cookies
      
      // setTimeout(() => {
      //   window.location.reload();
      // }, 100); 
    
  }

  deleteCookie(name: string, path: string, domain: string): Promise<void> {
    return new Promise((resolve) => {
      this.cookieService.delete(name, path, domain);
      resolve(); // Resolve the promise immediately after deleting the cookie
    });
  }
}
