
    <!-- back button (if on a main page) -->
    <button 
    *ngIf="tableCommand == 'all'"
    (click)="backToSpecifiedRedirect()"
    class="navtor" 
    type="button"
    style="color: black;">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
        </svg>
                
    </button>

    <!-- header btn -->
    <ng-container>
        <div 
        class="button-container text-center">
            <!-- <div class="text-center"> -->
            <button
            class="btn btn-outline-primary"
            style="border-radius: 40px; margin-top: 10px;"
            *ngIf="entityBookings$ | async as entityBookings">
                {{tableTitle}} 
                <span *ngIf="entityBookings[0]">
                    for {{entityBookings[0]?.relatedEntity.Name }}
                    
                </span>
            </button>

            <!-- </div> -->
            
        </div>

    </ng-container>
    <hr>

 
    <!-- loading spinner -->
    <section 
        *ngIf="entityBookingsLoading$ | async;" 
        class="spinner-container">

        <div class="spinner"></div>
    </section>

    <div 
    class="container"
    *ngIf="entityBookingsLoaded$ | async;">
    
        <div *ngIf="entityBookings$ | async as entityBookings">
            <!-- {{entityBookings | json}} -->
            <!-- claim aggregator -->


            <!-- when there is booking -->
            <ng-container *ngIf="entityBookings.length > 0">
                
                <!-- aggregate of all claimable bookings: aggregateUnclaimedBookings$ -->
                <div class="card">
                    <!-- collapisble header  -->
                    <div 
                    class="card-body"
                    *ngIf="aggregateUnclaimedBookings$ | async as aggregateUnclaimedBookings">
                        <!-- {{aggregateUnclaimedBookings | json}} -->
                        
                        <!-- if the paystack status is failed, reversed then it should be included here -->
                        <span
                            style="justify-content: left; width: 100px; font-size: 16px; margin: 15px 0px 0px 0px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-wallet2" viewBox="0 0 16 16">
                                <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5z"/>
                            </svg>

                            
                            <!-- summation of all unclaimed bookings for that activity -->
                            {{ getTotalPayableToActivityHost(aggregateUnclaimedBookings) | currency: 'ZAR' }}
                            <!-- {{ aggregateUnclaimedBookings | json }} -->

                        </span>

                        
                        <span 
                            style="float: inline-end; font-weight: bold; margin-left: 4px;">
                            
                            
                            <span 
                            *ngIf="aggregateUnclaimedBookings.length > 0"
                            (click)="claimBookings()"
                            style="color: red;">
                                claim all: 
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-toggle-off" viewBox="0 0 16 16">
                                    <path d="M11 4a4 4 0 0 1 0 8H8a5 5 0 0 0 2-4 5 5 0 0 0-2-4zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8M0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5"/>
                                    </svg>
                            </span>

                            <!-- *ngIf="entityBooking.IsClaimed" -->
                            <span 
                            *ngIf="aggregateUnclaimedBookings.length < 0"
                            style="color: rgb(157, 243, 27);">
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-toggle-on" viewBox="0 0 16 16">
                                    <path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8"/>
                                </svg>
                            </span>
                        </span>
                    </div>
                </div>

                <!-- all bookings -->
                <mat-accordion>
                    <mat-expansion-panel 
                        *ngFor="let entityBooking of entityBookings"
                        (opened)="panelOpenState = true"
                        (closed)="panelOpenState = false">

                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <ng-container>
                                    <img 
                                    class="img-circle" 
                                    [src]="rtImgPath + (entityBooking.userThatMadeBooking.ProfilePicture ?? 'nexPP.png')" 
                                    alt="" />
                                    <span 
                                        style="justify-content: left; width: 100px;">
                                        {{ entityBooking.userThatMadeBooking.UserName ?? 'Admin'}}

                                    </span>
                                </ng-container>
                                
                                <!-- Booking for {{ entityBooking.relatedEntity.Name }} -->

                            </mat-panel-title>

                            <mat-panel-description>
                            
                                <!-- Currently I am {{panelOpenState ? 'open' : 'closed'}} -->

                                

                                <span 
                                    style="float: inline-end; font-weight: bold; margin-left: 4px;">
                                    claim status: 
                                    <span 
                                    style="color: red;"
                                    *ngIf="!entityBooking.IsClaimed">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-toggle-off" viewBox="0 0 16 16">
                                            <path d="M11 4a4 4 0 0 1 0 8H8a5 5 0 0 0 2-4 5 5 0 0 0-2-4zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8M0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5"/>
                                        </svg>

                                     
                                    </span>

                                    <!-- if the booking is claim with success status from paystack -->
                                    <span 
                                    style="color: rgb(157, 243, 27);"
                                    *ngIf="entityBooking.IsClaimed && entityBooking.matchedClaim.Status == 'success'">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-toggle-on" viewBox="0 0 16 16">
                                            <path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8"/>
                                        </svg>
                                    </span>

                                    <!-- if the booking is claim with pending status from paystack -->
                                    <span 
                                    style="color: rgb(234, 255, 47);"
                                    *ngIf="entityBooking.IsClaimed && entityBooking.matchedClaim.Status == 'pending'">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-toggle-off" viewBox="0 0 16 16">
                                            <path d="M11 4a4 4 0 0 1 0 8H8a5 5 0 0 0 2-4 5 5 0 0 0-2-4zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8M0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5"/>
                                        </svg>
                                    </span>

                                    <!-- if the booking is claim with failed status from paystack -->
                                    <span 
                                    style="color: rgb(9, 10, 8);"
                                    *ngIf="entityBooking.IsClaimed && entityBooking.matchedClaim.Status == 'failed'">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-toggle-off" viewBox="0 0 16 16">
                                            <path d="M11 4a4 4 0 0 1 0 8H8a5 5 0 0 0 2-4 5 5 0 0 0-2-4zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8M0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5"/>
                                        </svg>
                                    </span>
                                </span>
                            </mat-panel-description>

                        </mat-expansion-panel-header>
                
                        <ng-container>
                            
                            <div class="card bookingCard">
                                <div class="card-header">
                                    <!-- {{entityBooking | json}} -->
                                    <!-- booking creation date -->
                                    <h3 class="headerText container">
                                        <span 
                                            style="color: rgb(62, 168, 62);">
                                            Booking Date: {{ getFormattedDate(entityBooking.CreatedAt) }}
                                        </span>
                                    </h3>

                                    <!-- activity start date -->
                                    <h3 
                                    *ngIf="mEEntity == 0"
                                    class="headerText container">
                                        <span style="color: rgb(171, 173, 30);">
                                            Activity start date: {{ getFormattedDate(entityBooking.DateCreatedFor) }}
                                        </span>
                                    </h3>

                                    <!-- event start time -->
                                    <h3 
                                    *ngIf="mEEntity == 1"
                                    class="headerText container">
                                        <span style="color: rgb(171, 173, 30);">
                                            Event Start date: {{ getFormattedDate(entityBooking.EventStartDateTime) }}
                                        </span>
                                    </h3>

                                    <h3>
                                        Claim Status: 
                                        {{
                                            !entityBooking.IsClaimed ? "Booking is not claimed" :
                                            (entityBooking.IsClaimed && entityBooking.matchedClaim.Status == 'success') ? "Claim successful" :
                                            (entityBooking.IsClaimed && entityBooking.matchedClaim.Status == 'pending') ? "Claim is pending" :
                                            (entityBooking.IsClaimed && entityBooking.matchedClaim.Status == 'failed') ? "Claim failed" : 
                                            (entityBooking.IsClaimed && entityBooking.matchedClaim.Status == 'reversed') ? "Claim was reversed" :"unknown status"
                                        }}
                                    </h3>

                                </div>
                                <div class="card-body">
                                    <h3 class="card-title">
                                        {{ entityBooking.relatedEntity.Name }}
                                    </h3>

                                    <p class="card-text">
                                        Charge: {{ entityBooking.relatedEntity.PricePerPerson}}
                                    </p>
                                    
                                    <p class="card-text">
                                        Service fee: {{ entityBooking.ServiceFee }}
                                    </p>
                                    
                                    <p class="card-text">
                                        Transaction Fee: {{ entityBooking.TransactionFee | number:'1.2-2' }}
                                    </p>

                                    <p class="card-text">
                                        Amount payable: {{ entityBooking.PayableAmount | number:'1.2-2' }}
                                    </p>
                                    
                                    <!-- <a
                                        (click)="claimBookings(true, false)"
                                        class="btn btn-primary">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-wallet2" viewBox="0 0 16 16">
                                            <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5z"/>
                                        </svg>

                                        Claim
                                    </a> -->
                                </div>
                            </div>
                            
                            
                            
                            <!-- {{ entityBooking | json }} -->
                            <!-- <hr> -->
                            <!-- {{ entityBooking.relatedEntity | json }} -->
                        </ng-container>

                    </mat-expansion-panel>
                </mat-accordion>
            </ng-container>

            <!-- no bookings (future, past or current) -->
            <ng-container *ngIf="entityBookings.length == 0">
                <div 
                style="display: flex; justify-content: center; align-items: center; height: 50vh;">
                    <div 
                    class="card" 
                    style="border: 1px solid #ddd; padding: 20px; border-radius: 5px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);">
                        <div class="card-body" style="font-size: 16px;">
                            No bookings available... click 
                            <a 
                            style="text-decoration: underline; text-decoration-color: blue;"
                            (click)="ViewActivity(entityId)">
                                here
                            </a> 
                            to view and share 
                            {{(mEEntity == 0) ? 'activity' : (mEEntity == 1) ? 'event' : '' }}
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

    </div>

   
 