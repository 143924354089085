import { createReducer, on } from "@ngrx/store";
import * as CurrentUserEventsAction from "../actions/current-user-events.actions";
import { CurrenUserEventsState } from "../state/current-user-events.state";


const initialState: CurrenUserEventsState = {
    currentuserevents: [],
    loading: false,
    loaded: false,
    error: null,
};

const _currentUserEvents = createReducer(
    initialState,
    on(CurrentUserEventsAction.LOAD_EVENTS_FOR_ONE_USER, state => ({ ...state, loading: true })),
    on(CurrentUserEventsAction.LOAD_EVENTS_SUCCESS_FOR_ONE_USER, (state, { currentuserevents }) => ({
        ...state,
        currentuserevents: [...currentuserevents],
        loading: false,
        loaded: true,
        error: null,
    })),
    on(CurrentUserEventsAction.LOAD_EVENTS_FAIL_FOR_ONE_USER, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error,
    }))
);

export function currentUserEventsReducer(state: any, action: any) {
    return _currentUserEvents(state, action);
}