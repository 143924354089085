import { createFeatureSelector, createSelector } from "@ngrx/store";
import { MaiXEventByStatusState, MaiXEventState } from "../state/maixevent.state";

export const selectEventState = createFeatureSelector<MaiXEventState>('maixevents');

export const selectAllEvents = createSelector(
    selectEventState,
  (state: MaiXEventState) => state.maixevents
);

export const selectEventLoading = createSelector(
    selectEventState,
  (state: MaiXEventState) => state.loading
);

export const selectEventLoaded = createSelector(
    selectEventState,
    (state: MaiXEventState) => state.loaded
  );

export const selectEventError = createSelector(
    selectEventState,
  (state: MaiXEventState) => state.error
);


//#region eventsByStatus
export const eventByStatusState = createFeatureSelector<MaiXEventState>('eventsByStatus');

export const selectEventsByStatus = createSelector(
  eventByStatusState,
  (state: MaiXEventState) => state.maixevents
);

export const selectEventsByStatusLoading = createSelector(
  eventByStatusState,
  (state: MaiXEventState) => state.loading
);

export const selectEventsByStatusLoaded = createSelector(
  eventByStatusState,
    (state: MaiXEventState) => state.loaded
  );

export const selectEventsByStatusError = createSelector(
  eventByStatusState,
  (state: MaiXEventState) => state.error
);

//#endregion