<!-- filters -->
<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab label="Active Activities">
        <app-all-activities></app-all-activities>
    </mat-tab>
    <mat-tab label="Inactive Activities">
        <!-- Inactive Activities -->

        <app-activity-per-status
            [activeSt]="inActiveStus">
        </app-activity-per-status>
    </mat-tab>
    <mat-tab label="Disabled Activities">
        <!-- Disabled Activities -->

        <app-activity-per-status
            [activeSt]="disabledStus">
        </app-activity-per-status>
    </mat-tab>
  </mat-tab-group>

