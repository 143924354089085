import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { GET_IP, GET_IP_FAIL, GET_IP_SUCCESS } from "../actions/ip.actions";
import { catchError, map, mergeMap, of, tap } from "rxjs";
import { environment } from "src/environments/environment";
import { VisitorInfoService } from "src/app/shared/services/visits/visitor-info.service";

@Injectable()
export class IpEffects {



    getIp$ = createEffect(() => this.actions$.pipe(
        ofType(GET_IP),
        mergeMap(() => this.visitorInfo.getIpObs()
        .pipe(
            tap((ip : any) => {
                if(!environment.production)
                {
                    console.log(`Response from IP request: ${ip}`);
                }
            }),
            map((ip: string) => GET_IP_SUCCESS({ ip })),
            catchError(error => of(GET_IP_FAIL({ error })))
        ))
        )
    );

  constructor(
    private actions$: Actions,
    private visitorInfo: VisitorInfoService
  ) {}
}