import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, filter, Observable, Subscription, tap } from 'rxjs';
import { GET_CURRENT_USER_ROLES } from 'src/app/ngrx/actions/current-user-roles.actions';
import { selectLoggedInUserRoles } from 'src/app/ngrx/selectors/current-user-roles.selectors';
import { AppState } from 'src/app/ngrx/state/app.state';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { UserSpecificService } from 'src/app/shared/services/dataService/user-specific.service';
import { EventStatus } from 'src/app/types/enums/eventStatus.enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-event-per-status',
  templateUrl: './event-per-status.component.html',
  styleUrls: ['./event-per-status.component.scss']
})
export class EventPerStatusComponent {
  pastEvents: EventStatus = EventStatus.Future;
  liveEvents: EventStatus = EventStatus.Future;
  futureEvents: EventStatus = EventStatus.Future;


  private canAddEventSubject = new BehaviorSubject<boolean>(false);
  canAddEvent$ = this.canAddEventSubject.asObservable();


  // useId:string | undefined;
  private useIdSubject = new BehaviorSubject<string | null>(null);
  useId$: Observable<string | null> = this.useIdSubject.asObservable();

    
  subscription: Subscription | undefined;
  
  constructor(private router: Router,
    private userSvc: UserSpecificService,
    private toastr: ToastrService,
    private authSvc: AuthService,
    private store: Store<AppState>
  ){

  }

  ngOnInit()
  {
      //who can add event
      this.subscription = this.store.pipe(
        select(selectLoggedInUserRoles))
        .pipe(
          tap((userRoles) => {
  
            if (
              !userRoles || 
              userRoles.length === 0 || 
              !this.useIdSubject.getValue() || 
              this.useIdSubject.getValue()!?.length < 1) 
            {
  
              this.authSvc.GetCurrentlyLoggedInUserIdIfAny()?.subscribe({
                next: (response: string) => {
                  this.useIdSubject.next(response);
                  
                  if (!environment.production) {
                    console.log(`About to dispatch current user roles`);
                  }
  
                  // Dispatch action to get user roles
                  this.store.dispatch(GET_CURRENT_USER_ROLES());
                },
                error: (err: any) => {
                  if(!environment.production)
                  {
                    console.log("Unable to get logged in user: " + err);
                  }
                },
                complete() {
                  if(!environment.production)
                  {
                    console.log("Complete");
                  }
                }
              });
            }
          }),
          filter(userRoles => userRoles && userRoles.length > 0),
          tap(userRoles => {
            
            const usrRoleToExclude = 'mxp_General';
            
            const filteredUserRoles = userRoles.filter(role => role !== usrRoleToExclude);
  
           
            if(filteredUserRoles.includes('mxp_EventHost'))
            {
              this.canAddEventSubject.next(true);
            }
            else{
              this.canAddEventSubject.next(false);
            }
          })
        ).subscribe()
      
    }

  RouteToAddNewEvent(){
    //observer to validate the cookie against the server
    const tknValidationObserver = {
      // if cookie is still valid
      next: async (response: any) => {
        if(!environment.production)
        {
          console.log(`Token validation from server response (event): ${JSON.stringify(response)}`);
        }
  
        // if token comes back as valid: route to addactivity
        this.router.navigate(['addevent']);
        
      },
      error: async (err: HttpErrorResponse) => {
        if(!environment.production)
        {
          console.log(`Error response from token validation (for event): ${JSON.stringify(err)}`);
        }
        
        if(err.url) //if err response is trying to navigate to another page, then it has expired
        {
          //remove the cookie (if any)
          this.userSvc.logout()
  
          //show the user some error
          this.toastr.error(
            "User not signed in...", "Error");
  
          //redirect to the login page
          this.router.navigate(['login'],
          {
            queryParams: {
              redirectUrl: 'addevent'
            }
          });
  

        }
      },
      complete: async () => {
        console.log(`user http request complete!`);
      }
    }
  
    //validate the token against the server
    this.authSvc.ValidateUserCookie().subscribe(tknValidationObserver)
    
   }
}
