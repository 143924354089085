import { HttpClient, HttpErrorResponse, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, NgZone, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Subscription, finalize, tap } from 'rxjs';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { ActivityService } from 'src/app/shared/services/activity.service';
import { MatStepper } from '@angular/material/stepper';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
// import { MapsAPILoader } from '@agm/core';
import { PerformerclassService } from 'src/app/shared/services/performerclass.service';
import { AddressService } from 'src/app/shared/services/address.service';
import { environment } from 'src/environments/environment';
import { Time } from '@angular/common';
import { ActivitySlotService } from 'src/app/shared/services/activity-slot.service';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { UIError } from 'src/app/types/models/uiError.model';
import { ActivityType } from 'src/app/types/enums/activityType.enum';
import { AddressModel } from 'src/app/types/models/addressModel.model';
import { Activity } from 'src/app/types/models/activity.model';
import { PriceCategoryType } from 'src/app/types/enums/priceCategoryType.enum';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/ngrx/state/app.state';
import { selectLoggedInUserRoles } from 'src/app/ngrx/selectors/current-user-roles.selectors';
import { GET_CURRENT_USER_ROLES } from 'src/app/ngrx/actions/current-user-roles.actions';
import * as ActivityActions from 'src/app/ngrx/actions/activity.actions';
import { LoaderService } from 'src/app/shared/utils/loader.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-new-activity',
  templateUrl: './new-activity.component.html',
  styleUrls: ['./new-activity.component.scss']
})
export class NewActivityComponent {
  @Input()
  requiredFileType:string | undefined;

  progress: number | undefined;
  message: string | undefined;
  @Output() public onUploadFinished = new EventEmitter();

  //UI Error handlers for activity model
  UIUserInteractionError: UIError = new UIError();
  ActivityNameErroHandler: UIError = new UIError();
  ActivityDescErroHandler: UIError = new UIError();
  // MinTeamParticipantErroHandler: UIError = new UIError();
  // MaxTeamParticipantErroHandler: UIError = new UIError();
  PricePerPersonErroHandler: UIError = new UIError();



  //UI Error handlers for activity slot model
  ActivityIdErroHandler: UIError = new UIError();
  MaxCapacityErroHandler: UIError = new UIError();
  PricePerSlotErroHandler: UIError = new UIError();
  CurrencyIdErroHandler: UIError = new UIError();
  PriceCategoryErroHandler: UIError = new UIError();
  OpeningTimeErroHandler: UIError = new UIError();
  ClosingTimeErroHandler: UIError = new UIError();
  slotCategoryTypeErroHandler: UIError = new UIError();

  fileName = '';
  uploadProgress:number | undefined;
  uploadSub: Subscription | undefined;

  activityModel: any;

  firstFiveselFiles: string = '';

  selFiles: string[] = [];
  
  rtImgPath: string = environment.fileRetriever;
  
  ActivityStep: boolean = false

  public activityTypes = Object.values(ActivityType).filter((entry:any) => isNaN(entry))
  

  panelOpenState = false;

  activityDetailsOpenStateAngMat = true; //panel 1
  activityImagesOpenStateAngMat = false; //panel 2
  locationPanelOpenStateAngMat = false; //panel 3
  preferencesPanelOpenStateAngMat = false; //panel 4


  imgSelectMaxSize = environment.maxImageSizeMB;


  //step 1 form
  public ActivityDetailsForm: FormGroup = this.formBuilder.group('');

  // step 2 form
  public ActivityImagesForm: FormGroup = this.formBuilder.group('');


  //location vars
  title: string = 'AGM project';
  latitude: number = 0;
  longitude: number = 0;
  zoom: number = 0;
  address: string | undefined;
  unformattedAddress: any;
  private geoCoder: google.maps.Geocoder | undefined;

  anAddressIsSelected: boolean = false;

  activityAddr: AddressModel = {
    Id: 0,
    Street_number: '',
    Street: '',
    Suburb: '',
    City: '',
    State: '',
    IsDefault: false,
    Building_name: '',
    Unit_number: '',
    Address_instruction: '',
    Country: '',
    Postal_code: '',
    Longitude: '',
    Latitude: '',
    CreatedAt: null,
    ModifiedAt: null
  }

  subscription: Subscription | undefined;

  @ViewChild('search')
  public searchElementRef: ElementRef = new ElementRef<any>(null);

  loggedinUserId!: string;
  
  activityForm: FormGroup | any;
  preferenceForm: FormGroup | any;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private addrService: AddressService,
    private loadingService: LoaderService,
    private formBuilder: FormBuilder,
    private authSvc: AuthService,
    private store: Store<AppState>) {

      this.activityForm = this.formBuilder.group({
        Id: [0],
        Name: ['', Validators.required],
        Description: ['', Validators.required],
        PricePerPerson: [0, [Validators.required, Validators.min(1)]],
      });

      this.preferenceForm = this.formBuilder.group({
        EnablePromotion: [false, Validators.required],
        ActivityPromotionPercentage: [0, Validators.required]
      });
    }



  ngOnInit(): void {
    
    //#region instance_of_Activity

    this.activityModel = new Activity(
      0, // Id
      this.loggedinUserId, // UserId
      '', //  Name
      '', //  Description
      0, // AddressId
      0, // PricePerPerson
      0, // MaxParticipantPerBooking
      '', //  Images
      '', //  Default Image
      false, // IsFeatured
      false, //EnablePromotion
      0 // ActivityPromotionPercentage
    );

    if(!environment.production)
    {
      console.log(`Activity Model: ${JSON.stringify(this.activityModel)}`);
      
    }
    //#endregion

    //#region initialize_places_autocomplete

    // //not in use
    // this.ActivityImagesForm = this.formBuilder.group({
    //   Image: new FormControl(''),
    //   DefaultImage: new FormControl('')
    // });

    //load Places Autocomplete
    //  this.mapsAPILoader.load().then(() => {
    //   this.setCurrentLocation();
    //   this.geoCoder = new google.maps.Geocoder;

    //   let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);

    //   autocomplete.addListener("place_changed", () => {
    //     this.ngZone.run(() => {
    //       //get the place result
    //       let place: google.maps.places.PlaceResult = autocomplete.getPlace();

    //       //verify result
    //       if (place.geometry === undefined || place.geometry === null) {
    //         return;
    //       }

    //       //set latitude, longitude and zoom
    //       this.latitude = place.geometry.location.lat();
    //       this.longitude = place.geometry.location.lng();

    //       this.address = place.formatted_address;

    //       console.log(`this address: ${this.address}`);
          
    //       // this.address = '';
    //       this.unformattedAddress = place.address_components;

    //       console.log(`this unformattedAddress: ${this.unformattedAddress}`);

    //       // if(!environment.production)
    //       // {
    //       //   console.log(place.address_components)
    //       // }
    //       this.zoom = 12;
    //     });
    //   });
    // });

    //#endregion
    
    
    //get the logged in user ID
    this.subscription = this.authSvc.GetCurrentlyLoggedInUserIdIfAny()?.subscribe({
      next: (response: string) => {
        this.loggedinUserId = response;

        
        if (!environment.production) {
          console.log(`About to dispatch current user: ${this.loggedinUserId} roles`);
        }

        // Dispatch action to get user roles
        this.store.dispatch(GET_CURRENT_USER_ROLES());
      },
      error: (err: any) => {
        if(!environment.production)
        {
          console.log("Unable to get logged in user: " + err);
        }
      },
      complete() {
        if(!environment.production)
        {
          console.log("Complete");
        }
      }
    })


  }
  
  getEnumKeyByValue(value: any): string {
    return PriceCategoryType[value];
  }

  //#region ActivityInfo_Error_Handling_Events
  //==========Activity related event change error handling methods//==========
  
  onSearchChange(searchValue: any): void {  
    console.log(searchValue.target.value);

    //if the checks pass
    this.ActivityNameErroHandler.status = "valid-feedback";
    this.ActivityNameErroHandler.message = "Valid Name"
  }

  onSearchChangeOnDescription(searchValue: any){
    console.log(searchValue.target.value);

    //if the checks pass
    this.ActivityDescErroHandler.status = "valid-feedback";
    this.ActivityDescErroHandler.message = "Valid Description"
  }

  //=============================Ends=============================
  
  onSearchChangeOnPricePerPerson(searchValue: any)
  {
    console.log(searchValue.target.value);

    //if the checks pass
    this.PricePerPersonErroHandler.status = "valid-feedback";
    this.PricePerPersonErroHandler.message = "Valid Price"
  }

  //#endregion

  removeSelection(element: any){
    this.selFiles = this.selFiles.filter(item => item !== element);

    if(!environment.production)
    {
      console.log(this.selFiles); // Updated array after removal
    }
  }
  
  onAppendFilesUploaded(fileNames: string[])
  {
    console.log('Appended files:', fileNames);

    this.selFiles = [...this.selFiles, ...fileNames];

    if(!environment.production)
    {
      console.log(this.selFiles); // Updated array after appending
    }
  }

  onFilesUploaded(fileNames: string[]): void {
    this.selFiles = fileNames;

    if(!environment.production)
    {
      console.log('Uploaded files:', fileNames);
    }
  }


  SlotAddComplete(fm: NgForm){
    if(!environment.production)
    {
      console.log(fm.value);
    }
  }

  activateStep()
  {
    console.log(`I am validated`);
    this.ActivityStep = true;
    
  }

  ValidateActivityDetailsAndProceedToImageUpload()
  {
    //get the userID of the creator and update the activity model
    this.activityModel.UserId = this.loggedinUserId
        
    if(!environment.production)
    {
      console.log(`User Id of logged on user: ${this.loggedinUserId}`);
      console.log(`Event body prepped: ${JSON.stringify(this.selFiles)}`);
      console.log(`Activity model: ${JSON.stringify(this.activityModel)}`);
      console.log(`Form vals: ${JSON.stringify(this.activityForm.value)}`);
    }
    
      if(this.activityForm.valid)
      {
        //open the next panel
        this.activityDetailsOpenStateAngMat = false; //panel 1
        this.activityImagesOpenStateAngMat = true; //panel 2
        this.locationPanelOpenStateAngMat = false; //panel 3
        this.preferencesPanelOpenStateAngMat =  false //panel 4
      }
  }
  

  toggleAppendPromotion(){
    console.log(`promotion toggle clicked`)
    this.activityModel.EnablePromotion = !this.activityModel.EnablePromotion
  }

  ValidateImageAddAndProceedAngMat()
  {
    //open the next panel      
    this.activityDetailsOpenStateAngMat = false; //panel 1
    this.activityImagesOpenStateAngMat = false; //panel 2
    this.locationPanelOpenStateAngMat = true; //panel 3
    this.preferencesPanelOpenStateAngMat =  false //panel 4
  }

  ValidateLocationAddAndProceedToPreferencesSettings()
  {
    //open the next panel      
    this.activityDetailsOpenStateAngMat = false; //panel 1
    this.activityImagesOpenStateAngMat = false; //panel 2
    this.locationPanelOpenStateAngMat = false; //panel 3
    this.preferencesPanelOpenStateAngMat =  true //panel 4
  }

  // NEW VIEW
  returnToActivityInfoFromImageAddAngMat()
  {
    //open the next panel      
    this.activityDetailsOpenStateAngMat = true; //panel 1
    this.activityImagesOpenStateAngMat = false; //panel 2
    this.locationPanelOpenStateAngMat = false; //panel 3
    this.preferencesPanelOpenStateAngMat =  false //panel 4
  }
 
   returnToActivityImagesAngMat()
  {
    //open the next panel      
    this.activityDetailsOpenStateAngMat = false; //panel 1
    this.activityImagesOpenStateAngMat = true; //panel 2
    this.locationPanelOpenStateAngMat = false; //panel 3
    this.preferencesPanelOpenStateAngMat =  false //panel 4
  }


  returnToLocationAddPanelFromPreferencesSettings()
  {
    //open the next panel      
    this.activityDetailsOpenStateAngMat = false; //panel 1
    this.activityImagesOpenStateAngMat = false; //panel 2
    this.locationPanelOpenStateAngMat = true; //panel 3
    this.preferencesPanelOpenStateAngMat =  false //panel 4
  }

  validatePreferences(): boolean
  {
    // console.log(`Promotion status: ${this.activityModel.EnablePromotion} And ${this.activityModel.ActivityPromotionPercentage}.`)
    // console.log(`Promotion is a number: ${!isNaN(this.activityModel.ActivityPromotionPercentage)}.`)
    // return false;

    if(this.activityModel.EnablePromotion)
    {
      if(this.activityModel.ActivityPromotionPercentage > 4 &&
        !isNaN(this.activityModel.ActivityPromotionPercentage))
      {
        return true
      }
      else{
        
        return false;
      }
    } 
    else 
    {
      return true;
    }
  }

  CompleteActivityAdd()
  {
    if(this.validatePreferences())
    {

      
      this.loadingService.show();
      
      if(!environment.production)
      {
        console.log(`completion to kick off`);
      }
      
      //prep the ActivityObject (activity info + images (as a unit))
      // this.selFiles
      this.activityModel.Images = this.selFiles.toString();
      this.activityModel.DefaultImage = this.selFiles[0];
      
      var avty = {activity : this.activityModel};

      if(environment.production)
      {
        console.log(`Activity model: ${JSON.stringify(avty)}`);
      }
    
      //address observer
      const addrObserver = {
        next: async (data: any) =>  {
          if(!environment.production)
          {
            console.log("location added with result:" + JSON.stringify(data));
          }

          //get the newly added activity ID
          let addrId = data?.split('|')[0] ?? 1;

          if(!environment.production)
          {
            console.log(`Newly added address Id: ${addrId}`);
          }

          this.activityModel.AddressId = addrId;

          // send data to the server
          this.store.dispatch(ActivityActions.ADD_ACTIVITY({activity: this.activityModel}))
          

          // this.activityService.AddActivity(this.activityModel)
          //   .subscribe(addActivityObserver); 
        },
        error: (err: HttpErrorResponse) => console.log(err),
        complete: () =>{
          this.loadingService.hide();

          if(!environment.production)
          {
            console.log("Addres add COMPLETE");
          }
        }
      }


      this.addrService.AddAddressToAnActivity(this.activityAddr)
      .subscribe(addrObserver)
    }
    else {
      this.toastr.error("Enter a valid promotion percentage and try again")
    }
  }


  goToActivities()
  {
    this.router.navigate(['activitylist'])
  }

  //location methods

  onAddressSelected(address: AddressModel) {
    // Save the address to the database or handle it as needed
    console.log('Selected Address in new activity:', address);

    if(this.addrService.validateSelectedAddress(address))
    {
      this.anAddressIsSelected = true;
      this.activityAddr = address

    }
     
  }

  

  // Get Current Location Coordinates
  // private setCurrentLocation() {
  //   if ('geolocation' in navigator) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       this.latitude = position.coords.latitude;
  //       this.longitude = position.coords.longitude;
  //       this.zoom = 8;
  //       this.getAddress(this.latitude, this.longitude);
  //     });
  //   }
  // }
  

  // markerDragEnd($event:any) {
  //   console.log('my event: ' + $event);
  //   this.latitude = $event.coords.lat;
  //   this.longitude = $event.coords.lng;
  //   this.getAddress(this.latitude, this.longitude);
  // }
  
  // getAddress(latitude: any, longitude: any) {
  //   this.geoCoder?.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
  //     // if(!environment.production)
  //     // {
  //     //   console.log('result: ' + JSON.stringify(results));
  //     //   console.log('status: ' + JSON.stringify(status));
  //     // }
      
  //     if (status === 'OK') {
  //       if (results[0]) {
  //         this.zoom = 12;

  //         this.address = results[0].formatted_address;

  //         this.unformattedAddress = results[0].address_components;

  //         // console.log(`sending unfh for formatting: ${this.unformattedAddress}`);
          
  //         this.addressFormatterHelper(this.address, "auto");

  //       } else {
  //         window.alert('No results found');
  //       }
  //     } else {
  //       window.alert('Geocoder failed due to: ' + status);
  //     }

  //   });
  // }

  // MapAddressToCurrentUser()
  // {
  //   //we get the address 
  //   console.log(`address_component: ${JSON.stringify(this.unformattedAddress)}`);

  //   // [
  //   //   {
  //   //     "long_name":"318",
  //   //     "short_name":"318",
  //   //     "types":["street_number"]
  //   //   },
  //   //   {
  //   //     "long_name":"5th Avenue",
  //   //     "short_name":"5th Ave",
  //   //     "types":["route"]
  //   //   },
  //   //   {
  //   //     "long_name":"Capital Park",
  //   //     "short_name":"Capital Park",
  //   //     "types":["sublocality_level_1",
  //   //     "sublocality","political"]
  //   //   },
  //   //   {
  //   //     "long_name":"Pretoria",
  //   //     "short_name":"Pretoria",
  //   //     "types":["locality","political"]
  //   //   },
  //   //   {
  //   //     "long_name":"City of Tshwane Metropolitan Municipality",
  //   //     "short_name":"City of Tshwane Metropolitan Municipality",
  //   //     "types":["administrative_area_level_2","political"]
  //   //   },
  //   //   {
  //   //     "long_name":"Gauteng",
  //   //     "short_name":"GP",
  //   //     "types":["administrative_area_level_1","political"]
  //   //   },
  //   //   {
  //   //     "long_name":"South Africa",
  //   //     "short_name":"ZA",
  //   //     "types":["country","political"]
  //   //   },
  //   //   {
  //   //     "long_name":"0084",
  //   //     "short_name":"0084",
  //   //     "types":["postal_code"]
  //   //   }
  //   // ]

  //   // var street = this.unformattedAddress?.types.filter(obj =>
  //   //   obj == 'street_number'
  //   // );

  //   // //street number extract
  //   // var streetNumber = this.unformattedAddress?.find(obj =>
  //   //   obj.types.filter(x => x === 'street_number')
  //   // ).short_name ?? "";

  //   // //street extract
  //   // var street = this.unformattedAddress?.find(obj =>
  //   //   obj.types.filter(x => x === 'route')
  //   // ).short_name ?? "";

  //   this.addressFormatterHelper(this.unformattedAddress);

  //   // if(!environment.production)
  //   // {
  //   //   console.log(`customer address: ${JSON.stringify(this.customerAddr)}`);
  //   // }
    
  //   // if(
  //   //   this.customerAddr.City == "Pretoria" || 
  //   //   this.customerAddr.State == "KZN")
  //   // {
  //   //   const addrSaveObserver = {
  //   //     next: (x: any) => {
  //   //       if(!environment.production)
  //   //       {
  //   //         console.log(`Address save response: ${x}`);
  //   //       }
          
  //   //       this.router.navigate(['cleaningservice']);
  //   //     },
  //   //     error: (err: any) => {
  //   //       //tell them some error occurred and reload for them to try again        
          
  //   //       this.alertService.danger("Try again...")

  //   //       setTimeout(() => {
  //   //           window.location.reload()
  //   //       }, 3000);
  //   //     }
  //   //   }
      
  //   //   //save it to the user
  //   //   // this.addressService.addAddress(this.customerAddr).subscribe(addrSaveObserver);
  //   // }
  //   // else{
  //   //   // this.alertService.danger
  //   //   alert("Oops! We are only live in Pretoria and KZN...");
  //   // }
  // }

  // addressFormatterHelper(unfmtdAddr: any, mode: string = '') : any
  // {
  //   if(!environment.production)
  //   {
  //     console.log('Format helper called');
  //   }

  //   //if this mode is a user selection
  //   if(mode != 'auto')
  //   {
  //     this.anAddressIsSelected = true;
  //   }
    
  //   var streetNumber;
  //   var street;
  //   var Suburb = "";
  //   var City = "";
  //   var State = "";
  //   var Country = "";
  //   var PostCode = "";
  //   var lng = "";
  //   var lat = "";

  //   var key;
    
  //   this.unformattedAddress.forEach((element: any) => {
  //     // console.log(`Element now: ${JSON.stringify(element)}`);
      
  //     // console.log(`Type(s): ${element['types']}`)

  //     // console.log((element['types'].includes('street_number')) ? "got street" : "Nostreet");
      
  //     // console.log((element['types'].includes('locality')) ? "got locality" : "No locality");
      

  //     //street no. (if type is street_number)
  //     if(element['types'].includes('street_number')){
  //       streetNumber = element?.short_name ?? "";
  //     }
  //     else if(element['types'].includes('route'))
  //     {
  //       street = element?.short_name ?? "";
  //     }
  //     else if(element['types'].includes('sublocality_level_1'))
  //     {
  //       Suburb = element?.short_name ?? "";
  //     }
  //     else if(element['types'].includes('locality'))
  //     {
  //       City = element?.short_name ?? "";
  //     }
  //     else if(element['types'].includes('administrative_area_level_1'))
  //     {
  //       State = element?.short_name ?? "";
  //     }
  //     else if(element['types'].includes('country'))
  //     {
  //       Country = element?.short_name ?? "";
  //     }
  //     else if(element['types'].includes('postal_code'))
  //     {
  //       PostCode = element?.short_name ?? "";
  //     }
  //   });

  

  //   this.activityAddr.Street_number = streetNumber ?? "";
  //   this.activityAddr.Street = street ?? "";
  //   this.activityAddr.Suburb = Suburb ?? "";
  //   this.activityAddr.City = City ?? "";
  //   this.activityAddr.State = State ?? "";
  //   this.activityAddr.Building_name = "";
  //   this.activityAddr.Unit_number = "";
  //   this.activityAddr.Address_instruction = "";
  //   this.activityAddr.Country = Country ?? "";
  //   this.activityAddr.Postal_code = PostCode ?? "";
  //   this.activityAddr.Longitude = this.longitude.toString() ?? "";
  //   this.activityAddr.Latitude = this.latitude.toString() ?? "";
  //   this.activityAddr.CreatedAt = new Date();
  //   this.activityAddr.ModifiedAt = null;

  //   console.log(`Address formatted: ${JSON.stringify(this.activityAddr)}`);
    
  // }
  
  
  //ends
  
}


