import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { GET_IP_LOCATION, GET_IP_LOCATION_FAIL, GET_IP_LOCATION_SUCCESS } from "../actions/ipLocation.actions";
import { catchError, map, mergeMap, of, tap } from "rxjs";
import { VisitorInfoService } from "src/app/shared/services/visits/visitor-info.service";
import { IpLocation } from "../state/ipLocation.state";
import { environment } from "src/environments/environment";

@Injectable()
export class IpLocationEffects {



    getLocation$ = createEffect(() => this.actions$.pipe(
        ofType(GET_IP_LOCATION),
        mergeMap(({ ipAddress }) => this.visitorInfo.getLocationOfIp(ipAddress)
        .pipe(
            tap( location => {
                if(!environment.production)
                {
                    console.log(`Location response for ip: ${ipAddress} is - ${JSON.stringify(location)}.}`);
                }
            }),
            map((ipLocation: IpLocation) => GET_IP_LOCATION_SUCCESS({ ipLocation })),
            catchError(error => of(GET_IP_LOCATION_FAIL({ error })))
        ))
        )
    );

  constructor(
    private actions$: Actions,
    private visitorInfo: VisitorInfoService
  ) {}
}