import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sharebox',
  templateUrl: './sharebox.component.html',
  styleUrls: ['./sharebox.component.scss']
})


export class ShareboxComponent {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: {route: any},
              private snackBar: MatSnackBar,
              private route: ActivatedRoute) {
           //  this.url = this.cleanUrl(window.location.href);
  }
  weblink = environment.myUrl
    url = this.weblink + "#" + this.data.route
     copyUrl() {
      navigator.clipboard.writeText(this.url).then(() => {
        this.snackBar.open('URL copied to clipboard!', 'Close', {
          duration: 2000,
        });
      }).catch(err => {
        console.error('Failed to copy: ', err);
      });
    }

    // cleanUrl(url: string): string {
    //   try {
    //     const parsedUrl = new URL(url);
    //     // Remove unwanted query parameters
    //     parsedUrl.searchParams.delete('utm_source');
    //     parsedUrl.searchParams.delete('utm_medium');
    //     parsedUrl.searchParams.delete('utm_campaign');
    //     // Add other parameters you want to remove here
  
    //     // Reconstruct the URL without hash
    //     return parsedUrl.origin + parsedUrl.pathname + parsedUrl.search;
    //   } catch (error) {
    //     console.error('Invalid URL:', error);
    //     return url; // Return the original URL if there's an error
    //   }
    // }

    getEncodedUrl() {
      return encodeURIComponent(this.url);
    }
}
