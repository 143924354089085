import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ScrollService } from 'src/app/shared/services/scroll.service';
import { LoaderService } from 'src/app/shared/utils/loader.service';


@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultComponent implements OnInit {
  sidebarOpen = false;
  rightbarOpen = false;

  isLoading: boolean = false;

  @ViewChild('content', { static: true }) content: ElementRef | undefined;

  constructor(
    public loaderService: LoaderService,
    private scrollService: ScrollService,
    private cdr: ChangeDetectorRef)
  {

  }

  ngOnInit(): void {
    // Set the height of the element dynamically
    this.setContentHeight();

    this.loaderService.isLoading.subscribe((isLoading) => {
      // Update the value
      this.isLoading = isLoading;

      // Manually trigger change detection
      this.cdr.detectChanges();
    });
  }
  
  setContentHeight() {
    // Get the screen height
    const screenHeight = window.innerHeight;

    // Set the height of the element to fill the screen
    this.content!.nativeElement.style.height = screenHeight + 'px';
  }

  sideBarToggler($event: any){
    this.sidebarOpen = !this.sidebarOpen;
  }

  rightBarToggler($event: any){
    this.rightbarOpen = !this.rightbarOpen;
  }
}
