import { createReducer, on } from "@ngrx/store";
import { ActiveActivityState } from "../state/active-activity.state";
import { GET_ACTIVE_ACTIVITIES, GET_ACTIVE_ACTIVITIES_FAIL, GET_ACTIVE_ACTIVITIES_SUCCESS } from "../actions/active-activity.actions";


const initialState: ActiveActivityState = {
    activeactivities: [],
    loading: false,
    loaded: false,
    error: null,
};

const _activeActivityReducer = createReducer(
    initialState,
    on(GET_ACTIVE_ACTIVITIES, state => ({ ...state, loading: true })),
    on(GET_ACTIVE_ACTIVITIES_SUCCESS, (state, { activeactivities }) => ({
        ...state,
        activeactivities,
        // activeactivities: [...state.activeactivities, ...activeactivities],
        loading: false,
        loaded: true,
        error: null,
    })),
    on(GET_ACTIVE_ACTIVITIES_FAIL, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error,
    }))
);

export function activeActivityReducer(state: any, action: any) {
    return _activeActivityReducer(state, action);
  }