import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CurrenUserEventsState } from "../state/current-user-events.state";

export const eventsOfAUserState = createFeatureSelector<CurrenUserEventsState>('currentuserevents');

export const selectUserEvents = createSelector(
    eventsOfAUserState, 
  ( state: CurrenUserEventsState ) => state.currentuserevents
);

export const selectUserEventsLoading = createSelector(
    eventsOfAUserState,
  ( state: CurrenUserEventsState ) => state.loading
);

export const selectUserEventsLoaded = createSelector(
    eventsOfAUserState,
    ( state: CurrenUserEventsState ) => state.loaded
  );

export const CurrenUserEventsError = createSelector(
    eventsOfAUserState,
  ( state: CurrenUserEventsState ) => state.error
);