import { createReducer, on } from "@ngrx/store";
import { FeaturedActivityState } from "../state/featuredActivity.state";
import { LOAD_FEATURED_ACTIVITIES, LOAD_FEATURED_ACTIVITIES_FAIL, LOAD_FEATURED_ACTIVITIES_SUCCESS } from "../actions/featuredActivity.actions";

const initialState: FeaturedActivityState = {
    featuredActivities: [],
    loading: false,
    loaded: false,
    error: null,
};

const _featuredActivityReducer = createReducer(
    initialState,
    on(LOAD_FEATURED_ACTIVITIES, state => ({ ...state, loading: true })),
    on(LOAD_FEATURED_ACTIVITIES_SUCCESS, (state, { featuredActivities }) => ({
        ...state,
        featuredActivities,
        loading: false,
        loaded: true,
        error: null,
    })),
    on(LOAD_FEATURED_ACTIVITIES_FAIL, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error,
    }))
);

export function featuredActivityReducer(state: any, action: any) {
    return _featuredActivityReducer(state, action);
  }