import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { AddressService } from 'src/app/shared/services/address.service';
import { UserSpecificService } from 'src/app/shared/services/dataService/user-specific.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { MaiXEventService } from 'src/app/shared/services/maiXEventRelated/mai-xevent.service';
import { environment } from 'src/environments/environment';
import { ObfuscationServiceService } from 'src/app/shared/services/obfuscation-service.service';
import { EventStatus } from 'src/app/types/enums/eventStatus.enum';
import { MaiXEvent } from 'src/app/types/models/maixevent.model';
import { BehaviorSubject, Observable, Subscription, catchError, combineLatest, distinctUntilChanged, filter, map, of, switchMap, take, tap } from 'rxjs';
import { AppState } from 'src/app/ngrx/state/app.state';
import { ActionsSubject, Store, select } from '@ngrx/store';
import { selectFutureEventError, selectFutureEventLoaded, selectFutureEventLoading, selectFutureEventsWithPagination, selectLiveEventError, selectLiveEventLoaded, selectLiveEventLoading, selectLiveEventsWithPagination, selectPastEventError, selectPastEventLoaded, selectPastEventLoading, selectPastEventsWithPagination } from 'src/app/ngrx/selectors/maixevent-future.selectors';
// import { selectFutureEventError, selectFutureEventLoadedByPage, selectFutureEventLoading, selectFutureEventsByPage, selectLiveEventError, selectLiveEventLoaded, selectLiveEventLoading, selectLiveEventsWithPagination, selectPastEventError, selectPastEventLoaded, selectPastEventLoading, selectPastEventsWithPagination } from 'src/app/ngrx/selectors/maixevent-future.selectors';
import { LOAD_FUTURE_EVENTS } from 'src/app/ngrx/actions/maixevents-future.actions';
import { GenericRole } from 'src/app/types/models/genericrole.model';
import { GET_CREATORS_OF_FUTURE_EVENTS } from 'src/app/ngrx/actions/future-events-creator.actions';
import { GenericUser } from 'src/app/types/models/genericuser.model';
import { selectFutureEventCreators } from 'src/app/ngrx/selectors/maixevent-future-creators.selectors';
import { selectLoggedInUserRoles } from 'src/app/ngrx/selectors/current-user-roles.selectors';
import { GET_CURRENT_USER_ROLES } from 'src/app/ngrx/actions/current-user-roles.actions';
import { GET_EVENTS_BY_STATUS } from 'src/app/ngrx/actions/maixevent.actions';
import { LOAD_LIVE_EVENTS } from 'src/app/ngrx/actions/maixevents.live.actions';
import { LOAD_PAST_EVENTS } from 'src/app/ngrx/actions/maixevents.past.actions';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-selected-events',
  templateUrl: './selected-events.component.html',
  styleUrls: ['./selected-events.component.scss']
})
export class SelectedEventsComponent {
  @Input() eventStatus: any = 2;
  
  
  allEvents: any;
  
  
  rtImgPath: string = environment.fileRetriever;

  isUsrLoggedIn: boolean = false;
  
  

  canAddEvent: boolean = false;
  

  // useId:string | undefined;
  private useIdSubject = new BehaviorSubject<string>('');
  useId$: Observable<string> = this.useIdSubject.asObservable();

  

  allMxpRoles: GenericRole[] = [];

  // validUsers: any

  pageNumber: number = 1;
  pageSize: number = 500;

  //past events variables
  pastEvents$!:  Observable<MaiXEvent[]>
  pastEventCreators$!: Observable<GenericUser[]>
  pastEventsLoading$: any
  pastEventsLoaded$: any
  pastEventsError$: any

  //live events variables
  liveEvents$!:  Observable<MaiXEvent[]>
  liveEventCreators$!: Observable<GenericUser[]>
  liveEventsLoading$: any
  liveEventsLoaded$: any
  liveEventsError$: any

  //future events variables
  futureEvents$!:  Observable<MaiXEvent[]>
  futureEventCreators$!: Observable<GenericUser[]>
  loading$: any
  loaded$: any
  error$: any


  futureCombinedData$: Observable<{deducedEvent: MaiXEvent, creator: GenericUser | string | undefined}[]> = of([]);
  liveCombinedData$: Observable<{deducedEvent: MaiXEvent, creator: GenericUser | undefined}[]> = of([]);
  pastCombinedData$: Observable<{deducedEvent: MaiXEvent, creator: GenericUser | undefined}[]> = of([]);
  
  subscription: Subscription | undefined;

  userRoles$!: Observable<GenericRole[]>
  
  constructor(
    private toastr: ToastrService,
    private authSvc: AuthService,
    private store: Store<AppState>)
  {
    
  }


  ngOnInit()
  {
    if(!environment.production)
    {
      console.log(`Incoming status: ${this.eventStatus}`);
    }

    this.loadDeducedEvents(this.eventStatus, this.pageNumber, this.pageSize)
    
    //get all roles the application has
    this.authSvc.GetAllRolesForMaiXp(environment.AppBoundIdentifier)
    .subscribe((data: any) => {

      this.allMxpRoles = data.map((role: any) => ({
        Name: role.name,
        AppBound: role.AppBound,
        Id: role.Id,
      }));

      if(!environment.production)
      {
        console.log(`All roles For App MaiXp: ${JSON.stringify(data)}`);
        console.log(`Roles Object: ${JSON.stringify(this.allMxpRoles)}`)
      }
    });

    //this determines who can edit an ativity
    this.getUserIdIfUserLoggedIn();
  }

  
  loadDeducedEvents(evntStatus: number, pgNum: number, pgSize: number) {

    switch (evntStatus) 
    {
      case 2:
        // console.log(`Case 2 Running. Status: ${this.eventStatus}`);
        console.log(`Case 2 Running. Status: FUTURE | Page Num: ${pgNum} | Page Size: ${pgSize}`);
        
        this.futureEvents$ = this.store.pipe(
          select(selectFutureEventLoaded),
          distinctUntilChanged(),
          switchMap((loaded) => {
            console.log(`Case FUTURE Running. Is Loaded: ${loaded}`);
            if (!loaded) 
            {
              console.log(`load events for status code: ${this.eventStatus}`);

              // Dispatch the load event action if the events are not loaded
              this.store.dispatch(LOAD_FUTURE_EVENTS({
                statusCode: this.eventStatus,
                pageNumber: pgNum,
                pageSize: pgSize
              }));
            }
            // Return the future events from the store (whether they're freshly loaded or already in the store)
            return this.store.pipe(select(selectFutureEventsWithPagination));
          })
        );

        // Subscribe to futureEvents$ and log the emitted values
        this.futureEvents$.subscribe(events => {
          console.log('Future events emitted:', events);
        });

        this.loading$ = this.store.pipe(select(selectFutureEventLoading));
        this.loaded$ = this.store.pipe(select(selectFutureEventLoaded));
        this.error$ = this.store.pipe(select(selectFutureEventError));
        
        // Automatically get creators of future events based on the loaded events
        this.futureEventCreators$ = this.futureEvents$.pipe(
          map((events: MaiXEvent[]) => events.map(event => event.UserId!)), // Extract user IDs from events
          distinctUntilChanged(), // Ensure we don't trigger unnecessary updates
          tap((userIds: string[]) => {
            if (userIds.length > 0) {
              // Dispatch the action to get creators of the future events
              this.store.dispatch(GET_CREATORS_OF_FUTURE_EVENTS({ arrayOfUserId: userIds }));
            }
          }),
          switchMap(() => 
            this.store.pipe(
              select(selectFutureEventCreators)
            )
          ), // Fetch creators from the store reactively
          catchError(error => {
            console.error('Error processing future events', error);
            return of([]); // Return an empty array on error
          })
        );

        // Subscribe to futureEvents$ and log the emitted values
        this.futureEventCreators$.subscribe(eventsCreators => {
          console.log(`Creators of future events: ${JSON.stringify(eventsCreators)}`);
        });
        

        // Combine futureEvents and futureEventCreators
        this.futureCombinedData$ = combineLatest([
          this.futureEvents$, 
          this.futureEventCreators$
        ]).pipe(
          map(([futureEvents, creators]) =>
            futureEvents.map(futureEvent => {
              const matchedCreator = creators.find(creator => creator.Id === futureEvent.UserId);

              console.log(`Matched creators: ${JSON.stringify(creators)}`);

              return {
                deducedEvent: futureEvent,
                creator: matchedCreator ?? `Creator ID ${futureEvent.UserId} not found for user:`
              };
            })
          ),
          tap((actuallyCombined) => {
            console.log(`Combined Eventus 1 - ${JSON.stringify(actuallyCombined)}`)
          })
        );
            
      
          
          // map(([futureEvents, creators]) =>
          //   futureEvents.map(futureEvent => ({
          //     deducedEvent: futureEvent,
          //     creator: creators.find(creator => creator.Id === futureEvent.UserId) ?? `Creator ID`
          //   }))


        //   ),
        //   tap((fEvents) => {
        //     console.log(`Combined Eventus 2 - ${JSON.stringify(fEvents)}`)
        //   })
          
        // );

        // Subscribe to futureEvents$ and log the emitted values
        this.futureCombinedData$.subscribe(combinedData => {
          console.log(`Combined data from selected events: ${JSON.stringify(combinedData)}`);
        });

        break;
      case 1:
        console.log(`Case 1 Running. Status: ${this.eventStatus}`);

        this.liveEvents$ = this.store.pipe(
          select(selectLiveEventLoaded),
          distinctUntilChanged(),
          // switchMap(isLoaded => this.loadEventsIfNeeded(isLoaded, this.eventStatus))
          switchMap((isLoaded) => {
            if (!isLoaded) 
            {
              console.log(`load events for status code: ${this.eventStatus}`);

              // Dispatch the load event action if the events are not loaded
              this.store.dispatch(LOAD_LIVE_EVENTS({
                statusCode: this.eventStatus,
                pageNumber: 1,
                pageSize: 50
              }));
            }
            // Return the future events from the store (whether they're freshly loaded or already in the store)
            return this.store.pipe(select(selectLiveEventsWithPagination));
          })
        );

        // Subscribe to liveEvents$ and log the emitted values
        this.liveEvents$.subscribe(events => {
          console.log('Live events emitted:', events);
        });


        this.liveEventsLoading$ = this.store.pipe(select(selectLiveEventLoading));
        this.liveEventsLoaded$ = this.store.pipe(select(selectLiveEventLoaded));
        this.liveEventsError$ = this.store.pipe(select(selectLiveEventError));
        

        // Automatically get creators of live events based on the loaded events
        this.liveEventCreators$ = this.liveEvents$.pipe(
          map((events: MaiXEvent[]) => events.map(event => event.UserId!)), // Extract user IDs from events
          distinctUntilChanged(), // Ensure we don't trigger unnecessary updates
          tap((userIds: string[]) => {
            if (userIds.length > 0) {
              // Dispatch the action to get creators of the future events
              this.store.dispatch(GET_CREATORS_OF_FUTURE_EVENTS({ arrayOfUserId: userIds }));
            }
          }),
          (() => this.store.pipe(select(selectFutureEventCreators))), // Fetch creators from the store
          catchError(error => {
            console.error('Error processing future events', error);
            return of([]); // Return an empty array on error
          })
        );


        //====================================
        // Combine live and liveEventCreators
        this.liveCombinedData$ = combineLatest([
          this.liveEvents$, 
          this.liveEventCreators$
        ]).pipe(
          map(([liveEvents, creators]) =>
            liveEvents.map(liveEvent => ({
              deducedEvent: liveEvent,
              creator: creators.find(creator => creator.Id === liveEvent.UserId)
            }))
          )
        );
        //====================================
        
        break;
      case 0:
        console.log(`Case 0 Running. Status: ${this.eventStatus}`);

        this.pastEvents$ = this.store.pipe(
          select(selectPastEventLoaded),
          distinctUntilChanged(),
          // switchMap(isLoaded => this.loadEventsIfNeeded(isLoaded, this.eventStatus))
          switchMap((isLoaded) => {
            if (!isLoaded) 
            {
              console.log(`load events for status code: ${this.eventStatus}`);

              // Dispatch the load event action if the events are not loaded
              this.store.dispatch(LOAD_PAST_EVENTS({
                statusCode: this.eventStatus,
                pageNumber: 1,
                pageSize: 50
              }));
            }
            // Return the future events from the store (whether they're freshly loaded or already in the store)
            return this.store.pipe(select(selectPastEventsWithPagination));
          })
        );

        // Subscribe to pastEvents$ and log the emitted values
        this.pastEvents$.subscribe(events => {
          console.log('Past events emitted:', events);
        });

        this.pastEventsLoading$ = this.store.pipe(select(selectPastEventLoading));
        this.pastEventsLoaded$ = this.store.pipe(select(selectPastEventLoaded));
        this.pastEventsError$ = this.store.pipe(select(selectPastEventError));
        

        // Automatically get creators of past events based on the loaded events
        this.pastEventCreators$ = this.pastEvents$.pipe(
          map((events: MaiXEvent[]) => events.map(event => event.UserId!)), // Extract user IDs from events
          distinctUntilChanged(), // Ensure we don't trigger unnecessary updates
          tap((userIds: string[]) => {
            if (userIds.length > 0) {
              // Dispatch the action to get creators of the future events
              this.store.dispatch(GET_CREATORS_OF_FUTURE_EVENTS({ arrayOfUserId: userIds }));
            }
          }),
          (() => this.store.pipe(select(selectFutureEventCreators))), // Fetch creators from the store
          catchError(error => {
            console.error('Error processing future events', error);
            return of([]); // Return an empty array on error
          })
        );
  
  
          //====================================
          // Combine live and liveEventCreators
          this.pastCombinedData$ = combineLatest([
            this.pastEvents$, 
            this.pastEventCreators$
          ]).pipe(
            map(([pastEvents, creators]) =>
              pastEvents.map(pastEvent => ({
                deducedEvent: pastEvent,
                creator: creators.find(creator => creator.Id === pastEvent.UserId)
              }))
            )
          );


        break;
      default:
        console.error('Invalid event status provided');
        return;
    }
    
    // this.combinedData$ = of([]); // Initialize combinedData$ to an empty observable array
  }



  // loadMoreEvents(eventParams: EventLoadParams) {
  //   console.log(`Load more events. Status: ${eventParams.evStatus} | Page Num: ${eventParams.pageNumber} | Page Size: ${eventParams.pgSize}`);

  //   this.pageNumber = eventParams.pageNumber

  //   //for now the page size won't chnage
  //   // this.pageSize = eventParams.pgSize

  //   this.loadDeducedEvents(
  //     eventParams.evStatus, 
  //     eventParams.pageNumber, 
  //     this.pageSize);
  // }

  loadMoreEvents(evStatus: number) {
    this.pageNumber = this.pageNumber + 1
    // const pageSz = this.pageSize
    console.log(`Load more events. Status: ${evStatus} | Page Num: ${this.pageNumber} | Page Size: ${this.pageSize}`);

    this.loadDeducedEvents(evStatus, this.pageNumber, this.pageSize);
  }

  previousPageEvents(evStatus: number)
  {
    this.pageNumber = this.pageNumber - 1
    
    console.log(`Load more events. Status: ${evStatus} | Page Num: ${this.pageNumber} | Page Size: ${this.pageSize}`);

    this.loadDeducedEvents(evStatus, this.pageNumber, this.pageSize);
  }

  // Global method
  
  getUserIdIfUserLoggedIn()
  {
    this.authSvc.GetCurrentlyLoggedInUserIdIfAny().subscribe({
      next: (response: string) =>
      {
        // this.useId = response;
        this.useIdSubject.next(response);
      },
      error: (error: any) =>
      {
        console.log("Funny Error")
      },
      complete() {
        console.log("Funny complete")
      }
    })

    return false;
    
  }

 
}


export interface EventLoadParams {
  evStatus: number;
  pageNumber: number;
  pgSize: number;
}
