import { createAction, props } from "@ngrx/store";
import { MaiXEvent } from "src/app/types/models/maixevent.model";

// past event
export const LOAD_PAST_EVENTS = createAction('[MaiXPastEvents] Load Past Events',
    props<{ statusCode: number, pageNumber: number, pageSize: number }>());

export const LOAD_PAST_EVENTS_SUCCESS = createAction('[MaiXPastEvents] Load Past Events Success',
    props<{pastMaixevents: MaiXEvent[]}>());

export const LOAD_PAST_EVENTS_FAIL = createAction('[MaiXPastEvents] Load Past Events Fail',
    props<{error: string}>());

