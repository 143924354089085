import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, tap } from "rxjs";
import { environment } from "src/environments/environment";
import { BookingService } from "src/app/shared/services/booking.service";
import * as BooingActions from "../actions/bookings.actions";
import { Booking } from "src/app/types/models/booking.model";

@Injectable()
export class BookingEffects {

    bookings$ = createEffect(() => this.actions$.pipe(
        ofType(BooingActions.LOAD_BOOKINGS),
        mergeMap(() => this.bookingService.GetAllBookings()
        .pipe(
            tap((bookings: Booking[]) => {
                if(!environment.production)
                {
                    console.log(`All Bookings: ${ JSON.stringify(bookings) }`);
                }
            }),
            map(bookings => BooingActions.LOAD_BOOKINGS_SUCCESS({ bookings })),
            catchError(error => of(BooingActions.LOAD_BOOKINGS_FAIL({ error })))
        ))
        )
    );

  constructor(
    private actions$: Actions,
    private bookingService: BookingService
  ) {}
}