import { createAction, props } from "@ngrx/store";
// import { GenericRole } from "src/app/types/models/genericrole.model";


export const GET_CURRENT_USER_ROLES = createAction('[Generic User Roles] Get Current Logged In User Roles');

export const GET_CURRENT_USER_ROLES_SUCCESS = createAction('[Generic User Roles] Get Current Logged In User Roles Success',
    props<{currentloggedinuserroles: string[] }>());

export const GET_CURRENT_USER_ROLES_FAIL = createAction('[Generic User Roles] Get Current Logged In User Roles Fail',
    props<{error: string}>());