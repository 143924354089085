import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { UserSpecificService } from 'src/app/shared/services/dataService/user-specific.service';
import { LoaderService } from 'src/app/shared/utils/loader.service';
import { EmailData } from 'src/app/types/models/emaildata.model';
import { environment } from 'src/environments/environment';
// import { EmailData } from '../login/login.component';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  public pwdResetForm: FormGroup = this.formBuilder.group('');

  currUser: any;

  base64Token: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private loadingService: LoaderService,
    private userService: UserSpecificService,
    private toastr: ToastrService)
    {
      this.pwdResetForm = this.formBuilder.group({
        usernameEmail: ['', [Validators.required]],
      });
    }

  submitPasswordReset()
  {
    

    if(this.pwdResetForm.valid)
    {
      if(!environment.production)
      {
        console.log(`Reset pass form: ${JSON.stringify(this.pwdResetForm.value)}... Actual: ${this.pwdResetForm.value.usernameEmail}`)
      }

      let resetPayload = {
        "emailOrUsername": this.pwdResetForm.value.usernameEmail,
        "appBound": environment.AppBoundIdentifier,
        "withEncoding": true
      }

      this.loadingService.show();

      //once the token returns 
      const confirmEmailObserver = {
        next: async (response: any) => {
          if(!environment.production)
          {
            console.log(`Response from email action to confirm email: ${JSON.stringify(response)}`);
          }

          this.toastr.success("Confirmation email sent. Please check your email", "Email notification");

          //clear the form
          this.pwdResetForm.get('usernameEmail')?.setValue('');
        },
        error: async (err: HttpErrorResponse) => {
          if(!environment.production)
          {
            console.log(`Error with forgot-password template generation: ${JSON.stringify(err)}`);
          }

          this.toastr.error("Error occurred. Please try again later", "Error");

        },
        complete: async () => {
          this.loadingService.hide();

          if(!environment.production)
          {
            console.log(`Token retrieved!`);
          }
        }
      }


      const templateGenObserver = {
        next: async (response: any) => {
          // if(!environment.production)
          // {
          //   console.log(`Response from forgot-password template generation : ${JSON.stringify(response)}`);
          // }

          
          // this.toastr.success("Welcome", "Success");
              
          //send the email
          let confirmMlForPassReset = new EmailData(
            this.currUser.Email,
            "Confirm email for password reset",
            response,
            environment.AppBoundIdentifier 
          )
          
          if(!environment.production)
          {
            console.log(`Email 2 send: ${this.currUser.Email}`); 
          }

          //send the email out 
          this.authService.SendConfirmEmailForPasswordReset(confirmMlForPassReset)
          .subscribe(confirmEmailObserver);
  
          //set the login state to true
          // this.userSvc.loggedIn$.next(true);
    
          // if(!environment.production)
          // {
          //   console.log(`I am redirecting to: ${this.redirectPage} from login`)
          // }
        },
        error: async (err: HttpErrorResponse) => {
          this.loadingService.hide();

          if(!environment.production)
          {
            console.log(`Error with forgot-password template generation: ${JSON.stringify(err)}`);
          }

          this.toastr.error(err?.error?.Message, "Error");
        },
        complete: async () => {
          

          if(!environment.production)
          {
            console.log(`Token retrieved!`);
          }
        }
      };


      const userRetrievalObserver = {
        next: async (usrData: any) => {
          if(!environment.production)
          {
            console.log(`Response from getting user data : ${JSON.stringify(usrData)}`);
          }

          //for later use in email sending operation
          this.currUser = usrData;

          if(!environment.production)
          {
            console.log(`User data: ${JSON.stringify(usrData)}`)
          }

          let rstTemplateGenModel = {
            "encodedToken": this.base64Token,
            "email": usrData.Email,
            "uiAppUrl": environment.myUrl,
            "username": usrData.UserName
          }

          this.authService.PassResetTemplateGenerator(rstTemplateGenModel)
          .subscribe(templateGenObserver);
        },
        error: async (err: HttpErrorResponse) => {

          this.loadingService.hide();

          if(!environment.production)
          {
            console.log(`Error with getting user details: ${JSON.stringify(err)}`);
          }

          this.toastr.error(err?.error?.Message, "Error");
        },
        complete: async () => {
          
          if(!environment.production)
          {
            console.log(`Token retrieved!`);
          }
        }
      }


      //get the password reset template
      const intiateTokenForPassResetObserver = {
        next: async (response: any) => {

          //get the token to be sent
          this.base64Token = response.Data.Value;

          if(!environment.production)
          {
            console.log(`Full response from token request: ${typeof(response)}. Actual token: ${JSON.stringify(this.base64Token)}`);
          }
          
          //prep for template extract
          //1. get user details
          this.userService.GetUserByEmailOrUsernameForAppByAppId(this.pwdResetForm.value.usernameEmail, environment.AppBoundIdentifier)
          .subscribe(userRetrievalObserver)
        },
        error: async (err: HttpErrorResponse) => {
          this.loadingService.hide();
          
          if(!environment.production)
          {
            console.log(`Error while getting token for user: ${JSON.stringify(err)}`);
          }

          // Clear the username input controls
          this.pwdResetForm.get('usernameEmail')?.setValue('');

          this.toastr.error(err?.error?.Message, "Error");
        },
        complete: async () => {
          if(!environment.production)
          {
            console.log(`Token retrieved!`);
          }
        }
      }

      //go get the token for the username of email if valid
      this.authService.InitiateResetPasswordForAKnownAppUser(resetPayload)
      .subscribe(intiateTokenForPassResetObserver);
    }
  }
}
