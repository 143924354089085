import { createReducer, on } from "@ngrx/store";
import { ActiveActivityCreatorsState } from "../state/active-activity-creators.state";
import { GET_CREATORS_OF_ACTIVE_ACTIVITIES, GET_CREATORS_OF_ACTIVE_ACTIVITIES_FAIL, GET_CREATORS_OF_ACTIVE_ACTIVITIES_SUCCESS } from "../actions/active-activity-creators.actions";

const initialState: ActiveActivityCreatorsState = {
    activeactivitycreators: [],
    loading: false,
    loaded: false,
    error: null,
};


const _activeActivitiesCreatorsReducer = createReducer(
    initialState,
    on(GET_CREATORS_OF_ACTIVE_ACTIVITIES, state => ({ ...state, loading: true })),
    on(GET_CREATORS_OF_ACTIVE_ACTIVITIES_SUCCESS, (state, { activeactivitycreators }) => ({
        ...state,
        activeactivitycreators,
        loading: false,
        loaded: true,
        error: null,
    })),
    on(GET_CREATORS_OF_ACTIVE_ACTIVITIES_FAIL, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error,
    }))
);

export function activeActivitiesCreatorsReducer(state: any, action: any) {
    return _activeActivitiesCreatorsReducer(state, action);
}