import { createReducer, on } from "@ngrx/store";
import * as CurrentUseerActivitiesAction from "../actions/current-user-activities.actions";
import { CurrenUserActivitiesState } from "../state/current-user-activities.state";


const initialState: CurrenUserActivitiesState = {
    currentuseractivities: [],
    loading: false,
    loaded: false,
    error: null,
};

const _currentUserActivities = createReducer(
    initialState,
    on(CurrentUseerActivitiesAction.LOAD_ACTIVITIES_FOR_ONE_USER, state => ({ ...state, loading: true })),
    on(CurrentUseerActivitiesAction.LOAD_ACTIVITIES_SUCCESS_FOR_ONE_USER, (state, { currentuseractivities }) => ({
        ...state,
        currentuseractivities: [...currentuseractivities],
        loading: false,
        loaded: true,
        error: null,
    })),
    on(CurrentUseerActivitiesAction.LOAD_ACTIVITIES_FAIL_FOR_ONE_USER, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error,
    }))
);

export function currentUserActivitiesReducer(state: any, action: any) {
    return _currentUserActivities(state, action);
  }