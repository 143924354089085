<form 
[formGroup]="usrForm!"
style="margin-top: 30px;"
class="container">
<!-- 
Before: {{this.initialFormValues.firstname}}
After: {{this.trackingFormValues.firstname}} -->
  <mat-form-field
    style="width: 100%;">
    <mat-label>
      Firstname
    </mat-label>
    <input 
      matInput
      [(ngModel)]="trackingFormValues.firstname"
      formControlName="firstname">
      
      <small 
      class="validateUser"
      *ngIf="usrForm!.get('firstname')?.errors?.['required']">
      First Name is required.
    </small>
      
      <small 
      class="validateUser"
      *ngIf="usrForm!.get('firstname')?.errors?.['pattern']">
      First Name must only contain letters.
    </small>
  </mat-form-field>

  <mat-form-field
    style="width: 100%;">
    <mat-label>
      Lastname
    </mat-label>
    <input 
      matInput
      [(ngModel)]="trackingFormValues.lastname"
      formControlName="lastname">
      
      <small 
        class="validateUser"
        *ngIf="usrForm!.get('lastname')?.errors?.['required']">
      Last Name is required.
      </small>

      <small 
      class="validateUser"
      *ngIf="usrForm!.get('lastname')?.errors?.['pattern']">
      Last Name must only contain letters.
    </small>
  </mat-form-field>

  <mat-form-field
    style="width: 100%;">
    <mat-label>
      Username
    </mat-label>
    <input 
      readonly
      matInput
      [(ngModel)]="trackingFormValues.username"
      formControlName="username">
  </mat-form-field>

  <mat-form-field
    style="width: 100%;">
    <mat-label>
      Email
    </mat-label>
    <input
      readonly
      matInput
      [(ngModel)]="trackingFormValues.email"
      formControlName="email">
  </mat-form-field>

  
<!-- Before: {{this.initialFormValues.phone}}
After: {{this.trackingFormValues.phone}} -->
  <mat-form-field
    style="width: 100%;">
    <mat-label>
      Phone
    </mat-label>
    <input 
      matInput
      [(ngModel)]="trackingFormValues.phone"
      formControlName="phone">

      <small 
      class="validateUser"
      *ngIf="usrForm!.get('phone')?.errors?.['required']">
        Phone number is required.
      </small>

      <small 
      class="validateUser"
      *ngIf="usrForm!.get('phone')?.errors?.['pattern']">
        Phone number must be exactly 10 digits.
      </small>
  </mat-form-field>

  <!-- Update Button -->
  <button 
  style="width: 100%;"
  mat-raised-button 
    color="primary" 
    *ngIf="(usrForm!.valid) &&
        (
          (this.initialFormValues.firstname !== this.trackingFormValues.firstname) ||
          (this.initialFormValues.lastname !== this.trackingFormValues.lastname) || 
          (this.initialFormValues.phone !== this.trackingFormValues.phone)
        )"
    (click)="UpdateUserInformation()" 
    >
    Update
  </button>
</form>