import { Injectable } from '@angular/core';
import { EventStatus } from 'src/app/types/enums/eventStatus.enum';
// import { EventStatus } from 'src/app/modules/event/selected-events/selected-events.component';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }
  
  determineDateStatus(date: string): EventStatus {
    //it should actually check the date in the user's timezone
    const currentDate = new Date();
    
    if (new Date(date) > currentDate) {
      return EventStatus.Future;
    } else if (new Date(date) < currentDate) {
      return EventStatus.Past;
    } else {
      return EventStatus.Active;
    }
  }
}
