import { createAction, props } from "@ngrx/store";
import { MaiXEvent } from "src/app/types/models/maixevent.model";






// future event
export const LOAD_FUTURE_EVENTS = createAction('[MaiXFutureEvents] Load Future Events',
    props<{ statusCode: number, pageNumber: number, pageSize: number }>());

export const LOAD_FUTURE_EVENTS_SUCCESS = createAction('[MaiXFutureEvents] Load Future Events Success',
    props<{futuremaixevents: MaiXEvent[]}>());

export const LOAD_FUTURE_EVENTS_FAIL = createAction('[MaiXFutureEvents] Load Future Events Fail',
    props<{error: string}>());
