<div 
*ngIf="this.canReset"
class="containerR">
    <div class="card">

        <div>
            <h3 class="text-center">
                Reset Password
            </h3>
            <hr>
        </div>


        <form
        [formGroup]="passResetForm" 
        (ngSubmit)="onSubmitPasswordReset()">

            <input 
                type="{{ passwordFieldType }}"   
                id="password" 
                formControlName="password" 
                required
                placeholder="Password (minimum length: 6)">

            <input 
                type="{{ passwordFieldType }}"   
                id="confirmPassword" 
                formControlName="confirmPassword" 
                required
                placeholder="Confirm Password (minimum length: 6)">

            <div 
            *ngIf="password?.value?.length > 3 || confirmPassword?.value?.length > 3" 
            class="toggle-password-checkbox">
                <input 
                    type="checkbox" 
                    id="showPasswordCheckbox" 
                    (change)="togglePasswordVisibility()" 
                    [checked]="passwordFieldType === 'text'">
                <label 
                    style="margin-left: 3px; padding-bottom: -5px;"
                    for="showPasswordCheckbox"> 
                    Show Password
                </label>
            </div>



            <div class="buttons">
                <!-- <button class="register-button">Register</button> -->
                <button 
                type="submit" 
                class="reset-button">Submit</button>
            </div>
        </form>
    </div>
</div>


<!-- invalid scenario -->
<div
class="container text-center"
style="margin-top: 200px;"
*ngIf="!this.canReset">

    <div>
        <h3>
            Error occurred. Please click link sent to your email again and if error persists, generate a new link with the url below:
        </h3>
    </div>
    <br/>

    <div>
        <button 
        [routerLink]="['/forgotpassword']"
        class="restart-button">
        Get new link
        </button>
    </div>
</div>