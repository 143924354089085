import { Component } from '@angular/core';

@Component({
  selector: 'app-how-competition',
  templateUrl: './how-competition.component.html',
  styleUrls: ['./how-competition.component.scss']
})
export class HowCompetitionComponent {

}
