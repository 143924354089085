import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-location-dialog',
  templateUrl: './location-dialog.component.html',
  styleUrls: ['./location-dialog.component.scss']
})
export class LocationDialogComponent {
  selectedAddr: any;

  formattedAddrString: string = ''

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    private router: Router,
    private clipboard: Clipboard,
    public dialogRef: MatDialogRef<LocationDialogComponent>,
    private dialogSvc: DialogService) 
    { 
      console.log('Location dialog');
      
      this.selectedAddr = data;

      if(this.selectedAddr != undefined)
      {
        this.formattedAddrString = this.selectedAddr?.Street_number + ' ' +
        this.selectedAddr?.Street  + ' ' +
        this.selectedAddr?.Suburb  + ' ' +
        this.selectedAddr?.City  + ' ' +
        this.selectedAddr?.State  + ' ' +
        this.selectedAddr?.Country  + ' ' +
        this.selectedAddr?.Postal_code;
      }
      else  
      {
        //ideally this should never be called as activities with no address should simply not return from the API call
        this.formattedAddrString = 'Address not provided'
      }


      // console.log(`Incoming: ${JSON.stringify(this.incomingFileList)}`);
    }

    ngOnInit(): void {
    
    }

    copyAddr()
    {
      //copy formattedAddrString to clipboard
      this.clipboard.copy(this.formattedAddrString);

      //swal notifier
    }
}
