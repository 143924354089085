import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CurrenUserActivitiesState } from "../state/current-user-activities.state";

export const activitiesOfAUserState = createFeatureSelector<CurrenUserActivitiesState>('currentuseractivities');

export const selectUserActivities = createSelector(
  activitiesOfAUserState, 
  (state: CurrenUserActivitiesState) => state.currentuseractivities
);

export const selectUserActivitiesLoading = createSelector(
  activitiesOfAUserState,
  (state: CurrenUserActivitiesState) => state.loading
);

export const selectUserActivitiesLoaded = createSelector(
  activitiesOfAUserState,
    (state: CurrenUserActivitiesState) => state.loaded
  );

export const CurrenUserActivitiesError = createSelector(
  activitiesOfAUserState,
  (state: CurrenUserActivitiesState) => state.error
);