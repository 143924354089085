import { createReducer, on } from "@ngrx/store";
import { GET_IP, GET_IP_FAIL, GET_IP_SUCCESS } from "../actions/ip.actions";
import { IPState } from "../state/ipState.state";

const initialState: IPState = {
    ip: '',
    loading: false,
    loaded: false,
    error: null,
};

const _ipReducer = createReducer(
    initialState,
    on(GET_IP, state => ({ ...state, loading: true })),
    on(GET_IP_SUCCESS, (state, { ip }) => ({
        ...state,
        ip,
        loading: false,
        loaded: true,
        error: null,
    })),
    on(GET_IP_FAIL, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: true,
        error,
    }))
);

export function ipReducer(state: IPState | undefined, action: any) : IPState {
    return _ipReducer(state, action);
  }