<div 
*ngIf="loaderService.isLoading | async as isLoading" 
class="loader-container abscenter">
    <div class="loading loading06">
        <span data-text="M">M</span>
        <span data-text="A">A</span>
        <span data-text="I">I</span>
        <span data-text="X">X</span>
        <span data-text="P">P</span>
        <span data-text="E">E</span>
        <span data-text="R">R</span>
        <span data-text="I">I</span>
        <span data-text="E">E</span>
        <span data-text="N">N</span>
        <span data-text="C">C</span>
        <span data-text="E">E</span>
      </div>
</div>


<div>
    <app-head-naviagation
        (toggleSideBarForMe)="sideBarToggler($event)" 
        (toggleRightBarForMe)="rightBarToggler($event)">
    </app-head-naviagation>
</div>

<!-- <div 
class="headerContainer">
    <app-header
        class="hItself"
        (toggleSideBarForMe)="sideBarToggler($event)" 
        (toggleRightBarForMe)="rightBarToggler($event)">
    </app-header>
</div> -->

<mat-drawer-container>
    <mat-drawer 
        mode="over" 
        [opened]="sidebarOpen">
        <app-leftbar (sbCloser)="sideBarToggler($event)"></app-leftbar>
    
    </mat-drawer>

    <mat-drawer-content>
        <span class="mainBody" #content>
            <router-outlet></router-outlet>

            <div style="margin-top: 50px;">
                <app-footer></app-footer>
            </div>
        </span>
        <br>
      
    </mat-drawer-content>
    
    <mat-drawer 
        mode="over" 
        position="end">

        <app-rightbar></app-rightbar>
        
    </mat-drawer>
</mat-drawer-container>

