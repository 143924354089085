import { BookingStatus } from "../enums/bookingStatis.enum";
import { MEEntity } from "../enums/meEntity.enum";

export class Booking
{
  Id: number;
  GenericUserId: string;
  EntityId: number;
  NumberOfParticipants: number;
  Status: BookingStatus;
  mEEntity: MEEntity;
  CreatedAt: string;
  ServiceFee: number;
  TransactionFee: number;
  PayableAmount: number;
  IsClaimed: boolean;
  DateCreatedFor?: string;

  constructor(
  Id: number,
  GenericUserId: string,
  EntityId: number,
  NumberOfParticipants: number,
  Status: BookingStatus,
  mEEntity: MEEntity,
  CreatedAt: string,
  ServiceFee: number,
  TransactionFee: number,
  PayableAmount: number,
  IsClaimed: boolean,
  DateCreatedFor?: string)
  {
    this.Id = Id;
    this.GenericUserId = GenericUserId;
    this.EntityId = EntityId;
    this.NumberOfParticipants = NumberOfParticipants;
    this.Status = Status;
    this.mEEntity = mEEntity;
    this.CreatedAt = CreatedAt;
    this.ServiceFee = ServiceFee;
    this.TransactionFee = TransactionFee;
    this.PayableAmount = PayableAmount;
    this.IsClaimed = IsClaimed;
    this.DateCreatedFor = DateCreatedFor;
  }
}