<mat-form-field style="width: 100%;">
    <input
      matInput
      [matDatepicker]="esdPicker"
      [min]="getCurrentDate()"
      placeholder="Event Start Date"
      (dateChange)="onDateChange($event)"
      [formControl]="dateControl"
      required>
    <mat-datepicker-toggle matIconSuffix [for]="esdPicker"></mat-datepicker-toggle>
    <mat-datepicker #esdPicker></mat-datepicker>
  </mat-form-field>
  
  <mat-form-field style="width: 100%;">
    <input
      placeholder="Event Start Time"
      matInput
      [ngxTimepicker]="picker"
      [formControl]="timeControl"
      (ngModelChange)="onTimeChange($event)">
    <ngx-material-timepicker #picker></ngx-material-timepicker>
    <mat-icon matSuffix (click)="picker.open()">schedule</mat-icon>
  </mat-form-field>
  