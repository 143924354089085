import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss']
})
export class CountdownTimerComponent {
  @Input() startdate: string | undefined; // Expecting the start date as a string
  private subscription!: Subscription;

  public timeLeft!: { 
    months: number,
    weeks: number,
    days: number, 
    hours: number, 
    minutes: number, 
    seconds: number 
  };


  constructor(private cdr: ChangeDetectorRef) {}


  ngOnInit() {
    this.calculateTimeLeft();
    this.subscription = interval(1000).subscribe(() => {
      this.calculateTimeLeft();
      this.cdr.markForCheck(); // Ensure change detection runs
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private calculateTimeLeft() {
    const eventDate = new Date(this.startdate!);
    const currentDate = new Date();
    const diff = eventDate.getTime() - currentDate.getTime();

    if (diff > 0) {
      const months = Math.floor(diff / (1000 * 60 * 60 * 24 * 30));
      const weeks = Math.floor((diff % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24 * 7));
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);
      
      this.timeLeft = {  months, weeks, days, hours, minutes, seconds };
    } else 
    {
      this.timeLeft = { months: 0, weeks: 0, days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
  }
}
