import { Component } from '@angular/core';

@Component({
  selector: 'app-user-voucher',
  templateUrl: './user-voucher.component.html',
  styleUrls: ['./user-voucher.component.scss']
})
export class UserVoucherComponent {

}
