<div class="wrapper">
    <div class="card">
        <h3 class="card-title">
            Total activities created
        </h3>

        <div class="button-container">
            <button 
            (click)="viewAllActivitiesCreated()"
            class="card-btn"
            *ngIf="allActivitiesUserCreated$ | async as allActivitiesUserCreated">
                {{allActivitiesUserCreated.length }}
            </button>
        </div>
	</div>

	<div class="card">
		<h3 class="card-title">
            Activity history
        </h3>

		<div class="button-container">
            <button class="card-btn">
                X
            </button>
        </div>
	</div>

	<div class="card">
		<h3 class="card-title">
            Total Income
        </h3>
        <div class="button-container">
            <button class="card-btn">
                R X
            </button>
        </div>
	</div>
  
    <div class="card">
		<h3 class="card-title">
            Viewers
        </h3>
		
        <div class="button-container">
            <button class="card-btn">
                X
            </button>
        </div>
	</div>
    
</div>

<hr>
<app-booking-table  
[tableTitle]="'Upcoming Activities'"
[tableCommand]="'future'"
[mEEntity]="mEEntity"
[entityId]="entityId"></app-booking-table>