import { KnownApplication } from "./knownapplication.model";

export class CustomLoginModel
{
  constructor(
    public appModel: KnownApplication,
    public username: string,
    public password: string,
    public signInOnlyOnEmailConfirmed: boolean,
    public email?: string
  ){}
}