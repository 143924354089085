// import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, Inject, NgZone, ViewChild } from '@angular/core';
import { AddressService } from 'src/app/shared/services/address.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { environment } from 'src/environments/environment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { AddressModel } from 'src/app/types/models/addressModel.model';
import { LoaderService } from 'src/app/shared/utils/loader.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx/state/app.state';
import { GET_ACTIVITY_BY_ID } from 'src/app/ngrx/actions/activity.actions';

@Component({
  selector: 'app-set-entity-address',
  templateUrl: './set-entity-address.component.html',
  styleUrls: ['./set-entity-address.component.scss']
})
export class SetEntityAddressComponent {
  anAddressIsSelected: boolean = false;

  activityAddr: AddressModel = {
    Id: 0,
    Street_number: '',
    Street: '',
    Suburb: '',
    City: '',
    State: '',
    IsDefault: false,
    Building_name: '',
    Unit_number: '',
    Address_instruction: '',
    Country: '',
    Postal_code: '',
    Longitude: '',
    Latitude: '',
    CreatedAt: null,
    ModifiedAt: null
  }

  addressReadyForUpdate: boolean = false;

  @ViewChild('search')
  public searchElementRef: ElementRef = new ElementRef<any>(null);

  selectedActivityModel: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    private dialogRef: MatDialogRef<SetEntityAddressComponent>,
    private addrService: AddressService,
    private dialogSvc: DialogService,
    private loadingService: LoaderService) 
  {
    this.selectedActivityModel = data

    if(!environment.production)
    {
      console.log(`Entity parsed in: ${JSON.stringify(this.selectedActivityModel)}`)
    }
  }

  ngOnInit(): void 
  {
  }

  onAddressSelected(address: AddressModel) {
    // Save the address to the database or handle it as needed
    if(!environment.production)
    {
      console.log('Selected Address:', address);
    }

    //validate address
    if(this.addrService.validateSelectedAddress(address)) {
      this.addressReadyForUpdate = true; //give user the choice to update the address
      this.activityAddr = address; //prep the model in case user updates
    }
  }


  SaveAddressAndHarvestItsID()
  {
    this.loadingService.show();

    if(!environment.production)
    {
      console.log(`Address to save: ${JSON.stringify(this.activityAddr)}`);
    }
    
    //address observer
    const addrObserver = {
      next: async (data: any) =>  {
        if(!environment.production)
        {
          console.log("location added with result:" + JSON.stringify(data));
        }

        //get the newly added activity ID
        let addrId = data?.split('|')[0] ?? 1;

        if(!environment.production)
        {
          console.log(`Newly added address Id: ${addrId}`);
        }

        //close dialog with the addrId response
        this.dialogRef.close(addrId)

        //open the previous edit back up
        // this.dialogSvc.EditSelectedActivityDialog(this.selectedActivityModel)
      },
      error: (err: HttpErrorResponse) => console.log(err),
      complete: () =>{
        // this.loadingService.hide()
        
        if(!environment.production)
        {
          console.log("Addres add COMPLETE");
        }
        
      }
    }
    
    this.addrService.AddAddressToAnActivity(this.activityAddr)
    .subscribe(addrObserver)

  
  }

}
