import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { DefaultComponent } from './layouts/default/default.component';
import { CoreComponent } from './modules/core/core.component';
import { FilesComponent } from './modules/files/files.component';
import { TeamsComponent } from './modules/teams/teams.component';
import { TeamListComponent } from './Templates/team-list/team-list.component';
import { ActivityDetailsComponent } from './modules/activity/activity-details/activity-details.component';
// import { ViewAllActivitiesComponent } from './modules/activity/view-all-activities/view-all-activities.component';
import { AllActivitiesComponent } from './modules/activity/all-activities/all-activities.component';
import { LoginComponent } from './modules/auth/login/login.component';
import { DashboardComponent } from './modules/profile/dashboard/dashboard.component';
import { IndexComponent } from './modules/home/index/index.component';
import { ManagerComponent } from './modules/activity/manager/manager.component';
import { ActivityPerStatusComponent } from './modules/activity/activity-per-status/activity-per-status.component';
import { SelectedCompsComponent } from './modules/competition/selected-comps/selected-comps.component';
import { SelectedEventsComponent } from './modules/event/selected-events/selected-events.component';
import { SelectedClubsComponent } from './modules/club/selected-clubs/selected-clubs.component';
import { NewActivityComponent } from './modules/activity/new-activity/new-activity.component';
import { BasicComponent } from './modules/scheduler/basic/basic.component';
import { RegisterComponent } from './modules/auth/register/register.component';
import { UtilsComponent } from './modules/utils/utils.component';
import { AddressManagerComponent } from './modules/admin/address-manager/address-manager.component';
import { AddEventComponent } from './modules/event/add-event/add-event.component';
import { EventDetailViewComponent } from './modules/event/event-detail-view/event-detail-view.component';
import { EventManagerComponent } from './modules/event/event-manager/event-manager.component';
import { EventPerStatusComponent } from './modules/event/event-per-status/event-per-status.component';
import { ConfirmEmailComponent } from './modules/auth/confirm-email/confirm-email.component';
import { ProfileComponent } from './modules/user/profile/profile.component';
import { ForgotPasswordComponent } from './modules/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './modules/auth/reset-password/reset-password.component';
import { UserRoleComponent } from './modules/user/subcomponents/user-role/user-role.component';
import { UserFundsComponent } from './modules/user/subcomponents/user-funds/user-funds.component';
import { UserProfileComponent } from './modules/user/subcomponents/user-profile/user-profile.component';
import { HowToComponent } from './modules/learn/how-to/how-to.component';
import { EnlargeImgComponent } from './Templates/enlarge-img/enlarge-img.component';
import { TermsConditionComponent } from './modules/others/terms-condition/terms-condition.component';
import { ActivityHostDashboardComponent } from './modules/activityhost/activity-host-dashboard/activity-host-dashboard.component';
import { HostActivitiesListComponent } from './modules/activityhost/host-activities-list/host-activities-list.component';
import { ActivityHistoryComponent } from './modules/activityhost/activity-history/activity-history.component';
import { PricingComponent } from './modules/others/pricing/pricing.component';
import { HowActivityComponent } from './modules/learn/how-activity/how-activity.component';
import { HowEventComponent } from './modules/learn/how-event/how-event.component';
import { HowCompetitionComponent } from './modules/learn/how-competition/how-competition.component';
import { HowClubComponent } from './modules/learn/how-club/how-club.component';
import { AGuardGuard } from './modules/auth/a-guard.guard';
import { ContactusComponent } from './modules/contactus/contactus.component';
import { EventHostDashboardComponent } from './modules/eventhost/event-host-dashboard/event-host-dashboard.component';
import { HostEventListComponent } from './modules/eventhost/host-event-list/host-event-list.component';
import { EventHistoryComponent } from './modules/eventhost/event-history/event-history.component';
import { TeamComponent } from './Templates/team/team.component';
import { CleanerComponent } from './modules/cleaner/cleaner.component';

export const routes: Routes = [{
  path: '',
  component: DefaultComponent,
    children: [
    { path: '', component: CoreComponent },
    { path: 'login', component: LoginComponent },
    { path: 'signup', component: RegisterComponent },
    { path: 'emailconfirm', component: ConfirmEmailComponent},
    { path: 'resetpassword', component: ResetPasswordComponent},
    { path: 'forgotpassword', component: ForgotPasswordComponent},
    { path: 'file', component: FilesComponent },
    { path: 'addactivity', component: NewActivityComponent, canActivate: [AGuardGuard] },
    { path: 'activitiesperstatus', component: ActivityPerStatusComponent },
    { path: 'activitylist', component: AllActivitiesComponent },
    { path: 'howTo', component: HowToComponent },
    { path: 'activitydetails/:activityId', component: ActivityDetailsComponent },
    { path: 'activitiesman', component: ManagerComponent, canActivate: [AGuardGuard]},
    { path: 'teams', component: TeamsComponent },
    { path: 'activeteams/:activityId', component: TeamListComponent},
    { path: 'dashboard', component: DashboardComponent},
    { path: 'index', component: IndexComponent},
    { path: 'utils', component: UtilsComponent},
    { path: 'addrman', component: AddressManagerComponent, canActivate: [AGuardGuard]},
    { path: 'profile', component: ProfileComponent, canActivate: [AGuardGuard]},
    { path: 'usrroles', component: UserRoleComponent, canActivate: [AGuardGuard]},
    { path: 'usrfunds', component: UserFundsComponent, canActivate: [AGuardGuard]},
    { path: 'usrprofile', component: UserProfileComponent, canActivate: [AGuardGuard]},
    { path: 'imgLarge', component: EnlargeImgComponent},
    { path: 'hostactivitylist', component: HostActivitiesListComponent, canActivate: [AGuardGuard]},
    { path: 'activityHistory/:activityId', component: ActivityHistoryComponent, canActivate: [AGuardGuard]},
    

    //================================================
    { path: 'competees', component: SelectedCompsComponent},
    { path: 'eves', component: SelectedEventsComponent},
    { path: 'ubs', component: SelectedClubsComponent},
    { path: 'team', component: TeamComponent},


    { path: 'bsk', component: BasicComponent},

    //================================================
    // EVent related
    //================================================
    { path: 'addevent', component: AddEventComponent},
    { path: 'eventdetails/:eventId', component: EventDetailViewComponent },
    { path: 'eventsman', component: EventManagerComponent, canActivate: [AGuardGuard]},
    { path: 'eventsperstatus', component: EventPerStatusComponent },
    
    //================================================
    // Others
    //================================================
    { path: 'termsandconditions', component: TermsConditionComponent },
    { path: 'pricing', component: PricingComponent },
    
    //================================================
    // Hosts
    //================================================
    { path: 'activityhost', component: ActivityHostDashboardComponent, canActivate: [AGuardGuard] },
    { path: 'eventhost', component: EventHostDashboardComponent, canActivate: [AGuardGuard] },
    { path: 'hosteventlist', component: HostEventListComponent, canActivate: [AGuardGuard] },
    { path: 'eventHistory/:eventId', component: EventHistoryComponent, canActivate: [AGuardGuard]},
    
    { path: 'contact', component: ContactusComponent },
    
    //=============
    // Learn how to
    //=============
    
    { path: 'howactivity', component: HowActivityComponent },
    { path: 'howevent', component: HowEventComponent },
    { path: 'howcompetition', component: HowCompetitionComponent },
    { path: 'howclub', component: HowClubComponent },
    
     //=============
    // Claims Email
    //=============
    { path: 'Cleaner', component: CleanerComponent }

  ]
}];

const routerOptions: ExtraOptions = {
  useHash: true, // Enable hash mode,
  onSameUrlNavigation: 'reload', // This option forces component reinitialization when navigating back to the same route
  scrollPositionRestoration: 'enabled', // or 'top'
  anchorScrolling: 'enabled'
};


@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
