import { Component } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-how-to',
  templateUrl: './how-to.component.html',
  styleUrls: ['./how-to.component.scss'],
  
  // animations: [
  //   trigger('featureAnimation', [
  //     state('inactive', style({
  //       transform: 'scale(1)',
  //       backgroundColor: '#f1f1f1'
  //     })),
  //     state('active', style({
  //       transform: 'scale(1.1)',
  //       backgroundColor: '#e0e0e0'
  //     })),
  //     transition('inactive => active', animate('300ms ease-in')),
  //     transition('active => inactive', animate('300ms ease-out'))
  //   ])
  // ]
  
})
export class HowToComponent {
  rtImagePath = environment.fileRetriever;
  
  activePanel: number = 0;

  togglePanel(panelNumber: number) {
    this.activePanel = this.activePanel === panelNumber ? 0 : panelNumber;
  }
}
