<div 
class="container">

    <h1 mat-dialog-title>
        Add new user
    </h1>
    <hr>
    <div mat-dialog-content>
        <form 
        [formGroup]="userManualRegistrationForm"
        (ngSubmit)="addUser()">

        
        <div class="mb-3">
            <label 
            for="exampleFormControlInput1" 
            class="form-label">Firstname
            </label>
            <input 
                type="text" 
                class="form-control" 
                formControlName="firstname"
                id="firstname"
                required=""
                placeholder="enter firstname">
          </div>

          <!-- lastname -->
          <div class="mb-3">
            <label 
            for="exampleFormControlInput1" 
            class="form-label">Lastname
            </label>
            <input 
                type="text" 
                class="form-control" 
                formControlName="lastname"
                id="lastname" 
                required=""
                placeholder="enter lastname">
          </div>

          <!-- username -->
          <div class="mb-3">
            <label 
            for="exampleFormControlInput1" 
            class="form-label">Username
            </label>
            <input 
                type="text" 
                class="form-control" 
                formControlName="username"
                required=""
                id="username" 
                placeholder="enter username">
          </div>

          <!-- email -->
          <div class="mb-3">
            <label 
            for="exampleFormControlInput1" 
            class="form-label">Email
            </label>
            <input 
                [ngClass]="{'error-input': userManualRegistrationForm.get('email').invalid && (userManualRegistrationForm.get('email').dirty || userManualRegistrationForm.get('email').touched)}"
                type="email" 
                class="form-control"
                formControlName="email" 
                id="email" 
                required
                placeholder="enter email">
            </div>

        <!-- pass -->
          <div class="mb-3">
            <label 
            for="exampleFormControlInput1" 
            class="form-label">Password
            </label>
            <input 
                [ngClass]="{'error-input': userManualRegistrationForm.get('password').invalid && (userManualRegistrationForm.get('password').dirty || userManualRegistrationForm.get('password').touched)}"
                type="password" 
                class="form-control" 
                formControlName="password"
                id="password" 
                placeholder="enter password">
            </div>

            <div class="mb-3">
                <label 
                class="form-label" 
                for="role">Role:</label>

                <select 
                    id="role" 
                    class="form-select form-control"
                    formControlName="role"
                    required>
                    <option value="" disabled selected>Select Role</option>
                    <option 
                    *ngFor="let role of allRoles" 
                    [value]="role.Name">{{ role.Name }}</option>
                </select>
            </div>

            <button 
                mat-raised-button
                color="primary"
                type="submit" 
                [disabled]="userManualRegistrationForm.invalid">
                Submit
            </button>
        </form>
    </div>

    
    

    
    <hr>
    <div mat-dialog-actions align="end">
        <!-- <button 
            mat-raised-button
            (click)="addUser()"
            color="primary">
            Submit
        </button> -->
        <button 
            mat-raised-button
            color="accent" 
            mat-dialog-close>Close</button>
    </div>


</div>