import { createAction, props } from "@ngrx/store";
import { Activity } from "src/app/types/models/activity.model";

    
export const LOAD_ACTIVITIES_FOR_ONE_USER = createAction('[Activity] Load Activities for one user',
    props<{ userId: string }>());

export const LOAD_ACTIVITIES_SUCCESS_FOR_ONE_USER = createAction('[Activity] Load Activities Success for one user',
    props<{currentuseractivities: Activity[]}>());

export const LOAD_ACTIVITIES_FAIL_FOR_ONE_USER = createAction('[Activity] Load Activities Fail for one user',
    props<{error: string}>());