import { Actions, createEffect, ofType } from "@ngrx/effects";

import { catchError, map, mergeMap, of, switchMap, tap } from "rxjs";
import { Injectable } from "@angular/core";
import { MaiXEvent } from "src/app/types/models/maixevent.model";
import { MaiXEventService } from "src/app/shared/services/maiXEventRelated/mai-xevent.service";
import { environment } from "src/environments/environment";
import * as EventActions from "../actions/maixevents-future.actions";

@Injectable()
export class MaiXFutureEventEffects {
    // pageNumber: number = 1;
    // pageSize: number = 100;

    //load future events
    loadFutureEvents$ = createEffect(() => this.actions$.pipe(
        ofType(EventActions.LOAD_FUTURE_EVENTS),
        switchMap(action => this.maixEventService.GetAllEventsByStatusWithPagination(action.statusCode, action.pageNumber, action.pageSize)
        .pipe(
            tap((futuremaixevents: MaiXEvent[]) => {
                if(!environment.production)
                {
                    console.log(`Events for status: ${action.statusCode} loaded successfully: ${JSON.stringify(futuremaixevents)}`);
                }
            }),
            map(futuremaixevents => EventActions.LOAD_FUTURE_EVENTS_SUCCESS({ futuremaixevents })),
            catchError(error => of(EventActions.LOAD_FUTURE_EVENTS_FAIL({ error })))
        ))
        )
    );


//      //#region eventsByStatus

//     getEventsByStatus$ = createEffect(() => this.actions$.pipe(
//         ofType(GET_EVENTS_BY_STATUS),
//         mergeMap(action => this.maixEventService.GetAllEventsByStatusWithPagination(action.eventStatusCode, action.pageNumber, action.pageSize)
//         .pipe(
//             tap((eventsByStatus : MaiXEvent[]) => {
//             if(!environment.production)
//             {
//                 console.log(`${eventsByStatus.length} Events with status ${action.eventStatusCode} loaded successfully:`);
//             }
//             }),
//             map(eventsByStatus => GET_EVENTS_BY_STATUS_SUCCESS({ eventsByStatus })),
//             catchError(error => of( GET_EVENTS_BY_STATUS_FAIL({ error })))
//         ))
//         )
//     );


//   //#endregion

    constructor(
        private actions$: Actions,
        private maixEventService: MaiXEventService
    ) {}
}