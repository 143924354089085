<div class="container"> 
    <!-- <h2 class="title">my activities</h2> -->

  <button 
  (click)="backToDashboard()"
  class="navtor" 
  type="button"
  style="color: black;">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
      </svg>
              
  </button>

  <ng-container>
    <div class="text-center">

      <button 
        style="border-radius: 40px; margin: 10px 0px 0px 0px;"
        class="btn btn-outline-primary">
        my events
      </button>
    </div>
  </ng-container>

  <hr>

  <section>
      <ng-container class="con" 
        *ngIf="hosteventList$ | async as hostList ; else noEvents">
          <a *ngFor="let event of hostList">
              <app-card 
                [entity]="event"
                [isActivity]="false"  
                [isEvent]="true"
                [showButton]="showButtonInFirstCard"></app-card>
        </a>
      </ng-container>
      
        <ng-template #noEvents>
          <p>No events found for this user.</p>
        </ng-template>
  </section>

</div>




