import { Actions, createEffect, ofType } from "@ngrx/effects";

import { catchError, map, mergeMap, of, switchMap, tap } from "rxjs";
import { Injectable } from "@angular/core";
import { MaiXEvent } from "src/app/types/models/maixevent.model";
import { MaiXEventService } from "src/app/shared/services/maiXEventRelated/mai-xevent.service";
import { environment } from "src/environments/environment";
import * as EventActions from "../actions/maixevents.past.actions";

@Injectable()
export class MaiXPastEventEffects {
    //load past events
    loadPastEvents$ = createEffect(() => this.actions$.pipe(
        ofType(EventActions.LOAD_PAST_EVENTS),
        switchMap(action => this.maixEventService.GetAllEventsByStatusWithPagination(action.statusCode, action.pageNumber, action.pageSize)
        .pipe(
            tap((pastMaixevents: MaiXEvent[]) => {
                if(!environment.production)
                {
                    console.log(`Events for status: ${action.statusCode} loaded successfully: ${JSON.stringify(pastMaixevents)}`);
                }
            }),
            map(pastMaixevents => EventActions.LOAD_PAST_EVENTS_SUCCESS({ pastMaixevents })),
            catchError(error => of(EventActions.LOAD_PAST_EVENTS_FAIL({ error })))
        ))
        )
    );


    constructor(
        private actions$: Actions,
        private maixEventService: MaiXEventService
    ) {}
}