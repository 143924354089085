import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest, map, Observable, tap } from 'rxjs';
import { AddressService } from 'src/app/shared/services/address.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ObfuscationServiceService } from 'src/app/shared/services/obfuscation-service.service';
import { GenericUser } from 'src/app/types/models/genericuser.model';
import { MaiXEvent } from 'src/app/types/models/maixevent.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-maixevent',
  templateUrl: './maixevent.component.html',
  styleUrl: './maixevent.component.scss'
})
export class MaixeventComponent {
  @Input() loading$: Observable<boolean> | undefined;
  @Input() futureEvents$: Observable<any[]> | undefined;
  @Input() eventStatus!: number;
  // @Input() futureCombinedData$: Observable<any[]> | undefined;

  @Input() futureCombinedData$: Observable<{
    deducedEvent: MaiXEvent,
     creator: GenericUser | any
    }[]> | undefined;

  @Input() rtImgPath: string = ''; // Image path
  @Input() useId$: Observable<string> | undefined;
  // @Input() currentPage!: Observable<number>;
  @Input() pageNumber: number = 1;
  // @Input() currentSize!: Observable<number>;
  @Input() pageSize: number = 5;

  @Output() fetchMoreEvents = new EventEmitter<number>();
  @Output() previousPageEventsPerStatus = new EventEmitter<number>();

  assignedUserRoles: string[] | undefined
  roleIsValid : boolean = false;
  locationResponse: any;

  // Pagination properties
  totalEvents = 0;      // Total number of events (could be fetched from the store)
  //  currentPage = 1;
  pageSizeSelection = 5;

  dataSource: any

  p: number = 1
  itemsPerPage: number = 5



  // card template variable
  // showButtonInSecondCard = false;

  constructor(
    private toastr: ToastrService,
    private addrService: AddressService,
    private dialogSvc: DialogService,
    private obfuscationService: ObfuscationServiceService,
    private router: Router
  ){
    
  }

  ngOnInit() {
    // Subscribe to the loading observable
    this.loading$!.subscribe((loading) => {
      // this.loading = loading;
    });

    if(this.futureEvents$)
    {

      // Subscribe to future events observable
      this.futureEvents$!?.subscribe((events) => {
        if(!environment.production)
        {
          console.log(`Deduced event x: ${JSON.stringify(events)}`)
        }
        
        this.totalEvents = events.length;  // Update the total length
      });

      
    }

    if(this.futureCombinedData$) 
    {
      // Subscribe to combined data observable
      this.futureCombinedData$!.subscribe((data) => {
        if(!environment.production)
        {
          console.log(`Deduced combined data x: ${JSON.stringify(data)}`)
        }
      });
    }

    // this.futureEvents$!?.subscribe((events) => {
    //   console.log(`Deduced event x: ${JSON.stringify(events)}`)

    //   this.totalEvents = events.length;  // Update the total length
    // });

   
    

    // Subscribe to useId observable
    this.useId$!.subscribe((id) => {
      if(!environment.production)
      {
        console.log(`logged in user x: ${JSON.stringify(id)}`)
      }
    });
  }

  


  roleIsValidForEditActivityAction(){
    if(
      this.assignedUserRoles?.includes("mxp_EventHost") ||
      this.assignedUserRoles?.includes("mxp_EventManager"))
    {
      this.roleIsValid = true;
    }
  }

   // Method to trigger fetch more event
   onFetchMoreEvents(statusNum: number) {
    this.fetchMoreEvents.emit(statusNum);
  }

  onpreviousPagePerStatus(statusNum: number) {
    this.previousPageEventsPerStatus.emit(statusNum)
  }
  
  async ViewEvent(mxEvent: any)
  {
    let obfsEventId = this.obfuscationService
                        .encryptEventId(mxEvent.Id.toString());

                        
                        
                        
  if(!environment.production)
  {
    console.log(`Event object: ${mxEvent} AND obfuscation of event ID: ${obfsEventId}`)
  }

    // this.router.navigate(['activitydetails', {queryParams: {acvt: activity}}]);
    this.router.navigate(['eventdetails', obfsEventId]);
  }


  EditActivityByAdmin(e: any, selEVent: any)
  {
    if(!environment.production)
    {
      console.log(`selected event for edit: ${JSON.stringify(selEVent)}`);
    } 

    //this prevents the parent routing click action to kickoff 
    e.stopPropagation();
    
    // alert("Edit activity by admin")
    this.dialogSvc.EditSelectedEventDialog(selEVent)
      .afterClosed().subscribe(_ => {

        if(!environment.production)
        {
          console.log(`Result from edit dialog APS component: ${JSON.stringify(_)}`)

          if(_.includes("refresh"))
          {
            // window.location.reload();

          }
        }
      });
  }

  ViewAdmin(creator: GenericUser){
    if(!environment.production)
    {
      console.log(`Event creator: ${JSON.stringify(creator)}`)
    }
  }

  // ViewAdmin(e:any, userId: string)
  // {
  //   const usr = this.validUsers.find((x: any) => x.Id == userId);  
    
  //   if(usr)
  //   {
  //     if(!environment.production)
  //     {
  //       console.log(`User found: ${JSON.stringify(usr)}`);
  //     }
  //     this.router.navigate(['userprofile', { state: usr }]);
  //   }
  //   else
  //   {
  //     if(!environment.production)
  //     {
  //       console.log(`User not found`)
  //     }

  //     this.toastr.error("User not found", "Error !")
  //   }

  //   //this prevents the parent routing click action to kickoff 
  //   e.stopPropagation();
  // }

  ViewLocationOfActivity(e: any, addressId: number)
  {
    if(!environment.production){
      console.log(`Extracted activity address ID: ${addressId}`);
      
    }

    const addrRetrieverObserver = {
      next: async (response: any) =>  {
        this.locationResponse = response;

    
        if(!environment.production)
        {
          console.log("location retrieved successfully:" + JSON.stringify(this.locationResponse));
        }
         
        await this.dialogSvc.ShowLocationDialog(this.locationResponse);

        // //get the longitude and latitude down
        // console.log(`Longitude: ${response?.Longitude}. Latitude: ${this.locationResponse?.Latitude}`);
        
        // let latitude = this.locationResponse?.Longitude; // Example latitude
        // let longitude = this.locationResponse?.Latitude; // Example longitude
        // // Build the map URL with the latitude and longitude parameters
        // // let mapUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
        
        // let mapUrl = `http://maps.google.com/maps?q=${latitude},${longitude}`;

        //  // Open the map in a new browser window or tab
        //  window.open(mapUrl, '_blank');
      },
      error: (err: HttpErrorResponse) => console.log(err),
      complete: () =>{
        console.log("Add activity COMPLETE");

       
        
      }
    }


    //get the location of the activity
    this.addrService.GetAddressByAddressId(addressId)
      .subscribe(addrRetrieverObserver);
        
        // console.log(JSON.stringify(response));

        
        

    //open on maps

    // alert("View location of an activity by anyone")
    //this prevents the parent routing click action to kickoff
    e.stopPropagation();
  }

  NewEventAdd(){
    alert("In Development")
  }


  //  ngOnDestroy() {
  //   if (this.subscription) {
  //     this.subscription.unsubscribe();
  //   }
  // }

  ViewImage(e: any, creator: GenericUser)
  {
    //make sure creator object is not empty
    //if profile isnt empty send image if not use a default
      
    let AdminImageUrl: string[] = [];
    if(creator && creator.ProfilePicture){
      if(!environment.production){
        console.log(`Extracted Image Url of Admin : ${creator.ProfilePicture}`);
      }
      
      AdminImageUrl.push(creator.ProfilePicture) 
      this.dialogSvc.ShowImgDialog(AdminImageUrl);
    }   
    else {
      AdminImageUrl.push('nImg.png') 
      this.dialogSvc.ShowImgDialog(AdminImageUrl);
    }

    AdminImageUrl = [] ;
      e.stopPropagation();
    }
}
