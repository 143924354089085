<!-- <div class="container"> -->
<div>

    
   

    <mat-dialog-content>
        <!-- <p>{{selectedAddr | json}}</p> -->
        <!-- <span style="float: right;">
            <button mat-button mat-dialog-close mat-dialog-close>X</button>
        </span> -->
        <mat-dialog-actions style="float: right;">
            <button 
            mat-raised-button 
            color="warn" 
            mat-dialog-close 
            mat-dialog-close>
                X
            </button>
        </mat-dialog-actions>

        <br>
        <!-- <br><br> -->
        <!-- <mat-divider></mat-divider> -->
        <!-- <br> -->
        <div class="input-group mb-3">
            <span 
            class="input-group-text" 
            id="basic-addon1"
            style="color: red;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                </svg>
            </span>
            <input 
            disabled
            type="text" 
            value="{{formattedAddrString}}"
            class="form-control" 
            placeholder="Username" 
            aria-label="Username" 
            aria-describedby="basic-addon1">
          </div>

    </mat-dialog-content>

    <mat-dialog-actions>
        <button 
            *ngIf="formattedAddrString != 'Address not provided'"
            (click)="copyAddr()"
            mat-raised-button 
            color="primary" 
            mat-dialog-close 
            mat-dialog-close>
            Copy
        </button>
        <button 
            mat-raised-button 
            color="primary" 
            mat-dialog-close 
            mat-dialog-close>
            Close
        </button>
    </mat-dialog-actions>
</div>
<!-- </div> -->