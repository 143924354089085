import { Component } from '@angular/core';

@Component({
  selector: 'app-how-club',
  templateUrl: './how-club.component.html',
  styleUrls: ['./how-club.component.scss']
})
export class HowClubComponent {

}
