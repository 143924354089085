import { ActivityStatus } from '../enums/activityStatus.enum';

export class Activity
{
  Id: number;
  UserId: string;
  Name: string;
  Description: string;
  AddressId: number;
  PricePerPerson: number;
  MaxParticipantPerBooking: number;
  Images: string;
  DefaultImage: string;
  IsFeatured: boolean;
  EnablePromotion: boolean;
  ActivityPromotionPercentage: number;
  CreatedDate?: Date;
  Status?: ActivityStatus;
  ModifiedDate?: Date

  constructor(
  Id: number,
  UserId: string,
  Name: string,
  Description: string,
  AddressId: number,
  PricePerPerson: number,
  MaxParticipantPerBooking: number,
  Images: string,
  DefaultImage: string,
  IsFeatured: boolean,
  EnablePromotion: boolean,
  ActivityPromotionPercentage: number,
  CreatedDate?: Date,
  Status?: ActivityStatus,
  ModifiedDate?: Date) //optional
  {
    // // Call the parent class constructor using super()
    // super(Loading, Loaded);

    this.Id = Id;
    this.UserId = UserId;
    this.Name = Name;
    this.Description = Description;
    this.AddressId = AddressId;
    this.PricePerPerson = PricePerPerson;
    this.MaxParticipantPerBooking = MaxParticipantPerBooking;
    this.Images = Images
    this.DefaultImage = DefaultImage
    this.IsFeatured = IsFeatured
    this.EnablePromotion = EnablePromotion
    this.ActivityPromotionPercentage = ActivityPromotionPercentage
    this.CreatedDate = CreatedDate
    this.Status = Status
    this.ModifiedDate = ModifiedDate
  }
}