import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { GET_APP_ROLES, GET_APP_ROLES_FAIL, GET_APP_ROLES_SUCCESS } from "../actions/application-roles.actions";
import { catchError, map, mergeMap, of, tap } from "rxjs";
import { AuthService } from "src/app/shared/services/CustomAuthenticator/auth.service";
import { environment } from "src/environments/environment";
import { GenericRole } from "src/app/types/models/genericrole.model";

@Injectable()
export class ApplicationRolesEffects {

    applicationRoles$ = createEffect(() => this.actions$.pipe(
        ofType(GET_APP_ROLES),
        mergeMap(() => this.authService.GetAllRolesForMaiXp(environment.AppBoundIdentifier)
        .pipe(
            tap((applicationroles: GenericRole[]) => {
                if(!environment.production)
                {
                    console.log(`All roles in the appliction: ${ JSON.stringify(applicationroles) }`);
                }
            }),
            map(applicationroles => GET_APP_ROLES_SUCCESS({ applicationroles })),
            catchError(error => of(GET_APP_ROLES_FAIL({ error })))
        ))
        )
    );

  constructor(
    private actions$: Actions,
    private authService: AuthService
  ) {}
}