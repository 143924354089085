
<!-- carousel -->
<ng-container>
  <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <!-- <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button> -->
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active" data-bs-interval="3000">
          <img 
              src="../../../assets/images/biko.jpg" 
              class="d-block w-100" 
              alt="..."
              >
          <div class="carousel-caption d-md-block">
            <h1>
              Explore activities near you
            </h1>
            <p>
              Search activities that best suite you and your loved ones. There are fun and exciting experiences to be had when you open up your world.
            </p>
            <button
              (click)="goToActivities()"
              class="btn btn-primary">
              Find your next activity
            </button>
          </div>
        </div>

        <div class="carousel-item" data-bs-interval="3000">
          <img 
          src="../../../assets/images/out.jpg" 
              class="d-block w-100"
              alt="...">
          <div 
          class="carousel-caption d-md-block"
          style="text-align: left;">
            <h1>
              Explore events happening near you
            </h1>
            <p>
              Immense yourself. Apply yourself to your community and try out an event near you
            </p>
            <button
              (click)="goToEvents()"
              class="btn btn-primary">
                  View events
            </button>
          </div>
        </div>

        <!-- This should be replaced with a wall of fame -->

        <!-- <div class="carousel-item" data-bs-interval="3000">
          <img 
          src="../../../assets/images/earn.webp" 
          class="d-block w-100" 
          alt="...">

          <div 
          style="text-align: right;"
          class="carousel-caption d-md-block">
            <h1>
              One more for good measure.
            </h1>
            <p>
              Earn by hosting activities or events.
            </p>
            <button
              (click)="goToLearnMore()"
              class="btn btn-primary">
                Learn more...
          </button>
          </div>
        </div> -->
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
  </div>
</ng-container>

<section 
  *ngIf="loading$ | async;" 
  class="spinner-container">

      <div class="spinner"></div>
</section>


<ng-container *ngIf="loaded$ | async">
  <!-- featured -->
  <ng-container>

    <!-- featured text -->
    <div class="container">

      <!-- {{activities$ | json }} -->
      <h2 style="position: relative;">
        Featured 

        <span
          style="position: absolute; top: -10px; z-index: 1;"
          class="badge rounded-pill text-bg-danger">
          New
        </span>
      </h2>  
    </div>
    <hr />
    
    <!-- featured activities and events section -->
    <section>

      <!-- featured activities container -->
      <ng-container *ngIf="featuredActivities$ | async as ftActivities">
        <a 
        *ngFor="let ftActivity of ftActivities"
        (click)="ViewActivity(ftActivity)">

          <app-card [entity]="ftActivity" [showButton]="showButtonInSecondCard"></app-card>

  <!--         
          <article class="card">
            <figure class="card-img">
              <img 
              [src]="rtImgPath + ftActivity.DefaultImage" />
            </figure>
            <div class="card-body">
              <h2 class="card-title">
                {{ftActivity.Name}}
              </h2>
              <p class="card-text">
                {{ ftActivity.Description?.length! <= 100 ? 
                    ftActivity.Description : ( ftActivity.Description | slice:0:100) + '...' }}

              </p>
            </div>
          </article> -->
        </a>
      </ng-container>

      <!-- featured events container -->
      <ng-container *ngIf="featuredEvents$ | async as ftEvents">
        <a 
          *ngFor="let ftEvent of ftEvents"
          (click)="ViewEvent(ftEvent)">
            <app-card [entity]="ftEvent" [showButton]="showButtonInSecondCard"></app-card>
          
          <!-- <article class="card">
            <figure class="card-img">
              <img 
              [src]="rtImgPath + ftEvent.DefaultImage" />
            </figure>
            <div class="card-body">
              <h2 class="card-title">
                {{ftEvent.Name}}
              </h2>
              <p class="card-text">
                {{ ftEvent.Description?.length! <= 100 ? 
                  showMoreOrLess($event, false, ftEvent?.Description!) : 
                  showMoreOrLess($event, true, ftEvent?.Description!) }}

              </p>
              <a 
                (click)="showMoreOrLess($event, false, ftEvent?.Description!)"
                [hidden]="ftEvent.Description?.length! <= 100"
                href="javascript:void(0)">
                ... Show more ...
              </a>
            </div>
          </article> -->
        </a>
      </ng-container>

    </section>

    <section 
      *ngIf="loading$ | async;" 
      class="spinner-container">

      <div class="spinner"></div>
    </section>
  </ng-container>


  <!-- activities -->
  <ng-container>
    <!-- Activities text/link -->
    <div class="container">

      <h2>
        <span (click)="goToActivities()">
          Activities 
        </span>

        <span style="float: right;">

          <!-- left -->
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
          </svg>

          <!-- right -->
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
          </svg>
        </span>
      </h2>
    </div>
    
    <hr />

    <!-- activities scrolling section with default 2 -->
    <section 
      class="scrollingCardsSection" 
      *ngIf="activities$ | async as AllActivities">
      
      <div class="cards-wrapper" >
        <div 
        *ngFor="let activity of AllActivities"
        class="cardContainer">

          <div 
            (click)="ViewActivity(activity)"
            class="col-sm-12 col-md-6 col-lg-4 mb-4">
        
          <div 
            [style.backgroundImage]="'url(' + rtImgPath + activity?.DefaultImage + ')'"
            class="scrollingcard card text-white card-has-bg click-col">
            
              <div class="card-img-overlay d-flex flex-column">
                <div class="card-body">
                    
                  <small 
                    style="align-items: center;"
                    class="card-meta mb-2">
                      {{ activity.Name.length <= 12 ? activity.Name : (activity.Name | slice:0:12) + '...' }}

                      <span
                        class="EntityMessageSpan" 
                        style="color: rgb(62, 158, 199);">
                      
                        <svg 
                        xmlns="http://www.w3.org/2000/svg"
                        width="16" 
                        height="16" 
                        fill="currentColor" 
                        class="bi bi-tag-fill" 
                        viewBox="0 0 16 16">
                            <path d="M2 1a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l4.586-4.586a1 1 0 0 0 0-1.414l-7-7A1 1 0 0 0 6.586 1H2zm4 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                        </svg>  
                        Activity
                      </span>
                  </small>

                    <h4 class="card-title mt-0 ">
                        <a class="text-white" herf="#">
                            <!-- {{activity.Description}} -->

                            {{ (activity.Description.length > 21) ? 
                                (activity.Description | slice:0:21)+'...' : 
                                (activity.Description) }}
                        </a>
                    </h4>

                    <!-- <small><i class="far fa-clock"></i> October 15, 2020</small> -->
                </div>

                <div class="card-footer">

                    <div class="media">
                        
                        <span 
                            (click)="ViewLocationOfActivity($event, activity?.AddressId ?? 0)"
                            class="locationSpan" 
                            style="float: right; color: red;">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                                </svg>
                        </span>

                    </div>

                    
                </div>
              </div>

            </div>
          
          </div>
        </div>

      
      </div>
    </section>
  </ng-container>


  <!-- events -->
  <ng-container>
    <!-- events text/link -->
    <div class="container">

      <h2>
        <span (click)="goToEvents()">
          Events
        </span>

        <span style="float: right;">

          <!-- left -->
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
          </svg>

          <!-- right -->
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
          </svg>
        </span>
      </h2>  
    </div>

    <hr />

    <!-- events scrolling section with default 2 -->
    <section 
    class="scrollingCardsSection"
    *ngIf="futureEvents$ | async as futureEvents">
      <div class="cards-wrapper">
        <div 
        *ngFor="let event of futureEvents"
        class="cardContainer">

          <div 
          (click)="ViewEvent(event)"
          class="col-sm-12 col-md-6 col-lg-4 mb-4">

            <div 
            [style.backgroundImage]="'url(' + rtImgPath + event?.DefaultImage + ')'"
            class="scrollingcard card text-white card-has-bg click-col">
            
              <div class="card-img-overlay d-flex flex-column">
                <div class="card-body">
                    <small 
                    style="align-items: center;"
                    class="card-meta mb-2">
                        {{ event?.Name?.length ?? 100 <= 12 ? event?.Name : (event?.Name | slice:0:12) + '...' }}

                        <span
                        class="EntityMessageSpan" 
                        style="color: rgb(62, 158, 199);">
                        
                        <svg 
                        xmlns="http://www.w3.org/2000/svg"
                        width="16" 
                        height="16" 
                        fill="currentColor" 
                        class="bi bi-tag-fill" 
                        viewBox="0 0 16 16">
                            <path d="M2 1a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l4.586-4.586a1 1 0 0 0 0-1.414l-7-7A1 1 0 0 0 6.586 1H2zm4 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                        </svg>  
                        Event
                        </span>
                    </small>

                    <h4 class="card-title mt-0 ">
                        <a class="text-white" herf="#">
                            <!-- {{activity.Description}} -->

                            {{ ((event?.Description?.length ?? 1) > 21) ? 
                                (event?.Description | slice:0:21)+'...' : 
                                (event?.Description) }}
                        </a>
                    </h4>

                    <!-- <small><i class="far fa-clock"></i> October 15, 2020</small> -->
                </div>

                <div class="card-footer">

                    <div class="media">
                        
                        <span 
                            (click)="ViewLocationOfActivity($event, event?.AddressId ?? 0)"
                            class="locationSpan" 
                            style="float: right; color: red;">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                                </svg>
                        </span>

                    </div>

                    
                </div>
              </div>

            </div>
          
          </div>
        </div>

      
      </div>
    </section>
  </ng-container>

</ng-container>