<mat-form-field 
[appearance]="appearance"
[ngStyle]="datePickerStyle" 
style="width: 100%;">
    <input
      matInput
      [matDatepicker]="esdPicker"
      [min]="minDate"
      [placeholder]="placeholder"
      (dateChange)="onDateChange($event)"
      [formControl]="dateControl"
      required>
    <mat-datepicker-toggle matIconSuffix [for]="esdPicker"></mat-datepicker-toggle>
    <mat-datepicker #esdPicker></mat-datepicker>
  </mat-form-field>