import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { ActivitySlotService } from 'src/app/shared/services/activity-slot.service';
import { ActivityService } from 'src/app/shared/services/activity.service';
import { BookingService } from 'src/app/shared/services/booking.service';
import { UserSpecificService } from 'src/app/shared/services/dataService/user-specific.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { PaymentService } from 'src/app/shared/services/payment.service';
import { PerformerclassService } from 'src/app/shared/services/performerclass.service';
import { BookingStatus } from 'src/app/types/enums/bookingStatis.enum';
import { MEEntity } from 'src/app/types/enums/meEntity.enum'
import { Booking } from 'src/app/types/models/booking.model';
import { GenericUser } from 'src/app/types/models/genericuser.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-activity-booking-dialog',
  templateUrl: './activity-booking-dialog.component.html',
  styleUrls: ['./activity-booking-dialog.component.scss']
})
export class ActivityBookingDialogComponent {
  activityModelToBeActedOn: any;

  bookingForm: FormGroup | undefined;

  usrId: string = '';
  usrEmail: string = '';
  usrRole: string = '';
  fName: string = '';
  lName: string = '';
  usrMod: any;

  bookingMetadataHidden: boolean = true;

  BookingModel: Booking | undefined

  reference = ''
  
  constructor(
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private router: Router,
    private activityService: ActivityService,
    private pcService: PerformerclassService,
    private activitySlotService: ActivitySlotService,
    public dialogRef: MatDialogRef<ActivityBookingDialogComponent>,
    private dialogSvc: DialogService,
    private bookingSvc: BookingService,
    private authSvc: AuthService,
    private toastr: ToastrService,
    private paymentService: PaymentService,
    private formBuilder: FormBuilder,
    private userSvc: UserSpecificService) 
  {
   
    if(this.data)
    {
      this.activityModelToBeActedOn = this.data;
      
      if(!environment.production)
      {
        //log incoming model for visibility
        console.log(`Activity Booking dialog: ${JSON.stringify(this.activityModelToBeActedOn)}`);
      }
    }
    else{
      if(!environment.production)
      {
        console.log(`Unaware of the activity to be booked`);
      }
    }
  }

  ngOnInit()
  {
    this.reference = `ref-${Math.ceil(Math.random() * 10e13)}`;

    //=========================================
    // Initialize booking class and bookingForm
    //=========================================
    
    this.BookingModel = new Booking(
      0, //ID
      "unknown", //GenericUserID
      0, //EntityId
      0, //NoOfParticipants
      BookingStatus.Pending, //Booking Status
      MEEntity.Activity, //MEEntity
      '',
      1,
      1,
      1,
      false,
      new Date().toString()
    );

    this.bookingForm = this.formBuilder.group({
      Id: new FormControl(0, [Validators.required]),
      GenericUserId: new FormControl(0, [Validators.required]),
      EntityId: new FormControl(this.activityModelToBeActedOn?.Details.Id, [Validators.required]),
      PricePerPerson: new FormControl(this.activityModelToBeActedOn?.Details.PricePerPerson),
      ServiceFee: new FormControl(environment.ActivityServiceFee, [Validators.required]),
      NumberOfParticipants: new FormControl(1, [Validators.required]),
      BookingDate: new FormControl(null, [Validators.required]),
      BookingTime: new FormControl(null, [Validators.required])
    });

    if(!environment.production)
    {
      console.log(`Activity booking form in construction: ${JSON.stringify(this.bookingForm.value)}`);
    }
    
    //==============================



    //cookie observer
    
    const cookieObserver = {
      next: async (response: any) => {
        if(!environment.production)
        {
          console.log(`Response from cookie decrypt: ${JSON.stringify(response)}`);
        }

        this.usrId = response.find((x: any) => x.Type === "UserIdClaim").Value;
        this.usrEmail = response.find((x: any) => x.Type === "EmailClaim").Value;
        this.usrRole = response.find((x: any) => x.Type === "RoleClaim").Value;
        this.fName = response.find((x: any) => x.Type === "FirstnameClaim").Value;
        this.lName = response.find((x: any) => x.Type === "LastnameClaim").Value;

        this.userSvc.GetUserByIdForAppByAppId(this.usrId,environment.AppBoundIdentifier)
        .subscribe((response) => {
          (!environment.production) ?
          console.log(`User General response: ${JSON.stringify(response)}`) : '';
          
          this.usrMod = response;
  
  
          (!environment.production) ?
        
          console.log(`User model response: ${JSON.stringify(this.usrMod.UserName)}`) : '';
          console.log(`User model response: ${JSON.stringify(this.usrMod.email)}`) ;

         });
      
        if(!environment.production)
        {
          console.log(`User Id: ${this.usrId} && Email: ${this.usrEmail} && Role: ${this.usrRole} && Firstname: ${this.fName} && Lastname: ${this.lName}`);
        }

        //build the form only after success is ensured
        this.bookingForm = this.formBuilder.group({
          Id: [0, Validators.required],
          GenericUserId: [this.usrId, Validators.required],
          EntityId: [this.activityModelToBeActedOn?.Details.Id, Validators.required],
          PricePerPerson: [this.activityModelToBeActedOn?.Details.PricePerPerson, Validators.required],
          ServiceFee: [environment.ActivityServiceFee, Validators.required],
          NumberOfParticipants: [1, Validators.required],
          BookingDate: [null, Validators.required],
          BookingTime: [null, Validators.required],
        });

        if(!environment.production)
        {
          console.log(`Reinitialize the booking form once the needed data is retrieved: ${JSON.stringify(this.bookingForm.value)}`);
        }
      },
      error: async (err: HttpErrorResponse) => {
        if(!environment.production)
        {
          console.log(`Error from cookie decrypt: ${JSON.stringify(err)}`);
        }
      },
      complete: async () => {
        // console.log(`User logged in!`);
        // this.router.navigate(['login'])
      }
    }

    //decrypt cookie
    this.authSvc.DecryptCookieForClaims().subscribe(cookieObserver)
  }
    


  addMoreParticipantsToABooking()
  {
    if(confirm("Do you want to add more participant(s) to this booking ?"))
    {
      //determine if the user to be added is already a member

      //if not already a member, create the user and send them an email

      // only then can they proceed to add them as participant
      alert("Add part in development...")
    }
  }

  getCurrentDate(): Date {
    return new Date();
  }

  bookingSelectionDate: string = ''


  confirmAndClose()
  {
    this.dialogRef.close("close click")
  }

  paymentInit() {
    if(!environment.production)
    {
      console.log('Payment initialized');
  
    }
  }

  testClick()
  {
    if(!environment.production)
    {
      console.log('Test click');
    }
  }

  title: any;

  paymentDone(ref: any) {
    this.title = 'Payment successfull';
    if(!environment.production)
    {
      console.log(this.title, ref);
    }
  }

  paymentCancel() {
    if(!environment.production)
    {
      console.log('payment failed');
    }
  }

  @ViewChild('paymentBtn') payBtn!: ElementRef;

  // Method to programmatically click the payment button
  clickPaymentButton() {
    if(!environment.production)
    {
      console.log("clicking payment button");
    }

    // Ensure the payment button reference is available
    if (this.payBtn && this.payBtn.nativeElement) {
      this.payBtn.nativeElement.click();
    }
  }


  selectedDate!: string;
  selectedTime!: string;

  onDateChange(date: string): void {
    //set the form 
    // this.form.get('BookingDate')?.setValue(date);
    this.bookingForm?.get('BookingDate')?.setValue(date);
    
    this.selectedDate = date;
    if(!environment.production)
    {
      console.log('Selected Date:', this.selectedDate);
    }

    //we need to look at the date at time and determine if all is well to go forth
    this.determineDateAndTimeCombo(date, this.selectedTime)
  }

  onTimeChange(time: string): void {
    this.bookingForm?.get('BookingTime')?.setValue(time);

    this.selectedTime = time;
    if(!environment.production)
    {
      console.log('Selected Time:', this.selectedTime);
    }

    this.determineDateAndTimeCombo(this.selectedDate, time)
  }


  determineDateAndTimeCombo(dt: string, tm: string) {
    //get the date
    const activityStartDateValue = dt //this.bookingForm!.get('BookingDate')?.value;


    if(activityStartDateValue)
    {
      //get the time
      const activityStartTimeValue = tm; // this.bookingForm!.get('BookingTime')?.value;

      if(activityStartTimeValue)
      {
        //split the time to a usable format
        // === Extract and format start time ST
        // Parse the time string to extract hours, minutes, and AM/PM indicator
        let activityStartTimeValueInParts = activityStartTimeValue?.split(':');
        let strtHours = parseInt(activityStartTimeValueInParts[0]);
        let startMinutes = parseInt(activityStartTimeValueInParts[1]);

        if(!environment.production)
        {
          console.log(`Booking date RAW: ${activityStartDateValue} | Booking time RAW: ${activityStartTimeValue}`);
          console.log(`Time in parts: ${JSON.stringify(activityStartTimeValueInParts)} | HH: ${strtHours} | MM: ${startMinutes}`);
        }

        //convert the date to plain JS date
        if (activityStartDateValue) {
          const activityStartDate = new Date(activityStartDateValue);

          //append the time (hh and mm)
          activityStartDate.setHours(strtHours);
          activityStartDate.setMinutes(startMinutes);

          //the timezone will activityually be replaced by a global service based on user selection
          let timezone = 'Africa/Johannesburg';
        
          const StartDateUTC = moment(activityStartDate).tz(timezone).toString()

          this.bookingSelectionDate = StartDateUTC

          if(!environment.production)
          {
            console.log(`Activity Start Date:', ${activityStartDate} && Activity Start Date UTC moment: ${this.bookingSelectionDate} `);
          }
        }
      }
      else{

        if(!environment.production)
        {
          console.log("No time detected yet");
        }
        
      }
    }

    
  }


  completeActivityBooking()
  {
    if (this.bookingForm!.valid) {
      if(!environment.production)
      {
        console.log(`Booking data from form: ${JSON.stringify(
          this.bookingForm!.value)
        }`);
      }

      const currentDT = new Date();

      //the timezone will activityually be replaced by a global service based on user selection
      let timezone = 'Africa/Johannesburg';
    
      const currentDT_UTC = moment(currentDT).tz(timezone).toString()

      const total2Pay = this.bookingForm!.value.PricePerPerson + this.bookingForm!.value.ServiceFee


      if(!environment.production)
      {
        console.log(`The activity will start at: ${this.bookingSelectionDate}`)
      }

      //update the BookingModel to be sent to the processing
      this.BookingModel!.GenericUserId = this.bookingForm!.value.GenericUserId
      this.BookingModel!.EntityId = this.bookingForm!.value.EntityId
      this.BookingModel!.NumberOfParticipants = this.bookingForm!.value.NumberOfParticipants
      this.BookingModel!.Status = BookingStatus.Pending, //this.bookingForm.value.Status
      this.BookingModel!.mEEntity = MEEntity.Activity //this.bookingForm.value.mEEntity
      this.BookingModel!.CreatedAt = currentDT_UTC

      this.BookingModel!.ServiceFee = environment.ActivityServiceFee;

      //
      this.BookingModel!.TransactionFee = ((environment.PaystackPercentageCharge / 100) * total2Pay) + 
                                          environment.PaystackBaseCharge +  
                                          ((environment.PaystackVATPercentage / 100) * total2Pay)

      this.BookingModel!.PayableAmount = this.bookingForm!.value.PricePerPerson - this.BookingModel!.TransactionFee;

      this.BookingModel!.IsClaimed = false;

      this.BookingModel!.DateCreatedFor = this.bookingSelectionDate;

      this.userSvc.GetUserByIdForAppByAppId(this.activityModelToBeActedOn?.Details.UserId , environment.AppBoundIdentifier)
        .subscribe((response : GenericUser) => {

          if(!environment.production)
          {
            console.log(`Response creator object: ${JSON.stringify(response)}`);
          }
          
          var hostName = response.UserName;
          var hostEmail = response.Email;

          const activityBookingObserver = {
            next: (response: any) => {
              if(!environment.production)
              {
                console.log(`Response from adding new booking: ${response}`);
              }
              
              //close dialog
              this.dialogRef.close();
    
              //show message
              if(!environment.production)
              {
                console.log("booking date " + this.BookingModel?.DateCreatedFor)
              }

              let activityMailData = {
                  "userName": this.usrMod?.UserName,
                  "activityName": this.activityModelToBeActedOn?.Details.Name ,
                  "activityVenue": this.activityModelToBeActedOn?.ActivityLoci.Street_number + " " +
                                this.activityModelToBeActedOn?.ActivityLoci.Street + " " +  
                                this.activityModelToBeActedOn?.ActivityLoci.Suburb +  " " + 
                                this.activityModelToBeActedOn?.ActivityLoci.City +  " " + 
                                this.activityModelToBeActedOn?.ActivityLoci.State ,
                  "paymentAmount": this.activityModelToBeActedOn?.Details.PricePerPerson,
                  "activityInstructions": this.activityModelToBeActedOn?.Details.Description,
                  "activityDate": this.BookingModel!.DateCreatedFor! ,
                  "activityTime": this.bookingForm?.value.BookingTime,
                  "recipientEmail": this.usrEmail
                }
    
                let activityHostMailData = {
                  "hostName": hostName,
                  "userName": this.usrMod?.UserName,
                  "activityName": this.activityModelToBeActedOn?.Details.Name ,
                  "activityVenue": this.activityModelToBeActedOn?.ActivityLoci.Street_number + " " +
                                this.activityModelToBeActedOn?.ActivityLoci.Street + " " +  
                                this.activityModelToBeActedOn?.ActivityLoci.Suburb +  " " + 
                                this.activityModelToBeActedOn?.ActivityLoci.City +  " " + 
                                this.activityModelToBeActedOn?.ActivityLoci.State ,
                  "paymentAmount": this.activityModelToBeActedOn?.Details.PricePerPerson,
                  "activityInstructions": this.activityModelToBeActedOn?.Details.Description,
                  "activityDate": this.BookingModel!.DateCreatedFor! ,
                  "activityTime": this.bookingForm?.value.BookingTime,
                  "recipientEmail": hostEmail
                }
    
                if(!environment.production)
                {
                  // Handle successful payment response
                    console.log("activity mail " + activityMailData)
                }
                
              //send the email out to the customer
              this.authSvc.SendActivityConfirmPaymentEmail(activityMailData)
              .subscribe((response: any) => {
                if(!environment.production)
                {
                  console.log(`Response from email send out end user: ${response}`);
                }
            
                //send email out to the admin/activity host
                this.authSvc.SendActivityHostPaymentEmail(activityHostMailData)
                .subscribe((response:any)=>{
                  if(!environment.production)
                  {
                    console.log(`Response from email send out admin: ${response}`);
                  }
                });

              });

              this.toastr.success("Booking saved. Enjoy the experience", "Success");
            },
            error: async (err: HttpErrorResponse) => {
              if(!environment.production)
              {
                console.log(`Error while saving booking or redirecting: ${JSON.stringify(err)}`);
              }
    
              this.toastr.error(err?.error?.Message, "Error");
            },
            complete: async () => {
    
              //notify the user
              if(!environment.production)
              {
                console.log(`..... COMPLETE`);
                // this.router.navigate(['login'])
              }
            }
    
            
          }
    
          const paymentStusObserver = {
            next: (response: any) => {
              if(!environment.production)
              {
                // Handle successful payment response
                console.log('Activity Booking Payment complete! Reference:', response.reference);
              }
    
              this.toastr.success("Successful", "Payment Status")
    
              //Update the status to paid before adding it
              this.BookingModel!.Status = BookingStatus.Paid
    
              this.bookingSvc.AddBooking(this.BookingModel)
              .subscribe(activityBookingObserver);
            },
            error: async (error: any) => {
              if(!environment.production)
              {
                // Handle payment failure or closure
                if(!environment.production)
                {
                  console.error('Payment error:', error);
                }
    
                this.toastr.error('Error processing booking payment', 'Payment Status');
              }
            },
            complete: async () => {}
          }
    
          if(environment.production)
          {
            this.paymentService.payWithPaystack(
              this.usrEmail,
              total2Pay,
              `${Math.ceil(Math.random() * 10e10)}`
            ).subscribe(paymentStusObserver);
          }
          else
          {
            this.paymentService.payWithPaystackSuccessMockLocal(
              this.usrEmail,
              total2Pay,
              `${Math.ceil(Math.random() * 10e10)}`
            ).subscribe(paymentStusObserver);
          }
        });


      
        
   
    }
  }

}




// export enum BookingStatus
// {
//   Pending = 'Pending',
//   Paid = 'Paid',
//   Cancelled = 'Cancelled'
// }





