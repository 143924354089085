import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { environment } from 'src/environments/environment';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { GenericRole } from 'src/app/types/models/genericrole.model';
import { CustomRoleChange } from 'src/app/types/models/customrolechange.model';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { GET_CURRENT_USER_ROLES } from 'src/app/ngrx/actions/current-user-roles.actions';
import { LoaderService } from 'src/app/shared/utils/loader.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx/state/app.state';
import { UserSpecificService } from 'src/app/shared/services/dataService/user-specific.service';

@Component({
  selector: 'app-add-user-role',
  standalone: true,
  imports: [
    FormsModule, 
    MatButtonModule,
    MatFormFieldModule, 
    MatSelectModule,
    MatDialogModule,
    CommonModule],
  templateUrl: './add-user-role.component.html',
  styleUrl: './add-user-role.component.scss'
})
export class AddUserRoleComponent {
  roleName: string = '';

  userAndRoles: any
  selectedRole: string | null = null;

  constructor(private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private dialog: DialogService,
    private loaderService: LoaderService,
    private store: Store<AppState>,
    private userSvc: UserSpecificService,
    public dialogRef: MatDialogRef<AddUserRoleComponent>,
    private authService: AuthService)
  {
    if(this.data)
    {
      this.userAndRoles = this.data

      if(!environment.production)
      {
        console.log(`User: ${JSON.stringify(this.userAndRoles.user)} and roles: ${JSON.stringify(this.userAndRoles.roles)}`)
      }
    }
  }

  ngOnInit(): void {
  
  }

  submitRole(action: string)
  {
    if(!environment.production)
    {
      console.log(`Role to be added: ${JSON.stringify(this.roleName)}`)
    }

    //prep backend data
    var genRole = new GenericRole(this.selectedRole!, environment.AppBoundIdentifier);

    var customRoleChange = new CustomRoleChange(
      this.userAndRoles.user.Id,
      genRole,
      action
    );
    
    //observer
    const roleChangeObserver = {
      next: async (response: any) => {
        this.loaderService.hide();
        if(!environment.production)
        {
          console.log(`Response from role change operation: ${JSON.stringify(response)}`);
        }
        
        // Dispatch action to get updated roles
        this.store.dispatch(GET_CURRENT_USER_ROLES());
        
        this.toastr.success("Success", `${action} action succeed`);
      },
      error: async (err: HttpErrorResponse) => {
        this.loaderService.hide();

        if(!environment.production)
        {
          console.log(`Error on role change operation: ${JSON.stringify(err)}`);
        }

        this.toastr.error("Error", `${action} action failed`);
      },
      complete: async () => {
        
      }
    }

    this.userSvc.UserRoleManagement(customRoleChange).subscribe(roleChangeObserver);
  
    
  }
}
