import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MEEntity } from 'src/app/types/enums/meEntity.enum';
import { Booking } from 'src/app/types/models/booking.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  private APIUrl: string | undefined; // = 'https://localhost:7032/api/'

  constructor(private http: HttpClient) 
  { 
    this.APIUrl = environment.APIUrl;
    if(!environment.production)
    {
      console.log(`Env: ${environment} || URL: ${environment.APIUrl}`);
    }
  }

    //add new booking
    AddBooking(newBooking: any)
    {
      return this.http.post<any>(
        this.APIUrl + 'Booking/AddBooking', 
        newBooking, 
        this.getHttpOptions());
    }
  
    //get all bookings
    GetAllBookings(): Observable<any[]> {
      let url = this.APIUrl + 'Booking/GetAllBookings';
  
      return this.http.get<any>(
        url,
        this.getHttpOptions());
  
    }

    
    // GetAllBookingsForAUserByEntityIdWithPagination


    //get all booking for a user by entitiy id with pagination
    GetAllBookingsPerEntityByIdWithPagination(
      mEEntity: MEEntity,
      entityId: number, 
      pageNum: number, 
      pageSz: number): Observable<Booking[]>
    {
      let url = this.APIUrl + `Booking/GetAllBookingsPerEntityByIdWithPagination?mEEntity=${mEEntity}&entityId=${entityId}&pageNumber=${pageNum}&pageSize=${pageSz}`;
  
      const entityBookings = this.http.get<Booking[]>(
        url
      );
  
      // if(!environment.production)
      // {
      //   console.log(`Response from GetAllActivitiesForAUser with pagination: ${JSON.stringify(actvs)}`);
      // }
  
      return entityBookings;
    }

    GetAttendeesOfAnEvent(eventId: number): Observable<any>
    {
      return this.http.post<any>(
        this.APIUrl + `Booking/GetAttendeesOfAnEvent/${eventId}`, 
        null, 
        this.getHttpOptions());
    }

    GetLatestUnpaidBookedEventForAUnder(usrId:string, eventId: number) : Observable<any>
    {
      return this.http.post<any>(
        this.APIUrl + `Booking/GetLatestUnpaidBookedEventForAUnder/${usrId}/${eventId}`, 
        null, 
        this.getHttpOptions());
    }

    // UpdateBooking
    UpdateBooking(bookingChanges: any): Observable<any>
    {
      return this.http.put<any>(
        this.APIUrl + `Booking/UpdateBooking`, 
        bookingChanges, 
        this.getHttpOptions());
    }


    UpdateClaimsById(bookingIds: string) : Observable<any>
    {
      return this.http.post<any>(
        this.APIUrl + `Booking/UpdateClaimsById?bookingIds=${bookingIds}`, 
        null, 
        this.getHttpOptions());
    }
    //GetAllBookingByEntity

    // GetBookingById/{id}

    // RemoveBooking/{id}

    


    getHttpOptions(){
      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        }),
      };
  
      return httpOptions;
    }
    
    // getHttpOptions(){
   
  
    //   const httpOptions = {
    //     headers: new HttpHeaders({
    //       Authorization: 'Bearer ' + localStorage.getItem('token'),
    //     })
    //     .set('Cache-Control', 'max-age=3600') // Set the cache control header,
    //     .delete('Pragma')
    //   };
  
    //   return httpOptions;
    // }

}
