export class GenericUser
{
    constructor( 
      public UserName: string,
      public Email: string,
      public AppBound: number,
      public Firstname?: string,
      public Lastname?: string,
      public ProfilePicture?: string,
      public Id?: string,  
      public PhoneNumber?: number,
      public IPRegisteredWith?: string
    ){} 
}