import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { LOAD_EVENTS_FOR_ONE_USER } from 'src/app/ngrx/actions/current-user-events.actions';
import { CurrenUserEventsError, selectUserEvents, selectUserEventsLoaded, selectUserEventsLoading } from 'src/app/ngrx/selectors/current-user-events.selectors';
import { AppState } from 'src/app/ngrx/state/app.state';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { MEEntity } from 'src/app/types/enums/meEntity.enum';
import { Booking } from 'src/app/types/models/booking.model';
import { MaiXEvent } from 'src/app/types/models/maixevent.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-event-host-dashboard',
  templateUrl: './event-host-dashboard.component.html',
  styleUrls: ['./event-host-dashboard.component.scss']
})
export class EventHostDashboardComponent {
  mEEntity: MEEntity = MEEntity.Event;
  entityId: number = 1; //hardcoding to 1 because it not important here

  private useIdSubject = new BehaviorSubject<string | null>('');
  userId$: Observable<string | null> = this.useIdSubject.asObservable();

  allEventsUserCreated$!: Observable<MaiXEvent[]>;
  allEventsUserCreatedLoading$!: Observable<boolean>;
  allEventsUserCreatedLoaded$!: Observable<boolean>;
  allEventsUserCreatedError$!: Observable<string | null>;

  //get the total number of booking for this specific user
  totalEventsBookingsCount$!: Observable<Booking[]>;
  totalBookingsCountLoading$!: Observable<boolean>;
  totalBookingsCountLoaded$!: Observable<boolean>;
  totalBookingsCountError$!: Observable<string | null>;

  constructor(
    private router: Router,
    private authSvc: AuthService, 
    private store: Store<AppState>) 
  {
    // get the current user activities from store
    this.allEventsUserCreated$ = this.store.pipe(select(selectUserEvents))
    this.allEventsUserCreatedLoading$ = this.store.pipe(select(selectUserEventsLoading))
    this.allEventsUserCreatedLoaded$ = this.store.pipe(select(selectUserEventsLoaded))
    this.allEventsUserCreatedError$ = this.store.pipe(select(CurrenUserEventsError))
  }



  ngOnInit() {
    //get logged user ID
    this.authSvc.GetCurrentlyLoggedInUserIdIfAny()?.subscribe({
      next: (response: string) => {
        // this.useId = response;
        this.useIdSubject.next(response);

        if (!environment.production) {
          console.log(`About to dispatch current user roles`);
        }
       
        // dispatch the current user activities if nothing is in the store
        this.allEventsUserCreated$.subscribe(allEventsUserCreated => {
          if(allEventsUserCreated.length === 0) {

            if(!environment.production)
            {
              console.log(`Currently logged in user from event host: ${this.useIdSubject.getValue()!}`)
            }
            
            this.store.dispatch(LOAD_EVENTS_FOR_ONE_USER({userId : this.useIdSubject.getValue()!}))
          }
        })

      },
      error: (err: any) => {
        if(!environment.production)
        {
          console.log("Unable to get logged in user: " + err);
        }
      },
      complete() {
        if(!environment.production)
        {
          console.log("Complete");
        }
      }
    });
  }

  viewAllEventsCreated()
  {
    this.router.navigate(['hosteventlist']);
  }
}
