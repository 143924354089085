import { Component } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { NavigationStart, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppState } from 'src/app/ngrx/state/app.state';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { UserSpecificService } from '../../services/dataService/user-specific.service';
import { CookieService } from 'ngx-cookie-service';
import { map, Observable } from 'rxjs';
import { selectLoggedInUserRoles } from 'src/app/ngrx/selectors/current-user-roles.selectors';
import { AuthService } from '../../services/CustomAuthenticator/auth.service';
import { GET_CURRENT_USER_ROLES } from 'src/app/ngrx/actions/current-user-roles.actions';
import { CommonModule } from '@angular/common';


@Component({
  selector: 'app-head-naviagation',
  standalone: true,
  imports: [
    MatToolbarModule, 
    MatButtonModule, 
    CommonModule,
    MatMenuModule,
    MatIconModule],
  templateUrl: './head-naviagation.component.html',
  styleUrl: './head-naviagation.component.scss'
})
export class HeadNaviagationComponent {
  onActivitiesPage: boolean = false

  canViewDashboard$: Observable<boolean> | undefined;
  canViewEventDashboard$: Observable<boolean> | undefined;
  canViewActivityPromotionDashboard$: Observable<boolean> | undefined;
  isUserLoggedIn: boolean = false;

  activitiesFilterToggled:boolean = false;

  currentLogedInUsrRole$!: Observable<string[]>;
  
  constructor(
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService,
    public userSvc: UserSpecificService,
    private cookieService: CookieService,
    private store: Store<AppState>) 
  {
      this.currentLogedInUsrRole$ = this.store.pipe(select(selectLoggedInUserRoles));

      // Map roles to a boolean indicating if the dashboard can be viewed
      this.canViewDashboard$ = this.currentLogedInUsrRole$.pipe(
        map((roles: string[]) => roles.includes('mxp_ActivityHost'))
      );
      this.canViewEventDashboard$ = this.currentLogedInUsrRole$.pipe(
        map((roles: string[]) => roles.includes('mxp_EventHost'))
      );


      this.canViewActivityPromotionDashboard$ = this.currentLogedInUsrRole$.pipe(
        map((roles: string[]) => roles.includes('mxp_ActivityPromoter'))
      );

      this.router.events.subscribe((event) => 
      {
        if (event instanceof NavigationStart) 
        {
          console.log('Route changed to:', event.url);

          localStorage.setItem('currentRoute', event.url);

          this.onActivitiesPageChecker(event.url);

        }

      
      });
  }

  ngOnInit()
  {
    const storedRoute = localStorage.getItem('currentRoute');
    if (storedRoute) {
      this.onActivitiesPageChecker(storedRoute);
      console.log('Current Route (on reload):', storedRoute);
    }

    this.authService.GetCurrentlyLoggedInUserIdIfAny()?.subscribe({
      next: (response: string) => {

        // this.isUserLoggedIn = true;

        // alert(`Dispatching user roles From header`)
        // Dispatch action to get user roles
        this.store.dispatch(GET_CURRENT_USER_ROLES());
      }
    })

    //trigger a check to see if the user is logged in
    this.userSvc.isUserLoggedIn
  }

  toggleActivityFilter()
  {
    this.activitiesFilterToggled = !this.activitiesFilterToggled;
  }
  
  onActivitiesPageChecker(pageUrl: string)
  {
    this.onActivitiesPage = (pageUrl.includes('activitylist')) ?
            true : false;

    if(!environment.production)
    {
      console.log(`==> On activities page: ${this.onActivitiesPage}`);
    }
          
  }

  goToDashboard(){
    this.router.navigate(['']);
  }

  goToHostDashboard(){
    this.router.navigate(['activityhost']);
    
  }

  goToEventHostDashboard() {
    this.router.navigate(['eventhost']);

  }

  goToActivityPromotersDashboard() 
  {
    this.router.navigate(['APDashboard']);
  }
  

  goToProfile()
  {
    this.router.navigate(['profile']);
  }

  goToLogin(){
    this.router.navigate(['login']);
  }

  goToRegister(){
    this.router.navigate(['signup']);
  }

  logOff()
  {
    this.userSvc.logout().subscribe((response: any) => {
      
      if(
          !environment.production && 
          !environment.qa &&
          !environment.dev)
      {
        this.deleteCookie('CustomAuthCookie', '/', 'localhost').then(() => {
          this.router.navigate(['/login']); // Redirect to the login page
        }).then(() => {
          this.toastr.success("You have been logged out from local.", "logout mesage")
        });
      }
      else if(environment.dev)
      {
        this.deleteCookie('CustomAuthCookie', '/', '.maixperience.co.za').then(() => {
          this.router.navigate(['/login']); // Redirect to the login page
        }).then(() => {
          this.toastr.success("You have been logged out from DEV.", "logout mesage")
        });
      }
      else if(environment.production)
      {
        this.deleteCookie('CustomAuthCookie', '/', 'maixperience.co.za').then(() => {
          this.router.navigate(['/login']); // Redirect to the login page
        }).then(() => {
          this.toastr.success("You have been logged out.", "logout mesage")
        });
      }
      
      // Dispatch an action to clear the store
      
      // this.cookieService.delete('CustomAuthCookie', '/', '.maixperience.co.za'); // Specify the domain when removing the cookie
      if(!environment.production)
      {
        console.log(`Logout response: ${JSON.stringify(response)}`)
      }

      
    });
  }

  deleteCookie(name: string, path: string, domain: string): Promise<void> {
    return new Promise((resolve) => {
      this.cookieService.delete(name, path, domain);
      resolve(); // Resolve the promise immediately after deleting the cookie
    });
  }
}
