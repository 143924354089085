import { Injectable } from '@angular/core';
// import { Md5 } from 'crypto-js';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class ObfuscationServiceService {

  private obfuscatedIdKey = 'obfuscatedIds';

  constructor() {}
  private secretKey = 'mySecretKey';  // Replace with a secure key

  encryptEventId(eventId: string): string {
    const encrypted = CryptoJS.AES.encrypt(eventId, this.secretKey).toString();
    return encodeURIComponent(encrypted);
  }

  decryptEventId(encryptedId: string): string | null {
    try {
      const decoded = decodeURIComponent(encryptedId);
      const bytes = CryptoJS.AES.decrypt(decoded, this.secretKey);
      const decryptedId = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedId || null;
    } catch (e) {
      console.error('Decryption error:', e);
      return null;
    }
  }


  // obfuscateEventId(eventId: string): string {
  //   const obfuscatedId = CryptoJS.MD5(eventId).toString();
  //   const obfuscatedIds = this.getObfuscatedIds();
  //   obfuscatedIds[obfuscatedId] = eventId;
  //   this.saveObfuscatedIds(obfuscatedIds);
  //   return obfuscatedId;
  // }

  // decodeObfuscatedEventId(obfuscatedId: string): string | undefined {
  //   const obfuscatedIds = this.getObfuscatedIds();
  //   return obfuscatedIds[obfuscatedId];
  // }

  // private getObfuscatedIds(): { [key: string]: string } {
  //   const obfuscatedIdsString = localStorage.getItem(this.obfuscatedIdKey);
  //   return obfuscatedIdsString ? JSON.parse(obfuscatedIdsString) : {};
  // }

  // private saveObfuscatedIds(obfuscatedIds: { [key: string]: string }): void {
  //   localStorage.setItem(this.obfuscatedIdKey, JSON.stringify(obfuscatedIds));
  // }

}
