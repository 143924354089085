import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import axios from 'axios';
import { Observable, from } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VisitorInfoService {
  IPExtractorUrl: any = environment.IPExtractorUrl
  
  constructor(private http: HttpClient) { }

  async getIp()
  {
    const response = await 
    axios.get(`${this.IPExtractorUrl}`);

    if(!environment.production)
    {
      // console.log(`Full response: ${JSON.stringify(response)}`);
      console.log(`Visitor IP: ${response.data.ip}`);
    }

    return response.data;
  }

  getIpObs(): Observable<any> {
    const axiosRequest = async () => {
      const response = await axios.get(this.IPExtractorUrl);
      
      if(!environment.production)
      {
        console.log(`Visitor IP: ${JSON.stringify(response.data)}`);
      }
      return response.data.ip;
    };
    return from(axiosRequest());
  }

  getLocationOfIp(ip: string): Observable<any>
  {
    return this.http.get(
      `${environment.CustomAuthBaseUrl}IpInfo/GetIpInfo/${ip}`,
      { withCredentials: true }
    )

    // const response = await
    // axios.get(`http://ip-api.com/json/${ip}`);

    // const response = await axios.get(
    //   `${environment.CustomAuthBaseUrl}IpInfo/GetIpInfo`
    // )
    
    // if(!environment.production)
    // {
    //   console.log(`Location response: ${JSON.stringify(response)}.}`);
    // }

    // return response.data;
  }

  sendVisitorInfo(visitorData: any) : Observable<any>
  {
    return this.http.post(
      `${environment.CustomAuthBaseUrl}visitor/AddNewVisitForAnApplication`,
      visitorData,
      { withCredentials: true }
    )
  }
}
