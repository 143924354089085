import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { BookingService } from 'src/app/shared/services/booking.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { MaiXEventService } from 'src/app/shared/services/maiXEventRelated/mai-xevent.service';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment';
import { PaymentService } from 'src/app/shared/services/payment.service';
import { UserSpecificService } from 'src/app/shared/services/dataService/user-specific.service';
import { GenericUser } from 'src/app/types/models/genericuser.model';
import { EmailData } from 'src/app/types/models/emaildata.model';
import { Booking } from 'src/app/types/models/booking.model';
import { BookingStatus } from 'src/app/types/enums/bookingStatis.enum';
import { MEEntity } from 'src/app/types/enums/meEntity.enum';

@Component({
  selector: 'app-event-booking-dialog',
  templateUrl: './event-booking-dialog.component.html',
  styleUrls: ['./event-booking-dialog.component.scss']
})
export class EventBookingDialogComponent {
  eventModelToBeActedOn: any;
  eventMail:any ;

  bookingForm: FormGroup | undefined;

  usrId: string = '';
  usrEmail: string = '';
  usrRole: string = '';
  fName: string = '';
  lName: string = '';
  usrName: string = '';
  usrMod: any;
  

  bookingMetadataHidden: boolean = true;

  BookingModel: Booking | undefined

  genericUser: GenericUser | undefined;

  emailTemplate: string = '';

  regEmailDt: EmailData | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    private router: Router,
    private eventSvc: MaiXEventService,
    public dialogRef: MatDialogRef<EventBookingDialogComponent>,
    private dialogSvc: DialogService,
    private bookingSvc: BookingService,
    private authSvc: AuthService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private paymentService: PaymentService,
    private userSvc: UserSpecificService) 
  {
   
    if(this.data)
    {
      this.eventModelToBeActedOn = this.data;
        
      if(!environment.production)
      {
        //log incoming model for visibility
        console.log(`Event Booking dialog: ${JSON.stringify(this.eventModelToBeActedOn)}`);
      }
    }
    else
    {
      if(!environment.production)
      {
        console.log(`Unaware of the event to be booked`);
      }
    }
  }

  ngOnInit()
  {
    // this.reference = `ref-${Math.ceil(Math.random() * 10e13)}`;

    //=========================================
    // Initialize booking class and bookingForm
    //=========================================
    
    this.BookingModel = new Booking(
      0, //ID
      '', //GenericUserID
      0, //EntityId
      0, //NoOfParticipants
      BookingStatus.Pending, //Booking Status
      MEEntity.Event, //MEEntity
      '',
      1,
      1,
      1,
      false,
      new Date().toString()
    );
    

    this.bookingForm = this.formBuilder.group({
      Id: new FormControl(0, [Validators.required]),
      GenericUserId: new FormControl(0, [Validators.required]),
      EventId: new FormControl(this.eventModelToBeActedOn?.Details.Id, [Validators.required]),
      ServiceFee: new FormControl(environment.EventServiceFee, [Validators.required]),
      PricePerPerson: new FormControl(this.eventModelToBeActedOn?.Details.PricePerPerson),
      EventStartDateTime: new FormControl(this.eventModelToBeActedOn?.Details?.EventStartDateTime),
      EventEndDataTime: new FormControl(this.eventModelToBeActedOn?.Details.EventEndDataTime),
      NumberOfParticipants: new FormControl(1, [Validators.required]),
      BookingDate: new FormControl(null)
    });

    if(!environment.production)
    {
      console.log(`Event booking form in construction: ${JSON.stringify(this.bookingForm.value)}`);
    }
    
    //==============================



    //cookie observer
    
    const cookieObserver = {
      next: async (response: any) => {
        if(!environment.production)
        {
          console.log(`Response from cookie decrypt: ${JSON.stringify(response)}`);
        }

        this.usrId = response.find((x: any) => x.Type === "UserIdClaim").Value;
        this.usrEmail = response.find((x: any) => x.Type === "EmailClaim").Value;
        this.usrRole = response.find((x: any) => x.Type === "RoleClaim").Value;
        this.fName = response.find((x: any) => x.Type === "FirstnameClaim").Value;
        this.lName = response.find((x: any) => x.Type === "LastnameClaim").Value;
       // this.usrName = response.find((x: any) => x.Type === "UserName").Value;


      this.userSvc.GetUserByIdForAppByAppId(this.usrId,environment.AppBoundIdentifier)
      .subscribe((response) => {
        (!environment.production) ?
        console.log(`User General response: ${JSON.stringify(response)}`) : '';
        
        this.usrMod = response;


        (!environment.production) ?
      
        console.log(`User model response: ${JSON.stringify(this.usrMod.UserName)}`) : '';
       });

       

        if(!environment.production)
        {
          console.log(`User Id: ${this.usrId} && Email: ${this.usrEmail} && Role: ${this.usrRole} && Firstname: ${this.fName} && Lastname: ${this.lName} && Usernaem: ${this.usrName}`);
        }

        //build the form only after success is ensured
        this.bookingForm = this.formBuilder.group({
          Id: new FormControl(0, [Validators.required]),
          GenericUserId: new FormControl(this.usrId, [Validators.required]),
          EventId: new FormControl(this.eventModelToBeActedOn?.Details.Id, [Validators.required]),
          ServiceFee: new FormControl(environment.EventServiceFee, [Validators.required]),
          PricePerPerson: new FormControl(this.eventModelToBeActedOn?.Details.PricePerPerson),
          EventStartDateTime: new FormControl(this.eventModelToBeActedOn?.Details.EventStartDateTime, Validators.required),
          EventEndDataTime: new FormControl(this.eventModelToBeActedOn?.Details.EventEndDataTime, Validators.required),
          NumberOfParticipants: new FormControl(1, [Validators.required]),
          BookingDate: new FormControl(null)
        });

        if(!environment.production)
        {
          console.log(`Reinitialize the booking form once the needed data is retrieved: ${JSON.stringify(this.bookingForm.value)}`);
        }
      },
      error: async (err: HttpErrorResponse) => {
        if(!environment.production)
        {
          console.log(`Error from cookie decrypt: ${JSON.stringify(err)}`);
        }      
      },
      complete: async () => {
        // console.log(`User logged in!`);
        // this.router.navigate(['login'])
      }
    }

    //decrypt cookie
    this.authSvc.DecryptCookieForClaims().subscribe(cookieObserver)
    // this.authSvc.DecryptCookie().subscribe(cookieObserver)
  }
    
  confirmAndClose()
  {
    this.dialogRef.close("close click")
  }

  currentParticipantsGlobalTracker: number = 0;

  addMoreParticipantsToABooking()
  {
    //increase number of participants
    const numberOfParticipantsControl = this.bookingForm!.get('NumberOfParticipants');
    const pricePerPersonControl = this.bookingForm!.get('PricePerPerson');
    const serviceFeeControl = this.bookingForm!.get('ServiceFee');
    
    if (numberOfParticipantsControl && pricePerPersonControl) {
      const currentParticipants = numberOfParticipantsControl.value;
      const pricePerPerson = this.eventModelToBeActedOn.Details.PricePerPerson;
      // const serviceFee = serviceFeeControl?.value

      this.currentParticipantsGlobalTracker = currentParticipants + 1;

      numberOfParticipantsControl.setValue(currentParticipants + 1);
      pricePerPersonControl.setValue(pricePerPerson * (currentParticipants + 1));
      serviceFeeControl?.setValue(environment.EventServiceFee * (currentParticipants + 1));
    }

  }

  removeParticipantsToABooking()
  {
    //increase number of participants
    const numberOfParticipantsControl = this.bookingForm!.get('NumberOfParticipants');
    const pricePerPersonControl = this.bookingForm!.get('PricePerPerson');
    const serviceFeeControl = this.bookingForm!.get('ServiceFee');
    
    if (numberOfParticipantsControl && pricePerPersonControl) {
      const currentParticipants = numberOfParticipantsControl.value;
      const pricePerPerson = this.eventModelToBeActedOn.Details.PricePerPerson;

      this.currentParticipantsGlobalTracker = currentParticipants - 1;

      numberOfParticipantsControl.setValue(currentParticipants - 1);
      pricePerPersonControl.setValue(pricePerPerson * (currentParticipants - 1));
      serviceFeeControl?.setValue(environment.EventServiceFee * (currentParticipants - 1));
    }
  }

  completeEventBooking_Revamped()
  {
    if (this.bookingForm!.valid) {
      if(!environment.production)
      {
        console.log(`Booking data for event: ${JSON.stringify(
          this.bookingForm!.value)
        }`);
      }

      const currentDT = new Date();

      //the timezone will eventually be replaced by a global service based on user selection
      let timezone = 'Africa/Johannesburg';
    
      const currentDT_UTC = moment(currentDT).tz(timezone).toString()

      const total2Pay = this.bookingForm!.value.PricePerPerson + this.bookingForm!.value.ServiceFee


      //update the BookingModel to be sent to the processing
      this.BookingModel!.GenericUserId = this.bookingForm!.value.GenericUserId
      this.BookingModel!.EntityId = this.bookingForm!.value.EventId
      this.BookingModel!.NumberOfParticipants = this.bookingForm!.value.NumberOfParticipants
      this.BookingModel!.Status = BookingStatus.Pending //this.bookingForm.value.Status
      this.BookingModel!.mEEntity = MEEntity.Event //this.bookingForm.value.mEEntity
      this.BookingModel!.CreatedAt = currentDT_UTC

      this.BookingModel!.ServiceFee = environment.EventServiceFee;

      //
      this.BookingModel!.TransactionFee = ((environment.PaystackPercentageCharge / 100) * total2Pay) + 
                                          environment.PaystackBaseCharge +  
                                          ((environment.PaystackVATPercentage / 100) * total2Pay)

      this.BookingModel!.PayableAmount = this.bookingForm!.value.PricePerPerson - this.BookingModel!.TransactionFee;

      this.BookingModel!.IsClaimed = false;


      if(!environment.production)
      {
        console.log(`Current time to be sent: ${JSON.stringify(currentDT_UTC)}`);

        console.log(`Booking data for event: ${JSON.stringify(this.BookingModel)}`);
      }

      this.userSvc.GetUserByIdForAppByAppId(this.eventModelToBeActedOn?.Details.UserId , environment.AppBoundIdentifier)
      .subscribe((response : GenericUser) => {

        if(!environment.production)
          {
            console.log(`Response creator object: ${JSON.stringify(response)}`);
          }
          
          var hostName = response.UserName;
          console.log("this is my hostname " + hostName)
          var hostEmail = response.Email;

      const evntBookingObserver = {
        next: (response: any) => {
          if(!environment.production)
          {
            console.log(`Response from adding new booking: ${response}`);
          }
          
          //close dialog
          this.dialogRef.close();

          //show message
          this.toastr.success("Booking confirmed. Enjoy the event !", "Booking Status");
        
          //
          let eventMailData = {
              "userName": this.usrMod?.UserName,
              "eventName": this.eventModelToBeActedOn?.Details.Name ,
              "eventStartDateTime": this.eventModelToBeActedOn?.Details.EventStartDateTime,
              "eventEndDateTime": this.eventModelToBeActedOn?.Details.EventEndDataTime,
              "eventVenue": this.eventModelToBeActedOn?.EventLoci.Street_number + " " +
                            this.eventModelToBeActedOn?.EventLoci.Street + " " +  
                            this.eventModelToBeActedOn?.EventLoci.Suburb +  " " + 
                            this.eventModelToBeActedOn?.EventLoci.City +  " " + 
                            this.eventModelToBeActedOn?.EventLoci.State ,
              "paymentAmount": this.eventModelToBeActedOn?.Details.PricePerPerson,
              "eventInstructions": this.eventModelToBeActedOn?.Details.Description,
              "recipientEmail": this.usrEmail
            }

            let eventHostMailData = {
              "hostName": hostName,
              "userName": this.usrMod?.UserName,
              "eventName": this.eventModelToBeActedOn?.Details.Name ,
              "eventVenue": this.eventModelToBeActedOn?.EventLoci.Street_number + " " +
                            this.eventModelToBeActedOn?.EventLoci.Street + " " +  
                            this.eventModelToBeActedOn?.EventLoci.Suburb +  " " + 
                            this.eventModelToBeActedOn?.EventLoci.City +  " " + 
                            this.eventModelToBeActedOn?.EventLoci.State ,
              "paymentAmount": this.eventModelToBeActedOn?.Details.PricePerPerson,
              "eventInstructions": this.eventModelToBeActedOn?.Details.Description,
              "eventStartDateTime": this.eventModelToBeActedOn?.Details.EventStartDateTime ,
              "eventEndDateTime": this.eventModelToBeActedOn?.Details.EventEndDataTime,
              "recipientEmail": hostEmail
            }
            // "hostName": "joi",
            // "userName": "jk",
            // "eventName": "streetrrtr",
            // "eventVenue": "xct",
            // "eventStartDataTime": "12 date datime",
            // "eventEndDateTime": "now",
            // "paymentAmount": "12",
            // "eventInstructions": "yiui ilk il",
            // "recipientEmail": "amdisoski@gmail.com"
          

            if(!environment.production)
            {
              // Handle successful payment response
                console.log("event mail " + JSON.stringify(eventHostMailData))
            }
            
          //send the email out 
          this.authSvc.SendConfirmPaymentEmail(eventMailData)
          .subscribe((response: any) => {
            if(!environment.production)
            {
              console.log(`Response from email send out: ${response}`);
            }
          //send email out to the admin/event host
          this.authSvc.SendEventHostPaymentEmail(eventHostMailData)
          .subscribe((response:any)=>{
            if(!environment.production)
            {
              console.log(`Response from email send out admin: ${response}`);
            }
          });


          });
        },
        error: async (err: HttpErrorResponse) => {
          if(!environment.production)
          {
            console.log(`Error while saving booking or redirecting: ${JSON.stringify(err)}`);
          }

          this.toastr.error(err?.error?.Message, "Error");
        },
        complete: async () => {

          //notify the user
          if(!environment.production)
          {
            console.log(`..... COMPLETE`);
            // this.router.navigate(['login'])
          }
        }
      }

      const paymentStusObserver = {
        next: (response: any) => {
          if(!environment.production)
          {
            // Handle successful payment response
            console.log('Payment complete! Reference:', response.reference);
          }

          this.toastr.success("Successful", "Payment Status")

          //Update the status to paid before adding it
          this.BookingModel!.Status = BookingStatus.Paid

          this.bookingSvc.AddBooking(this.BookingModel)
          .subscribe(evntBookingObserver);
        },
        error: async (error: any) => {
          if(!environment.production)
          {
            // Handle payment failure or closure
            console.error('Payment error:', error);
            this.toastr.error('Error processing payment', 'Payment Status');
          }
        },
        complete: async () => {}
      }

     

      //Proceed to payment immediately
      // this.paymentService.payWithPaystack(
      //   this.usrEmail,
      //   this.bookingForm!.value.PricePerPerson + this.bookingForm!.value.ServiceFee,
      //   `${Math.ceil(Math.random() * 10e10)}`
      // ).subscribe(paymentStusObserver);


      if(environment.production)
      {
        this.paymentService.payWithPaystack(
          this.usrEmail,
          total2Pay,
          `${Math.ceil(Math.random() * 10e10)}`
        ).subscribe(paymentStusObserver);
      }
      else
      {
        this.paymentService.payWithPaystackSuccessMockLocal(
          this.usrEmail,
          total2Pay,
          `${Math.ceil(Math.random() * 10e10)}`
        ).subscribe(paymentStusObserver);
      }
    });

    }
    
  }
  
}
