import { createReducer, on } from "@ngrx/store";
import { LOAD_FUTURE_EVENTS, LOAD_FUTURE_EVENTS_FAIL, LOAD_FUTURE_EVENTS_SUCCESS } from "../actions/maixevents-future.actions";
import { MaiXFutureEventState } from "../state/maixevent-future.state";

const initialState: MaiXFutureEventState = {
    futuremaixevents: [],
    loading: false,
    loaded: false,
    error: null,
};


//
// Note: the 'on' method takes 2 arguments
//      1. The action type to handle
//      2. A function that specifies how the state should be updated when action is dispatched

const _maixFutureEventsReducer = createReducer(
    initialState,
    // on(LOAD_FUTURE_EVENTS, (state, { statusCode }) => ({ 
    on(LOAD_FUTURE_EVENTS, (state) => ({ 
        ...state, 
        loading: true,
        loaded: false,
        error: null })),
    on(LOAD_FUTURE_EVENTS_SUCCESS, (state, { futuremaixevents }) => ({
        ...state,
        futuremaixevents,
        loading: false,
        loaded: true,
        error: null,
    })),
    on(LOAD_FUTURE_EVENTS_FAIL, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error,
    }))
);

export function maixFutureEventReducer(state: any, action: any) {
    return _maixFutureEventsReducer(state, action);
}