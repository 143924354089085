import { createReducer, on } from "@ngrx/store";
import { GET_ACTIVE_ACTIVITIES, GET_ACTIVE_ACTIVITIES_FAIL, GET_ACTIVE_ACTIVITIES_SUCCESS } from "../actions/active-activity.actions";
import { ActivityPromoterLogsState } from "../state/activity-promoter-logs.state";
import * as ActivityPromotersLogActions from "../actions/activity-promoter-logs.actions";


const initialState: ActivityPromoterLogsState = {
    activityPromoterLogs: [],
    loading: false,
    loaded: false,
    error: null,
};

const _activityPromoterLogsReducer = createReducer(
    initialState,
    on(ActivityPromotersLogActions.LOAD_ACTIVITY_PROMOTER_LOGS_FOR_A_USER, state => ({ ...state, loading: true })),
    on(ActivityPromotersLogActions.LOAD_ACTIVITY_PROMOTER_LOGS_FOR_A_USER_SUCCESS, (state, { activityPromoterLogs }) => ({
        ...state,
        activityPromoterLogs,
        loading: false,
        loaded: true,
        error: null,
    })),
    on(ActivityPromotersLogActions.LOAD_BOOKINGS_FAIL, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error,
    }))
);

export function activityPromoterLogsReducer(state: any, action: any) {
    return _activityPromoterLogsReducer(state, action);
}