import { Actions, createEffect, ofType } from "@ngrx/effects";

import { catchError, map, mergeMap, of, tap } from "rxjs";
import { Injectable } from "@angular/core";
import { MaiXEvent } from "src/app/types/models/maixevent.model";
import { MaiXEventService } from "src/app/shared/services/maiXEventRelated/mai-xevent.service";
import { environment } from "src/environments/environment";
import { LOAD_FUTURE_EVENTS, LOAD_FUTURE_EVENTS_FAIL, LOAD_FUTURE_EVENTS_SUCCESS } from "../actions/maixevents-future.actions";
import { GET_CREATORS_OF_ACTIVE_ACTIVITIES, GET_CREATORS_OF_ACTIVE_ACTIVITIES_FAIL, GET_CREATORS_OF_ACTIVE_ACTIVITIES_SUCCESS } from "../actions/active-activity-creators.actions";
import { UserSpecificService } from "src/app/shared/services/dataService/user-specific.service";
import { GenericUser } from "src/app/types/models/genericuser.model";

@Injectable()
export class ActiveActivityCreatorsEffects {

    //load active activities creators
    loadActiveActivitiesCreators$ = createEffect(() => this.actions$.pipe(
        ofType(GET_CREATORS_OF_ACTIVE_ACTIVITIES),
        mergeMap(action => this.userSvc.GetAllUsersOfAnAppByUserIds(environment.AppBoundIdentifier, action.arrayOfUserId)
        .pipe(
            tap((activeactivitycreators: GenericUser[]) => {
                if(!environment.production)
                {
                    console.log('Creators of active activities loaded successfully:', activeactivitycreators);
                }
            }),
            map(activeactivitycreators => GET_CREATORS_OF_ACTIVE_ACTIVITIES_SUCCESS({ activeactivitycreators })),
            catchError(error => of(GET_CREATORS_OF_ACTIVE_ACTIVITIES_FAIL({ error })))
        ))
        )
    );
    
    constructor(
        private actions$: Actions,
        private userSvc: UserSpecificService
    ) {}
}