import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subscription, combineLatestWith, distinctUntilChanged, filter, first, interval, map, of, pairwise, shareReplay, startWith, switchMap, take, tap, timer } from 'rxjs';
import { selectEventError, selectEventLoaded, selectEventLoading } from 'src/app/ngrx/selectors/maixevent.selectors';
import { AppState } from 'src/app/ngrx/state/app.state';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { AddressService } from 'src/app/shared/services/address.service';
import { BookingService } from 'src/app/shared/services/booking.service';
import { UserSpecificService } from 'src/app/shared/services/dataService/user-specific.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { MaiXEventService } from 'src/app/shared/services/maiXEventRelated/mai-xevent.service';
import { ObfuscationServiceService } from 'src/app/shared/services/obfuscation-service.service';
import { LoaderService } from 'src/app/shared/utils/loader.service';
import { MaiXEvent } from 'src/app/types/models/maixevent.model';
import { environment } from 'src/environments/environment';
import { GenericUser } from 'src/app/types/models/genericuser.model';
import { selectFutureEventCreators } from 'src/app/ngrx/selectors/maixevent-future-creators.selectors';
import { selectFutureEventsWithPagination } from 'src/app/ngrx/selectors/maixevent-future.selectors';
import { GET_CREATORS_OF_FUTURE_EVENTS } from 'src/app/ngrx/actions/future-events-creator.actions';
import { LOAD_FUTURE_EVENTS } from 'src/app/ngrx/actions/maixevents-future.actions';
import { AddressModel } from 'src/app/types/models/addressModel.model';
// import { selectFutureEventsByPage } from 'src/app/ngrx/selectors/maixevent-future.selectors';

@Component({
  selector: 'app-event-detail-view',
  templateUrl: './event-detail-view.component.html',
  styleUrls: ['./event-detail-view.component.scss']
})
export class EventDetailViewComponent {

  maixevents$:  Observable<MaiXEvent[]> | undefined
  loading$: any
  loaded$: any
  error$: any

  private validAttendeeUsersSubject = new BehaviorSubject<any[]>([]);
  public validAttendeeUsers$ = this.validAttendeeUsersSubject.asObservable();

  
  
  previousRoute: any
  eventIdentifier: any;
  evntAddr: any;

  imgs: string[] | undefined;

  locationResponse: any;
  attendees: any;

  _eventEnded: boolean = true;

  private _eventEndedSubject = new BehaviorSubject<boolean>(false);
  public eventEnded$: Observable<boolean> = this._eventEndedSubject.asObservable();
  
  private _eventStartedSubject = new BehaviorSubject<boolean>(false);
  public eventStarted$: Observable<boolean> = this._eventStartedSubject.asObservable();


  
  rtImgPath: string = environment.fileRetriever;

  imgs$!: Observable<string[]>; 
  
  currentIndex: number = 0;

  selectedImageUrl: string | null = null;

  selectedImageUrls: string[] = [];

  startX: number = 0;
  startY: number = 0;

  private subscription: Subscription = new Subscription();

  
  futureEventStatus: number = 2;
  pageNumber: number = 1;
  pageSize: number = 100;


  futureEventsPaginated$!:  Observable<MaiXEvent[]>
  futureEventsCreators$!: Observable<GenericUser[]>

  eventSelect: MaiXEvent | undefined;
  eventSelectUsr: GenericUser | undefined;
  
  eventSelect$!: Observable<any>
  eventSelectUsr$!: Observable<GenericUser | undefined>;
  eventLocation$ = new BehaviorSubject<AddressModel | null>(null);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private obfuscationService: ObfuscationServiceService,
    private dialogSvc: DialogService,
    private addrService: AddressService,
    private lociService: AddressService,
    private eventSvc: MaiXEventService,
    private userSvc: UserSpecificService,
    private authSvc: AuthService,
    private toastr: ToastrService,
    private location: Location,
    private store: Store<AppState>,
    private loaderService: LoaderService,
    private bookingSvc: BookingService)
  {
    // get event from store
    
    this.loading$ = this.store.pipe(select(selectEventLoading));
    this.loaded$ = this.store.pipe(select(selectEventLoaded));
    this.error$ = this.store.pipe(select(selectEventError));

    this.futureEventsPaginated$ = this.store.pipe(select(selectFutureEventsWithPagination));
    this.futureEventsCreators$ = this.store.pipe(select(selectFutureEventCreators))
  }

  
  ngOnInit()
  {
    this.store.pipe(select(selectEventLoaded),
      first())
      .subscribe((loaded) => {
        if (!loaded) {
          this.store.dispatch(LOAD_FUTURE_EVENTS({
            statusCode: this.futureEventStatus,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize
          }));
        }
      })


    const eventId$ = this.route.paramMap.pipe(
      map(paramMap => paramMap.get('eventId')),
      filter(activityId => !!activityId)
    );

    //get future events
    const futureEvents$ = this.futureEventsPaginated$.pipe(
      filter(futureEvents => futureEvents.length > 0),
      first(), // Complete the observable after the first emission
      shareReplay(1)
    );

    //get selected event
    this.eventSelect$ = eventId$.pipe(
      combineLatestWith(futureEvents$.pipe(
        filter(futureEvents => futureEvents && futureEvents.length > 0),
        take(1) // Ensure the observable completes after the first valid emission
      )),
      switchMap(([eventId, futureEvents]) => {
        const selectedEvent = futureEvents.find(futureEvent =>
          futureEvent.Id?.toString() === this.obfuscationService.decryptEventId(eventId!)
        );

        this.eventSelect = selectedEvent;
        if(!environment.production)
        {
          console.log(`Before map: `)
        }
        const userIds = futureEvents.map(mxEvent => mxEvent.UserId);
        
        if(!environment.production)
        {
          console.log(`UserIDs after map: ${userIds.join()}`)
        }

        this.store.dispatch(GET_CREATORS_OF_FUTURE_EVENTS({ arrayOfUserId: userIds }));
        
        if (this.eventSelect) {

          // alert(`Event select for time proc: ${JSON.stringify(this.eventSelect)}`)
          // Fetch start and end times of the event
          this.getStartDateTime(this.eventSelect);
          this.getEndDateTime(this.eventSelect);
        }

        return of(selectedEvent);
      }),
      shareReplay(1)
    );
    
    //get location of the event
    this.subscription.add(
      this.eventSelect$.pipe(
        switchMap(eventSelect => {
          if (eventSelect && eventSelect.AddressId) {
         
            this.getLocationOfEvent(eventSelect);
            
            
            
            //simulating a return for now
            return of(true)

          } else {
            return of(null);
          }
        })
      ).subscribe()
    );

    // get attendees of the event
    this.subscription.add(
      this.eventSelect$.pipe(
        distinctUntilChanged(),
        switchMap(eventSelect => {
          if (eventSelect && eventSelect.AddressId) {
            // Fetch the attendees
            this.getAttendeesOfEvent(eventSelect);
    
             // Deduce if the event has ended
             let timezone = 'Africa/Johannesburg';

            // Get the current time
            let currentTime = moment().tz(timezone);
            let eventStartMoment = moment(eventSelect.EventStartDateTime).tz(timezone);
            let eventEndMoment = moment(eventSelect.EventEndDataTime).tz(timezone);

            if (!environment.production) {
              console.log(`Current Time: ${currentTime.format()}`);
              console.log(`Event Start Time: ${eventStartMoment.format()}`);
              console.log(`Event End Time: ${eventEndMoment.format()}`);
            }
              
            // Calculate the delay until the event start and end times in milliseconds
            const timeUntilStart = eventStartMoment.diff(currentTime);
            const timeUntilEnd = eventEndMoment.diff(currentTime);

            if (!environment.production) {
              // console.log(`timeUntilStart: ${timeUntilStart}`);
              // console.log(`timeUntilEnd: ${timeUntilEnd}`);
            }


            // Immediately set the event started if it's already in the past
            if (timeUntilStart <= 0) {
              this._eventStartedSubject.next(true);
              console.log('Event has started immediately!');
            } else {
              let countdownInterval: any;

              // Set a countdown interval
              countdownInterval = setInterval(() => {
                // const currentTime = moment().tz(timezone);
                // const updatedTimeUntilStart  = eventStartMoment.diff(currentTime);
                const updatedTimeUntilStart  = moment(eventSelect.EventStartDateTime).tz(timezone).diff(moment().tz(timezone));

                // console.log(`Updated time until start: ${updatedTimeUntilStart}`);

                if (updatedTimeUntilStart <= 0) {
                  clearInterval(countdownInterval); // Stop the interval when it hits zero
                  this._eventStartedSubject.next(true);
                  console.log('Event has started!');
                }
              }, 1000); // Update every second
            }




            // Immediately set the event ended if has ended
            if (timeUntilEnd <= 0) {
              this._eventEndedSubject.next(true);
              console.log('Event has ended immediately!');
            } else {
              let countdownInterval2: any;

              // Set a countdown interval
              countdownInterval2 = setInterval(() => {
                // const currentTime = moment().tz(timezone);
                // const updatedTimeUntilEnd = eventStartMoment.diff(currentTime);
                const updatedTimeUntilEnd = moment(eventSelect.EventEndDataTime).diff(moment().tz(timezone));


                // console.log(`Updated time until end: ${updatedTimeUntilEnd}`);

                if (updatedTimeUntilEnd <= 0) {
                  clearInterval(countdownInterval2); // Stop the interval when it hits zero
                  this._eventEndedSubject.next(true);
                  console.log('Event has ended!');
                }
              }, 1000); // Update every second
            }
            //===========================

            // if (timeUntilStart > 0) {
            //   // Set a timeout for the event start time
            //   setTimeout(() => {
            //     // alert(`Time until start: ${timeUntilStart}`);
            //     if(timeUntilStart < 3)
            //     {
            //       this._eventStartedSubject.next(true);
            //       console.log('Event has started!');
            //     }

            //     console.log('Error spotted. Event has not started but we handled it!');
            //     // this.handleEventStart(); // Call your logic for when the event starts
            //   }, timeUntilStart);
            // }
            // else if(timeUntilStart <= 0) {
            //   console.log('Event started in the past!');
            //   this._eventStartedSubject.next(true);
            // }

            // if (timeUntilEnd > 0) {
            //   // Set a timeout for the event end time
            //   setTimeout(() => {
            //     if(timeUntilEnd < 3)
            //     {
            //       this._eventEndedSubject.next(true); // Mark event as ended
            //       console.log('Event has ended!');
            //     }

            //     console.log('Error spotted. Event has not ended but we handled it!');
                
            //   }, timeUntilEnd);
            // }
            // else if(timeUntilEnd <= 0) {
            //   this._eventEndedSubject.next(true);
            // }




            //====================


            // if (timeUntilStart > 0) {
            //   // Set a timeout for the event start time
            //   setTimeout(() => {
            //     alert(`Time until start: ${timeUntilStart}`);

            //     this._eventStartedSubject.next(true);

            //     // this.handleEventStart(); // Call your logic for when the event starts
            //     console.log('Event has started!');
            //   }, timeUntilStart);
            // }
            // else if(timeUntilStart <= 0) {
            //   console.log('Event started in the past!');
            //   this._eventStartedSubject.next(true);
            // }

            // if (timeUntilEnd > 0) {
            //   // Set a timeout for the event end time
            //   setTimeout(() => {
            //     this._eventEndedSubject.next(true); // Mark event as ended
            //     console.log('Event has ended!');
            //   }, timeUntilEnd);
            // }
            // else if(timeUntilEnd <= 0) {
            //   this._eventEndedSubject.next(true);
            // }
    
            return of(true);

           
          }
    
          return of(null);
        })
        // tap(() => {
        //   if (!environment.production) {
        //     console.log("Time calculations about to start");
        //   }
    
        //   // Fetch start and end times of the event
        //   this.getStartDateTime(this.eventSelect!);
        //   this.getEndDateTime(this.eventSelect!);
        // })
      ).subscribe()
    );
    
    //split the images
    this.imgs$ = this.eventSelect$.pipe(
      map(selectedEvent => selectedEvent?.Images?.split(',') || [])
    );
    
    //get the event creator for this specific event
    this.eventSelectUsr$ = this.eventSelect$.pipe(
      switchMap((selectedEvent: MaiXEvent) => this.store.pipe(
        select(selectFutureEventCreators),
        filter(users => users.length > 0 && users.some(user => user.Id === selectedEvent.UserId)), //includes checking that the array of users is not empty and that it contains a user with an ID matching selectedEvent.UserId.
        map(users => users.find(user => user.Id === selectedEvent.UserId)),
        first(user => !!user) // Ensure the user is not undefined
      ))
    );

    this.subscription.add(
      this.eventSelectUsr$.pipe(
        switchMap(eventSelectUsr => {
          if (eventSelectUsr) {

            if(!environment.production)
            {
              console.log(`found user: ${JSON.stringify(eventSelectUsr)}`);
            }

            this.eventSelectUsr = eventSelectUsr;

            return of(true);
          }

          return of(null);
        })
      ).subscribe()
    );
  }


  getAttendeesOfEvent(eventSelect: MaiXEvent)
  {
    if(!environment.production)
    {
      console.log("Starting to get attendees")
    }

    this.bookingSvc.GetAttendeesOfAnEvent(eventSelect.Id!)
    .subscribe((response: any) => 
    {
      this.attendees = response;

      if(!environment.production)
      {
        console.log(`Attendees of ${this.eventSelect?.Name} event: ${JSON.stringify(this.attendees)}`)
      }

      //get all the userIds in the attendees list
      const arrayOfIds: string[] = this.attendees.map((obj: any) => obj.GenericUserId)
  
      //for all the userID that were retrieved, 
      //get user object that match a valid userId
      this.userSvc.GetAllUsersOfAnAppByUserIds(
        environment.AppBoundIdentifier,
        arrayOfIds
      ).subscribe((response: any) => {

        //rematch valid userIds
        // Push the valid users to the subject
        this.validAttendeeUsersSubject.next(response);

        // this.validAttendeeUsers = response;

        if(!environment.production)
        {
          console.log(`Valid attendees of the IDs sent: ${JSON.stringify(response)}`)
        }
      })

    })

  }

  getLocationOfEvent(eventSelect: MaiXEvent)
  {
    if(!environment.production)
    {
      console.log("Starting to get location")
    }

    this.lociService.GetAddressByAddressId(eventSelect?.AddressId!)
    .subscribe((address: AddressModel) => {
      
        
      this.eventLocation$.next(address)
      this.evntAddr = address;

      if(!environment.production)
      {
        console.log(`Location of ${this.eventSelect?.Name} event: ${JSON.stringify(this.evntAddr)}`)
      }
  
    }); 
  }

  currentTime = new Date();

  date: any
  time: any
  day: any
  eventStartTime: string = ''
  eventEndTime: string = ''

  getStartDateTime(eventSelect: MaiXEvent)
  {
    // Define the time zone
    const timezone = 'Africa/Johannesburg'; // Adjust as needed

     // Parse the moment string
    const eventStartMoment = moment(eventSelect!.EventStartDateTime).tz(timezone);

    //log
    if(!environment.production)
    {
      console.log('eventStartMoment date:', eventStartMoment);
    }
   
     // Check if the moment object is valid
    if (!eventStartMoment.isValid()) {
      console.log('Invalid start date:', eventSelect!.EventStartDateTime);
      // alert(`Invalid start date: ${eventSelect!.EventStartDateTime}. Original format ${eventSelect!.EventStartDateTime}`);
      this.eventStartTime = 'Invalid Start Date';
      return;
    }
  
    // Convert moment object to JavaScript Date object
    const eventStartDate = eventStartMoment.utc().toDate();

    if(!environment.production)
    {
      console.log('eventStartDate date:', eventStartDate);
    }

     // Check if the Date object is valid
    if (isNaN(eventStartDate.getTime())) {
      console.log('Start date conversion to Date failed:', eventStartMoment);
      // alert('Start date conversion to Date failed:' + eventStartMoment);
      this.eventStartTime = 'Invalid Start Time';
      return;
    }

    // Format the date components
    const year = eventStartMoment.year().toString();
    const month = String(eventStartMoment.month() + 1).padStart(2, '0');
    const day = String(eventStartMoment.date()).padStart(2, '0');
    const startHours = String(eventStartMoment.hour()).padStart(2, '0');
    const startMinutes = String(eventStartMoment.minute()).padStart(2, '0');
    const startSeconds = String(eventStartMoment.second()).padStart(2, '0');

    this.eventStartTime = `${year}-${month}-${day} ${startHours}:${startMinutes}:${startSeconds}`;
  }

  getEndDateTime(eventSelect: MaiXEvent)
  {
    // Define the time zone
    const timezone = 'Africa/Johannesburg'; // Adjust as needed
    
    // Parse the moment string
    const eventEndMoment = moment(eventSelect!.EventEndDataTime).tz(timezone);

    //log
    if(!environment.production)
    {
      console.log('event end moment date:', eventEndMoment);
    }


    // Check if the moment object is valid
    if (!eventEndMoment.isValid()) {
      console.log('Invalid end date:', eventSelect!.EventEndDataTime);
      this.eventEndTime = 'Invalid End Date';
      return;
    }

    // Convert moment object to JavaScript Date object
    const eventEndDate = eventEndMoment.utc().toDate();

    if(!environment.production)
    {
      console.log('eventEndDate date:', eventEndDate);
    }

    // Check if the Date object is valid
    if (isNaN(eventEndDate.getTime())) {
      console.log('End date Conversion to Date failed:', eventEndMoment);
      this.eventEndTime = 'Invalid End Time';
      return;
    }

    // Format the date components
    const year = eventEndMoment.year();
    const month = String(eventEndMoment.month() + 1).padStart(2, '0');
    const day = String(eventEndMoment.date()).padStart(2, '0');
    const endHours = String(eventEndMoment.hour()).padStart(2, '0');
    const endMinutes = String(eventEndMoment.minute()).padStart(2, '0');
    const endSeconds = String(eventEndMoment.second()).padStart(2, '0');

    // const eventEndDate = this.convertMomentStringToUTCDate(eventSelect!.EventEndDataTime);
    
    // const year = eventEndDate.getFullYear();
    // const month = String(eventEndDate.getMonth() + 1).padStart(2, "0");
    // const day = String(eventEndDate.getDate()).padStart(2, "0");

    // let endHours = String( 
    //   eventEndDate.getHours()).padStart(2, "0");

    // let endMinutes = String(
    //   eventEndDate.getMinutes()).padStart(2, "0");

    // let endSeconds = String( 
    //   eventEndDate.getSeconds()).padStart(2, "0");
    
    this.eventEndTime = `${year}-${month}-${day} ${endHours}:${endMinutes}:${endSeconds}`;
  }


  // convertMomentStringToUTCDate(momentString: string): Date {
  //   if(!environment.production)
  //   {
  //     console.log(`convert moment string to date: ${momentString}`)
  //   }

  //   //this should be extracted from a app_global selection (which is based on the country selection the user defaults to or select) 
  //   let timezone = 'Africa/Johannesburg';

  //   // Parse the moment string with timezone
  //   // const parsedDate = moment.tz(momentString, 'ddd MMM DD YYYY HH:mm:ss ZZ');
  //   const parsedDate = moment(momentString).tz(timezone);// moment.tz(, 'ddd MMM DD YYYY HH:mm:ss ZZ');


  //   // const eventStartMoment = moment(eventSelect.EventStartDateTime).tz(timezone);
  //   // const eventEndMoment = moment(eventSelect.EventEndDataTime).tz(timezone);


  //   // Convert to UTC
  //   const utcDate = parsedDate.utc();

  //   // Convert to JavaScript Date object
  //   // return utcDate.toDate();
  //   return parsedDate.toDate();

  // }

  backToEvntList()
  {
    this.router.navigate(['eventsperstatus']);
    // this.router.navigate(['eves']);
  }

  validateImagePath(imgPath: string): any {
    if(imgPath?.length > 0)
    {
      const img = new Image();
      img.src = imgPath;

      if(img.onload) {
        return imgPath;
      }
      else {
        return 'nImg.png'
      };
    }

    return 'nImg.png'
  }

  validateImagePath_RetPromise(imgPath: string): Promise<boolean> {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = imgPath;
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
    });
  }

  locationCheck(e: any)
  {
    if(!environment.production)
    {
      console.log("location event :" + this.eventSelect?.AddressId);
    }

      const addrRetrieverObserver = {
        next: async (response: any) =>  {
          this.locationResponse = response;
  
          if(!environment.production)
          {
            console.log("location retrieved successfully:" + JSON.stringify(this.locationResponse));
          }
           
          this.dialogSvc.ShowLocationDialog(this.locationResponse);       
        },
        error: (err: HttpErrorResponse) => console.log(err),
        complete: () =>{
          console.log("Add activity COMPLETE");
        }
      }
  
  
      //get the location of the activity
      this.addrService.GetAddressByAddressId(this.eventSelect?.AddressId!)
        .subscribe(addrRetrieverObserver);
          
         
      // alert("View location of an activity by anyone")
      //this prevents the parent routing click action to kickoff
      e.stopPropagation();
    
  }

  getUserObjectWithId(genUserId: any): any
  {
    return this.validAttendeeUsers$
    .pipe(
      map(users => users.find((user: any) => user.Id === genUserId))
    )
    
  }

  InDevMsg()
  {
    this.toastr.warning("Feature In development", "status");
  }


  usrId: string = '';
  usrEmail: string = '';
  usrRole: string = '';
  fName: string = '';
  lName: string = '';
  usrName: string = '';

  BookEvent()
  {
    //
    let eventToBeReserved = {
      Details: this.eventSelect,
      EventLoci: this.evntAddr,
      UsrInfo: this.eventSelectUsr
    }

    if(!environment.production)
    {
      console.log(`Event to be reserved from event detail view: ${JSON.stringify(eventToBeReserved)}`);
    }

    const cookieObserver = {
      next: async (response: any) => {
        if(!environment.production)
        {
          console.log(`Response from cookie decrypt: ${JSON.stringify(response)}`);
        }

        this.usrId = response.find((x: any) => x.Type === "UserIdClaim").Value;
        this.usrEmail = response.find((x: any) => x.Type === "EmailClaim").Value;
        this.usrRole = response.find((x: any) => x.Type === "RoleClaim").Value;
        this.fName = response.find((x: any) => x.Type === "FirstnameClaim").Value;
        this.lName = response.find((x: any) => x.Type === "LastnameClaim").Value;
        // this.usrName = response.find((x: any) => x.Type === "UserName").Value;

        if(!environment.production)
        {
          console.log(`Logged in user: ${this.usrId} && Email: ${this.usrEmail} && Role: ${this.usrRole} && Firstname: ${this.fName} && Lastname: ${this.lName} && Usernaem: ${this.usrName}`);
        }

        if(this.usrId == eventToBeReserved.Details?.UserId)
        {
          if(confirm("You created this event, are you sure you want to book"))
          {
            // when token comes back as valid, then open the booking dialog
            this.dialogSvc.EventBookingDialog(eventToBeReserved)
          }
        }
        else
        {
          // when token comes back as valid, then open the booking dialog
          this.dialogSvc.EventBookingDialog(eventToBeReserved)
        }
      },
      error: async (err: HttpErrorResponse) => {
        console.log(`Error from cookie decrypt: ${JSON.stringify(err)}`);
      },
      complete: async () => {
        // console.log(`User logged in!`);
        // this.router.navigate(['login'])
      }
    }

    //validate token to ensure that the user is logged in
    //observer for token validation against the server
    const tknValidationObserver = {
      //if the cookie is still intact
      next: async (response: any) => {
        if(!environment.production)
        {
          console.log(`Token validation from server response: ${JSON.stringify(response)}`);
        }

        // this.authSvc.DecryptCookie().subscribe(cookieObserver);
        this.authSvc.DecryptCookieForClaims().subscribe(cookieObserver);
      },
      error: async (err: HttpErrorResponse) => {
        if(!environment.production)
        {
          console.log(`Error response from token validation: ${JSON.stringify(err)}`);
        }
        
        if(err.url) //if err response is trying to navigate to another page, then it has expired
        {
          //remove the cookie 
          this.userSvc.logout()

          //show the user some error
          this.toastr.error(
            "Session ended. Login", "Error");

          //clear recursive interval timing
          // this.stopRecurringTask()

          //redirect to the login page
          this.router.navigate(['login'],
          {
            queryParams: {
              redirectUrl: 'eventdetails',
              data: JSON.stringify(this.eventSelect), // Serialize the object to a JSON string
              evnt2be: JSON.stringify(eventToBeReserved)
            },
            skipLocationChange: true
          });
        }
      },
      complete: async () => {
        if(!environment.production)
        {
          console.log(`user http request complete!`);
        }
      }
    }

    const eventBookingObserver = {
      //if user is logged in
      next: async (response: any) => {

        //ensure that we get a response: true (this means locally, the token is intact)
        if(!environment.production)
        {
          console.log(`$$$ Response from event booking prep.: ${JSON.stringify(response)}`)
        }

        //validate the token against the server if the response is true
        this.authSvc.ValidateUserCookie().subscribe(tknValidationObserver)
      },
      error: async (err: HttpErrorResponse) => {
        if(!environment.production)
        {
          console.log(`Event Booking error response: ${JSON.stringify(err)}`);
        }
      },
      complete: async () => {
        if(!environment.production)
        {
          console.log(`http request for event booking prep complete!`);
        }
      }
    }
      
    //determine if user is logged in (Local cookie check)
    this.userSvc.isUserLoggedIn.pipe(take(1)).subscribe(eventBookingObserver);
  } 
   

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

      
  ViewAdminImage(e: any, creatorImg: string)
  {
    //make sure creator object is not empty
    //if profile isnt empty send image if not use a default
      
    let AdminImageUrl: string[] = [];

    if(creatorImg)
    {
      if(!environment.production){
        console.log(`Extracted Image Url of Admin : ${creatorImg}`);
      }
      
      AdminImageUrl.push(creatorImg) 

      this.dialogSvc.ShowImgDialog(AdminImageUrl);
    } 

    AdminImageUrl = [] ;
    
    e.stopPropagation();
  }

  ViewImage(e: any, imageUrls: string[], index:number)
  {
    if(!environment.production){
      console.log(`Extracted Image Url of Activity: ${imageUrls}`);
      
    }
    this.selectedImageUrls = imageUrls;

    this.dialogSvc.ShowImgDialog(this.selectedImageUrls,index);

    e.stopPropagation();
  }

  getAllImageUrls(): string[] {
    return this.imgs!;
  }

  setCurrentIndex(index: number) {
    this.currentIndex = index;
    console.log("this curr index " + this.currentIndex)
  }

  share(currUrl: any){
    currUrl = this.router.url;
    this.dialogSvc.ShowShareDialog(currUrl)
  }

  
}
