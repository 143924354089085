//===========================================================
// Material imports
//===========================================================
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input'; 
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list'
import { MatIconModule } from '@angular/material/icon'
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxPaginationModule } from 'ngx-pagination';
import {MatMomentDateModule } from '@angular/material-moment-adapter';
//===========================================================


import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultComponent } from './default.component';
// import { CoreComponent } from 'src/app/modules/core/core.component';
import { LoginComponent } from 'src/app/modules/auth/login/login.component';
import { RegisterComponent } from 'src/app/modules/auth/register/register.component';
import { ResetPasswordComponent } from 'src/app/modules/auth/reset-password/reset-password.component';
import { ForgotPasswordComponent } from 'src/app/modules/auth/forgot-password/forgot-password.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FilesComponent } from 'src/app/modules/files/files.component';
import { TeamComponent } from 'src/app/Templates/team/team.component';
import { TeamsComponent } from 'src/app/modules/teams/teams.component';
import { TeamListComponent } from 'src/app/Templates/team-list/team-list.component';
import { ActivityDetailsComponent } from 'src/app/modules/activity/activity-details/activity-details.component';
import { AddMediaDialogComponent } from 'src/app/modules/activity/add-media-dialog/add-media-dialog.component';
import { AllActivitiesComponent } from 'src/app/modules/activity/all-activities/all-activities.component';
import { DashboardComponent } from 'src/app/modules/profile/dashboard/dashboard.component';
import { IndexComponent } from 'src/app/modules/home/index/index.component';
import { LocationDialogComponent } from 'src/app/modules/activity/location-dialog/location-dialog.component';
import { EdtiActivityDialogComponent } from 'src/app/modules/activity/edti-activity-dialog/edti-activity-dialog.component';
import { ManagerComponent } from 'src/app/modules/activity/manager/manager.component';
import { ActivityPerStatusComponent } from 'src/app/modules/activity/activity-per-status/activity-per-status.component';
import { SelectedCompsComponent } from 'src/app/modules/competition/selected-comps/selected-comps.component';
import { SelectedEventsComponent } from 'src/app/modules/event/selected-events/selected-events.component';
import { SelectedClubsComponent } from 'src/app/modules/club/selected-clubs/selected-clubs.component';
import { ActivityBookingDialogComponent } from 'src/app/modules/activity/activity-booking-dialog/activity-booking-dialog.component';
import { NewActivityComponent } from 'src/app/modules/activity/new-activity/new-activity.component';
import { BasicComponent } from 'src/app/modules/scheduler/basic/basic.component';
import { HowToComponent } from 'src/app/modules/learn/how-to/how-to.component';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns'
import { UtilsComponent } from 'src/app/modules/utils/utils.component';
import { ToastrModule } from 'ngx-toastr';
import { AddRoleComponent } from 'src/app/modules/utils/add-role/add-role.component';
import { AddUserComponent } from 'src/app/modules/utils/add-user/add-user.component';
import { SetEntityAddressComponent } from 'src/app/modules/activity/set-entity-address/set-entity-address.component';
import { AddressManagerComponent } from 'src/app/modules/admin/address-manager/address-manager.component';
import { AddEventComponent } from 'src/app/modules/event/add-event/add-event.component';
import { EventDetailViewComponent } from 'src/app/modules/event/event-detail-view/event-detail-view.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { EventPerStatusComponent } from 'src/app/modules/event/event-per-status/event-per-status.component';
import { EventBookingDialogComponent } from 'src/app/modules/event/event-booking-dialog/event-booking-dialog.component';
import { EventManagerComponent } from 'src/app/modules/event/event-manager/event-manager.component';
import { ConfirmEmailComponent } from 'src/app/modules/auth/confirm-email/confirm-email.component';
import { ProfileComponent } from 'src/app/modules/user/profile/profile.component';
import { UserRoleComponent } from 'src/app/modules/user/subcomponents/user-role/user-role.component';
import { UserProfileComponent } from 'src/app/modules/user/subcomponents/user-profile/user-profile.component';
import { UserFundsComponent } from 'src/app/modules/user/subcomponents/user-funds/user-funds.component';
import { UserVoucherComponent } from 'src/app/modules/user/subcomponents/user-voucher/user-voucher.component';

//===========================================================
// Store devtools imports
//===========================================================
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { activityReducer } from 'src/app/ngrx/reducers/activity.reducers';
import { ActivityEffects } from 'src/app/ngrx/effects/activity.effect';
import { maixEventReducer } from 'src/app/ngrx/reducers/maixevent.reducers';
import { MaiXEventEffects } from 'src/app/ngrx/effects/maixevent.effect';
import { featuredActivityReducer } from 'src/app/ngrx/reducers/featuredActivity.reducers';
import { FeaturedActivityEffects } from 'src/app/ngrx/effects/featuredActivity.effect';
import { featuredEventReducer } from 'src/app/ngrx/reducers/featuredEvent.reducers';
import { FeaturedEventEffects } from 'src/app/ngrx/effects/featuredEvent.effect';
import { ipLocationReducer } from 'src/app/ngrx/reducers/ipLocation.reducers';
import { IpLocationEffects } from 'src/app/ngrx/effects/ipLocation.effect';
import { IpEffects } from 'src/app/ngrx/effects/ip.effect';
import { ipReducer } from 'src/app/ngrx/reducers/ip.reducers';
import { maixFutureEventReducer } from 'src/app/ngrx/reducers/maixevent-future.reducers';
import { MaiXFutureEventEffects } from 'src/app/ngrx/effects/maixevents.future.effects';
import { maixFutureEventsCreatorsReducer } from 'src/app/ngrx/reducers/future-events-creator.reducers';
import { FutureEventCreatorsEffects } from 'src/app/ngrx/effects/future-events-creator.effect';
import { activeActivityReducer } from 'src/app/ngrx/reducers/active-activity.reducers';
import { activeActivitiesCreatorsReducer } from 'src/app/ngrx/reducers/active-activity-creators.reducers';
import { ActiveActivityEffects } from 'src/app/ngrx/effects/active-activity.effect';
import { ActiveActivityCreatorsEffects } from 'src/app/ngrx/effects/active-activity-creators.effect';
import { currentLoggedinUserRoleReducer } from 'src/app/ngrx/reducers/current-user-roles.reducers';
import { currentLoggedinUserReducer, eventCreatorsReducer } from 'src/app/ngrx/reducers/current-user.reducers';
import { UserRolesEffects } from 'src/app/ngrx/effects/current-user-roles.effect';
import { UserEffects } from 'src/app/ngrx/effects/current-user.effect';
import { applicationRolesReducer } from 'src/app/ngrx/reducers/application-roles.reducers';
import { ApplicationRolesEffects } from 'src/app/ngrx/effects/application-roles.effect';
// import { CardComponent } from 'src/app/Templates/card/card.component';
import { ImageUploadComponent } from 'src/app/Templates/image-upload/image-upload.component';
import { EnlargeImgComponent } from 'src/app/Templates/enlarge-img/enlarge-img.component';
import { EditEventDialogComponent } from 'src/app/modules/event/edit-event-dialog/edit-event-dialog.component';
import { ShareboxComponent } from 'src/app/Templates/sharebox/sharebox.component';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { CountdownTimerComponent } from 'src/app/Templates/countdown-timer/countdown-timer.component';
import { TermsConditionComponent } from 'src/app/modules/others/terms-condition/terms-condition.component';
// import { HostActivitiesListComponent } from 'src/app/modules/activityhost/host-activities-list/host-activities-list.component';

import { ActivityHostDashboardComponent } from 'src/app/modules/activityhost/activity-host-dashboard/activity-host-dashboard.component';
import { AppState } from 'src/app/ngrx/state/app.state';
import { BookingTableComponent } from 'src/app/Templates/booking-table/booking-table.component';
import { currentUserActivitiesReducer } from 'src/app/ngrx/reducers/current-user-activities.reducers';
import { CurretUserActivitiesEffects } from 'src/app/ngrx/effects/current-user-activities.effect';
import { DateTimePickerComponent } from 'src/app/Templates/date-time-picker/date-time-picker.component';
import { DatePickerComponent } from 'src/app/Templates/date-picker/date-picker.component';
import { TimePickerComponent } from 'src/app/Templates/time-picker/time-picker.component';
import { bookingReducer } from 'src/app/ngrx/reducers/bookings.reducer';
import { entityBookingsReducer } from 'src/app/ngrx/reducers/entity-bookings.reducer';
import { EntityBookingEffects } from 'src/app/ngrx/effects/entity-booking.effects';
import { BookingEffects } from 'src/app/ngrx/effects/booking.effect';
import { ActivityHistoryComponent } from 'src/app/modules/activityhost/activity-history/activity-history.component';
import { PricingComponent } from 'src/app/modules/others/pricing/pricing.component';
import { HowActivityComponent } from 'src/app/modules/learn/how-activity/how-activity.component';
import { HowEventComponent } from 'src/app/modules/learn/how-event/how-event.component';
import { HowCompetitionComponent } from 'src/app/modules/learn/how-competition/how-competition.component';
import { HowClubComponent } from 'src/app/modules/learn/how-club/how-club.component';
import { ListComponent } from 'src/app/Templates/bank-details/list/list.component';
import { EditComponent } from 'src/app/Templates/bank-details/edit/edit.component';
import { AddComponent } from 'src/app/Templates/bank-details/add/add.component';
import { ContactusComponent } from 'src/app/modules/contactus/contactus.component';
import { EventHostDashboardComponent } from 'src/app/modules/eventhost/event-host-dashboard/event-host-dashboard.component';
import { EventHistoryComponent } from 'src/app/modules/eventhost/event-history/event-history.component';
import { HostEventListComponent } from 'src/app/modules/eventhost/host-event-list/host-event-list.component';
import { currentUserEventsReducer } from 'src/app/ngrx/reducers/current-user-events.reducers';
import { CurrentUserEventsEffects } from 'src/app/ngrx/effects/current-user-events.effect';
import { GoogleMapsModule } from '@angular/google-maps';
import { MapComponent } from 'src/app/Templates/map/map.component';
import { CleanerComponent } from 'src/app/modules/cleaner/cleaner.component';
import { maixLiveEventsReducer } from 'src/app/ngrx/reducers/maixevent-live.reducers';
import { maixPastEventsReducer } from 'src/app/ngrx/reducers/maixevent.past.reducers';
import { MaiXLiveEventEffects } from 'src/app/ngrx/effects/maixevents.live.effects';
import { MaiXPastEventEffects } from 'src/app/ngrx/effects/maixevents.past.effects';
import { MaixeventComponent } from 'src/app/Templates/maixevent/maixevent.component';
import { IntersectionObserverDirective } from 'src/app/shared/utils/directives/intersection-observer.directive';
import { HeadNaviagationComponent } from 'src/app/shared/components/head-naviagation/head-naviagation.component';
import { ActivityPromotionComponent } from 'src/app/modules/user/subcomponents/activity-promotion/activity-promotion.component';

// import { DocuModule } from 'src/app/docu/docu.module';

//===========================================================
// Store devtools imports ends
//===========================================================
@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    DefaultComponent,
    // CoreComponent, //made standalone
    FilesComponent,
    LoginComponent,
    RegisterComponent,
    ConfirmEmailComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    NewActivityComponent,
    ManagerComponent,
    ActivityDetailsComponent,
    ActivityPerStatusComponent,
    TeamComponent,
    TeamsComponent,
    TeamListComponent,
    AddMediaDialogComponent,
    AllActivitiesComponent,
    DashboardComponent,
    IndexComponent,
    LocationDialogComponent,
    EdtiActivityDialogComponent,
    SelectedCompsComponent,
    SelectedEventsComponent,
    EditEventDialogComponent,
    SelectedClubsComponent,
    ActivityBookingDialogComponent,
    SetEntityAddressComponent,
    AddressManagerComponent,
    UtilsComponent,
    AddUserComponent,
    AddRoleComponent,
    BasicComponent,
    AddEventComponent,
    EventDetailViewComponent,
    EventManagerComponent,
    EventBookingDialogComponent,
    EventPerStatusComponent,
    EventDetailViewComponent,
    ProfileComponent,
    UserRoleComponent,
    UserProfileComponent,
    UserFundsComponent,
    UserVoucherComponent,
    HowToComponent,
    ImageUploadComponent,
    EnlargeImgComponent,
    ShareboxComponent,
    CountdownTimerComponent,
    TermsConditionComponent,
    PricingComponent, //Pricing
    ActivityHostDashboardComponent,
    BookingTableComponent,
    DateTimePickerComponent, //To be removed
    DatePickerComponent,
    TimePickerComponent,

    ShareboxComponent,
    // HostActivitiesListComponent, // now standalone
    ActivityHistoryComponent,

    ContactusComponent,

    //learn how to
    HowActivityComponent,
    HowEventComponent,
    HowCompetitionComponent,
    HowClubComponent,
    AddComponent, //add bank details
    ListComponent, //list bank details
    EditComponent, //edit bank details
    MapComponent,
    EventHostDashboardComponent,
    EventHistoryComponent,
    HostEventListComponent,

    MaixeventComponent,
    CleanerComponent,
    IntersectionObserverDirective

  ],
  imports: [
    //===================
    // STANDALONES STARTS
    //===================
    //standalone components has to be imported 
    //as it is not part of the shared module anymore
    
    HeadNaviagationComponent,
    ActivityPromotionComponent,
    //=================
    // STANDALONES ENDS
    //=================

    StoreModule.forRoot({ 
      activities: activityReducer,
      userActivities: activityReducer,
      selectedActivity: activityReducer,
      activeactivities: activeActivityReducer,
      activeactivitycreators: activeActivitiesCreatorsReducer,
      featuredActivities: featuredActivityReducer,
      maixevents: maixEventReducer,
      featuredEvents: featuredEventReducer,
      futuremaixevents: maixFutureEventReducer,
      liveMaixevents: maixLiveEventsReducer,
      pastMaixevents: maixPastEventsReducer,
      futureeventscreators: maixFutureEventsCreatorsReducer,
      ip: ipReducer,
      ipLocation: ipLocationReducer,
      currentloggedinuser: currentLoggedinUserReducer,
      eventcreatorsbystatus : eventCreatorsReducer,
      currentloggedinuserroles: currentLoggedinUserRoleReducer,
      applicationroles: applicationRolesReducer,
      currentuseractivities: currentUserActivitiesReducer,
      currentuserevents: currentUserEventsReducer,
      bookings: bookingReducer,
      entitybookings: entityBookingsReducer 
    }),
    EffectsModule.forRoot([
      ActivityEffects,
      ActiveActivityEffects,
      ActiveActivityCreatorsEffects,
      FeaturedActivityEffects,
      MaiXEventEffects, 
      FeaturedEventEffects,
      MaiXFutureEventEffects,
      MaiXLiveEventEffects,
      MaiXPastEventEffects,
      FutureEventCreatorsEffects,
      IpEffects,
      IpLocationEffects,
      ApplicationRolesEffects,
      UserRolesEffects,
      UserEffects,
      CurretUserActivitiesEffects,
      CurrentUserEventsEffects,
      BookingEffects,
      EntityBookingEffects]
    ),
    StoreDevtoolsModule.instrument(),
    CommonModule,
    RouterModule,
    SharedModule,
    // DocuModule, //documentation module
    MatSidenavModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCardModule,
    MatListModule,
    MatIconModule,
    MatTableModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatButtonModule,
    MatDialogModule,
    MatStepperModule,
    MatExpansionModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatGridListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMaterialTimepickerModule,
    NgxPaginationModule,
    MatMomentDateModule,
    MatSnackBarModule,
    GoogleMapsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    })
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en-US' },
  ],
  bootstrap: [
    DefaultComponent
  ]
})
export class DefaultModule { }

