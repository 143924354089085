import { createAction, props } from "@ngrx/store";
import { GenericUser } from "src/app/types/models/genericuser.model";


// creators of active activities
export const GET_CREATORS_OF_ACTIVE_ACTIVITIES = createAction('[Generic Users] Get Creators of Active Activities',
    props<{ arrayOfUserId: string[] }>());

export const GET_CREATORS_OF_ACTIVE_ACTIVITIES_SUCCESS = createAction('[Generic Users] Get Creators of Active Activities Success',
    props<{activeactivitycreators: GenericUser[]}>());

export const GET_CREATORS_OF_ACTIVE_ACTIVITIES_FAIL = createAction('[Generic Users] Get Creators of Active Activities Fail',
    props<{error: string}>());