export class EmailData
{
  constructor(
    public recipientEmail: string,
    public emailSubject: string,
    public templateContent: string,
    public appBound: number
  ){}

}
