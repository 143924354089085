import { Location } from '@angular/common';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import * as QRCode from 'qrcode';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { ActivityService } from 'src/app/shared/services/activity.service';
import { UserSpecificService } from 'src/app/shared/services/dataService/user-specific.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { environment } from 'src/environments/environment';
// import { CustomProfilePictureChange, GenericRole, GenericUser } from '../../auth/login/login.component';
import { ToastrService } from 'ngx-toastr';
import { MatTabGroup } from '@angular/material/tabs';
import { GenericUser } from 'src/app/types/models/genericuser.model';
import { CustomProfilePictureChange } from 'src/app/types/models/customprofilepicturechange.model';
import { AppState } from 'src/app/ngrx/state/app.state';
import { Store, select } from '@ngrx/store';
import { GET_CURRENT_USER } from 'src/app/ngrx/actions/current-user.actions';
import { GET_CURRENT_USER_ROLES } from 'src/app/ngrx/actions/current-user-roles.actions';
import { Observable, first, switchMap, tap } from 'rxjs';
import { selectLoggedInUser } from 'src/app/ngrx/selectors/current-user.selectors';
import { LoaderService } from 'src/app/shared/utils/loader.service';
import { GenericRole } from 'src/app/types/models/genericrole.model';
import { selectLoggedInUserRoles } from 'src/app/ngrx/selectors/current-user-roles.selectors';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
  })
export class ProfileComponent {
  @ViewChild('tabGroup') tabGroup: MatTabGroup | undefined;
  
  public userSelect:any 

  rtImgPath: string = environment.fileRetriever;


  isUsrLoggedIn: boolean = false;

  isGenericUser: boolean = false;
  isActivityHostUser: boolean = false;
  isActivityManagerUser: boolean = false;
  isEventHostUser: boolean = false; 

  useId:string | null = null;

  usrObject$: Observable<GenericUser | null>
  usrRoles$: Observable<string[] | null>

  // elementType = 'url';
  // value = 'google.com';
  qrCodeData: string = 'https://maixperience.co.za';
  qrCodeImage: string | null = null;

  hasClickedViewQR: boolean = false
  canUseQR: boolean = true
  qrText: string = "view QR code"


  constructor(
    private router: Router,
    private activityService: ActivityService,
    private auth: AuthService,
    private dialogSvc: DialogService,
    private fileUploadSvc: FileUploadService,
    private location: Location,
    private authSvc: AuthService,
    private userSvc: UserSpecificService,
    private toastr: ToastrService,
    private loadingService: LoaderService,
    private store: Store<AppState>) 
  { 
    this.usrObject$! = this.store.pipe(select(selectLoggedInUser));
    this.usrRoles$! = this.store.pipe(select(selectLoggedInUserRoles));
  }


  ngOnInit()
  {
    
    // this.subscription = this.applicationRoles$.subscribe(applicationRoles => {
    //   if (!applicationRoles || applicationRoles.length === 0) {
    //     this.store.dispatch(GET_APP_ROLES());
    //   }
    // });

    // //get the ID of the currently logged in user and dispatch to store
    // this.authService.GetCurrentlyLoggedInUserIdIfAny().subscribe(
    //   currentUsrId => {
    //     if(!environment.production)
    //     {
    //       console.log(`About to dispatch current user`)
    //     }

    //     //get the current user to the store
    //     this.store.dispatch(GET_CURRENT_USER({ userId: currentUsrId }));

    //     //get the roles of the currently logged in user
    //     this.store.dispatch(GET_CURRENT_USER_ROLES());

    //   });





    //get currently logged in user
    this.generateQRCode();

    this.usrObject$.pipe(
      // first(), // Take the first value emitted by the observable
      tap(user => {
        if (!user) {
          this.loadingService.show();
          this.authSvc.GetCurrentlyLoggedInUserIdIfAny()?.subscribe(
          {
            next: (response: string) =>
            {
              this.useId = response;

              if(!environment.production)
              {
                console.log(`About to dispatch current user : ${this.useId}`)
              }
        
                //get the current user to the store
                // this.store.dispatch(GET_CURRENT_USER({ userId: response }));
                this.store.dispatch(GET_CURRENT_USER());
        
                //get the roles of the currently logged in user
                this.store.dispatch(GET_CURRENT_USER_ROLES());

                this.loadingService.hide()

                
            },
            error: (err: any) =>
            {
              if(!environment.production)
              {
                console.log("Funny Error: " + err)
              }

              this.loadingService.hide()
            },
            complete() {
              if(!environment.production)
              {
                console.log("Funny complete")
              }

            }
          })
        }
      }),
      switchMap(() => this.usrObject$) // Listen for new updates from the store
    ).subscribe()
  
    this.usrRoles$.pipe(
      tap(userRoles => {
        if(!environment.production)
        {
          console.log(`Current logged in user roles: ${JSON.stringify(userRoles)}`)
        }
      })
    ).subscribe()
  }

  generateQRCode() {
    QRCode.toDataURL(
      this.qrCodeData, 
    (error, url) => {
      if(error)
      {
        console.error(`Error generating QR Code: ${error}`)
      }
      else{
        this.qrCodeImage = url;
        console.log(`QR Code successfully generated`)
      }
    })
  }

  toggleHasClickedQR()
  {
    this.hasClickedViewQR = !this.hasClickedViewQR

    this.qrText = this.hasClickedViewQR ? "hide QR code" : "view QR code"
  }
 
  InDevMsg()
  {
    this.toastr.warning("Feature is in development", "status");
  }


  navigateToTab(index: number) {
    this.tabGroup!.selectedIndex = index;
  }

  progress: number | undefined;
  message: string | undefined;
  @Output() public onUploadFinished = new EventEmitter();

  onFileSelected(event: any)
  {
    // window.alert("Processing PP change. Event" + event)

    //upload the selected picture

   

    //reload the profile picture 

    let selFileList = event.target.files;

    let numOfFiles = selFileList.length;

    if(!environment.production)
    {
      // log number of files
      console.log(`Selected file list: ${JSON.stringify(selFileList)}`);
      console.log(`Number of files: ${numOfFiles}`);
    }

    //log the FILE attribues of each file
    if(!environment.production)
    {
      //name
      console.log(`Name: ${selFileList[0]?.name}`);
      //size
      console.log(`Size: ${selFileList[0]?.size}`);
      //type
      console.log(`Type: ${selFileList[0]?.type}`);
      //additional attribute: extension
      // var fileExt = filename.split('.').pop();
      console.log(`Extension: ${selFileList[0]?.name.split('.').pop()}`);
    }
    
    this.loadingService.show()

    //upload selected files 
    const uploadSelectionObserver = {
      next: async (event: any) => 
      {
        if (event.type === HttpEventType.UploadProgress)
        {
          this.progress = Math.round(100 * event.loaded / event.total);
        }
        else if (event.type === HttpEventType.Response) 
        {
          //upload complete
          this.message = 'Upload success...';
          this.onUploadFinished.emit(event.body); //upload complete


          //prep data for DB update 
          let customUserChange = new CustomProfilePictureChange(
            this.useId!,
            selFileList[0]?.name,
            environment.AppBoundIdentifier
          )
          //  update the database
          this.userSvc.UpdateUserProfilePicture(customUserChange)
          .subscribe((data: any) => {
            if(!environment.production)
            {
              console.log(`update user result: ${JSON.stringify(data)}`)
            }

            //if all goes well
            // this.getUserUpdates();

            //dispatch 
            this.store.dispatch(GET_CURRENT_USER());


            this.loadingService.hide()

            this.toastr.success("Successful", "File upload")
          })
          

          
        }
      },
      error: (err: HttpErrorResponse) => {

        if(!environment.production)
        {
          console.log(err)
        }

        this.loadingService.hide()
        
        this.toastr.error("Error on file upload", "File upload error")
      },
      complete: () =>{
        console.log("COMPLETE");
      }
    }

    this.fileUploadSvc.upload(selFileList).subscribe(uploadSelectionObserver);
  }


//#region Role_Related


  getUserIdIfUserLoggedIn()
  {
    //if a user is logged in then try to decrypt the user's cookie
    if(this.isUsrLoggedIn)
    {
      this.authSvc.GetCurrentlyLoggedInUserIdIfAny()?.subscribe({
        next: (response: string) =>
        {
          this.useId = response;
        },
        error: (error: any) =>
        {
          console.log("Funny Error")
        },
        complete() {
          console.log("Funny complete")
        }
      })
    }

    return false;
    
  }


//#endregion

//#region Funds related

//Funds management needs to have
//1.  History of how you arrived at X (any amount). Must be an activityHost or EventHost at minimum
//2.  Payout/claims process
//3.  
//#endregion
}

