import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, switchMap, tap } from 'rxjs';
import { LOAD_ACTIVITIES_I_AM_PROMOTING } from 'src/app/ngrx/actions/activity-i-am-promoting.actions';
import { GET_CURRENT_USER } from 'src/app/ngrx/actions/current-user.actions';
import { selectActivitiesIAmPromotingWithPagination, selectActivitiesIAmPromotingWithPaginationError, selectActivitiesIAmPromotingWithPaginationLoaded, selectActivitiesIAmPromotingWithPaginationLoading } from 'src/app/ngrx/selectors/activity-i-am-promoting.selectors';
import { selectLoggedInUser } from 'src/app/ngrx/selectors/current-user.selectors';
import { AppState } from 'src/app/ngrx/state/app.state';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { ActivityPromotersLog } from 'src/app/types/models/activityPromotersLog.model';
import { GenericUser } from 'src/app/types/models/genericuser.model';

@Component({
  selector: 'app-activity-promoter-dashboard',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './activity-promoter-dashboard.component.html',
  styleUrl: './activity-promoter-dashboard.component.scss'
})
export class ActivityPromoterDashboardComponent {

  usrObject$: Observable<GenericUser | null>
  
  activitiesPromoting$: Observable<ActivityPromotersLog[]> | undefined
  activitiesPromotingLoading$: Observable<boolean> | undefined
  activitiesPromotingLoaded$: Observable<boolean> | undefined
  activitiesPromotingError$: Observable<string | null> 

  pageNumber: number = 1;
  pageSize: number = 10;

  constructor(
    private router: Router,
    private authSvc: AuthService, 
    private store: Store<AppState>) 
  {
    //currently logged in user
    this.usrObject$! = this.store.pipe(select(selectLoggedInUser));


    // get the activities I am promoting from store
    this.activitiesPromoting$ = this.store.pipe(select(selectActivitiesIAmPromotingWithPagination))
    this.activitiesPromotingLoading$ = this.store.pipe(select(selectActivitiesIAmPromotingWithPaginationLoading))
    this.activitiesPromotingLoaded$ = this.store.pipe(select(selectActivitiesIAmPromotingWithPaginationLoaded))
    this.activitiesPromotingError$ = this.store.pipe(select(selectActivitiesIAmPromotingWithPaginationError))

  }

  ngOnInit(){
    this.usrObject$.pipe(
      // first(), // Take the first value emitted by the observable
      tap(user => {
        if (!user) {
          // dispatch
          this.store.dispatch(GET_CURRENT_USER());
        }
        else {
          this.store.dispatch(LOAD_ACTIVITIES_I_AM_PROMOTING({
            promoterUserId: user?.Id!,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize
          }));
        }
      }),
      switchMap(() => this.usrObject$) // Listen for new updates from the store
    ).subscribe()


    
    // Subscribe to the activities observable and log the data when it updates
    // Log the observable itself to check if it's emitting
    this.activitiesPromoting$!.subscribe(data => {
      console.log('Observable emitted');
      if (data) {
        console.log('Promotion data:', data);
      } else {
        console.log('No Promotion available');
      }
    });

    
  }


  viewActivitiesIAmPromoting(){
    this.router.navigate(['pvtpromotingacts'])
  }

  viewSuccessfulPromotionHistory()
  {
    this.router.navigate(['APHistory'])
  }
}
