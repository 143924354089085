import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map } from 'rxjs';
import { ObfuscationServiceService } from 'src/app/shared/services/obfuscation-service.service';
import { MEEntity } from 'src/app/types/enums/meEntity.enum';

@Component({
  selector: 'app-activity-history',
  templateUrl: './activity-history.component.html',
  styleUrls: ['./activity-history.component.scss']
})
export class ActivityHistoryComponent {

  mEEntity: MEEntity = MEEntity.Activity;
  entityId: number = 0;
  
  constructor(private route: ActivatedRoute,
    private router: Router,
    private obfuscationService: ObfuscationServiceService
  ) {
    
  }

  ngOnInit() {
    const activityId$ = this.route.paramMap.pipe(
      map(paramMap => paramMap.get('activityId')),
      filter(activityId => !!activityId)
    );

    activityId$.subscribe(activityId => {
      if (activityId) {
        this.entityId = +this.obfuscationService.decryptEventId(activityId)!; // The + operator converts the string to a number
      } else {
        this.entityId = 0; // Default or fallback value

        this.router.navigate(['']) //go to core
      }

    });
  }
}
