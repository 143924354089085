import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { AppState } from 'src/app/ngrx/state/app.state';
import { ActivityService } from 'src/app/shared/services/activity.service';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { UserSpecificService } from 'src/app/shared/services/dataService/user-specific.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { LoaderService } from 'src/app/shared/utils/loader.service';
import { CardComponent } from 'src/app/Templates/card/card.component';
import { Activity } from 'src/app/types/models/activity.model';

@Component({
  selector: 'app-activity-promotion',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent
  ],
  templateUrl: './activity-promotion.component.html',
  styleUrl: './activity-promotion.component.scss'
})
export class ActivityPromotionComponent {
  @Input() usrObjectInp: any;

  activitiesOpenForPromotion: Activity[] | undefined
  
  showButtonHistoryAndEdit = false; // user does not get to view those actions here
  showActivityPromoButton = true
  
  constructor(
    private router: Router,
    private dialogSvc: DialogService,
    private loaderService: LoaderService,
    private authSvc: AuthService,
    private store: Store<AppState>,
    private userSvc: UserSpecificService,
    private activitySvc: ActivityService,
    private toastr: ToastrService) 
  {

  }

  ngOnInit()
  {
    // get all activities that are open for promotion
    this.activitySvc.GetAllActivitiesThatAreOpenForPromotionWithPaginationWithFilter(
      1,
      10,
      'ZA, South Africa'
    ).subscribe((response: any) => {
      this.activitiesOpenForPromotion = response
    })
  }

  // backToDashboard()
  // {
  //   this.router.navigate(['activityhost']);
  // }
  
}
