import { Actions, createEffect, ofType } from "@ngrx/effects";

import { catchError, map, mergeMap, of, tap } from "rxjs";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { GET_CREATORS_OF_FUTURE_EVENTS, GET_CREATORS_OF_FUTURE_EVENTS_FAIL, GET_CREATORS_OF_FUTURE_EVENTS_SUCCESS } from "../actions/future-events-creator.actions";
import { UserSpecificService } from "src/app/shared/services/dataService/user-specific.service";
import { GenericUser } from "src/app/types/models/genericuser.model";

@Injectable()
export class FutureEventCreatorsEffects {
  
    //load future event creators
    loadFutureEventCreators$ = createEffect(() => this.actions$.pipe(
        ofType(GET_CREATORS_OF_FUTURE_EVENTS),
        mergeMap(action => this.userSvc.GetAllUsersOfAnAppByUserIds(environment.AppBoundIdentifier, action.arrayOfUserId)
        .pipe(
            tap((futureeventscreators: GenericUser[]) => {
                if(!environment.production)
                {
                    console.log('User IDs:', action.arrayOfUserId);
                    console.log('Creators of future events (with status code 2) loaded successfully:', futureeventscreators);
                }
            }),
            map(futureeventscreators => GET_CREATORS_OF_FUTURE_EVENTS_SUCCESS({ futureeventscreators })),
            catchError(error => of(GET_CREATORS_OF_FUTURE_EVENTS_FAIL({ error })))
        ))
        )
    );
    
    constructor(
        private actions$: Actions,
        private userSvc: UserSpecificService
    ) {}
}