import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-how-activity',
  templateUrl: './how-activity.component.html',
  styleUrls: ['./how-activity.component.scss']
})
export class HowActivityComponent {
  rtImagePath = environment.fileRetriever;
}
