
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark "  data-bs-theme="dark">
        <div class="container-fluid">
          <a 
          class="navbar-brand" 
          (click)="goToDashboard()"
          style="border: 1px solid white;">
            <span style="padding: 15px 7px 15px 7px; ">
                mxp
            </span>
          </a>
          <button 
          class="navbar-toggler" 
          type="button" 
          data-bs-toggle="collapse" 
          data-bs-target="#navbarSupportedContent" 
          aria-controls="navbarSupportedContent" 
          aria-expanded="false" 
          aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div 
            class="collapse navbar-collapse" 
            id="navbarSupportedContent">

            <ul 
            class="navbar-nav me-auto mb-2 mb-lg-0">
              
            </ul>

            <span class="navbar-text">
              


              <!-- if user is logged in -->
              <ul 
                *ngIf="userSvc.loggedIn$ | async"
                class="navbar-nav me-auto mb-2 mb-lg-0">
            
                <li class="nav-item">
                  <div class="dropdown dropstart">

                    <a 
                      style="border: 1px solid green; color: green; margin: 2px 10px 0 0;"
                      class="nav-link btn btn-outline-primary dropdown-toggle" 
                      data-bs-toggle="dropdown" 
                      href="#">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
                      </svg>
                    </a>

                    <ul class="dropdown-menu">

                      <li *ngIf="canViewDashboard$ | async">
                        <button 
                          class="dropdown-item" 
                          (click)="goToHostDashboard()"
                          type="button">
                          
                            Activity-Host Dashboard
                        </button>
                      </li>

                      <li *ngIf="canViewEventDashboard$ | async">
                        <button 
                          class="dropdown-item" 
                          (click)="goToEventHostDashboard()"
                          type="button">
                          
                            Event-Host Dashboard
                        </button>
                      </li>


                      <li>
                        <button 
                          class="dropdown-item" 
                          (click)="goToProfile()"
                          type="button">
                          
                            Profile
                        </button>
                      </li>
                      <li>
                        <button 
                        class="dropdown-item"
                        type="button"
                        (click)="logOff()">
                          Sign out
                        </button>
                      </li>
                    </ul>
                  </div>
                </li>
               
              </ul>


              <!-- if user is not logged in -->
              <ul 
                *ngIf="!(userSvc.loggedIn$ | async)"
                class="navbar-nav me-auto mb-2 mb-lg-0">
                
                <li class="nav-item">
                  <a 
                  style="border: 1px solid green; color: green; margin: 2px 10px 0 0;"
                  class="nav-link btn btn-outline-primary" 
                  aria-current="page" 
                  (click)="goToLogin()">
                    Login
                  </a>
                </li>

                <li class="nav-item">
                  <a 
                  style="border: 1px solid green; color: green; margin: 2px 10px 0 0;"
                  class="nav-link btn btn-outline-primary" 
                  (click)="goToRegister()">
                  Sign up
                  </a>
                </li>
              
              </ul>
            </span>

          </div>
        </div>
      </nav>