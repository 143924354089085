<div class="container">

    <!-- main definition -->
    <h2 style="margin-top: 8px;">
        # Competition
    </h2>

    <hr>
      
    <p>
        A competition is a type of event where two individuals or groups compete against each other to win a title. 
        An event host creates a competition which could be arcade, sports, academics, arts, gaming, culinary arts, 
        fitness, and more...

    </p>

</div>