import { createFeatureSelector, createSelector } from "@ngrx/store";
import { FutureEventCreatorsState } from "../state/future-events-creator.state";

export const selectFutureEventCreatorState = createFeatureSelector<FutureEventCreatorsState>('futureeventscreators');

export const selectFutureEventCreators = createSelector(
    selectFutureEventCreatorState,
  (state: FutureEventCreatorsState) => state.futureeventscreators
);

export const selectFutureEventCreatorsLoading = createSelector(
    selectFutureEventCreatorState,
  (state: FutureEventCreatorsState) => state.loading
);

export const selectFutureEventCreatorsLoaded = createSelector(
    selectFutureEventCreatorState,
    (state: FutureEventCreatorsState) => state.loaded
  );

export const selectFutureEventCreatorsError = createSelector(
    selectFutureEventCreatorState,
  (state: FutureEventCreatorsState) => state.error
);
