import { createAction, props } from "@ngrx/store";
import { ActivityPromotersLog } from "src/app/types/models/activityPromotersLog.model";


// get all the activities that a promoter user is promoting
export const LOAD_ACTIVITY_PROMOTER_LOGS_FOR_A_USER = createAction('[ActivityPromotersLog] Load Activity Promoters Log for a user',
    props<{ activityId: number, pageNumber: number, pageSize: number }>());

export const LOAD_ACTIVITY_PROMOTER_LOGS_FOR_A_USER_SUCCESS = createAction('[ActivityPromotersLog] Load activity Promoters Log for a user Success',
    props<{activityPromoterLogs: ActivityPromotersLog[]}>());

export const LOAD_BOOKINGS_FAIL = createAction('[ActivityPromotersLog] Load activity Promoters Log for a user Fail',
    props<{error: string}>());