import { Component } from '@angular/core';
import { AddressModel } from 'src/app/types/models/addressModel.model';

@Component({
  selector: 'app-selected-comps',
  templateUrl: './selected-comps.component.html',
  styleUrls: ['./selected-comps.component.scss']
})
export class SelectedCompsComponent {
  onAddressSelected(address: AddressModel) {
    // Save the address to the database or handle it as needed
    console.log('Selected Address:', address);
  }
}
