import { createAction, props } from "@ngrx/store";
import { GenericUser } from "src/app/types/models/genericuser.model";


// creators of future events
export const GET_CREATORS_OF_FUTURE_EVENTS = createAction('[Generic Users] Get Creators of Future Events',
    props<{ arrayOfUserId: string[] }>());

export const GET_CREATORS_OF_FUTURE_EVENTS_SUCCESS = createAction('[Generic Users] Get Creators of Future Events Success',
    props<{futureeventscreators: GenericUser[]}>());

export const GET_CREATORS_OF_FUTURE_EVENTS_FAIL = createAction('[Generic Users] Get Creators of Future Events Fail',
    props<{error: string}>());