import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, delay, map, mergeMap, of, tap } from "rxjs";
import { ActivityService } from "src/app/shared/services/activity.service";
// import * as ActivityActions from "../actions/activity.actions";
// import { LOAD_ACTIVITIES, LOAD_ACTIVITIES_FAIL, LOAD_ACTIVITIES_SUCCESS } from "../actions/activity.actions";
import * as ActivityActions from "../actions/activity.actions";
import { Activity } from "src/app/types/models/activity.model";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable()
export class ActivityEffects {

  pageNumber: number = 1;
  pageSize: number = 2;

  loadActivities$ = createEffect(() => this.actions$.pipe(
      ofType(ActivityActions.LOAD_ACTIVITIES),
      mergeMap(() => this.activityService.GetAllActivitiesWithPagination(this.pageNumber, this.pageSize)
      .pipe(
          tap((activities: Activity[]) => {
              console.log('Activities loaded successfully:', activities);
          }),
          map(activities => ActivityActions.LOAD_ACTIVITIES_SUCCESS({ activities })),
          catchError(error => of(ActivityActions.LOAD_ACTIVITIES_FAIL({ error })))
      ))
      )
  );

  getActivityById$ = createEffect(() => this.actions$.pipe(
    ofType(ActivityActions.GET_ACTIVITY_BY_ID),
    mergeMap(action => this.activityService.GetActivityById(action.activityId)
    .pipe(
        tap((activity: Activity) => {
          if(!environment.production)
          {
            console.log('Activity loaded successfully:', activity);
          }
        }),
        map(activity => ActivityActions.GET_ACTIVITY_BY_ID_SUCCESS({ activity })),
        catchError(error => of(ActivityActions.GET_ACTIVITY_BY_FAIL({ error })))
    ))
    )
  );


  getActivitiesByUserId$ = createEffect(() => this.actions$.pipe(
    ofType(ActivityActions.GET_ACTIVITIES_BY_USERID),
    mergeMap(action => this.activityService.GetAllActivitiesByUserId(action.userId)
    .pipe(
        // delay(10000),  // Introduce a 10-second delay 
        tap((usrActivities: Activity[]) => {
          
          if(!environment.production)
          {
            console.log(`Activities of user ${action.userId} loaded successfully: ${ JSON.stringify(usrActivities)}`);
          }
        }),
        map(usrActivities => ActivityActions.GET_ACTIVITIES_BY_USERID_SUCCESS({ usrActivities })),
        catchError(error => of(ActivityActions.GET_ACTIVITIES_BY_USERID_FAIL({ error })))
    ))
    )
  );

  addActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityActions.ADD_ACTIVITY),
      mergeMap(action =>
        this.activityService.AddActivity(action.activity).pipe(
          map((activity: Activity) => ActivityActions.ADD_ACTIVITY_SUCCESS({ activity })),
          catchError(error => of(ActivityActions.ADD_ACTIVITY_FAILURE({ error })))
        )
      )
    )
  );

  addActivitySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActivityActions.ADD_ACTIVITY_SUCCESS),
        tap(() => this.router.navigate(['activitylist']).then(() => {
          //this is a temporary solution to make sure that the new data activity reads
          //Reason: there is a separation in states: activities and activeactivities  
          window.location.reload()
        }))
      ),
    { dispatch: false }
  );


  constructor(
    private actions$: Actions,
    private router: Router,
    private activityService: ActivityService
  ) {}
}