import { createAction, props } from "@ngrx/store";
import { GenericRole } from "src/app/types/models/genericrole.model";

export const GET_APP_ROLES = createAction('[Generic Roles] Get application roles');

export const GET_APP_ROLES_SUCCESS = createAction('[Generic Roles] Get application roles Success',
    props<{applicationroles: GenericRole[]}>());

export const GET_APP_ROLES_FAIL = createAction('[Generic Roles] Get application roles Fail',
    props<{error: string}>());
