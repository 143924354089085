<div class="container mat-dialog-container">
    <mat-dialog-content>
        <mat-dialog-actions style="float: left; z-index: 1; margin-top: 8px;">
            <p style="font-size: 1.6em;">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-receipt" viewBox="0 0 16 16">
                    <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27m.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0z"/>
                    <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5"/>
                </svg>
                RSVP
            </p>
        </mat-dialog-actions>
        
        <mat-dialog-actions style="float: right; z-index: 1; ">
            <span>
                <button 
                mat-raised-button 
                color="warn" 
                (click)="confirmAndClose()">
                    X
                </button>
            </span>
        </mat-dialog-actions>
        <br> <br>
        <hr>

    </mat-dialog-content>

    <div 
    style="margin: 10px;">
        <!-- Details: {{activityModelToBeActedOn.Details | json}} -->
        <form 
        [formGroup]="bookingForm!"
        style="text-align: center;">
        
            <!-- ID -->
            <mat-form-field 
            [hidden]="bookingMetadataHidden"
            style="width: 100%;"
            appearance="outline">
                <mat-label>ID</mat-label>
                <input 
                    readonly="true"
                    matInput 
                    formControlName="Id">
            </mat-form-field>


            <!-- Generic User ID -->
            <mat-form-field 
            [hidden]="bookingMetadataHidden"
            style="width: 100%;"
            appearance="outline">
                <mat-label>Generic User ID</mat-label>
                <input 
                    readonly="true"
                    matInput 
                    formControlName="GenericUserId">
            </mat-form-field>

            <!-- Activity ID -->
            <mat-form-field 
            [hidden]="bookingMetadataHidden"
            style="width: 100%;"
            appearance="outline">
                <mat-label>Entity ID</mat-label>
                <input 
                    readonly="true"
                    matInput 
                    formControlName="EntityId">
            </mat-form-field>

            <!-- Price Per Person -->
            <mat-form-field 
            style="width: 100%;"
            appearance="outline">
                <mat-label>Price Per Person</mat-label>
                <input 
                    readonly="true"
                    matInput 
                    formControlName="PricePerPerson">
                <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
                <!-- <mat-hint>Hint</mat-hint> -->
            </mat-form-field>

            <!-- No. Of Participants -->
            <mat-form-field 
            style="width: 100%;"
            appearance="outline">
                <mat-label>Participant(s)</mat-label>
                <input 
                    readonly="true"
                    matInput 
                    formControlName="NumberOfParticipants">
                
                    <mat-icon matSuffix>
                    <span (click)="addMoreParticipantsToABooking()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                        </svg>
                    </span>
                </mat-icon>
                <!-- add new participant(s) -->
                <mat-hint>
                    <span 
                        (click)="addMoreParticipantsToABooking()"
                        style="color: blue;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                        </svg>

                        <a style="text-decoration: none;">
                            Add More Participants
                        </a>
                    </span>
                </mat-hint>
            </mat-form-field>

            <br><br>

              <!-- Service fee -->
              <mat-form-field 
              style="width: 100%;"
              appearance="outline">
                  <mat-label>Service fee Per Person</mat-label>
                  <span 
                      style="margin-left: 10px;"
                      matPrefix>
                      ZAR&nbsp;
                  </span> 
                  <input 
                      readonly="true"
                      matInput 
                      formControlName="ServiceFee">
              </mat-form-field>
              

            <!-- Booking date (NEW) -->
            <app-date-picker 
                (dateChange)="onDateChange($event)"
                placeholder="Select Booking Date"
                [minDate]="getCurrentDate()"
                appearance="outline"></app-date-picker>
            
             <!-- Booking time (NEW) -->
             <app-time-picker
                (timeChange)="onTimeChange($event)"
                placeholder="Select Start Time"
                appearance="outline"></app-time-picker>

            <!-- Booking date (OLD) -->
            <!-- <mat-form-field 
            style="width: 100%;"
            appearance="outline">
                <mat-label>Booking date</mat-label>
                <input 
                    [matDatepicker]="esdPicker"
                    [min]="getCurrentDate()"
                    readonly="true"
                    matInput 
                    (dateChange)="onBookingSelectionChange()"
                    formControlName="BookingDate">
                <mat-datepicker-toggle matIconSuffix [for]="esdPicker"></mat-datepicker-toggle>
                <mat-datepicker #esdPicker ></mat-datepicker>
            </mat-form-field> -->

            <div>
                <button 
                    mat-raised-button 
                    color="primary"
                    [disabled]="!bookingForm?.valid"
                    (click)="completeActivityBooking()"
                    style="width: 100%;">
                    Complete RSVP
                </button>
            </div>
        </form>

    </div>

</div>

