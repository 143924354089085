import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map } from 'rxjs';
import { ObfuscationServiceService } from 'src/app/shared/services/obfuscation-service.service';
import { MEEntity } from 'src/app/types/enums/meEntity.enum';

@Component({
  selector: 'app-event-history',
  templateUrl: './event-history.component.html',
  styleUrls: ['./event-history.component.scss']
})
export class EventHistoryComponent {
  
  mEEntity: MEEntity = MEEntity.Event;
  entityId: number = 0;
  
  constructor(private route: ActivatedRoute,
    private router: Router,
    private obfuscationService: ObfuscationServiceService
  ) { }

  ngOnInit() {
    const eventId$ = this.route.paramMap.pipe(
      map(paramMap => paramMap.get('eventId')),
      filter(eventId => !!eventId)
    );

    eventId$.subscribe(eventId => {
      if (eventId) {
        this.entityId = +this.obfuscationService.decryptEventId(eventId)!; // The + operator converts the string to a number
      } else {
        this.entityId = 0; // Default or fallback value

        this.router.navigate(['']) //go to core
      }
    });
  }
}
