import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { DefaultModule } from './layouts/default/default.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpInterceptorService } from './shared/utils/http-interceptor.service';
import { PragmaInterceptorService } from './shared/utils/interceptors/pragma-interceptor.service';
import { clientIdInterceptor } from './shared/utils/interceptors/client-id.interceptor';

@NgModule({ declarations: [
      AppComponent,
    ],
    bootstrap: [AppComponent], 
    imports: [BrowserModule,
        AppRoutingModule,
        DefaultModule,
        BrowserAnimationsModule], 
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: PragmaInterceptorService,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi())
    ] 
})
export class appModule { }
