import { Component } from '@angular/core';

@Component({
  selector: 'app-user-funds',
  templateUrl: './user-funds.component.html',
  styleUrls: ['./user-funds.component.scss']
})
export class UserFundsComponent {

}
