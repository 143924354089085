import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, tap } from "rxjs";
import { ActivityService } from "src/app/shared/services/activity.service";
import { Activity } from "src/app/types/models/activity.model";
import { GET_ACTIVE_ACTIVITIES, GET_ACTIVE_ACTIVITIES_FAIL, GET_ACTIVE_ACTIVITIES_SUCCESS } from "../actions/active-activity.actions";

@Injectable()
export class ActiveActivityEffects {

    getActiveActivities$ = createEffect(() => this.actions$.pipe(
        ofType(GET_ACTIVE_ACTIVITIES),
        mergeMap(action => this.activityService.GetAllActivitiesByStatusWithPagination(action.statusCode, action.pageNumber, action.pageSize)
        .pipe(
            tap((activeactivities: Activity[]) => {
                console.log(`${activeactivities.length} Activities with status ${action.statusCode} loaded successfully:`);
            }),
            map(activeactivities => GET_ACTIVE_ACTIVITIES_SUCCESS({ activeactivities })),
            catchError(error => of(GET_ACTIVE_ACTIVITIES_FAIL({ error })))
        ))
        )
    );

  constructor(
    private actions$: Actions,
    private activityService: ActivityService
  ) {}
}