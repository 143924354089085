import { createReducer, on } from "@ngrx/store";
import { ApplicationRoleState } from "../state/application-roles.state";
import { GET_APP_ROLES, GET_APP_ROLES_FAIL, GET_APP_ROLES_SUCCESS } from "../actions/application-roles.actions";

const initialState: ApplicationRoleState = {
    applicationroles: [],
    loading: false,
    loaded: false,
    error: null,
};

const _applicationRolesReducer = createReducer(
    initialState,
    on(GET_APP_ROLES, state => ({ ...state, loading: true })),
    on(GET_APP_ROLES_SUCCESS, (state, { applicationroles }) => ({
        ...state,
        applicationroles,
        loading: false,
        loaded: true,
        error: null,
    })),
    on(GET_APP_ROLES_FAIL, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error,
    }))
);

export function applicationRolesReducer(state: any, action: any) {
    return _applicationRolesReducer(state, action);
  }