<div class="wrapper">
    <div class="card">
        <h3 class="card-title">
            Promotion log(s)
        </h3>

        <div class="button-container">
            <button 
            (click)="viewActivitiesIAmPromoting()"
            class="card-btn"
            *ngIf="activitiesPromoting$ | async as activitiesPromoting">
                {{activitiesPromoting.length }}
            </button>
        </div>
	</div>

	<div class="card">
		<h3 class="card-title">
            Succssful Promotions
        </h3>

		<div class="button-container">
            <button 
            (click)="viewSuccessfulPromotionHistory()"
            class="card-btn">
                X
            </button>
        </div>
	</div>

	<!-- <div class="card">
		<h3 class="card-title">
            Total Income
        </h3>
        <div class="button-container">
            <button class="card-btn">
                R X
            </button>
        </div>
	</div>
  
    <div class="card">
		<h3 class="card-title">
            Viewers
        </h3>
		
        <div class="button-container">
            <button class="card-btn">
                X
            </button>
        </div>
	</div>
     -->
</div>