import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ActivityIAmPromotingState } from "../state/activities-I-Am-Promoting.state";

export const selectActivitiesIAmPromotingState = createFeatureSelector<ActivityIAmPromotingState>('activitiesiampromoting');

export const selectActivitiesIAmPromotingWithPagination = createSelector(
    selectActivitiesIAmPromotingState,
  (state: ActivityIAmPromotingState) => state.activitiesiampromoting
);

export const selectActivitiesIAmPromotingWithPaginationLoading = createSelector(
    selectActivitiesIAmPromotingState,
  (state: ActivityIAmPromotingState) => state.loading
);

export const selectActivitiesIAmPromotingWithPaginationLoaded = createSelector(
    selectActivitiesIAmPromotingState,
    (state: ActivityIAmPromotingState) => state.loaded
  );

export const selectActivitiesIAmPromotingWithPaginationError = createSelector(
    selectActivitiesIAmPromotingState,
  (state: ActivityIAmPromotingState) => state.error
);