<ng-container *ngIf="eventStatus == 0 && pastEventsLoaded$ | async">
    <app-maixevent 
        [loading$]="pastEventsLoading$" 
        [futureEvents$]="pastEvents$" 
        [futureCombinedData$]="pastCombinedData$"
        [rtImgPath]="rtImgPath"
        [useId$]="useId$">
    </app-maixevent>
</ng-container>



<ng-container *ngIf="eventStatus == 1 && liveEventsLoaded$ | async">
    <app-maixevent 
        [loading$]="liveEventsLoading$" 
        [futureEvents$]="liveEvents$" 
        [futureCombinedData$]="liveCombinedData$"
        [rtImgPath]="rtImgPath"
        [useId$]="useId$">
    </app-maixevent>
</ng-container>

<ng-container *ngIf="eventStatus == 2 && loaded$ | async">
    
    <app-maixevent 
        [loading$]="loading$" 
        [futureEvents$]="futureEvents$" 
        [futureCombinedData$]="futureCombinedData$"
        [rtImgPath]="rtImgPath"
        [useId$]="useId$"
        [eventStatus]="eventStatus"
        [pageNumber]="pageNumber"
        [pageSize]="pageSize"
        (fetchMoreEvents)="loadMoreEvents($event)">
    </app-maixevent>

</ng-container>
