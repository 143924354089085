export class UserSubscription
{
    constructor( 
      public Id: number,
      public UserId: string,
      public TierId: number,
      public BillingCycle: string,
      public StartDate: string,
      public EndDate: string,
      public IsActive: Boolean
      ){} 
}