import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { GET_CURRENT_USER_ROLES } from 'src/app/ngrx/actions/current-user-roles.actions';
import { AppState } from 'src/app/ngrx/state/app.state';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { MaiXEventService } from 'src/app/shared/services/maiXEventRelated/mai-xevent.service';
import { MaiXEvent } from 'src/app/types/models/maixevent.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-host-event-list',
  templateUrl: './host-event-list.component.html',
  styleUrls: ['./host-event-list.component.scss']
})
export class HostEventListComponent {
  userId: any;
  rtImgPath: string = environment.fileRetriever;

  private useIdSubject = new BehaviorSubject<string | null>('');
  userId$: Observable<string | null> = this.useIdSubject.asObservable();

  private hosteventListSubject = new BehaviorSubject<MaiXEvent[] | null>(null);
  hosteventList$: Observable<MaiXEvent[] | null> = this.hosteventListSubject.asObservable();

  showButtonInFirstCard = true;
  pageNumber: number = 1;
  pageSize: number = 50;

  constructor(
    private authSvc: AuthService,
    private eventSvc: MaiXEventService,
    private router: Router,
    private store: Store<AppState>
    ) { 
      // call the user from store
    }
  
  ngOnInit(){
    // if no user is in store, dispath and wait for response



    this.authSvc.GetCurrentlyLoggedInUserIdIfAny()?.subscribe({
      next: (response: string) => {
        this.userId = response;
        this.useIdSubject.next(response);
        
        if (!environment.production) {
          console.log(`About to dispatch current user roles`);
        // console.log("this user id " + JSON.stringify(this.useIdSubject.value))
          console.log("this user id 2" + JSON.stringify(this.userId))

        }
        
        // Dispatch action to get user roles if role does not exist
        this.store.dispatch(GET_CURRENT_USER_ROLES());

        this.eventSvc.GetAllEventsForAUserWithPagination(this.userId, this.pageNumber, this.pageSize)?.pipe(
          map((eventResponse: any[]) => {

            // Filter activities to include only those with status 1 = active or 2 = future
            return eventResponse.filter(evnt => (evnt.Status == 1) || (evnt.Status == 2));
          })
        )
        .subscribe({
          next: (eventResponse) => {
            this.hosteventListSubject.next(eventResponse)
            
            if (!environment.production) {
              console.log("host event list " + JSON.stringify(eventResponse));
            }
          },
          error: (eventError) => {
            if (!environment.production) {
              console.log("Unable to get events: " + eventError);
            }
          }
        });

      },
      error: (err: any) => {
        if(!environment.production)
          {
            console.log("Unable to get logged in user: " + err);
          }
      },
      complete() {
          if(!environment.production)
            {
              console.log("Complete");
            }
          }
      });
    }


  backToDashboard()
  {
    this.router.navigate(['eventhost']);
  }
}
