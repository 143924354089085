import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, NgZone, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, NgForm, ValidatorFn, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
// import { MapsAPILoader } from '@agm/core';
import { ActivityService } from 'src/app/shared/services/activity.service';
import * as moment from 'moment-timezone';

import { PerformerclassService } from 'src/app/shared/services/performerclass.service';
import { AddressService } from 'src/app/shared/services/address.service';
import { DatePipe, Time } from '@angular/common';
import { ActivitySlotService } from 'src/app/shared/services/activity-slot.service';
import { Observer, Subscription, tap } from 'rxjs';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
// import { EventStatus, MaiXEvent } from '../selected-events/selected-events.component';
import { DateService } from 'src/app/shared/services/date.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MaiXEventService } from 'src/app/shared/services/maiXEventRelated/mai-xevent.service';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { MatStepper } from '@angular/material/stepper';
import { PriceCategoryType } from 'src/app/types/enums/priceCategoryType.enum';
import { SlotCategoryType } from 'src/app/types/enums/slotCategoryType.enum';
import { ActivityType } from 'src/app/types/enums/activityType.enum';
import { AddressModel } from 'src/app/types/models/addressModel.model';
import { UIError } from 'src/app/types/models/uiError.model';
import { MaiXEvent } from 'src/app/types/models/maixevent.model';
import { EventStatus } from 'src/app/types/enums/eventStatus.enum';
import { ToastrService } from 'ngx-toastr';
import * as EventActions from 'src/app/ngrx/actions/maixevent.actions';
import { AppState } from 'src/app/ngrx/state/app.state';
import { Store, select } from '@ngrx/store';
import { selectLoggedInUserRoles } from 'src/app/ngrx/selectors/current-user-roles.selectors';
import { GET_CURRENT_USER_ROLES } from 'src/app/ngrx/actions/current-user-roles.actions';


@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.scss'],
  providers: [  
    MatDatepickerModule,
    DatePipe 
  ],
})
export class AddEventComponent {
  @Input()
  requiredFileType:string | undefined;

  progress: number | undefined;
  message: string | undefined;
  @Output() public onUploadFinished = new EventEmitter();

  //UI Error handlers for activity model
  UIUserInteractionError: UIError = new UIError();
  ActivityNameErroHandler: UIError = new UIError();
  ActivityDescErroHandler: UIError = new UIError();
  // MinTeamParticipantErroHandler: UIError = new UIError();
  // MaxTeamParticipantErroHandler: UIError = new UIError();
  PricePerPersonErroHandler: UIError = new UIError();




  //UI Error handlers for activity slot model
  ActivityIdErroHandler: UIError = new UIError();
  MaxCapacityErroHandler: UIError = new UIError();
  PricePerSlotErroHandler: UIError = new UIError();
  CurrencyIdErroHandler: UIError = new UIError();
  PriceCategoryErroHandler: UIError = new UIError();
  OpeningTimeErroHandler: UIError = new UIError();
  ClosingTimeErroHandler: UIError = new UIError();
  slotCategoryTypeErroHandler: UIError = new UIError();

  fileName = '';
  uploadProgress:number | undefined;
  uploadSub: Subscription | undefined;

  
  activitySlotModel: any;

 
  PriceCategoryTypes = Object.values(PriceCategoryType).filter((key:any) => 
    !isNaN(Number(PriceCategoryType[key]))) as string[];
  
  selectedPCT = null;



  slotCategoryTypes = Object.values(SlotCategoryType).filter((key:any) => 
    !isNaN(Number(SlotCategoryType[key]))) as string[];
  
  selectedSlotCT = null;

  
  firstFiveselFiles: string = '';

  selFiles: string[] = [];

  rtImgPath: string = environment.fileRetriever;
  

  ActivityStep: boolean = false

  public activityTypes = Object.values(ActivityType).filter((entry:any) => isNaN(entry))
  

  panelOpenState = false;

  //Panels
  activityDetailsOpenState = true; //panel 1
  activityImagesOpenState = false; //panel 2
  activitySlotOpenState = false; //panel 3
  performaceClassesPanelOpenState = false; //panel 4
  locationPanelOpenState = false; //panel 5


  //step 1 form
  public ActivityDetailsForm: FormGroup = this.formBuilder.group('');

  // step 2 form
  public ActivityImagesForm: FormGroup = this.formBuilder.group('');

  // public AddActivityForm: FormGroup = this.formBuilder.group('');

  //PC vars
  perfomanceClassArray: ActivityType[] = [];


  anAddressIsSelected: boolean = false;

  eventAddr: AddressModel = {
    Id: 0,
    Street_number: '',
    Street: '',
    Suburb: '',
    City: '',
    State: '',
    IsDefault: false,
    Building_name: '',
    Unit_number: '',
    Address_instruction: '',
    Country: '',
    Postal_code: '',
    Longitude: '',
    Latitude: '',
    CreatedAt: null,
    ModifiedAt: null
  }


  imgSelectMaxSize = environment.maxImageSizeMB;

  @ViewChild('search')
  public searchElementRef: ElementRef = new ElementRef<any>(null);

  imgFormGroup: FormGroup = this.formBuilder.group('');
  
  thirdFormGroup: FormGroup = 
    this.formBuilder.group({thirdCtrl: ['']});
  

  startTimeConvert: any;
  endTimeConvert: any;

  endTimeForm: FormGroup | undefined;
  // selectedDateTime: any;
  
  // dateTimeControl = new FormControl(new Date());

  
  subscription: Subscription | undefined;

  loggedinUserId: any;

  //=================================================

  //event details form group
  eventFormGroup!: FormGroup //= this.formBuilder.group('');

  //event object model 
  eventModel!: MaiXEvent;

  startDateVar: Date = new Date();

  constructor(
    private router: Router,
    private dateService: DateService,
    private addrService: AddressService,
    private authSvc: AuthService,
    private dialogSvc: DialogService,
    private formBuilder: FormBuilder,
    private store: Store<AppState>) 
  {
  }

  ngOnInit(): void 
  {
      //#region instance_of_MaiXEvent
  
      this.eventModel = new MaiXEvent 
      (
        0, //Id
        this.loggedinUserId, //userId
        undefined, //Name
        undefined, //Description
        undefined, //AddressId
        undefined, //PricePerPerson
        undefined, //MaxNumberOfInviteesForEvent - max capacity of venue
        '', //Images
        '', //Default Image
        '', //EventStartDateTime
        '', //EventEndDataTime
        undefined, //CreatedDate
        this.dateService.determineDateStatus(
          this.eventModel?.EventStartDateTime.toString() ?? new Date().toISOString()
        ), //use to event startdate to determine the STATUS of the event
        undefined //ModifiedDate
      );

      //#endregion

      //#region initialize_the_event_form

      // initialize the event form and append userId from above
      this.eventFormGroup = this.formBuilder.group({
        Id: new FormControl(0),
        UserId: new FormControl(this.eventModel?.UserId, Validators.required),
        Name: new FormControl(this.eventModel?.Name, Validators.required),
        Description: new FormControl(this.eventModel?.Description, Validators.required),
        PricePerPerson: new FormControl(this.eventModel?.PricePerPerson, [Validators.required, Validators.pattern('^[0-9]*$')]),
        MaxNumberOfInviteesForEvent: new FormControl(this.eventModel?.MaxNumberOfInviteesForEvent, [Validators.required, Validators.pattern('^[0-9]*$')]),
        EventStartDate: new FormControl(this.eventModel?.EventStartDateTime, Validators.required), 
        EventStartTime: new FormControl(undefined, Validators.required), 
        EventEndDate: new FormControl(this.eventModel?.EventEndDataTime, Validators.required),
        EventEndTime: new FormControl(undefined, Validators.required)
      });
      
      //#endregion

      //#region initialize_the_eventImage_form_and_places_autocomplete
      this.imgFormGroup = this.formBuilder.group({
        formSelectedFiles: [[], Validators.required]
      })


      this.endTimeForm = this.formBuilder.group({
        hours: [null, Validators.required],
        minutes: [null, Validators.required]
      });

      

      //#endregion

      //#region getUserIdOfLoggedInUser_and_patch_UserId


      //get the logged in user ID
      this.subscription = this.authSvc.GetCurrentlyLoggedInUserIdIfAny()?.subscribe({
        next: (response: string) => {
          this.loggedinUserId = response;

          //patch the user ID
          this.eventFormGroup.patchValue({
            UserId: this.loggedinUserId
          });

          if (!environment.production) {
            console.log(`About to dispatch current user: ${this.loggedinUserId} roles`);
          }

          // Dispatch action to get user roles
          this.store.dispatch(GET_CURRENT_USER_ROLES());
        },
        error: (err: any) => {
          if(!environment.production)
          {
            console.log("Unable to get logged in user: " + err);
          }
        },
        complete() {
          if(!environment.production)
          {
            console.log("Complete");
          }
        }
      })
    


      //#endregion

  }

  
  getCurrentDate(): Date {
    return new Date();
  }

  onStartDateChange()
  {
    const eventStartDateValue = this.eventFormGroup.get('EventStartDate')?.value;

    if(!environment.production)
    {
      console.log('Event Start Date RAW:', eventStartDateValue);
    }

    if (eventStartDateValue) 
    {
      const eventStartDate = new Date(eventStartDateValue);
      
      this.startDateVar = eventStartDate;

      //the timezone will eventually be replaced by a global service based on user selection
      let timezone = 'Africa/Johannesburg';
    
      const eventStartDateUTC = moment(eventStartDateValue).tz(timezone).toString()

      if(!environment.production)
      {
        console.log(`Event Start Date: ${eventStartDate} && Event Start Date UTC moment: ${eventStartDateUTC} `);
      }
    }
  }

  onTimeChange(newTime: string) {
    this.startTimeConvert = this.convertTo24HourFormat(newTime);

    if(!environment.production)
    {
      console.log(`start time:, ${newTime} | converted 24: ${this.startTimeConvert}`);
    }
  }

  //this method plays with moment.js convertion with timzone
  onEndDateChange()
  {
    const eventEndDateValue = this.eventFormGroup.get('EventEndDate')?.value;

    console.log('Event End Date RAW:', eventEndDateValue);

    if (eventEndDateValue) {
      const eventEndDate = new Date(eventEndDateValue);

      //the timezone will eventually be replaced by a global service based on user selection
      let timezone = 'Africa/Johannesburg';
    
      const eventEndDateUTC = moment(eventEndDateValue).tz(timezone).toString()

      if(!environment.production)
      {
        console.log(`Event Start Date:', ${eventEndDate} && Event Start Date UTC moment: ${eventEndDateUTC} `);
      }

      //convert to UTC
    }
  }

  onEndTimeChange(event: any) 
  {
    this.endTimeConvert = this.convertTo24HourFormat(event);    

    if(!environment.production)
    {
      console.log(`end time:, ${event} | converted 24: ${this.endTimeConvert}`);
    }
  }
  
  convertTo24HourFormat(timeString: string): string {
    const [time, period] = timeString.split(' ');

    let [hours, minutes] = time.split(':').map(Number);

    if (period === 'PM' && hours !== 12) {
        hours += 12;
    } else if (period === 'AM' && hours === 12) {
        hours = 0;
    }

    // Ensure leading zeros for single-digit hours and minutes
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
  }

  extractHourAndMinute(timeString: string): { hour: number, minute: number } {
    const [hourStr, minuteStr] = timeString.split(':').map(Number);
    return { hour: hourStr, minute: minuteStr };
  }

  activityDetailsCaptured()
  {
    const startDateValue: string = this.eventFormGroup.get('EventStartDate')?.value; // Get the date value from the form control
    const startDateObject: Date = new Date(startDateValue); // Convert the date string to a Date object
    
    const endDateValue: string = this.eventFormGroup.get('EventEndDate')?.value; // Get the date value from the form control
    const endDateObject: Date = new Date(endDateValue); // Convert the date string to a Date object

    if(!environment.production)
    {
      console.log(`Hello world.. going to images: ${JSON.stringify(this.eventFormGroup.value)}`);
    }
    
    
    let eventStartDateTime = new Date(
      startDateObject.getFullYear(),
      startDateObject.getMonth(),
      startDateObject.getDay(),
      this.extractHourAndMinute(this.startTimeConvert).hour,
      this.extractHourAndMinute(this.startTimeConvert).minute
    )

    let eventEndDateTime = new Date(
      endDateObject.getFullYear(),
      endDateObject.getMonth(),
      endDateObject.getDay(),
      this.extractHourAndMinute(this.endTimeConvert).hour,
      this.extractHourAndMinute(this.endTimeConvert).minute
    )

    if(!environment.production)
    {
      console.log(`start date: ${JSON.stringify(eventStartDateTime)}`);
      console.log(`end date: ${JSON.stringify(eventEndDateTime)}`);
    }
  
  }

  onAppendFilesUploaded(fileNames: string[])
  {
    console.log('Appended files:', fileNames);

    this.selFiles = [...this.selFiles, ...fileNames];

    if(!environment.production)
    {
      console.log(this.selFiles); // Updated array after appending
    }
  }

  onFilesUploaded(fileNames: string[]): void {
    this.selFiles = fileNames;
    
    if(!environment.production)
    {
      console.log('Uploaded files:', fileNames);
    }
    
  }

  goToLocationStep(stepper: MatStepper): void 
  {
    stepper.next();
  }

  @ViewChild('fileInput') fileInput: any;
  async appendFileSelection()
  {
    // Trigger click event on the file input element
    this.fileInput.nativeElement.click();
  }


  removeSelection(element: any){
    this.selFiles = this.selFiles.filter(item => item !== element);

    if(!environment.production)
    {
      console.log(this.selFiles); // Updated array after removal
    }
  }
  
  openSelectedImages(){
    //call new image upload dialog service
    this.dialogSvc.NewImageUploadsDialog(this.selFiles);

    event?.preventDefault();
    event?.stopPropagation();
  }

  goToEvents()
  {
    // this.router.navigate(['eves'])
    this.router.navigate(['eventsperstatus']);

  }

 
  determineEventStatus() : EventStatus
  {
    //Fact: Event can not be in the past

    //Use the EventStartDate and Time to check if it past, current, or future
    return EventStatus.Future;
  }

  onAddressSelected(address: AddressModel) {
    // Save the address to the database or handle it as needed
    console.log('Selected Address in new activity:', address);

    if(this.addrService.validateSelectedAddress(address))
    {
      this.anAddressIsSelected = true;
      this.eventAddr = address
    }
  }

  completeEventAdd()
  {
    //prepare data to be persisted
    if(!environment.production)
    {
      console.log(`event add kick off...`);
      console.log(`selfiles: ${this.selFiles}`);
    }
    
    let evntStDate = new Date(this.eventFormGroup.get('EventStartDate')?.value);

    let evntEndDate = new Date(this.eventFormGroup.get('EventEndDate')?.value);

    // === Extract and format start time ST
    // Parse the time string to extract hours, minutes, and AM/PM indicator
    let timeParts = this.eventFormGroup.get('EventStartTime')?.value.split(':');
    let strtHours = parseInt(timeParts[0]);
    let startMinutes = parseInt(timeParts[1]);

    //set event start hours and minutes
    evntStDate.setHours(strtHours);
    evntStDate.setMinutes(startMinutes);
    // === Extract and format start time END


    // === Extract and format start time ST
    // Parse the time string to extract hours, minutes, and AM/PM indicator
    let endtimeParts = this.eventFormGroup.get('EventEndTime')?.value.split(':'); // Split by space or colon
    let endstrtHours = parseInt(endtimeParts[0]);
    let endstartMinutes = parseInt(endtimeParts[1]);

    //set event start hours and minutes
    evntEndDate.setHours(endstrtHours);
    evntEndDate.setMinutes(endstartMinutes);

    // === Extract and format start time END
    

    //this should be extracted from a app_global selection (which is based on the country selection the user defaults to or select) 
    let timezone = 'Africa/Johannesburg';
    
    // this.eventModel!.EventStartDateTime = this.datePipe.transform(evntStDate, 'yyyy-MM-dd')
    this.eventModel!.EventStartDateTime = moment(evntStDate).tz(timezone).toString()
    
    // this.eventModel!.EventEndDataTime = this.datePipe.transform(evntEndDate, 'yyyy-MM-dd')
    this.eventModel!.EventEndDataTime = moment(evntEndDate).tz(timezone).toString();
    
    this.eventModel!.UserId = this.eventFormGroup.get('UserId')?.value
    this.eventModel!.Name = this.eventFormGroup.get('Name')?.value
    this.eventModel!.Description = this.eventFormGroup.get('Description')?.value
    this.eventModel!.PricePerPerson = this.eventFormGroup.get('PricePerPerson')?.value
    this.eventModel!.MaxNumberOfInviteesForEvent = this.eventFormGroup.get('MaxNumberOfInviteesForEvent')?.value
    
    this.eventModel!.Images = this.selFiles.toString();
    this.eventModel!.DefaultImage = this.selFiles[0];
  
    this.eventModel!.Status = this.determineEventStatus()

    if(!environment.production)
    {
      console.log(`Event model: ${JSON.stringify(this.eventModel)}`);
    }
    
    let eventID = 0;

    if(!environment.production)
    {
      console.log(`Address to save: ${JSON.stringify(this.eventAddr)}`);
    }

  
    // //add event observer
    // const eventAddObserver = {
    //   next: async (data: any) => {
    //     if(!environment.production)
    //     {
    //       console.log(`Add Event Response: ${JSON.stringify(data)}`);
    //     }
        
    //     if(!environment.production)
    //     {
    //       console.log(`event Id: ${eventID}`);
    //     }

    //     //redirect to the list of events
    //     alert("Done adding event")
    //   },
    //   error: (err: HttpErrorResponse) => console.log(err),
    //   complete: () =>{
    //     if(!environment.production)
    //     {
    //       console.log("Add activity COMPLETE");
    //     }

    //     //alert user that the add was successful (check out SWAL)
    //     // this.router.navigate(['eves'])
    //     this.router.navigate(['eventsperstatus']);
    //   }
    // }

    //address observer
    const addrObserver: Partial<Observer<any>> = {
      next: async (data: any) =>  {
        if(!environment.production)
        {
          console.log("location added with result:" + JSON.stringify(data));
        }
  
        //get the newly added event ID
        eventID = await data?.split('|')[0].trim() ?? 1;
  
        //assign the new ID to the AddressId
        this.eventModel!.AddressId = eventID;
  
        // send data to the server
        this.store.dispatch(EventActions.ADD_EVENT({maixevent: this.eventModel}))

        // send data to the server
        // this.mxEventService.AddEvent(this.eventModel)
        // .subscribe(eventAddObserver); 
        this.router.navigate(['eventsperstatus']);
      },
      error: (err: HttpErrorResponse) => {
        console.log(err)
      },
      complete: () => {
        if(!environment.production)
        {
          console.log(`Event add COMPLETED with response`);
        }
      }
    }

    if(!environment.production)
    {
      console.log(JSON.stringify(this.eventAddr))
    };

    //add address
    this.addrService.AddAddressToAnActivity(this.eventAddr)
    .subscribe(addrObserver);
  
  }
  //ends
  
} 

//Needs work
export function endTimeValidator(startControlName: string): any {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const startControl = control.parent?.get(startControlName);
    const startTime = startControl?.value;
    const endTime = control.value;

    if (!startTime || !endTime) {
      return null; // No validation if either start or end time is not set
    }

    if (endTime < startTime) {
      return { 'endTimeInvalid': true }; // Return an error if end time is earlier than start time
    }

    return null; // No error, validation passes
  };
}

