import { createAction, props } from "@ngrx/store";
import { MaiXEvent } from "src/app/types/models/maixevent.model";

// live event
export const LOAD_LIVE_EVENTS = createAction('[MaiXLiveEvents] Load Live Events',
    props<{ statusCode: number, pageNumber: number, pageSize: number }>());

export const LOAD_LIVE_EVENTS_SUCCESS = createAction('[MaiXLiveEvents] Load Live Events Success',
    props<{liveMaixevents: MaiXEvent[]}>());

export const LOAD_LIVE_EVENTS_FAIL = createAction('[MaiXLiveEvents] Load Live Events Fail',
    props<{error: string}>());