import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, tap } from "rxjs";
// import { ActivityService } from "src/app/shared/services/activity.service";
import { LOAD_ACTIVITIES, LOAD_ACTIVITIES_FAIL, LOAD_ACTIVITIES_SUCCESS } from "../actions/activity.actions";
import { Activity } from "src/app/types/models/activity.model";
import { LOAD_FEATURED_ACTIVITIES, LOAD_FEATURED_ACTIVITIES_FAIL, LOAD_FEATURED_ACTIVITIES_SUCCESS } from "../actions/featuredActivity.actions";
import { ActivityService } from "src/app/shared/services/activity.service";

@Injectable()
export class FeaturedActivityEffects {

    pageNumber: number = 1;
    pageSize: number = 2;

    loadFeaturedActivities$ = createEffect(() => this.actions$.pipe(
        ofType(LOAD_FEATURED_ACTIVITIES),
        mergeMap(() => this.activityService.getFeaturedActivities(2)
        .pipe(
            tap((featuredActivities: Activity[]) => {
                console.log('Featured activities  loaded successfully:', featuredActivities);
            }),
            map(featuredActivities => LOAD_FEATURED_ACTIVITIES_SUCCESS({ featuredActivities })),
            catchError(error => of(LOAD_FEATURED_ACTIVITIES_FAIL({ error })))
        ))
        )
    );

    constructor(
        private actions$: Actions,
        private activityService: ActivityService
    ) {}
}