import { createReducer, on } from "@ngrx/store";
import { ActivityIAmPromotingState } from "../state/activities-I-Am-Promoting.state";
import * as ActivityPromotingActions from "../actions/activity-i-am-promoting.actions";


const initialState: ActivityIAmPromotingState = {
    activitiesiampromoting: [],
    loading: false,
    loaded: false,
    error: null,
};

const _activitiesIAmPromotingReducer = createReducer(
    initialState,
    on(ActivityPromotingActions.LOAD_ACTIVITIES_I_AM_PROMOTING, state => ({ ...state, loading: true })),
    on(ActivityPromotingActions.LOAD_ACTIVITIES_I_AM_PROMOTING_SUCCESS, (state, { activitiesiampromoting }) => ({
        ...state,
        activitiesiampromoting,
        loading: false,
        loaded: true,
        error: null,
    })),
    on(ActivityPromotingActions.LOAD_ACTIVITIES_I_AM_PROMOTING_FAIL, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error,
    }))
);

export function activitiesIAmPromotingReducer(state: any, action: any) {
    return _activitiesIAmPromotingReducer(state, action);
}