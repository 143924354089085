import { EventStatus } from "../enums/eventStatus.enum";

export class MaiXEvent{
  Id: number | undefined;
  UserId: string;
  Name: string | undefined;
  Description: string | undefined;
  AddressId: number | undefined;
  PricePerPerson: number | undefined;
  MaxNumberOfInviteesForEvent: number | undefined;
  Images: string | undefined;
  DefaultImage: string | undefined; 
  EventStartDateTime: string;
  EventEndDataTime: string;
  CreatedDate?: Date | undefined;
  Status?: EventStatus | undefined;
  ModifiedDate?: Date | undefined

  constructor(
  Id: number | undefined,
  UserId: string,
  Name: string | undefined,
  Description: string | undefined,
  AddressId: number | undefined,
  PricePerPerson: number | undefined,
  MaxNumberOfInviteesForEvent: number | undefined,
  Images: string | undefined,
  DefaultImage: string | undefined,
  EventStartDateTime: string,
  EventEndDataTime:string,
  CreatedDate?: Date | undefined,
  Status?: EventStatus | undefined,
  ModifiedDate?: Date | undefined //optional
  ) 
  {
    this.Id = Id;
    this.UserId = UserId;
    this.Name = Name;
    this.Description = Description;
    this.AddressId = AddressId;
    this.PricePerPerson = PricePerPerson;
    this.MaxNumberOfInviteesForEvent = MaxNumberOfInviteesForEvent;
    this.Images = Images
    this.DefaultImage = DefaultImage
    this.EventStartDateTime = EventStartDateTime
    this.EventEndDataTime = EventEndDataTime
    this.CreatedDate = CreatedDate
    this.Status = Status
    this.ModifiedDate = ModifiedDate
  }
}