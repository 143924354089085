import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ActivityService } from 'src/app/shared/services/activity.service';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { AddressService } from 'src/app/shared/services/address.service';
import { ActivityStatus } from 'src/app/types/enums/activityStatus.enum';
import { AddressModel } from 'src/app/types/models/addressModel.model';
import { UIError } from 'src/app/types/models/uiError.model';
import { LoaderService } from 'src/app/shared/utils/loader.service';
import { AppState } from 'src/app/ngrx/state/app.state';
import { Store } from '@ngrx/store';
import { GET_ACTIVITIES_BY_USERID, GET_ACTIVITY_BY_ID } from 'src/app/ngrx/actions/activity.actions';
import { GET_ACTIVE_ACTIVITIES } from 'src/app/ngrx/actions/active-activity.actions';

@Component({
  selector: 'app-edti-activity-dialog',
  templateUrl: './edti-activity-dialog.component.html',
  styleUrls: ['./edti-activity-dialog.component.scss']
})
export class EdtiActivityDialogComponent  {
  defaultImage: string = ''
//===============================================
  //UI Error handlers
  ActivityNameErroHandler: UIError = new UIError();

  //is production environment
  isProdEnv: boolean = false;

  //
  selFiles: string[] = [];

  //Panels
  activityDetailsOpenState = true; //panel 1
  activityImagesOpenState = false; //panel 2
  activityLocationOpenState = false; //panel 3
  preferencesPanelOpenStateAngMat =  false; //panel 4

  //state tracking variables
  prevStateOfSelectedActivityModel : any;
  selectedActivityModel: any;

  // state tracking variables ends
  activityStatuses = Object.values(ActivityStatus).filter((key:any) => 
  !isNaN(Number(ActivityStatus[key]))) as string[];

  selectedStatus: ActivityStatus | any;

  activityForm: FormGroup | any;

  updateHappened: boolean = false;

  //Location related
  selectedActvtyAddr: any
  
  activityAddr: AddressModel = {
    Id: 0,
    Street_number: '',
    Street: '',
    Suburb: '',
    City: '',
    State: '',
    IsDefault: false,
    Building_name: '',
    Unit_number: '',
    Address_instruction: '',
    Country: '',
    Postal_code: '',
    Longitude: '',
    Latitude: '',
    CreatedAt: null,
    ModifiedAt: null
  }

  formattedAddrString: string = ''

  rtImgPath: string = environment.fileRetriever;
  imgSelectMaxSize = environment.maxImageSizeMB;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    private activityService: ActivityService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<EdtiActivityDialogComponent>,
    private lociService: AddressService,
    private store: Store<AppState>,
    private loadingService: LoaderService,
    private dialogSvc: DialogService) 
  { 
    //deepClone the state objects to be able to keep track of changes/delta
    this.prevStateOfSelectedActivityModel = this.deepClone(data);
    this.selectedActivityModel = this.deepClone(data);
    
    if(!environment.production)
    {
      //log incoming model for visibility
      console.log(`Edit selected activity dialog: ${JSON.stringify(this.selectedActivityModel)}`);
    }

    //split and assign images of the selected activity into comma separated values
    this.selFiles = this.selectedActivityModel?.Images.split(',')
    this.defaultImage = this.selectedActivityModel?.DefaultImage;

    if(!environment.production)
    {
      //log incoming model for visibility
      console.log(`Selected Files: ${JSON.stringify(this.selFiles)}`);
    }


    //for the panel notifier
    if(environment.production)
    {
      this.isProdEnv = true;
    }

    this.activityForm = this.formBuilder.group({
      Id: [this.selectedActivityModel.Id],
      
      Name: [
        this.selectedActivityModel.Name, 
        Validators.required],
      
      Description: [
        this.selectedActivityModel.Description, 
        Validators.required],
      
      PricePerPerson: [
        this.selectedActivityModel.PricePerPerson, 
        [Validators.required, Validators.min(1)]],
      
      Status: [
        this.getKeyByValue(ActivityStatus, this.selectedActivityModel.Status), 
        Validators.required]
    });
    
    //confirm that the form is pre-prepped
    if(!environment.production)
    {
      console.log(this.activityForm.value)
    }
  }

  deepClone(obj: any): any {
    // Custom deep cloning logic
    return JSON.parse(JSON.stringify(obj));
  }
  
  getKeyByValue(enumObject: any, enumValue: number): string | undefined {
    const keys = Object.keys(enumObject).filter((key) => enumObject[key] === enumValue);
    return keys.length > 0 ? keys[0] : undefined;
  }

  getValueByKey(enumObject: any, key: string): number | undefined {
    if (key in enumObject) {
      return enumObject[key];
    }
    return undefined;
  }


  ngOnInit()
  {
    this.getAddressByIdAnUpdatePageIfNecessary(this.selectedActivityModel.AddressId ?? 0)
  }


  onStatusChange(event: any): void {
    const selectedStatus: string = event.value;
    // Handle the selected status, perform actions based on the selected value
    console.log('Selected Status:', selectedStatus);
  }

  BindChangeToActivityModel(incStatusUpdate: any)
  {
    //bind the selection 
    this.selectedActivityModel.Status = this.getValueByKey(ActivityStatus, incStatusUpdate);

    if(!environment.production)
    {
      console.log('Selected Status:', incStatusUpdate)
      // console.log('Selected Status:', incUpdate);
      // console.log('Decrypted:', this.selectedActivityModel.Status);
    }
    
    // this.selectedActivityModel.Status = ActivityStatus[incUpdate as keyof typeof ActivityStatus];
    // update status 
    // this.selectedActivityModel.Status = this.activityForm.Status
  }

  onAppendFilesUploaded(fileNames: string[])
  {
    console.log('Appended files:', fileNames);

    this.selFiles = [...this.selFiles, ...fileNames];

    //update the model tracker
    this.selectedActivityModel.Images = this.selFiles.join(',');

    if(!environment.production)
    {
      console.log(this.selFiles); // Updated array after appending
    }
  }

  onFilesUploaded(fileNames: string[]): void {

    this.selFiles = fileNames;

    //update the model tracker
    this.selectedActivityModel.Images = this.selFiles.join(',');

    if(!environment.production)
    {
      console.log('Uploaded files from edit activity:', fileNames);
    }
  }


  removeSelectedImage(element: any){
    this.selFiles = this.selFiles.filter(item => item !== element);

    this.selectedActivityModel.Images = this.selFiles.join(',');

    if(!environment.production)
    {
      console.log(this.selFiles); // Updated array after removal
    }
  }

  onSearchChange(searchValue: any): void {  
    if(!environment.production)
    {
      console.log(searchValue.target.value);
    }
    //if the checks pass
    this.ActivityNameErroHandler.status = "valid-feedback";
    this.ActivityNameErroHandler.message = "Valid Name"
  }

  simpleAlert(msg: string)
  {  
    Swal.fire(msg);  
  }  

  validatePreferences(): boolean
  {
    // console.log(`Promotion status: ${this.activityModel.EnablePromotion} And ${this.activityModel.ActivityPromotionPercentage}.`)
    // console.log(`Promotion is a number: ${!isNaN(this.activityModel.ActivityPromotionPercentage)}.`)
    // return false;

    if(this.selectedActivityModel.EnablePromotion)
    {
      if(this.selectedActivityModel.ActivityPromotionPercentage > 4 &&
        !isNaN(this.selectedActivityModel.ActivityPromotionPercentage)) 
      {
        return true
      }
      else {
        return false
      }
    } 
    else {
      return true;
    }
  }

  UpdateActivityDetails()
  {
    if(this.validatePreferences())
    {
      // this.loadingService.show()

      if(!environment.production)
      {
        console.log(`Activity to update: ${JSON.stringify(this.selectedActivityModel)}`);
      }

      //if the previous status was active(0) [AND] then was changed to Inactive(1) or Disabled(2)
      if(
        (this.prevStateOfSelectedActivityModel.Status == 0) && 
        this.selectedActivityModel.Status == 1 || this.selectedActivityModel.Status == 2)
      {
        if(confirm("I accept that setting the activity status to inactive state will remove the item from public view."))
        {
          this.loadingService.show()
          this.innerWorkingsOfUpdateActivity()
        }
      }
      else{
        this.innerWorkingsOfUpdateActivity();
      }
    }
    else {
      this.toastr.error("Enter a valid promotion percentage and try again")
    }
  }

  activeActivityStatus: number = 0;

  pageNumber: number = 1;
  pageSize: number = 100;
  
  innerWorkingsOfUpdateActivity()
  {
    if(!environment.production)
    {
      console.log(`Default Image ??: ${this.selectedActivityModel?.DefaultImage}`);
      console.log(`Activity to be updated: ${JSON.stringify(this.selectedActivityModel)}`);
    }

    this.updateHappened = true;

    //update activity observer
    const updateActivityObserver = {
      next: async (resp: any) =>  {
        if(!environment.production)
        {
          console.log(`Activity update result: ${JSON.stringify(resp)}`);
        }

        //reset change trackings by getting the updated model
        this.prevStateOfSelectedActivityModel =  this.deepClone(this.selectedActivityModel);

        if(!environment.production)
        {
          console.log(`Reset state of activity update: ${JSON.stringify(this.prevStateOfSelectedActivityModel)}`);
        }

        // UpdateActivityDetails
        if(resp.status == 200) //all went well
        {

        }

        //in effort to load the update back in time
        this.store.dispatch(GET_ACTIVE_ACTIVITIES({ statusCode: this.activeActivityStatus, pageNumber: this.pageNumber, pageSize: this.pageSize }));
        // this.store.dispatch(GET_ACTIVITY_BY_ID({ activityId: this.selectedActivityModel.Id }));
        
        this.toastr.success("Activity Updated Successfully !", "Update success")
        
      },
      error: (err: HttpErrorResponse) => {
        console.log(`Error: ${JSON.stringify(err)}`)
      },
      complete: () =>{
        this.loadingService.hide();

        if(!environment.production)
        {
          console.log("Update activity COMPLETE");
        }

        this.simpleAlert("Update successful !");
        //alert user that the add was successful (check out SWAL)
        // this.router.navigate(['activitylist'])
      }

    }

    this.activityService.UpdateActivity(this.selectedActivityModel)
    .subscribe(updateActivityObserver);
  }

  //get the nth occurrence in a string
  getPosition(str: string, subStr: string, occurrence: number) {
    return str?.toString().split(subStr, occurrence).join(subStr).length;
  }

  ValidateActivityDetailsAndProceedToImagesPanel()
  {
    if(!environment.production)
    {
      console.log(`Activity form values: ${JSON.stringify(this.activityForm.value)}`);
    }
    
    if (this.activityForm.valid)
    {
      //open the next panel
      this.activityDetailsOpenState = false; //panel 1
      this.activityImagesOpenState = true; //panel 2
      this.activityLocationOpenState = false; //panel 3
      this.preferencesPanelOpenStateAngMat = false //panel 4
    }
  }

  ValidatImagesPanelAndRevertBackToActivityDetailsPanel()
  {
    if (this.activityForm.valid)
    {
      //open the next panel
      this.activityDetailsOpenState = true; //panel 1
      this.activityImagesOpenState = false; //panel 2
      this.activityLocationOpenState = false; //panel 3
      this.preferencesPanelOpenStateAngMat = false //panel 4
    }
  }

  ValidatImagesPanelAndProceedToLocationPanel()
  {
    if (this.activityForm.valid)
    {
      //open the next panel
      this.activityDetailsOpenState = false; //panel 1
      this.activityImagesOpenState = false; //panel 2
      this.activityLocationOpenState = true; //panel 3
      this.preferencesPanelOpenStateAngMat = false //panel 4
    }
  }

  ValidatLocationPanelAndRevertBackToImagesPanel()
  {
    if (this.activityForm.valid)
    {
      //open the next panel
      this.activityDetailsOpenState = false; //panel 1
      this.activityImagesOpenState = true; //panel 2
      this.activityLocationOpenState = false; //panel 3
      this.preferencesPanelOpenStateAngMat = false //panel 4
    }
  }

  ValidatLocationAndProceedToPreferences()
  {
    if (this.activityForm.valid)
    {
      //open the next panel
      this.activityDetailsOpenState = false; //panel 1
      this.activityImagesOpenState = false; //panel 2
      this.activityLocationOpenState = false; //panel 3
      this.preferencesPanelOpenStateAngMat = true //panel 4
    }
  }


  ValidatPreferencesSettingsAndRevertBackToLocation()
  {
    if (this.activityForm.valid)
    {
      //open the next panel
      this.activityDetailsOpenState = false; //panel 1
      this.activityImagesOpenState = false; //panel 2
      this.activityLocationOpenState = true; //panel 3
      this.preferencesPanelOpenStateAngMat = false //panel 4
    }
  }



  toggleAppendPromotion(){
    console.log(`promotion toggle clicked`)
    this.selectedActivityModel.EnablePromotion = !this.selectedActivityModel.EnablePromotion
  }

  dummyClick()
  {
    alert("button cliecked")
  }

  confirmAndClose()
  {
  
    // these checks don't have any significance anymore.
    // the code below should be sufficient to proceed but leaving the current implementation for a little longer for testing
    // this.dialogRef.close('');
    if
    (
      (this.prevStateOfSelectedActivityModel.Images !== this.selectedActivityModel.Images)
        &&
      (!this.updateHappened)
    )
    {
      if(confirm("Seems there is an update outstanding. Are you sure you want to close the modal ?"))
      {
        console.log("about to close");
        this.dialogRef.close("noaction");
      }
    }
    else if
    (
      (this.prevStateOfSelectedActivityModel.Images !== this.selectedActivityModel.Images)
      ||
      (this.updateHappened) //update to the image or any form of update
    )
    {
      this.dialogRef.close("refresh");
    }
    else{
      this.dialogRef.close("noactionDefault");
    }
  }

  formatteraddressAndSetString()
  {
    //
    this.activityAddr.Street_number = this.selectedActvtyAddr.Street_number ?? "";
    this.activityAddr.Street = this.selectedActvtyAddr.Street ?? "";
    this.activityAddr.Suburb = this.selectedActvtyAddr.Suburb ?? "";
    this.activityAddr.City = this.selectedActvtyAddr.City ?? "";
    this.activityAddr.State = this.selectedActvtyAddr.State ?? "";
    this.activityAddr.Building_name = this.selectedActvtyAddr.Building_name ?? "";
    this.activityAddr.Unit_number =  this.selectedActvtyAddr.Unit_number ?? "";
    this.activityAddr.Address_instruction = this.selectedActvtyAddr.Address_instruction ?? "";
    this.activityAddr.Country = this.selectedActvtyAddr.Country ?? "";
    this.activityAddr.Postal_code = this.selectedActvtyAddr.Postal_code ?? "";
    this.activityAddr.Longitude = this.selectedActvtyAddr.Longitude ?? "";
    this.activityAddr.Latitude = this.selectedActvtyAddr.Latitude ?? "";
    this.activityAddr.CreatedAt = this.selectedActvtyAddr.CreatedAt ?? null;
    this.activityAddr.ModifiedAt = this.selectedActvtyAddr.ModifiedAt ?? null;

    console.log(`Address formatted: ${JSON.stringify(this.activityAddr)}`);
    
    //set string
    if(this.selectedActvtyAddr != undefined)
    {
      this.formattedAddrString = this.selectedActvtyAddr?.Street_number + ' ' +
      this.selectedActvtyAddr?.Street  + ' ' +
      this.selectedActvtyAddr?.Suburb  + ' ' +
      this.selectedActvtyAddr?.City  + ' ' +
      this.selectedActvtyAddr?.State  + ' ' +
      this.selectedActvtyAddr?.Country  + ' ' +
      this.selectedActvtyAddr?.Postal_code;
    }
  }

  getAddressByIdAnUpdatePageIfNecessary(addrId: number)
  {
    if(this.selectedActivityModel && this.selectedActivityModel.AddressId > 0)
      {
        //get the location of the activity (It must have a location if it is visible)
        this.lociService.GetAddressByAddressId(this.selectedActivityModel.AddressId)
        .subscribe((data: any) => {
          
          this.selectedActvtyAddr = data;

          if(!environment.production)
          {
            console.log(`address from getAddressByIdAnUpdatePageIfNecessary method: ${JSON.stringify(this.selectedActvtyAddr)}`)
          }
  
          this.formatteraddressAndSetString();
        })
      }
  }

  editAddressInfo()
  {
    this.dialogRef.close('');

    //open dialog to update address and harvest the result
    this.dialogSvc.SetAddressDialog(this.selectedActivityModel)
      .afterClosed()
      .subscribe(addressId => {
        if (addressId) {
          if(!environment.production)
          {
            console.log('Dialog result (should return address ID):', addressId);
          }
          
          this.selectedActivityModel.AddressId = addressId;
          
           //open the previous edit back up
          this.dialogSvc.EditSelectedActivityDialog(this.selectedActivityModel)
      

          this.innerWorkingsOfUpdateActivity();

          // ??
          this.getAddressByIdAnUpdatePageIfNecessary(addressId);
        
        } 
        else {
          // Handle the case where the dialog is closed without any result
          console.log('Dialog closed without result');
        }
      });
    }
  }
