import { ActivityPromotionAcceptanceStatus } from '../enums/activityPromotionAcceptanceStatus.enum';

export class ActivityPromotersLog
{
  // Id: number;
  // ActivityId: number;
  // PromoterId: string;
  // AcceptanceStatus: ActivityPromotionAcceptanceStatus;
  // IsActivityLogRemoved: boolean;
  // ActivityCreationDateTime: string;
  // ActivityStatusLastUpdatedDateTime: string;
  // UserUpdating?: string;

  constructor(
    public Id: number,
    public ActivityId: number,
    public PromoterId: string,
    public AcceptanceStatus: ActivityPromotionAcceptanceStatus,
    public IsActivityLogRemoved: boolean,
    public ActivityCreationDateTime: string,
    public ActivityStatusLastUpdatedDateTime: string,
    public UserUpdating: string //optional
  ) {}
  
    // // Call the parent class constructor using super()
  //   this.Id = Id;
  //   this.ActivityId = ActivityId;
  //   this.PromoterId = PromoterId;
  //   this.AcceptanceStatus = AcceptanceStatus;
  //   this.AcceptanceStatus = AcceptanceStatus;
  //   this.IsActivityLogRemoved = IsActivityLogRemoved;
  //   this.ActivityCreationDateTime = ActivityCreationDateTime;
  //   this.ActivityStatusLastUpdatedDateTime = ActivityStatusLastUpdatedDateTime
  //   this.UserUpdating = UserUpdating
  // }
}