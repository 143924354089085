
<div 
style="margin-top: 150px;">
  
  <div 
    *ngFor="let progressInfo of progressInfos" 
    class="mb-2">

    <span>{{ progressInfo.fileName }}</span>
    
    <div class="progress">
      <div
        class="progress-bar progress-bar-info progress-bar-striped"
        role="progressbar"
        attr.aria-valuenow="{{ progressInfo.value }}"
        aria-valuemin="0"
        aria-valuemax="100"
        [ngStyle]="{ width: progressInfo.value + '%' }"
      >
        {{ progressInfo.value }}%
      </div>
    </div>
  </div>
  
  <div class="row">
    <div class="col-8">
      <label class="btn btn-default p-0">
        <input type="file" accept="image/*" multiple (change)="selectFiles($event)" />
      </label>
    </div>
  
    <div class="col-4">
      <button
        class="btn btn-success btn-sm"
        [disabled]="!selectedFiles"
        (click)="uploadFiles()"
      >
        Upload
      </button>
    </div>
  </div>
  
  <div>
      <img *ngFor='let preview of previews' [src]="preview" class="preview">
  </div>
  
  <div *ngIf="message.length" class="alert alert-secondary my-3" role="alert">
    <ul *ngFor="let msg of message; let i = index">
      <li>{{ msg }}</li>
    </ul>
  </div>
  
  <div class="card mt-3">
    <div class="card-header">List of Images</div>
    <ul
      class="list-group list-group-flush"
      *ngFor="let image of imageInfos | async"
    >
      <li class="list-group-item">
        <p><a href="{{ image.url }}">{{ image.name }}</a></p>
        <img src="{{ image.url }}" alt="{{ image.name }}" height="80px" />
      </li>
    </ul>
  </div>
</div>