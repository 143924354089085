export class SubscriptionTier
{
    constructor( 
      public Id: number,
      public TierName: string,
      public MaxActivitiesPerMonth: number,
      public MaxEventsPerMonth: number,
      public MaxActivityPromoters: number,
      public MaxEventPromoters: number,
      public PSTPlanId: number,
      public PSTPlanCode: string,
      public Price: number,  
      public CreatedAt: string,
      public AdditionalFeaturesCSV?: string,
      ){} 
}