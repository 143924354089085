import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription, take } from 'rxjs';
import { LOAD_ACTIVITIES_FOR_ONE_USER } from 'src/app/ngrx/actions/current-user-activities.actions';
import { CurrenUserActivitiesError, selectUserActivities, selectUserActivitiesLoaded, selectUserActivitiesLoading } from 'src/app/ngrx/selectors/current-user-activities.selectors';
import { AppState } from 'src/app/ngrx/state/app.state';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { MEEntity } from 'src/app/types/enums/meEntity.enum';
import { Activity } from 'src/app/types/models/activity.model';
import { Booking } from 'src/app/types/models/booking.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-activity-host-dashboard',
  templateUrl: './activity-host-dashboard.component.html',
  styleUrls: ['./activity-host-dashboard.component.scss']
})
export class ActivityHostDashboardComponent {
  // GetAllActivitiesForAUserWithPagination

  mEEntity: MEEntity = MEEntity.Activity;
  entityId: number = 1; //hardcoding to 1 because it not important here

  private useIdSubject = new BehaviorSubject<string | null>('');
  userId$: Observable<string | null> = this.useIdSubject.asObservable();

  private subscriptions: Subscription[] = [];

  allActivitiesUserCreated$!: Observable<Activity[]>;
  allActivitiesUserCreatedLoading$!: Observable<boolean>;
  allActivitiesUserCreatedLoaded$!: Observable<boolean>;
  allActivitiesUserCreatedError$!: Observable<string | null>;

  totalBookingsCount$!: Observable<Booking[]>;
  totalBookingsCountLoading$!: Observable<boolean>;
  totalBookingsCountLoaded$!: Observable<boolean>;
  totalBookingsCountError$!: Observable<string | null>;

  constructor(
    private router: Router,
    private authSvc: AuthService, 
    private store: Store<AppState>) 
  {
    // get the current user activities from store
    this.allActivitiesUserCreated$ = this.store.pipe(select(selectUserActivities))
    this.allActivitiesUserCreatedLoading$ = this.store.pipe(select(selectUserActivitiesLoading))
    this.allActivitiesUserCreatedLoaded$ = this.store.pipe(select(selectUserActivitiesLoaded))
    this.allActivitiesUserCreatedError$ = this.store.pipe(select(CurrenUserActivitiesError))

    //get the total number of booking for this specific user
    
  }

  ngOnInit() {
    //get logged user ID
    this.authSvc.GetCurrentlyLoggedInUserIdIfAny()?.subscribe({
      next: (response: string) => {
        // this.useId = response;
        this.useIdSubject.next(response);

        if (!environment.production) {
          console.log(`About to dispatch current user roles`);
        }
       

      this.allActivitiesUserCreated$
      .pipe(take(1))
        .subscribe(allActivitiesUserCreated => {
          if (allActivitiesUserCreated.length === 0) {
            if (!environment.production) {
              console.log(`Currently logged in user from activity host: ${this.useIdSubject.getValue()!}`);
            }
            
            // Dispatch events for the user only once
            this.store.dispatch(LOAD_ACTIVITIES_FOR_ONE_USER({ userId: this.useIdSubject.getValue()! }));
          }
        });
        
        // dispatch the current user activities if nothing is in the store
        // this.allActivitiesUserCreated$.subscribe(allActivitiesUserCreated => {
        //   if(allActivitiesUserCreated.length === 0) {

        //     if(!environment.production)
        //     {
        //       console.log(`Currently logged in user from activity host: ${this.useIdSubject.getValue()!}`)
        //     }
            
        //     this.store.dispatch(LOAD_ACTIVITIES_FOR_ONE_USER({userId : this.useIdSubject.getValue()!}))
        //   }
        // })

      },
      error: (err: any) => {
        if(!environment.production)
        {
          console.log("Unable to get logged in user: " + err);
        }
      },
      complete() {
        if(!environment.production)
        {
          console.log("Complete");
        }
      }
    });

    
  }

  viewAllActivitiesCreated()
  {
    this.router.navigate(['hostactivitylist']);
  }

  ngOnDestroy() {
    // this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
