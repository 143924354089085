<h2 mat-dialog-title style="align-items: center;">Share with Friends </h2>
<mat-dialog-content>
    <div class="url-container">
        <h3 class="url-header">Event URL</h3>
        <div class="url-content">
        <p class="url-text">{{ url }} </p>
        <button mat-icon-button class="copy-button" (click)="copyUrl()">
          <mat-icon>content_copy</mat-icon>
        </button>
        </div>
    </div>
    <div class="share-buttons">
        <a class="share-button whatsapp" [href]="'https://api.whatsapp.com/send?text=' + getEncodedUrl()" target="_blank">
            <i class="bi bi-whatsapp"></i>
        </a>
        <a class="share-button twitter" [href]="'https://twitter.com/intent/tweet?url=' + getEncodedUrl()" target="_blank">
            <i class="bi bi-twitter"></i>
        </a>
        <a class="share-button instagram" [href]="'https://www.instagram.com/?url=' + getEncodedUrl()" target="_blank">
            <i class="bi bi-instagram"></i>
        </a>
        <a class="share-button facebook" [href]="'https://www.facebook.com/sharer/sharer.php?u=' + getEncodedUrl()" target="_blank">
            <i class="bi bi-facebook"></i>
        </a>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
