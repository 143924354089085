import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { UserSpecificService } from 'src/app/shared/services/dataService/user-specific.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { GenericRole } from 'src/app/types/models/genericrole.model';
import { CustomRoleChange } from 'src/app/types/models/customrolechange.model';
import { LoaderService } from 'src/app/shared/utils/loader.service';
import { map, Observable } from 'rxjs';
import { AppState } from 'src/app/ngrx/state/app.state';
import { Store } from '@ngrx/store';
import { selectLoggedInUserRoles } from 'src/app/ngrx/selectors/current-user-roles.selectors';
import { GET_CURRENT_USER_ROLES } from 'src/app/ngrx/actions/current-user-roles.actions';

@Component({
  selector: 'app-user-role',
  templateUrl: './user-role.component.html',
  styleUrls: ['./user-role.component.scss']
})
export class UserRoleComponent {

  //roles of the currently logged in user
  assignedUserRoles: any

  isGenericUser$!: Observable<boolean>;
  isActivityHostUser$!: Observable<boolean>;
  isActivityPromoterUser$!: Observable<boolean>;
  isActivityManagerUser$!: Observable<boolean>;
  isEventHostUser$!: Observable<boolean>; 
  isEventPromoterUser$!: Observable<boolean>;
  
  @Input() usrObjectInp: any;

   //claims of the currently logged in user
  //  assignedUserclaims: any

   //holder for all roles for mxp
  //  allMxpRoles: any;

   roles$!: Observable<string[]>; // Observable for user roles from store
   
  constructor(
    private router: Router,
    private auth: AuthService,
    private dialogSvc: DialogService,
    private fileUploadSvc: FileUploadService,
    private cdRef: ChangeDetectorRef,
    private loaderService: LoaderService,
    private authSvc: AuthService,
    private store: Store<AppState>,
    private userSvc: UserSpecificService,
    private toastr: ToastrService) 
  {
    // Select the roles from the store
    this.roles$ = this.store.select(selectLoggedInUserRoles);
  }

  ngOnInit()
  {
    //1. For roles UI flow
    //a. get all the roles that a logged in user has
    //  this.getAllRolesAssignedToAUser();
    
    //a. Dispatch an action to load roles if needed
    this.store.dispatch(GET_CURRENT_USER_ROLES());

     //  b. all claims the logged in user has
    //  this.authSvc.getAllClaimsAUserHas()?.subscribe({
    //    next: (claims: any) => {
    //      this.assignedUserclaims! = claims;

    //      if(!environment.production)
    //      {
    //        console.log(`All claims of logged in user from Profile page: ${JSON.stringify(this.assignedUserclaims)}`)
    //      }
         
    //    },
    //    error: (error) => {
    //      // Handle the error
    //      console.error('Errorizaton:', error);
    //    },
    //    complete: () => {
    //      // Handle completion if needed
    //      console.log('Complete Shon');
    //    }
    //  })

     //  c. all roles the application holds
    //  this.authSvc.GetAllRolesForMaiXp(environment.AppBoundIdentifier)
    //  .subscribe((data: any) => {

    //    this.allMxpRoles = data.map((role: any) => ({
    //      Name: role.name,
    //      AppBound: role.AppBound,
    //      Id: role.Id,
    //    }));

    //    if(!environment.production)
    //    {
    //      console.log(`All roles For App MaiXp from profile page: ${JSON.stringify(data)}`);
    //      console.log(`Roles Object: ${JSON.stringify(this.allMxpRoles)}`)
    //    }
    //  });

     //===========================================
     // Set up observables to derive flags from roles
    this.isGenericUser$ = this.roles$.pipe(
      map((roles) => roles.includes('mxp_General'))
    );

    this.isActivityHostUser$ = this.roles$.pipe(
      map((roles) => roles.includes('mxp_ActivityHost'))
    );

    this.isActivityPromoterUser$ = this.roles$.pipe(
      map((roles) => roles.includes('mxp_ActivityPromoter'))
    );

    this.isActivityManagerUser$ = this.roles$.pipe(
      map((roles) => roles.includes('mxp_ActivityManager'))
    );

    this.isEventHostUser$ = this.roles$.pipe(
      map((roles) => roles.includes('mxp_EventHost'))
    );

    this.isEventPromoterUser$ = this.roles$.pipe(
      map((roles) => roles.includes('mxp_EventPromoter'))
    );

    // If you need to manually trigger change detection
    this.roles$.subscribe(() => {
      this.cdRef.detectChanges();
    });
  }

  
  ToggleSelectedRole(roleName: string, action: string)
  {
    this.loaderService.show();

    if(!environment.production)
    {
      console.log(`User ID from OBJ: ${this.usrObjectInp?.Id }`)
    }

    //prep backend data
    var genRole = new GenericRole(roleName, environment.AppBoundIdentifier);

    var customRoleChange = new CustomRoleChange(
      this.usrObjectInp?.Id,
      genRole,
      action
    );

    //observer
    const roleChangeObserver = {
      next: async (response: any) => {
        this.loaderService.hide();
        if(!environment.production)
        {
          console.log(`Response from role change operation: ${JSON.stringify(response)}`);
        }
        
        // Dispatch action to get updated roles
        this.store.dispatch(GET_CURRENT_USER_ROLES());
        
        this.toastr.success("Success", `${action} action succeed`);
      },
      error: async (err: HttpErrorResponse) => {
        this.loaderService.hide();

        if(!environment.production)
        {
          console.log(`Error on role change operation: ${JSON.stringify(err)}`);
        }

        this.toastr.error("Error", `${action} action failed`);
      },
      complete: async () => {
        
      }
    }

    this.userSvc.UserRoleManagement(customRoleChange).subscribe(roleChangeObserver);
  }
}
