export class BookingClaim
{
  constructor( 
    public Id: number,  
    public Reference: string,
    public IsIndividualClaim: boolean,
    public IsAggregateClaim: boolean,
    public BookingIds: string,
    public AmountPaid: number,
    public RecipientCode: string,
    public Status: string,
    public CreatedAt: string
  ){} 
}