import { Component } from '@angular/core';
import { CleanerService } from 'src/app/shared/services/cleaner.service';

@Component({
  selector: 'app-cleaner',
  templateUrl: './cleaner.component.html',
  styleUrls: ['./cleaner.component.scss']
})
export class CleanerComponent {
  checkResult: any;

  constructor(private websiteCheckService: CleanerService) {}

  checkWebsite() {
    this.websiteCheckService.checkWebsiteNow().subscribe(
     (result) => {
        this.checkResult = result;
      },
      (error) => {
        this.checkResult = { success: false, error: 'Error contacting server.' };
      }
    );
  }
}
