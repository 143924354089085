import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of, shareReplay, tap } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { Activity } from 'src/app/types/models/activity.model';
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  private cachedData: any;

  private APIUrl: string | undefined; // = 'https://localhost:7032/api/'

  constructor(private http: HttpClient) 
  { 
    this.APIUrl = environment.APIUrl;

    if(!environment.production)
    {
      console.log(`Env: ${environment} || URL: ${environment.APIUrl}`);
    }
    
  }

  //add activity
  AddActivity(newActivity: any)
  {
    
    return this.http.post<any>(
      this.APIUrl + 'Activity/AddActivity', 
      newActivity, 
      this.getHttpOptions());
  }

  //get all activities
  GetAllActivities(): Observable<Activity[]> {
    let url = this.APIUrl + 'Activity/GetAllActivities';

    
    const actvs = this.http.get<Activity[]>(
      url);

    if(!environment.production)
    {
      console.log(`Response from GetAllActivities: ${JSON.stringify(actvs)}`);
    }

    return actvs;
  }


  //get all activities with pagination with knownList if any
  GetAllActivitiesWithPagination(pageNum: number, pageSz: number): Observable<Activity[]> {
    let url = this.APIUrl + `Activity/GetAllActivitiesWithPagination?pageNumber=${pageNum}&pageSize=${pageSz}`;

    
    const actvs = this.http.get<Activity[]>(
      url
    );

    if(!environment.production)
    {
      console.log(`Response from GetAllActivities with pagination: ${JSON.stringify(actvs)}`);
    }

    return actvs;
  }

  GetAllActivitiesForAUserWithPagination(
    userId: string, 
    pageNum: number, 
    pageSz: number): Observable<Activity[]>
  {
    let url = this.APIUrl + `Activity/GetAllActivitiesForAUserWithPagination?userId=${userId}&pageNumber=${pageNum}&pageSize=${pageSz}`;

    const actvs = this.http.get<Activity[]>(
      url
    );

    // if(!environment.production)
    // {
    //   console.log(`Response from GetAllActivitiesForAUser with pagination: ${JSON.stringify(actvs)}`);
    // }

    return actvs;
  }

  GetAllActivitiesByStatusWithPagination(statusCode: number, pageNum: number, pageSz: number) : Observable<Activity[]>
  {
    let url = this.APIUrl + `Activity/GetAllActivitiesByStatusWithPagination?statusCode=${statusCode}&pageNumber=${pageNum}&pageSize=${pageSz}`;

    const actvs = this.http.get<Activity[]>(
      url
    );

    if(!environment.production)
    {
      console.log(`Response from GetAllActivitiesByStatus with pagination: ${JSON.stringify(actvs)}`);
    }

    return actvs;
  }

  GetAllActivitiesThatAreOpenForPromotionWithPaginationWithFilter(
    pageNum: number, 
    pageSz: number,
    countryVariants = "ZA, South Africa"
  ) : Observable<Activity[]>
  {
    let url = this.APIUrl + `Activity/GetAllActivitiesThatAreOpenForPromotionWithPaginationWithFilter?pageNumber=${pageNum}&pageSize=${pageSz}&countryVariants=${countryVariants}`;

    const actvs = this.http.get<Activity[]>(
      url
    );

    if(!environment.production)
    {
      console.log(`Response from Get All Activities ThatAreOpen For Promotion WithPagination WithFilter : ${JSON.stringify(actvs)}`);
    }

    return actvs;
  }


  getFeaturedActivities(count: number): Observable<any[]> {
    let url = this.APIUrl + `Activity/GetFeaturedActivitiesWithCount_Default2/${count}`;

    return this.http.post<any>(
      url,
      null);
  }

  GetAdvertisedEntities(activityId: number, activityId2: number, eventId: number, eventId2: number): Observable<any[]> {
    let url = this.APIUrl + `Assorted/GetAdvertisedEntities/${activityId}/${activityId2}/${eventId}/${eventId2}`;
    return this.http.get<any>(
      url,
      this.getHttpOptions()
    );
  }

  private activitiesCache: any[] = [];
  private activitiesSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  // public activities$: Observable<any[]> = this.activitiesSubject.asObservable();
  public activities$: Observable<any[]> = this.activitiesSubject.asObservable();
  
  // Get activities from the server and cache them using shareReplay operator
  GetAllActivitiesByStatus(statusCode: number): Observable<any[]>
  {
    let url = this.APIUrl + `Activity/GetAllActivitiesByStatus/${statusCode}`;
      var result = this.http.get<any>(
        url,
        this.getHttpOptions());
        
        this.activitiesSubject.next(result);
        
        return result;
  }

   // Clear cache and fetch activities from the server
   refreshActivities(statusCode: number): void {
    let url = this.APIUrl + `Activity/GetAllActivitiesByStatus/${statusCode}`;

    this.activitiesSubject.next(null);

    this.GetAllActivitiesByStatus(0).subscribe(activities => {
      this.activitiesSubject.next(activities);
    });

    // this.http.get<any[]>(url)
    //   .subscribe(activities => {
    //     this.activitiesCache = activities;
    //     this.activitiesSubject.next(activities);
    // });
  }

  //GetActivityById
  GetActivityById(activityId: number)
  {
    return this.http.get<any>(this.APIUrl + `Activity/GetActivityById/${activityId}`, this.getHttpOptions());
  }

  //GetAllActivitiesByUserId
  GetAllActivitiesByUserId(userId: any){
    return this.http.get<any>(this.APIUrl + `Activity/GetAllActivitiesByUserId/${userId}`, this.getHttpOptions());
  }

  //UpdateActivity
  UpdateActivity(actChanges: any)
  {
    return this.http.put<any>(
      this.APIUrl + 'Activity/UpdateActivity', 
      actChanges, 
      this.getHttpOptions()).pipe(
        // Assuming the update API returns the updated activity
        // If not, you might need to fetch the updated activity separately
        tap(() => this.refreshActivities(0))
      );
  }

  //RemoveActivity
  RemoveActivity(activityId: number){
    return this.http.post<any>(
      this.APIUrl + `Activity/RemoveActivity/${activityId}`,
      null,
      this.getHttpOptions());
  }

  getHttpOptions(){

    const httpOptions = {
      
    };

    return httpOptions;
  }

}
