import { createAction, props } from "@ngrx/store";
import { Activity } from "src/app/types/models/activity.model";



//  featured activity actions
export const LOAD_FEATURED_ACTIVITIES = createAction('[Activity] Load Featured Activities');

export const LOAD_FEATURED_ACTIVITIES_SUCCESS = createAction('[Activity] Load Featured Activities Success',
    props<{featuredActivities: Activity[]}>());

export const LOAD_FEATURED_ACTIVITIES_FAIL = createAction('[Activity] Load Featured Activities Fail',
    props<{error: string}>());