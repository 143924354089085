import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { GET_CURRENT_USER_ROLES } from 'src/app/ngrx/actions/current-user-roles.actions';
import { AppState } from 'src/app/ngrx/state/app.state';
import { ActivityService } from 'src/app/shared/services/activity.service';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { environment } from 'src/environments/environment';
import { Activity } from '../../../types/models/activity.model';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/shared/utils/loader.service';
import { GET_ACTIVITIES_BY_USERID } from 'src/app/ngrx/actions/activity.actions';
import { selectActivitiesOfUser, selectActivitiesOfUserError, selectActivitiesOfUserLoaded, selectActivitiesOfUserLoading } from 'src/app/ngrx/selectors/activity.selectors';
import { CommonModule } from '@angular/common';
import { CardComponent } from 'src/app/Templates/card/card.component';

@Component({
  selector: 'app-host-activities-list',
  templateUrl: './host-activities-list.component.html',
  styleUrls: ['./host-activities-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CardComponent
  ],
})
export class HostActivitiesListComponent {

  userId: any;
  rtImgPath: string = environment.fileRetriever;

  private useIdSubject = new BehaviorSubject<string | null>('');
  userId$: Observable<string | null> = this.useIdSubject.asObservable();

  // private hostactivityListSubject = new BehaviorSubject<Activity[] | null>(null);
  // hostactivityList$: Observable<Activity[] | null> = this.hostactivityListSubject.asObservable();
  hostactivityList$: Observable<Activity[]> | undefined;
  hostactivityListLoading$: Observable<boolean> | undefined;
  hostactivityListLoaded$: Observable<boolean> | undefined;
  hostactivityListLoadedError$: Observable<string | null> | undefined;

  showButtonInFirstCard = true;


  constructor(
    private authSvc: AuthService,
    private activitySvc: ActivityService,
    private router: Router,
    private store: Store<AppState>,
    private loadingService: LoaderService
    ) { 
      // call the user from store

      // selectActivitiesOfUser
      
      this.hostactivityList$ = this.store
        .pipe(
          select(selectActivitiesOfUser),
          map((activities: Activity[]) => 
            activities?.filter(activity => activity.Status == 0)
          ));
      this.hostactivityListLoading$ = this.store.pipe(select(selectActivitiesOfUserLoading));
      this.hostactivityListLoaded$ = this.store.pipe(select(selectActivitiesOfUserLoaded));
      this.hostactivityListLoadedError$ = this.store.pipe(select(selectActivitiesOfUserError));
    }
  
  ngOnInit()
  {
    // this.loadingService.show();

    // if no user is in store, dispath and wait for response
    this.authSvc.GetCurrentlyLoggedInUserIdIfAny()?.subscribe({
        next: (response: string) => {
           this.userId = response;
          this.useIdSubject.next(response);
          
          if (!environment.production) {
            console.log(`About to dispatch current user roles`);
         // console.log("this user id " + JSON.stringify(this.useIdSubject.value))
            console.log("this user id 2" + JSON.stringify(this.userId))

          }
          
          // Dispatch action to get user roles if role does not exist
          this.store.dispatch(GET_CURRENT_USER_ROLES());

          this.store.dispatch(GET_ACTIVITIES_BY_USERID({userId: this.userId}))
       
          // this.activitySvc.GetAllActivitiesByUserId(this.userId)?.pipe(
          //   map((activityResponse: any[]) => {
          //     // Filter activities to include only those with status 0
          //     return activityResponse.filter(activity => activity.Status == 0);
          //   })
          // )
          // .subscribe({
          //   next: (activityResponse) => {
          //   //   this.hostactivityList = activityResponse;
          //   this.hostactivityListSubject.next(activityResponse)
          //     if (!environment.production) {
          //       console.log("host activity list " + JSON.stringify(activityResponse));
          //     }
          //   },
          //   error: (activityError) => {
          //     if (!environment.production) {
          //       console.log("Unable to get activities: " + activityError);
          //     }
          //   },
          //   complete: () => {
          //     this.loadingService.hide();
          //   }
          // });


        },
        error: (err: any) => {
          if(!environment.production)
            {
              console.log("Unable to get logged in user: " + err);
            }
        },
        complete: () => {
          // this.loadingService.hide();
            
          if(!environment.production)
            {
              console.log("Complete");
            }
          }
      });

  }


  backToDashboard()
  {
    this.router.navigate(['activityhost']);
  }
}
    