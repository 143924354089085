import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivityPromotersLog } from 'src/app/types/models/activityPromotersLog.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActivityPromoterLogsService {

  private APIUrl: string | undefined; // = 'https://localhost:7032/api/'

  constructor(private http: HttpClient) 
  { 
    this.APIUrl = environment.APIUrl;
    if(!environment.production)
    {
      console.log(`Env: ${environment} || URL: ${environment.APIUrl}`);
    }
  }

    //add new activity promoter log
    CreateActivityPromotersLog(newActivityPromoterLogs: ActivityPromotersLog)
    {
      return this.http.post<any>(
        this.APIUrl + 'ActivityPromotersLog/CreateActivityPromotersLog', 
        newActivityPromoterLogs, 
        this.getHttpOptions());
    }
  

    ViewActivityIamPromotingWithPagination(
      promoterUserId: string, 
      pageNumber: number, 
      pageSize: number): Observable<any[]> {
      let url = this.APIUrl + `ActivityPromotersLog/ViewActivityIamPromotingWithPagination?promoterUserId=${promoterUserId}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
  
      return this.http.get<any>(
        url,
        this.getHttpOptions());
    }

    //get all promoters for an activity
    ViewAllPromotersForActivityWithPagination(
      activityId: number, 
      pageNumber: number, 
      pageSize: number
    ): Observable<any[]> {
      let url = this.APIUrl + `ActivityPromotersLog/ViewActivityPromotersLogThatAreNotRemovedForAnActivityWithPagination?promoterUserId=${activityId}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
  
      return this.http.get<any>(
        url,
        this.getHttpOptions());
    }

    // //get all booking for a user by entitiy id with pagination
    // GetAllBookingsPerEntityByIdWithPagination(
    //   mEEntity: MEEntity,
    //   entityId: number, 
    //   pageNum: number, 
    //   pageSz: number): Observable<Booking[]>
    // {
    //   let url = this.APIUrl + `Booking/GetAllBookingsPerEntityByIdWithPagination?mEEntity=${mEEntity}&entityId=${entityId}&pageNumber=${pageNum}&pageSize=${pageSz}`;
  
    //   const entityBookings = this.http.get<Booking[]>(
    //     url
    //   );
  
    //   return entityBookings;
    // }

   

    // Update activity promoter log
    
    UpdateActivityPromotersLog(activityPromotersLogChanges: ActivityPromotersLog): Observable<any>
    {
      return this.http.put<any>(
        this.APIUrl + `ActivityPromotersLog/UpdateActivityPromotersLog`, 
        activityPromotersLogChanges, 
        this.getHttpOptions());
    }

    RemoveActivityPromotersLog(activityPromotersLog: ActivityPromotersLog) : Observable<any>
    {
      return this.http.post<any>(
        this.APIUrl + `ActivityPromotersLog/RemoveActivityPromotersLog`, 
        activityPromotersLog, 
        this.getHttpOptions());
        
      // return this.http.delete<any>(
      //   this.APIUrl + `ActivityPromotersLog/RemoveActivityPromotersLog`, 
      //   activityPromotersLog, 
      //   this.getHttpOptions());
    }
 

    


    getHttpOptions(){
      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        }),
      };
  
      return httpOptions;
    }
}
