import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TeamService } from 'src/app/shared/services/team.service';

export interface Team {
  Id: number
  Name: string;
  ActivityId: number;
  Creator: any;
}

export interface TeamMember{
  Id: number,
  TeamId: number,
  Member: any
}

@Component({
  selector: 'app-team-list',
  templateUrl: './team-list.component.html',
  styleUrls: ['./team-list.component.scss']
})
export class TeamListComponent {
  actvId: number = 0;

  constructor(
    private teamService: TeamService,
    private route: ActivatedRoute) 
  {

  }

  ngOnInit()
  {
    this.actvId = parseInt(this.route.snapshot.paramMap.get('activityId') ?? "")
  
    console.log(`Activity ID retrieved: ${this.actvId}`);
  }
}
